import GroupsIcon from '@mui/icons-material/Groups';
import SignIn from "layouts/authentication/sign-in";
import Dashboard from "layouts/dashboard";


//import Documents from "layouts/documents";
// import DocumentView from "layouts/documents/DocumentView";
// import Vehicles from "layouts/vehicles";
// import VehicleType from "layouts/VehicleType";
// import VehicleTypeAdd from "layouts/VehicleType/AddVehicle";
// import VehicleTypeEdit from "layouts/VehicleType/EditVehicle";
// import Users from "layouts/users";
// import ViewUser from "layouts/users/ViewUser";
// import Contents from "layouts/contents";
import AddContent from "layouts/contents/AddContent";
import EditContent from "layouts/contents/EditContent";
import ViewContent from "layouts/contents/ViewContent";
// import Rides from "layouts/rides";
// import ViewRide from "layouts/rides/ViewRide";

import Faqs from "layouts/faqs";
import AddFaqs from "layouts/faqs/AddFaqs";
import EditFaq from "layouts/faqs/EditFaq";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
//import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";

import SignUp from "layouts/authentication/sign-up";


import Assets from "layouts/assets";
import AddAsset from "layouts/assets/AddAsset";
import EditAsset from "layouts/assets/EditAsset";
import ViewAsset from "layouts/assets/ViewAsset";
import Interests from "layouts/interests";
import AddInterest from "layouts/interests/AddInterest";
import EditInterest from "layouts/interests/EditInterest";
import ViewInterest from "layouts/interests/ViewInterest";


// import Staff from "layouts/staff";
// import AddAStaff from "layouts/staff/AddStaff";
// import ViewStaff from "layouts/staff/ViewStaff";
// import Editstaff from "layouts/staff/Editstaff";


import IpWhitelist from "layouts/ipwhitelist";

import Filters from "layouts/filters";
import FilterList from "layouts/filters/filterList";
import GuidelinesCategories from "layouts/guidelinesCategories";
import Guidelines from "layouts/guidelines";
import GuidelineAdd from "layouts/guidelines/Addguideline";






// @mui icons
import Icon from "@mui/material/Icon";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Filters",
    key: "filters",
    icon: <Icon fontSize="small">filter_alt</Icon>,
    route: "/filters",
    component: <Filters />,
  },
  {
    type: "route",
    name: "FilterList",
    key: "filterlist",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/filterList",
    component: <FilterList />,
  },
  
  {
    type: "collapse",
    name: "Guideline Categories",
    key: "guidelines-categories",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guidelines-categories",
    component: <GuidelinesCategories />,
  },
  {
    type: "collapse",
    name: "Guidelines",
    key: "guidelines",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guidelines",
    component: <Guidelines />,
  },
  {
    type: "route",
    name: "GuidelineAdd",
    key: "guidelineadd",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guideline-add",
    component: <GuidelineAdd />,
  },
  // {
  //   type: "collapse",
  //   name: "Assets",
  //   key: "assets",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/assets",
  //   component: <Assets />,
  // },
  // {
  //   type: "route",
  //   name: "Add Asset",
  //   key: "addAsset",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/addAsset",
  //   component: <AddAsset />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Asset",
  //   key: "edit-asset",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/edit-asset/:id",
  //   component: <EditAsset />,
  // },
  // {
  //   type: "route",
  //   name: "View Asset",
  //   key: "view-asset",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/view-asset/:id",
  //   component: <ViewAsset />,
  // },
  // {
  //   type: "collapse",
  //   name: "Investment Details",
  //   key: "interests",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/interests",
  //   component: <Interests />,
  // },
  // {
  //   type: "route",
  //   name: "Add Interest",
  //   key: "addInterest",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/addInterest",
  //   component: <AddInterest />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Interest",
  //   key: "edit-interest",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/edit-interest/:id",
  //   component: <EditInterest />,
  // },
  // {
  //   type: "route",
  //   name: "View Interest",
  //   key: "view-interest",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/view-interest/:id",
  //   component: <ViewInterest />,
  // },
  
  // {
  //   type: "collapse",
  //   name: "Users",
  //   key: "users",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/users",
  //   component: <Users />,
  // },
  // {
  //   type: "route",
  //   name: "Users",
  //   key: "users",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/users/:type",
  //   component: <Users />,
  // },
  // {
  //   type: "route",
  //   name: "Users",
  //   key: "users",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/viewUser/:id",
  //   component: <ViewUser />,
  // },
  
  // {
  //   type: "collapse",
  //   name: "Vehicle Types",
  //   key: "vehicle_types",
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/vehicleTypes",
  //   component: <VehicleType />,
   
  // },
  // {
  //   type: "route",
  //   name: "Add Vehicle",
  //   key: "add_vehicle",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/addVehicle",
  //   component: <VehicleTypeAdd />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Vehicle",
  //   key: "edit_vehicle",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/editVehicle/:id",
  //   component: <VehicleTypeEdit />,
  // },

  // {
  //   type: "collapse",
  //   name: "Vehicle/Documents",
  //   key: "driver_documents",
  //   icon: <Icon fontSize="small">description</Icon>,
  //   route: "/vehicles/:id",
  //   component: <Vehicles />,
  // },
  // {
  //   type: "route",
  //   name: "Vehicle/Documents",
  //   key: "driver_documents",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/viewDocuments/:id",
  //   component: <DocumentView />,
  // },
  // {
  //   type: "collapse",
  //   name: "Staff",
  //   key: "staff",
  //   icon: <Icon fontSize="small"><GroupsIcon /></Icon>,
  //   route: "/staff",
  //   component: <Staff />,
  // },
  // {
  //   type: "route",
  //   name: "Add Staff",
  //   key: "add-staff",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/staff/add",
  //   component: <AddAStaff />,
  // },
  // {
  //   type: "route",
  //   name: "View Staff",
  //   key: "view-staff",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/viewStaff/:id",
  //   component: <ViewStaff />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Staff",
  //   key: "Edit-staff",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/editStaff/:id",
  //   component: <Editstaff />,
  // },
  // {
  //   type: "collapse",
  //   name: "Contents",
  //   key: "contents",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/contents",
  //   component: <Contents />,
  // },
  // {
  //   type: "route",
  //   name: "Add Content",
  //   key: "add-content",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/contents/add",
  //   component: <AddContent />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Content",
  //   key: "edit-content",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/editContent/:id",
  //   component: <EditContent />,
  // },
  // {
  //   type: "route",
  //   name: "View Content",
  //   key: "view-content",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/viewContent/:id",
  //   component: <ViewContent />,
  // },
  // {
  //   type: "collapse",
  //   name: "Rides",
  //   key: "rides",
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/rides",
  //   component: <Rides />,
  // },
  // {
  //   type: "route",
  //   name: "View Ride",
  //   key: "view-ride",
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/viewRide/:id",
  //   component: <ViewRide />,
  // },
  // {
  //   type: "collapse",
  //   name: "Rides",
  //   key: "rides",
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/rides/:type",
  //   component: <Rides />,
  // },
  // {
  //   type: "collapse",
  //   name: "IpWhitelist",
  //   key: "ipwhitelist",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/ipwhitelist",
  //   component: <IpWhitelist />,
  
  // },
  // {
  //   type: "route",
  //   name: "View Whitelist",
  //   key: "view-Whitelist",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/ViewWhitelistIp/",
  //   component: <ViewWhitelistIp />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Faq",
  //   key: "edit-faq",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/faqs/:id",
  //   component: <EditFaq/>,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  //  {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  //  },
  
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },


];

export default routes;
