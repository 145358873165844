import axios from "axios";
import './CarouselTool.css';

class CarouselTool {
    static get toolbox() {
        return {
            title: 'Carousel',
            icon: '<svg width="20" height="20" viewBox="0 0 20 20"><rect x="1" y="5" width="18" height="10" fill="none" stroke="black" stroke-width="2"/><line x1="1" y1="10" x2="19" y2="10" stroke="black" stroke-width="2"/></svg>', // Example icon
        };
    }

    constructor({ data, api, config, divId }) {
        this.api = api;
        this.data = data || { files: [] };
        this.data.files = this.data.files || []; // Ensure files is always an array
        this.config = config || {};
        
        // Ensure wrapper exists or create one if not found
        this.wrapper = document.getElementById(divId) || document.createElement('div');
        this.wrapper.id = divId || `carousel-${Math.random().toString(36).substr(2, 9)}`;
        
        // If a new wrapper is created, append it to the body (or a desired parent)
        if (!document.body.contains(this.wrapper)) {
            document.body.appendChild(this.wrapper);
        }
    }

    render() {
        // Ensure wrapper is defined before rendering
        if (!this.wrapper) {
            console.error("Wrapper element is not defined");
            return;
        }

        this.wrapper.innerHTML = ''; // Clear previous content

        // Create carousel container
        const carouselContainer = document.createElement('div');
        carouselContainer.classList.add('carousel-container');

        // Render each image or video from the data
        if (this.data.files && this.data.files.length > 0) {
            this.data.files.forEach((file) => {
                const mediaElement = this.createMediaElement(file);
                carouselContainer.appendChild(mediaElement);
            });
        } else {
            carouselContainer.innerHTML = `<div class="carousel-placeholder">Click to upload images/videos</div>`;
        }

        // Create the upload button
        const uploadButton = document.createElement('button');
        uploadButton.classList.add('upload-button');
        uploadButton.type = 'button'; // Prevent default form submission behavior
        uploadButton.textContent = 'Upload Images/Videos';
        uploadButton.addEventListener('click', () => {
            this.uploadFiles();
        });

        // Append the carousel and upload button to the wrapper
        this.wrapper.appendChild(carouselContainer);
        this.wrapper.appendChild(uploadButton);

        return this.wrapper;
    }

    createMediaElement(file) {
        const mediaWrapper = document.createElement('div'); // Create a wrapper for the media element and the remove icon

        const element = document.createElement(file.type.startsWith('image/') ? 'img' : 'video');
        element.src = file.url; // Ensure this is a valid URL
        element.classList.add('carousel-media');

        // Only set controls for video elements
        if (file.type.startsWith('video/')) {
            element.controls = true;
        }

        // Set appropriate attributes for the img element (e.g., alt text)
        if (file.type.startsWith('image/')) {
            element.alt = 'Uploaded Image'; // Optional: set alt text for accessibility
        }

        // Create a remove icon
        const removeIcon = document.createElement('span');
        removeIcon.innerHTML = '❌'; // You can use any icon or SVG here
        removeIcon.classList.add('remove-icon');
        
        // Add event listener to the remove icon
        removeIcon.addEventListener('click', () => {
            this.removeMedia(file.url); // Call remove function with the media URL
        });

        // Append the media element and remove icon to the wrapper
        mediaWrapper.appendChild(element);
        mediaWrapper.appendChild(removeIcon);
        
        return mediaWrapper; // Return the media wrapper
    }

    removeMedia(url) {
        // Filter out the media item from this.data.files
        this.data.files = this.data.files.filter(file => file.url !== url);
        
        // Re-render the tool to reflect the changes
        this.render();
    }

    uploadFiles() {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = 'image/*, video/*';

        input.addEventListener('change', async (event) => {
            const files = event.target.files;
            const formData = new FormData();

            Array.from(files).forEach(file => {
                formData.append('image', file); // Ensure the key 'image' matches your server code
            });

            try {
                const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/uploadCrosalImages`, formData);
                console.log('Upload Success:', data);

                // Ensure data.files is defined and is an array before pushing
                if (data.files && Array.isArray(data.files)) {
                    this.data.files.push(...data.files); // Add new files to the existing data
                }

                // Re-render the tool to show new images
                this.render(); // Re-render the tool to reflect the new data

            } catch (error) {
                if (error.response) {
                    console.error('Error Response:', error.response.data);
                } else {
                    console.error('Error:', error.message);
                }
            }
        });

        input.click();
    }

    save() {
        return this.data; // Return the current data state
    }

    destroy() {
        // Clean up any listeners and DOM elements to prevent memory leakss
        this.wrapper.removeEventListener('click', this.uploadFiles);
        this.wrapper.innerHTML = ''; // Clear content when destroyed
    }
}

export default CarouselTool;
