import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import MDAvatar from "components/MDAvatar";
import moment from 'moment';
import Icon from "@mui/material/Icon";


const ToggleSwitch = ({ id, isPublished, onToggle }) => {
  const switchStyles = {
    position: 'relative',
    display: 'inline-block',
    width: '40px',
    height: '20px'
  };

  const sliderStyles = {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: isPublished ? '#2196F3' : '#ccc',
    transition: '.4s',
    borderRadius: '20px'
  };

  const circleStyles = {
    position: 'absolute',
    content: '""',
    height: '12px',
    width: '12px',
    left: isPublished ? '24px' : '4px',  // Move the circle based on checked state
    bottom: '4px',
    backgroundColor: 'white',
    transition: '.4s',
    borderRadius: '50%'
  };

  return (
    <label style={switchStyles}>
      <input 
        type="checkbox" 
        checked={isPublished} 
        onChange={() => onToggle(id, !isPublished)} 
        style={{ display: 'none' }}  // Hide the default checkbox input
      />
      <span style={sliderStyles}>
        <span style={circleStyles}></span>
      </span>
    </label>
  );
};

// ActionButtons component defined in the same file
const ActionButtons = ({ subid, onEdit, onView, onDelete }) => {
  return (
    <>
      <Icon
        style={{ cursor: "pointer", color: "primary" }}
        onClick={() => onEdit(subid)}
      >
        edit
      </Icon>
      {/* <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onView(subid)}
      >
        visibility
      </Icon> */}
      <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onDelete(subid)}
      >
        delete
      </Icon>
    </>
  );
};

export default function Data(id) {
  
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [singleCategory, setSingleCategory] = useState([]);

  

  const LoadData = async () => {
    if (id) {
      try {
        await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/guideCategoryWithId`,{ id }).then((res) => {
          var response = res.data.categorydetail.subCategories;
          var responseData = [];
          for (var i = 0; i < response.length; i++) {
            var inc = i + 1;

            responseData.push({
              id: inc,
              icon: <MDAvatar src={`${process.env.REACT_APP_BASE_URL}${response[i].iconurl}`} />,
              name: response[i].name,
              description: response[i].description,
              //pagetype: response[i].pagetype,
              //date: formatUTCDate(response[i].createdAt),
              // isPublished: response[i].isPublished,
              // publishToggle: (
              //   <ToggleSwitch
              //     id={response[i]._id}
              //     isPublished={response[i].isPublished}
              //     onToggle={handleTogglePublish}
              //   />
              // ),
              
              pagetypes: <Link to={`/guidelinesubtypePage-edit/${id}/${response[i]._id}`}>Add/Edit Screens</Link>,
              action: (
                <ActionButtons
                  subid={response[i]._id}
                  onEdit={handleEdit}
                  // onView={handleView}
                  onDelete={handleDelete}
                />
              ), // Using the ActionButtons component
            });
          }
          setData(responseData);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  async function fetchGuidelineType() {
    var singlecatdata = [];
    if (id) {
      // Only fetch if editing
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/guideCategoryWithId`,
          { id }
        );
        
        if (response.data.status === 1) {
          setSingleCategory([{ name: response.data.categorydetail.name }]);
        }
      } catch (error) {
        console.error("Error fetching guideline:", error);
      }
    }
  }

  useEffect(() => {
    LoadData();
    fetchGuidelineType();
  }, []);

 



  // Handle edit action
  const handleEdit = (subid) => {
    navigate(`/guidelinesubtype-edit/${id}/${subid}`);
  };

  

  // Handle view action
  // const handleView = (id) => {
  //   navigate(`/view-guideline/${id}`);
  // };

  // Handle delete action
  const handleDelete = async (subid) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this guideline!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deleteSubCategory`, { categoryId:id,subCategoryId:subid });
          swal("Deleted!", "The guideline has been deleted.", "success");
          LoadData(); // Reload the data after deletion
        } catch (error) {
          swal("Error!", "Failed to delete the guideline.", "error");
        }
      } else {
        swal("Your guideline is safe!");
      }
    });
  };

  return {
    columns: [
      { Header: "Sno", accessor: "id" },
      { Header: "icon", accessor: "icon" },
      { Header: "name", accessor: "name" },
      { Header: "description", accessor: "description" },
      { Header: "screens", accessor: "pagetypes" },
      //{ Header: "pagetype available", accessor: "pagetype" },
      //{ Header: "Publish/Unpublish", accessor: "publishToggle" },
      //{ Header: "date", accessor: "date" },
      { Header: "action", accessor: "action" }, // Added the action columns
    ],

    rows: data ? data : "",
    singleCategory,
  };
}

// Format date helper function
function formatUTCDate(utcDate) {
  const momentDate = moment(utcDate);
  return momentDate.format('MM/DD/YYYY, hh:mm A');
}
