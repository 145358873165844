import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

import Icon from "@mui/material/Icon";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import {format} from 'date-fns';
function removeTags(str) {
  if ((str===null) || (str===''))
      return false;
  else
      str = str.toString();
        
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}

export default function Data() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.post("/api/admin/getfaqslist").then((res) => {
      var response = res.data;
      response = response.faqsList;
      console.log(response);
      var responseData = [];
      if(response.length > 0)
      {
        for (var i = 0; i < response.length; i++) {
          var inc=i+1;
          var answerAll = removeTags(response[i].answer);
          var answerShort = answerAll.substring(0,100);
          responseData.push(
            {
              date: format(new Date(response[i].updatedAt), 'dd/MM/yyyy, p'),
              question: response[i].question,
              answer: answerShort+'...',
              action: Action(navigate, response[i]._id),
            }
          );
        }
      }
      
      setData(responseData);
    });
  }, []);

  return {
    columns: [
      { Header: "Recently updated", accessor: "date" },
      { Header: "Question", accessor: "question" },
      { Header: "Answer", accessor: "answer" },
      { Header: "action", accessor: "action" },
    ],

    rows: data ? data : "",
  };
}

function Action(navigate, faqId) {
  //const [status, setStatus] = useState(userStatus);
  const editFaq = async (e) => {
    console.log(e.target);
    var id=e.target.attributes.value.nodeValue;
    navigate("/faqs/"+id);
  };
  const deleteFaq = async (e) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this faqs!",
      icon: "warning",
      buttons: [
        'No',
        'Yes'
      ],
      dangerMode: true,
    }).then(function(isConfirm) {
      if (isConfirm) {
        var id=e.target.attributes.value.nodeValue;
        axios.post("/api/admin/deleteFaqs", {id}).then((res) => {
          var response = res.data;
          if(response.status==1){
            swal("success", "Faq deleted successfully!", "success");
            navigate(0);
          }
        });
      }
    });
  };
  return (
    <>
      <span className="formAction">
        <a href="javascript:void(0);" onClick={editFaq}><Icon fontSize="small" value={faqId}>edit</Icon></a> | 
        <a href="javascript:void(0);" onClick={deleteFaq}><Icon fontSize="small" value={faqId}>delete</Icon></a>
      </span>
    </>
  );
}
