import ReactDOM from "react-dom/client";
import { IoCloudUpload } from "react-icons/io5";
import "./EditorEnhance.css";

class ImageGalleryTool {
  static get toolbox() {
    return {
      title: "Image Gallery",
      icon: "🖼️",
    };
  }

  constructor({ data, api }) {
    this.api = api;
    this.icons = [
      { name: "Correct", url: "/editorImages/correct-bg.png" },
      { name: "Wrong", url: "/editorImages/wrong-bg.png" },
      { name: "Warning", url: "/editorImages/warning-bg.png" },
      { name: "Idea", url: "/editorImages/idea-bg.png" },
    ];
    this.data = {
      images: data.images || [],
    };

    this.wrapper = null;
    this.buttonWrapper = null;
    this.imagesWrapper = null;
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("image-gallery-tool");

    this.buttonWrapper = document.createElement("div");
    this.buttonWrapper.classList.add("upload-button-wrapper");

    const addImageButton = document.createElement("button");
    addImageButton.type = "button";
    addImageButton.classList.add("custom-upload-img");

    const iconSpan = document.createElement("span");
    const textSpan = document.createElement("span");
    textSpan.innerText = "Upload Image";

    const iconRoot = ReactDOM.createRoot(iconSpan);
    iconRoot.render(<IoCloudUpload />);

    addImageButton.appendChild(iconSpan);
    addImageButton.appendChild(textSpan);
    addImageButton.onclick = () => this.addImage();

    this.buttonWrapper.appendChild(addImageButton);

    this.imagesWrapper = document.createElement("div");
    this.imagesWrapper.classList.add("GalleryBox");

    this.wrapper.appendChild(this.buttonWrapper);
    this.wrapper.appendChild(this.imagesWrapper);

    this.updateImages();
    return this.wrapper;
  }

  addImage() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;

    input.onchange = async (event) => {
      const files = Array.from(event.target.files);

      for (const file of files) {
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/admin/uploadImage`, {
            method: "POST",
            body: formData,
          });
          const data = await response.json();

          this.data.images.push({
            url: data.file.url,
            description: "",
            showDescription: "no",
            align: "start",
            iconUrl: "",
            iconName: "",
          });
        } catch (error) {
          console.error("Image upload failed", error);
        }
      }

      this.updateImages();
    };

    input.click();
  }

  updateImages() {
    this.imagesWrapper.innerHTML = "";

    this.data.images.forEach((image, index) => {
      const imageItem = document.createElement("div");
      imageItem.classList.add("image-item");
      imageItem.style.marginBottom = "15px";

      const img = document.createElement("img");
      img.classList.add("imagemain");
      img.src = image.url;
      imageItem.appendChild(img);

      // Alignment dropdown
      const dropdownWrapperal = document.createElement("div");
      dropdownWrapperal.classList.add("imgSelectDiv");

      const alignLabel = document.createElement("label");
      alignLabel.innerText = "Align: ";
      alignLabel.style.marginRight = "10px";
      alignLabel.style.fontWeight = "bold";

      const alignSelect = document.createElement("select");
      ["Left", "Center", "Right"].forEach((opt) => {
        const option = new Option(opt, opt);
        alignSelect.appendChild(option);
      });

      alignSelect.value = ["Left", "Center", "Right"].includes(image.align) ? image.align : "Center";
      alignSelect.onchange = (e) => {
        this.data.images[index].align = e.target.value;
      };

      dropdownWrapperal.appendChild(alignLabel);
      dropdownWrapperal.appendChild(alignSelect);
      imageItem.appendChild(dropdownWrapperal);

      // Icon Dropdown
      const iconDropdownWrapper = document.createElement("div");
      iconDropdownWrapper.classList.add("imgSelectDiv");

      const iconLabel = document.createElement("label");
      iconLabel.innerText = "Choose Icon: ";
      iconLabel.style.marginRight = "10px";
      iconLabel.style.fontWeight = "bold";

      const iconSelect = document.createElement("select");
      const defaultOption = new Option("Select", "");
      iconSelect.appendChild(defaultOption);

      this.icons.forEach((icon) => {
        const option = new Option(icon.name, icon.url);
        iconSelect.appendChild(option);
      });

      iconSelect.value = image.iconUrl || "";

      // Preview selected icon
      const iconPreviewWrapper = document.createElement("div");
      // iconPreviewWrapper.style.marginTop = "8px";
      const iconPreview = document.createElement("img");
      iconPreview.src = image.iconUrl || "";
      iconPreview.alt = image.iconName || "Icon";
      iconPreview.style.width = "30px";
      iconPreview.style.height = "30px";
      // iconPreview.style.marginLeft = "8px";
      iconPreview.style.verticalAlign = "middle";
      iconPreview.style.display = image.iconUrl ? "inline-block" : "none";
      iconPreviewWrapper.appendChild(iconPreview);

      iconSelect.onchange = (e) => {
        this.data.images[index].iconUrl = e.target.value;
        const selectedIcon = this.icons.find((icon) => icon.url === e.target.value);
        this.data.images[index].iconName = selectedIcon?.name || "";

        iconPreview.src = e.target.value;
        iconPreview.style.display = e.target.value ? "inline-block" : "none";
      };

      iconDropdownWrapper.appendChild(iconLabel);
      iconDropdownWrapper.appendChild(iconPreviewWrapper);
      iconDropdownWrapper.appendChild(iconSelect);
      
      imageItem.appendChild(iconDropdownWrapper);

      // Show Description dropdown
      const dropdownWrapper = document.createElement("div");
      dropdownWrapper.classList.add("imgSelectDiv");

      const label = document.createElement("label");
      label.innerText = "Is Description? ";
      label.style.marginRight = "10px";
      label.style.fontWeight = "bold";

      const showDescriptionSelect = document.createElement("select");
      const yesOption = new Option("Yes", "yes");
      const noOption = new Option("No", "no");

      showDescriptionSelect.appendChild(yesOption);
      showDescriptionSelect.appendChild(noOption);

      showDescriptionSelect.value = image.showDescription || "no";
      showDescriptionSelect.onchange = (e) => {
        this.data.images[index].showDescription = e.target.value;
        this.updateImages(); // re-render
      };

      dropdownWrapper.appendChild(label);
      dropdownWrapper.appendChild(showDescriptionSelect);
      imageItem.appendChild(dropdownWrapper);

      // Description textarea
      if (image.showDescription === "yes") {
        const descriptionTextarea = document.createElement("textarea");
        descriptionTextarea.classList.add("imgdescription");
        descriptionTextarea.placeholder = "Add description...";
        descriptionTextarea.value = image.description || "";
        descriptionTextarea.rows = 2;

        descriptionTextarea.oninput = (e) => {
          this.data.images[index].description = e.target.value;
        };

        imageItem.appendChild(descriptionTextarea);
      }

      // Delete Button
      const deleteButton = document.createElement("button");
      deleteButton.classList.add("imgboxbtn");
      deleteButton.innerText = "❌";
      deleteButton.type = "button";
      deleteButton.onclick = () => {
        this.data.images.splice(index, 1);
        this.updateImages();
      };

      imageItem.appendChild(deleteButton);
      this.imagesWrapper.appendChild(imageItem);
    });
  }

  save() {
    return this.data;
  }
}

export default ImageGalleryTool;
