const updateOrCreateSpan = (range, styleUpdates, type = "style") => {
    const container = range.commonAncestorContainer;

    // If the container is a text node, get its parent
    let targetElement = container.nodeType === Node.TEXT_NODE ? container.parentElement : container;

    // Traverse up to find an existing <span>
    while (targetElement && targetElement.tagName !== "SPAN") {
        targetElement = targetElement.parentElement;
    }

    if (targetElement && targetElement.tagName === "SPAN") {
        // If a <span> is found, update it
        if (type === "style") {
            Object.assign(targetElement.style, styleUpdates);
        }
    } else {
        // If no <span> is found, wrap the selection in a new one
        const span = document.createElement("span");
        span.setAttribute("data-type", "uxinsight");
        if (type === "style") {
            Object.assign(span.style, styleUpdates);
        }
        try {
            range.surroundContents(span);
        } catch (error) {
            console.error("Error wrapping selection:", error.message);
        }
    }
};


export const handleFontSizeChange = async (size, editorInstance, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setSelectfontsize, setFormData, keypoints) => {
    const selection = window.getSelection();

    if (!selection || selection.rangeCount === 0 || selection.getRangeAt(0).collapsed) {
        console.log("No text selected");
        return;
    }

    // const range = selection.getRangeAt(0);
    // const selectedText = range.extractContents();
    // const span = document.createElement("span");
    // span.style.fontSize = `${size}px`;
    // span.appendChild(selectedText);
    // range.insertNode(span);
    // selection.removeAllRanges();
    //const range = selection.getRangeAt(0);
    //updateOrCreateSpan(range, { fontSize: `${size}px` },"style");
    const range = selection.getRangeAt(0);
    const selectedContent = range.extractContents();

    // Ensure startContainer is an element, if not use its parentNode
    let startElement = range.startContainer.nodeType === Node.ELEMENT_NODE ? range.startContainer : range.startContainer.parentNode;

    // Check if the selection is already wrapped in a span with data-type="uxinsight"
    const existingSpan = startElement.closest('span[data-type="uxinsight"]');

    if (existingSpan) {
        // If an existing span is found, update its data-id and data-type attributes
        existingSpan.style.fontSize = `${size}px`;
        existingSpan.setAttribute("data-type", "uxinsight");
        existingSpan.appendChild(selectedContent);  // Re-append the selected content to the updated span
    } else {
        // If no existing span is found, create a new span and add the data-id attribute
        const span = document.createElement("span");
        span.style.fontSize = `${size}px`;
        span.setAttribute("data-type", "uxinsight");
        span.appendChild(selectedContent);
        
        // Insert the new span with the selected content
        range.insertNode(span);
    }


    setSelectfontsize("");
    const savedData = await editorInstance.current.save();
    setHistory(prevHistory => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
    setCurrentHistoryIndex(prevIndex => prevIndex + 1);
    setFormData(prevData => ({ ...prevData, [keypoints]: savedData }));
};

export const handleFontFamilyChange = async (family, editorInstance, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setSelectfontfamily, setFormData, keypoints) => {
    const selection = window.getSelection();

    if (!selection || selection.rangeCount === 0 || selection.getRangeAt(0).collapsed) {
        console.log("No text selected");
        return;
    }

    // const range = selection.getRangeAt(0);
    // const selectedText = range.extractContents();
    // const span = document.createElement("span");
    // span.style.fontFamily = family;
    // span.appendChild(selectedText);
    // range.insertNode(span);
    // selection.removeAllRanges();
    //const range = selection.getRangeAt(0);
    //updateOrCreateSpan(range, { fontFamily: family },"style");
    const range = selection.getRangeAt(0);
    const selectedContent = range.extractContents();

    // Ensure startContainer is an element, if not use its parentNode
    let startElement = range.startContainer.nodeType === Node.ELEMENT_NODE ? range.startContainer : range.startContainer.parentNode;

    // Check if the selection is already wrapped in a span with data-type="uxinsight"
    const existingSpan = startElement.closest('span[data-type="uxinsight"]');

    if (existingSpan) {
        // If an existing span is found, update its data-id and data-type attributes
        existingSpan.style.fontFamily = family;
        existingSpan.setAttribute("data-type", "uxinsight");
        existingSpan.appendChild(selectedContent);  // Re-append the selected content to the updated span
    } else {
        // If no existing span is found, create a new span and add the data-id attribute
        const span = document.createElement("span");
        span.style.fontFamily = family;
        span.setAttribute("data-type", "uxinsight");
        span.appendChild(selectedContent);
        
        // Insert the new span with the selected content
        range.insertNode(span);
    }

    setSelectfontfamily(family);
    const savedData = await editorInstance.current.save();
    setHistory(prevHistory => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
    setCurrentHistoryIndex(prevIndex => prevIndex + 1);
    setFormData(prevData => ({ ...prevData, [keypoints]: savedData }));
};

export const handleFontStyleChange = async (style, editorInstance, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setFormData, keypoints) => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0 || selection.getRangeAt(0).collapsed) {
        console.log("No text selected");
        return;
    }

    const range = selection.getRangeAt(0);
    const span = document.createElement("span");
    span.style.fontWeight = style === "bold" ? "bold" : "";
    span.style.fontStyle = style === "italic" ? "italic" : "";
    range.surroundContents(span);

    const savedData = await editorInstance.current.save();
    setHistory(prevHistory => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
    setCurrentHistoryIndex(prevIndex => prevIndex + 1);
    setFormData(prevData => ({ ...prevData, [keypoints]: savedData }));
};

export const handleColorChange = async (color, editorInstance1, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setFormData, keypoints) => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0 || selection.getRangeAt(0).collapsed) {
        console.log("No text selected");
        return;
    }
    

    // const range = selection.getRangeAt(0);
    // const selectedContent = range.extractContents();
    // const span = document.createElement("span");
    // span.style.color = color;
    // span.appendChild(selectedContent);
    // range.insertNode(span);
    // selection.removeAllRanges();

    //const range = selection.getRangeAt(0);
    //updateOrCreateSpan(range, { color: color },"style");
    const range = selection.getRangeAt(0);
    const selectedContent = range.extractContents();

    // Ensure startContainer is an element, if not use its parentNode
    let startElement = range.startContainer.nodeType === Node.ELEMENT_NODE ? range.startContainer : range.startContainer.parentNode;

    // Check if the selection is already wrapped in a span with data-type="uxinsight"
    const existingSpan = startElement.closest('span[data-type="uxinsight"]');

    if (existingSpan) {
        // If an existing span is found, update its data-id and data-type attributes
        existingSpan.style.color = color;
        existingSpan.setAttribute("data-type", "uxinsight");
        existingSpan.appendChild(selectedContent);  // Re-append the selected content to the updated span
    } else {
        // If no existing span is found, create a new span and add the data-id attribute
        const span = document.createElement("span");
        span.style.color = color;
        span.setAttribute("data-type", "uxinsight");
        span.appendChild(selectedContent);
        
        // Insert the new span with the selected content
        range.insertNode(span);
    }


    // Save the editor content and include the new data-id in the saved data
    const savedData = await editorInstance1.current.save();
    setHistory(prevHistory => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
    setCurrentHistoryIndex(prevIndex => prevIndex + 1);
    setFormData(prevData => ({ ...prevData, [keypoints]: savedData }));
};


export const handleBackgroundColorChange = async (color, editorInstance, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setFormData, keypoints) => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0 || selection.getRangeAt(0).collapsed) {
        console.log("No text selected");
        return;
    }

    // const range = selection.getRangeAt(0);
    // const selectedContent = range.extractContents();
    // const span = document.createElement("span");
    // span.style.backgroundColor = color;
    // span.appendChild(selectedContent);
    // range.insertNode(span);
    // selection.removeAllRanges();
    //const range = selection.getRangeAt(0);
    //updateOrCreateSpan(range, { backgroundColor: color },"style");
    const range = selection.getRangeAt(0);
    const selectedContent = range.extractContents();

    // Ensure startContainer is an element, if not use its parentNode
    let startElement = range.startContainer.nodeType === Node.ELEMENT_NODE ? range.startContainer : range.startContainer.parentNode;

    // Check if the selection is already wrapped in a span with data-type="uxinsight"
    const existingSpan = startElement.closest('span[data-type="uxinsight"]');

    if (existingSpan) {
        // If an existing span is found, update its data-id and data-type attributes
        existingSpan.style.backgroundColor = color;
        existingSpan.setAttribute("data-type", "uxinsight");
        existingSpan.appendChild(selectedContent);  // Re-append the selected content to the updated span
    } else {
        // If no existing span is found, create a new span and add the data-id attribute
        const span = document.createElement("span");
        span.style.backgroundColor = color;
        span.setAttribute("data-type", "uxinsight");
        span.appendChild(selectedContent);
        
        // Insert the new span with the selected content
        range.insertNode(span);
    }

    selection.removeAllRanges();

    const savedData = await editorInstance.current.save();
    setHistory(prevHistory => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
    setCurrentHistoryIndex(prevIndex => prevIndex + 1);
    setFormData(prevData => ({ ...prevData, [keypoints]: savedData }));
};

export const handleAssignGuideline = async (guideline, editorInstance, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setFormData, keypoints) => {
    console.log("guidelineguideline",guideline);
    const selection = window.getSelection();
    console.log("selectionselection",selection);
    if (!selection || selection.rangeCount === 0 || selection.getRangeAt(0).collapsed) {
        console.log("No text selected");
        return;
    }
    // Get the selected text
    const selectedText = selection.toString();
    console.log("Selected text:", selectedText);

    const range = selection.getRangeAt(0);
    const selectedContent = range.extractContents();

    // Ensure startContainer is an element, if not use its parentNode
    let startElement = range.startContainer.nodeType === Node.ELEMENT_NODE ? range.startContainer : range.startContainer.parentNode;

    // Check if the selection is already wrapped in a span with data-type="uxinsight"
    const existingSpan = startElement.closest('span[data-type="uxinsight"]');

    if (existingSpan) {
        console.log("exist-exist-exist-exist-exist-exist-");
        // If an existing span is found, update its data-id and data-type attributes
        existingSpan.setAttribute("data-id", guideline);
        existingSpan.setAttribute("data-type", "uxinsight");
        // ✅ Ensure class is applied
        if (guideline.trim() !== "") {
            
            existingSpan.classList.add("highlighted-ux");
        } else {
            existingSpan.classList.remove("highlighted-ux");
        }

        console.log("existingSpan - existingSpan",existingSpan);
        existingSpan.appendChild(selectedContent);  // Re-append the selected content to the updated span
    } else {
        console.log("new-new-new-new-new-new-new-");
        // If no existing span is found, create a new span and add the data-id attribute
        const span = document.createElement("span");
        span.setAttribute("data-id", guideline);
        span.setAttribute("data-type", "uxinsight");
         // ✅ Add class if needed
         if (guideline.trim() !== "") {
            span.classList.add("highlighted-ux");
        }
        span.appendChild(selectedContent);
        
        // Insert the new span with the selected content
        range.insertNode(span);
    }

    selection.removeAllRanges();

    // Save the editor content and update history
    const savedData = await editorInstance.current.save();
    console.log("savedDatak", savedData);
    setHistory(prevHistory => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
    setCurrentHistoryIndex(prevIndex => prevIndex + 1);
    setFormData(prevData => ({ ...prevData, [keypoints]: savedData }));
};





