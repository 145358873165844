import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Icon from "@mui/material/Icon";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import ImageTool from "@editorjs/image";
import Table from "@editorjs/table";
import Paragraph from "@editorjs/paragraph";
import CodeTool from "@editorjs/code";
import HorizontalLine from "components/Editor/HorizontalLine";
import CarouselTool from "components/Editor/CarouselTool/CarouselTool.js";
import CheckboxTool from "components/Editor/CheckboxTool/CheckboxTool.js";
import ColorTool from "components/Editor/ColorTool/ColorTool.js";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { RiFontSize } from "react-icons/ri";
import { RxFontFamily } from "react-icons/rx";
import { MdFormatColorText } from "react-icons/md";
import { IoMdColorFill } from "react-icons/io";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Button,
  Avatar
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams, Link, Router } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const notifySuccess = (message) => {
  toast.success(message);
};

const notifyError = (message) => {
  toast.error(message);
};
export default function AnnotationMoreDetail() {
  const { id, mainid, isview } = useParams();
  const navigate = useNavigate();
  const editorInstance = useRef(null);


  const [selectfontsize, setSelectfontsize] = useState('');
  const [selectfontfamily, setSelectfontfamily] = useState('Arial');
  const [history, setHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const [showEditor, setShowEditor] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    subTitle: "",
    titleDescription: "",
    selectedDesktopFiles: [],
    selectedDesktopFileUrls: [],
    selectedDesktopFileUrlsShow: [],
    description: {},
    uxinsightslist: [],
  });

  const uxinsights = ['UX Insight 1', 'UX Insight 2', 'UX Insight 3'];
  const handleChangeUXInsights = (event) => {
    const { value } = event.target;
    console.log("valuevalue",value);
    setFormData({
      ...formData,
      uxinsightslist: typeof value === 'string' ? value.split(',') : value, // Update uxinsightslist
    });
  };
  const handleDesktopImageChange = (e) => {
    const newFiles = Array.from(e.target.files); // Get newly selected files
    
    setFormData((prevState) => {
      // Get the existing files and calculate the total
      const existingFiles = prevState.selectedDesktopFiles || [];
      const totalFiles = existingFiles.length + newFiles.length;
  
      // Check if total exceeds 5
      if (totalFiles+formData.selectedDesktopFileUrlsShow.length > 5) {
        toast.error("You can only upload up to 5 images.");
        return prevState;
     
      }
  
      const newImageUrls = newFiles.map((file) => URL.createObjectURL(file)); // Create new image URLs for display
  
      return {
        ...prevState,
        // Append new files to existing files
        selectedDesktopFiles: [...existingFiles, ...newFiles],
        // Append new URLs to existing URLs
        selectedDesktopFileUrls: [
          ...(prevState.selectedDesktopFileUrls || []),
          ...newImageUrls,
        ],
      };
    });
  };
  

  const handleDeleteDesktopImage = (indexToDelete) => {
    const updatedFiles = formData.selectedDesktopFiles.filter((_, index) => index !== indexToDelete);
    const updatedUrls = formData.selectedDesktopFileUrls.filter((_, index) => index !== indexToDelete);
  
    setFormData((prevState) => ({
      ...prevState,
      selectedDesktopFiles: updatedFiles,
      selectedDesktopFileUrls: updatedUrls, // Update both files and URLs
    }));
  };

  const handleDeleteDesktopImageShow = async (imageUrl, type) => {
    const confirmDelete = await swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    });

    if (confirmDelete) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/deleteImageAnnotationMoreDetail`,
          { id, mainid, type, imageUrl }
        );

        if (response.data.status === 1) {
          // Update the state to remove the deleted image from the display
          if (type === "desktopimage") {
            setFormData((prevData) => ({
              ...prevData,
              selectedDesktopFileUrlsShow:
                prevData.selectedDesktopFileUrlsShow.filter(
                  (url) => url !== imageUrl
                ),
            }));
          } 

          // Optionally show a success message
          notifySuccess("File deleted successfully.");
        } else {
          // Handle error from API response
          notifyError("Failed to delete the image.");
        }
      } catch (error) {
        console.error("Error deleting image:", error);
        notifyError("An error occurred while deleting the image.");
      }
    }
  };




  useEffect(() => {
    // Fetch existing annotation data
    const fetchSingleAnnotationMoreDetail = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getSingleAnnotationMoreDetail`, { annotation_sub_id: id, annotation_main_id: mainid });

        const { annotation } = response.data;

        const description =
              typeof annotation.description === "string"
                ? JSON.parse(annotation.description)
                : annotation.description || {};

                

        setFormData(
          {
            ...formData,
            title: annotation.title,
            subTitle: annotation.subTitle,
            titleDescription: annotation.titleDescription,
            selectedDesktopFileUrlsShow:annotation.desktopImages || [],
            description: description,
            uxinsightslist: annotation.uxinsightslist,

          })
          setShowEditor(true);
          
      } catch (error) {
        setShowEditor(true);
        toast.error(error);
      }
    };


    fetchSingleAnnotationMoreDetail();
  }, [id]);

  useEffect(() => {
    
    if (showEditor &&  !editorInstance.current) {
      const style = document.createElement("style");
      style.innerHTML = `div#editorjs .ce-tooltip { color: black !important; }`;
      document.head.appendChild(style);

      editorInstance.current = new EditorJS({
        holder: "editorjs",
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: ["bold", "italic","color"],
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                async uploadByFile(file) {
                  const formData = new FormData();
                  formData.append("image", file);
                  try {
                    const { data } = await axios.post(
                      `${process.env.REACT_APP_BASE_URL}api/admin/uploadImage`,
                      formData,
                      {
                        headers: { "Content-Type": "multipart/form-data" },
                      }
                    );
                    return { success: 1, file: { url: data.file.url } };
                  } catch (error) {
                    console.error("Image upload failed:", error);
                    return { success: 0 };
                  }
                },
                async uploadByUrl(url) {
                  return { success: 1, file: { url: url } };
                },
              },
            },
          },
          color: ColorTool,
         
          
          quote: Quote,
          table: {
            class: Table,
            inlineToolbar: ["bold", "italic","color"], // Default options
          },
          //table: Table,
          delimiter: HorizontalLine,
          checkbox: CheckboxTool,
          code: CodeTool,
        },
        data: formData.description,
        onChange: async () => {
          const savedData = await editorInstance.current.save();
          console.log("savedData", savedData);
          setHistory((prevHistory) => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
          setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
          setFormData((prevData) => ({ ...prevData, description: savedData }));
          
        },
      });
      return () => {
        if (editorInstance.current) {
          editorInstance.current.destroy();
          editorInstance.current = null;
        }
      };

      
    }

    return () => {
      if (editorInstance.current) {
        editorInstance.current.destroy();
        editorInstance.current = null;
      }
    };
  }, [showEditor]);

  const handleFontSizeChange = async (size) => {
    console.log("size",size);
    const selection = window.getSelection();
    
    // Check if there's a valid selection
    if (!selection || selection.rangeCount === 0) {
      console.log("No text selected"); // Optional: You can show a message or do nothing
      //toast.error("No text is selected");
      return;
    }
  
    const range = selection.getRangeAt(0); // Get the selected range
    
    // If the range is collapsed (no selection), exit the function
    if (range.collapsed) {
      console.log("No text is selected");
      //toast.error("No text is selected");
      return;
    }
  
    // If text is selected, modify it
    const selectedText = range.extractContents(); // Extract the selected content
    const span = document.createElement('span');
    span.style.fontSize = size + 'px'; // Apply the font size
    span.appendChild(selectedText); // Append the selected content inside the span
    
    range.insertNode(span); // Insert the new span back into the range
  
    // Clear selection to avoid multiple insertions
    selection.removeAllRanges();
  
    // Update the selected font size in state
    //setSelectfontsize(size);
    setSelectfontsize("");

    // Save the editor data and update history
  const savedData = await editorInstance.current.save();
  setHistory((prevHistory) => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
  setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
  };



const handleFontFamilyChange = async (family) => {
  const selection = window.getSelection();
  
  // Check if there's a valid selection
  if (!selection || selection.rangeCount === 0) {
    console.log("No text selected");
    toast.error("No text is selected");
    return;
  }

  const range = selection.getRangeAt(0); // Get the selected range

  // If the range is collapsed (no selection), exit the function
  if (range.collapsed) {
    console.log("No text is selected");
    toast.error("No text is selected");
    return;
  }

  // If text is selected, modify it
  const selectedText = range.extractContents(); // Extract the selected content
  const span = document.createElement('span');
  span.style.fontFamily = family; // Apply the font family
  span.appendChild(selectedText); // Append the selected content inside the span
  
  range.insertNode(span); // Insert the new span back into the range

  // Clear selection to avoid multiple insertions
  selection.removeAllRanges();

  // Update the selected font family in state
  setSelectfontfamily(family);

   // Save the editor data and update history
   const savedData = await editorInstance.current.save();
   setHistory((prevHistory) => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
   setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
};
const handleFontStyleChange = async (style) => {
  console.log("Font style:", style);
  const selection = window.getSelection();
  if (selection.rangeCount === 0) return;

  const range = selection.getRangeAt(0); // Get the selected range
  const span = document.createElement('span');

  if (style === "bold") {
      span.style.fontWeight = "bold"; // Apply bold style
  } else if (style === "italic") {
      span.style.fontStyle = "italic"; // Apply italic style
  }

  range.surroundContents(span); // Wrap the selected content with the span
   // Save the editor data and update history
   const savedData = await editorInstance.current.save();
   setHistory((prevHistory) => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
   setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
};

const handleColorChange = async (color) => {
  console.log("color", color);

  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) {
    toast.error("No text is selected");
    return; // No selection, nothing to change
  }

  const range = selection.getRangeAt(0); // Get the selected range

  // Extract the contents of the range (this will remove them from the document temporarily)
  const selectedContent = range.extractContents();

  // Create a new span element and set the color
  const span = document.createElement('span');
  span.style.color = color;

  // Append the selected content to the new span
  span.appendChild(selectedContent);

  // Insert the span back into the original range
  range.insertNode(span);

  // Clear the selection after applying the color
  selection.removeAllRanges();

  
  
 // Now, trigger the editor's onChange callback to update the history stack
 const savedData = await editorInstance.current.save();
 console.log("Saved Data after color change:", savedData); // Log to verify

 // Update the history for undo/redo functionality
 setHistory((prevHistory) => [
   ...prevHistory.slice(0, currentHistoryIndex + 1),
   savedData,
 ]);
 setCurrentHistoryIndex((prevIndex) => prevIndex + 1);

 // Update the editor data (description field or other)
 setFormData((prevData) => ({ ...prevData, description: savedData }));
};


const handleBackgroundColorChange = async (color) => {
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) return;

  const range = selection.getRangeAt(0); // Get the selected range

  // Extract the selected content from the range
  const selectedContent = range.extractContents();

  // Create a new span element with the background color
  const span = document.createElement('span');
  span.style.backgroundColor = color;

  // Append the extracted content to the span
  span.appendChild(selectedContent);

  // Insert the span back into the original range
  range.insertNode(span);

  // Clear the selection after applying the background color
  selection.removeAllRanges();
  // Optionally, save the editor content
  const savedData =  await editorInstance.current.save();
   setHistory((prevHistory) => [...prevHistory.slice(0, currentHistoryIndex + 1), savedData]);
   setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
};

  const handleSubmit = async (e) => {
    e.preventDefault();


    // if (!formData.title) {
    //   toast.error("Title is required");
    //   return;
    // }
    // if (!formData.subTitle) {
    //   toast.error("Sub title is required");
    //   return;
    // }
    // if (!formData.titleDescription) {
    //   toast.error("Title description is required");
    //   return;
    // }

    // if (
    //   !formData.uxinsightslist ||
    //   Object.keys(formData.uxinsightslist).length === 0
    // ) {
    //   toast.error("Please select UX Insight");
    //   return;
    // }
    if (
      !formData.description ||
      Object.keys(formData.description).length === 0
    ) {
      toast.error("Please enter description");
      return;
    }
   


    console.log("formData", formData);
    const formDataToSend = new FormData();
    formDataToSend.append("id", mainid);
    formDataToSend.append("annotation_id", id);
    //formDataToSend.append("title", formData.title);
    //formDataToSend.append("subTitle", formData.subTitle);
    //formDataToSend.append("titleDescription", formData.titleDescription);


    if (formData.selectedDesktopFiles.length > 0) {
        for (let i = 0; i < formData.selectedDesktopFiles.length; i++) {
          formDataToSend.append('desktopFiles', formData.selectedDesktopFiles[i]);
        }
    }

    formDataToSend.append("description", JSON.stringify(formData.description));
    formDataToSend.append("uxinsightslist", JSON.stringify(formData.uxinsightslist));


    console.log("formDataToSend", formDataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/addAnnotationDetail`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if (response.data.status == 1) {
        swal("Success!", "Data saved successfully", "success");
        
         if(isview === "yes")
          {
            navigate(`/research-view-annotation/${mainid}`);
          }
          else
          {
            navigate(`/annotations-list/${mainid}`);
          }   
        
        //navigate("/search-keyword");
      }
      else {
        swal("Error!", response.data.message, "error");
      }

    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
  };

  return (
    <DashboardLayout>
      <section className="FilterSec">
        <div className="Add_Guide_Sec">
          <div className="FilterHead">
            <h2>Edit More Details</h2>
          </div>

          <div className=" guidLink mt-5">
            <Link to={isview === "yes" ? `/research-view-annotation/${mainid}` : `/annotations-list/${mainid}`}>
              <ArrowBackIcon /> {isview === "yes" ? "Back To View Page" : "Back"}
            </Link>
          </div>

          <div className="GuidlineForm">
          <div className="Filters">
                  <h6> <strong>Upload Images</strong></h6>
                  <div className="FilterGrid">
                    <div className="DeskImgDiv">
                      <div className="DesktopImages">
                        {/* Display existing desktop images with delete option */}
                        {formData.selectedDesktopFileUrlsShow?.map(
                          (imageUrl, index) => (
                            <div key={index} >
                              <div className="ImgShow" key={index}>
                                <Avatar
                                  src={`${process.env.REACT_APP_BASE_URL}${imageUrl}`}
                                  alt={`Uploaded Icon ${index + 1}`}
                                  style={{
                                    width: 120,
                                    height: 100,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                />
                              
                                <div
                                  className="ImgIcon"
                                  onClick={() =>
                                    handleDeleteDesktopImageShow(
                                      imageUrl,
                                      "desktopimage"
                                    )
                                  }
                                  >
                                  <ClearIcon />
                                  
                                </div>
                                
                              </div>
                              
                              
                            </div>
                          )
                        )}
                        {formData.selectedDesktopFileUrls?.map((imageUrl, index) => (
                          <>
                            <div className="ImgShow">
                              <Avatar key={index} src={imageUrl}  alt={`Uploaded Icon ${index + 1}`}style={{ width: 120, height: 100, marginRight: 0 , borderRadius:2 }}/>
                              <div className="ImgIcon" onClick={() => handleDeleteDesktopImage(index)}>
                                <ClearIcon />
                              </div>
                              {/* <div className="ImgCheckbox">
                                <Checkbox
                                  checked={selectfeaturedesktop === index}  // Compare index with selectedDesktopImage
                                  onChange={() => handleCheckboxChange(index, 'desktop')} // Pass index as parameter
                                  value={index} // Set the value to index
                                  inputProps={{ 'aria-label': `Image ${index + 1}` }}
                                />
                              </div> */}
                            </div>
                          </>
                        
                        ))}
                      </div>
                      <div className="uplbtn">
                        <Button component="label">
                          Upload Images
                        <CloudUploadIcon />
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            multiple
                            onChange={handleDesktopImageChange}
                        />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>  
            <div className="Filters">
              <div className="guidFormControl">
                <h6> <strong>Description</strong></h6>
                <div className="EditFontDiv">
                  <div className="FontFixed">

                    {/* Font Size Selection */}
                    <div className="editfont">
                      <OverlayTrigger placement="top" overlay={<Tooltip>Change Font Size</Tooltip>}>
                        <span><RiFontSize /></span>
                      </OverlayTrigger>
                      <select onChange={(e) => handleFontSizeChange(e.target.value)} value={selectfontsize}>
                      <option key={0} value="">
                            Select
                          </option>
                        {/* Loop to generate font sizes from 12 to 24 */}
                        {[...Array(13).keys()].map(i => (
                          <option key={i + 12} value={i + 12}>
                            {i + 12} px
                          </option>
                        ))}
                      </select>
                    </div>

                      {/* Font Style Selection */}
                      {/* <div className="editfont">
                        <OverlayTrigger  placement="top" overlay={<Tooltip>Change Font Weight</Tooltip>} >
                          <span><GrBold /></span>
                        </OverlayTrigger>
                        
                        <select onChange={(e) => handleFontStyleChange(e.target.value)}>
                          <option value="normal">Normal</option>
                          <option value="bold">Bold</option>
                          <option value="italic">Italic</option>
                        </select>
                      </div> */}

                      {/* Font Family Selection */}
                      <div className="editfont">
                        <OverlayTrigger  placement="top" overlay={<Tooltip>Change Font Family</Tooltip>} >
                          <span><RxFontFamily /></span>
                        </OverlayTrigger>
                        
                        <select onChange={(e) => handleFontFamilyChange(e.target.value)} value={selectfontfamily}>
                          <option value="Arial">Arial</option>
                          <option value="Verdana">Verdana</option>
                          <option value="Tahoma">Tahoma</option>
                          <option value="Times New Roman">Times New Roman</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Courier New">Courier New</option>
                          <option value="Comic Sans MS">Comic Sans MS</option>
                          <option value="Impact">Impact</option>
                          <option value="Roboto">Roboto</option>
                          <option value="Open Sans">Open Sans</option>
                          <option value="Lora">Lora</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Arial Black">Arial Black</option>
                          <option value="Tahoma">Tahoma</option>
                          <option value="Helvetica">Helvetica</option>
                          <option value="Sans-serif">Sans-serif</option>
                          <option value="Serif">Serif</option>
                        </select>
                    </div>

                    {/* Text Color Selection */}
                    <div className="editfont">
                      <OverlayTrigger  placement="top" overlay={<Tooltip>Change Font Color</Tooltip>} >
                        <span><MdFormatColorText /></span>
                      </OverlayTrigger>
                      <input type="color"
                        onChange={(e) => handleColorChange(e.target.value)}
                      />
                    </div>

                    {/* Background Color Selection */}
                    <div className="editfont">
                      <OverlayTrigger  placement="top" overlay={<Tooltip>Change Background Color</Tooltip>} >
                        <span><IoMdColorFill /></span>
                      </OverlayTrigger>
                      
                      <input type="color"  onChange={(e) => handleBackgroundColorChange(e.target.value)}/>
                    </div>

                  </div>
                  
                  <div className="EditiorDiv" id="editorjs"  ></div>
                  {/* Internal CSS to ensure bullets/numbers show correctly */}
                  <style>{`#editorjs ul.cdx-list--unordered li {list-style-type: disc !important; /* Force show bullets */ }
                          #editorjs ul.cdx-list--ordered li { list-style-type: decimal !important; /* Force show numbers for ordered lists */ } `}
                  </style>
                </div>

              </div>
            </div>
            <div className="MoreDetailsDiv">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="guidFormControl">
                      <h6> <strong>References</strong></h6>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>UX Insights</InputLabel>
                        <Select
                          label="UX Insights"
                          multiple
                          value={formData.uxinsightslist}
                          onChange={handleChangeUXInsights}
                          renderValue={(selected) => selected.join(', ')}
                          style={{ lineHeight: "3em" }}
                        >
                          {uxinsights.map((uxinsight) => (
                            <MenuItem key={uxinsight} value={uxinsight}>
                              <Checkbox checked={formData.uxinsightslist.indexOf(uxinsight) > -1} />
                              <ListItemText primary={uxinsight} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
                
                           
                <div style={{ display: "flex", justifyContent: "center", padding: "50px 0px" }}>
                  <Button type="submit" class="myButton" data-label="Save"></Button>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </DashboardLayout>
  );
}
