class HorizontalLine {
    static get toolbox() {
        return {
            title: 'Horizontal Line',
            icon: '<svg width="20" height="20" viewBox="0 0 20 20"><line x1="1" y1="10" x2="19" y2="10" stroke="black" stroke-width="2"/></svg>',
        };
    }

    render() {
        console.log("Rendering HorizontalLine");
        const hr = document.createElement('hr');
        return hr;
    }

    save() {
        return {
            type: 'horizontal-line'
        };
    }
}

export default HorizontalLine;
