import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import MDAvatar from "components/MDAvatar";
import moment from 'moment';
import { Icon, Tooltip } from '@mui/material';
import {
  Button,
} from "@mui/material";


const ToggleSwitch = ({ id, isPublished, onToggle }) => {
  const switchStyles = {
    position: 'relative',
    display: 'inline-block',
    width: '40px',
    height: '20px'
  };

  const sliderStyles = {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: isPublished ? '#2196F3' : '#ccc',
    transition: '.4s',
    borderRadius: '20px'
  };

  const circleStyles = {
    position: 'absolute',
    content: '""',
    height: '12px',
    width: '12px',
    left: isPublished ? '24px' : '4px',  // Move the circle based on checked state
    bottom: '4px',
    backgroundColor: 'white',
    transition: '.4s',
    borderRadius: '50%'
  };

  return (
    <label style={switchStyles}>
      <input 
        type="checkbox" 
        checked={isPublished} 
        onChange={() => onToggle(id, !isPublished)} 
        style={{ display: 'none' }}  // Hide the default checkbox input
      />
      <span style={sliderStyles}>
        <span style={circleStyles}></span>
      </span>
    </label>
  );
};

// ActionButtons component defined in the same file
const ActionButtonsList = ({ id,onViewCat }) => {
  return (
    <>
      
      {/* <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onViewCat(id)}
      >
        visibility
      </Icon> */}
       
      <Button
        style={{ cursor: "pointer", color: "#fff", marginLeft: "10px" }}
        onClick={() => onViewCat(id)}
        variant="contained" // You can change the variant to "outlined" if needed
      >
        Edit Sub Type
      </Button>
      
    </>
  );
};
const ActionButtons = ({ id, onEdit, onView, onDelete }) => {
  return (
    <>
      <Tooltip title="Edit" arrow>
        <Icon
          style={{ cursor: "pointer", color: "primary" }}
          onClick={() => onEdit(id)}
        >
          edit
        </Icon>
      </Tooltip>
     
      {/* <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onView(id)}
      >
        visibility
      </Icon> */}
      <Tooltip title="Delete" arrow>
      <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onDelete(id)}
      >
        delete
      </Icon>

      </Tooltip>
      
    </>
  );
};

export default function Data() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);

  const LoadData = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getPageType`).then((res) => {
        var response = res.data.pageTypes;
        var responseData = [];
        for (var i = 0; i < response.length; i++) {
          var inc = i + 1;

          responseData.push({
            id: inc,
            name: response[i].name,
            status: response[i].status,
            statusToggle: (
              <ToggleSwitch
                id={response[i]._id}
                isPublished={response[i].status}
                onToggle={handleTogglePublish}
              />
            ),
            date: formatUTCDate(response[i].createdAt),
            action: (
              <ActionButtons
                id={response[i]._id}
                onEdit={handleEdit}
                // onView={handleView}
                onDelete={handleDelete}
              />
            ), // Using the ActionButtons component
          });
        }
        setData(responseData);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  const handleToggleForUser = async (id, newStatus) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/updateForUserStatus`, {
        id: id,
        forUsers: newStatus ? 1 : 0
      });
      setData(prevData =>
        prevData.map(item =>
          item.id === id ? { ...item, forUsers: newStatus } : item
        )
      );
      LoadData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleTogglePublish = async (id, newStatus) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/updatePageTypeStatus`, {
        id: id,
        status: newStatus
      });
      // Optionally update the UI after successful response
      setData(prevData => prevData.map(item => 
        item.id === id ? { ...item, status: newStatus } : item
      ));
      console.log(`Page Type ${id} is now ${newStatus ? 'Active' : 'Inactive'}`);
      LoadData();
    } catch (error) {
      console.log(error);
    }
  };

  
  // Handle view action
  const handleViewCat = (id) => {
    navigate(`/guideline-sub-types/${id}`);
  };

  // Handle edit action
  const handleEdit = (id) => {
    navigate(`/page-type-edit/${id}`);
  };

  // Handle view action
  // const handleView = (id) => {
  //   navigate(`/view-guideline/${id}`);
  // };

  // Handle delete action
  const handleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Page Type!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deletePageType`, { id });
          swal("Deleted!", "The Page Type has been deleted.", "success");
          LoadData(); // Reload the data after deletion
        } catch (error) {
          swal("Error!", "Failed to delete the Page Type.", "error");
        }
      } else {
        swal("Your Page Type is safe!");
      }
    });
  };

  return {
    columns: [
      { Header: "Sno", accessor: "id" },
      // { Header: "icon", accessor: "icon" },
       { Header: "name", accessor: "name" },
      // { Header: "pagetype available", accessor: "pagetype" },
     
      { Header: "Active/Inactive", accessor: "statusToggle" },
      { Header: "date", accessor: "date" },
      // { Header: "Sub Type", accessor: "subcatlist" },
      { Header: "action", accessor: "action" }, // Added the action columns
    ],

    rows: data ? data : "",
  };
}

// Format date helper function
function formatUTCDate(utcDate) {
  const momentDate = moment(utcDate);
  return momentDate.format('MM/DD/YYYY, hh:mm A');
}
