import {
  Link,
} from 'react-router-dom';

import Icon from "@mui/material/Icon";


// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/searchKeyword/data/authorsTableData";

function Tables() {
  const { columns, rows } = authorsTableData();

  return (
    <DashboardLayout>

      <section className="FilterSec">
        <div className="Add_Guide_Sec">

          <div className="FilterHead">
            <h2>Search Keywords List</h2>
          </div>
          <div className="guidLink mt-5">
            <Link to="/search-keyword-add"><Icon fontSize="small">add</Icon>Add Keyword</Link>
          </div>

          <div className="GuidlineForm">
              <div className='GuideCateDiv'>
                  
                <DataTable
                  table={{ columns, rows }}
                  //isSorted={false}
                  canSearch={true}
                  entriesPerPage={false}
                  pagination={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </div>
          </div>

        </div>

      </section>

        


    </DashboardLayout>
  );
}

export default Tables;
