import EditorJS from '@editorjs/editorjs';

class CustomSectionTool {
  constructor({ data, api, config }) {
    this.api = api;
    this.data = {
      backgroundColor: data?.backgroundColor || "",
      padding: data?.padding || "10px",
      borderRadius: data?.borderRadius || "5px",
      gap: data?.gap || "10px",
      content: data?.content || "",
    };
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("custom-section");
    this.wrapper.contentEditable = true;
    this.wrapper.style.backgroundColor = this.data.backgroundColor;
    this.wrapper.style.padding = this.data.padding;
    this.wrapper.style.borderRadius = this.data.borderRadius;
    this.wrapper.style.marginBottom = this.data.gap;
    this.wrapper.innerHTML = this.data.content;
    this.wrapper.addEventListener("input", () => {
      this.data.content = this.wrapper.innerHTML;
    });

    // Add toolbar buttons
    this.toolbar = this.createToolbar();
    this.wrapper.appendChild(this.toolbar);
    return this.wrapper;
  }

  createToolbar() {
    const toolbar = document.createElement("div");
    toolbar.classList.add("custom-section-toolbar");
    toolbar.style.position = "absolute";
    toolbar.style.top = "-30px";
    toolbar.style.left = "0";
    toolbar.style.background = "#f1f1f1";
    toolbar.style.padding = "5px";
    toolbar.style.display = "flex";
    toolbar.style.gap = "5px";

    const boldBtn = document.createElement("button");
    boldBtn.innerHTML = "<b>B</b>";
    boldBtn.addEventListener("click", () => document.execCommand("bold"));
    
    const italicBtn = document.createElement("button");
    italicBtn.innerHTML = "<i>I</i>";
    italicBtn.addEventListener("click", () => document.execCommand("italic"));

    const underlineBtn = document.createElement("button");
    underlineBtn.innerHTML = "<u>U</u>";
    underlineBtn.addEventListener("click", () => document.execCommand("underline"));

    toolbar.appendChild(boldBtn);
    toolbar.appendChild(italicBtn);
    toolbar.appendChild(underlineBtn);
    return toolbar;
  }

  renderSettings() {
    const wrapper = document.createElement("div");
    wrapper.className = "ce-settings";
    const createInput = (labelText, type, defaultValue, onChange) => {
      const container = document.createElement("div");
      const label = document.createElement("label");
      label.textContent = labelText;
      
      const input = document.createElement("input");
      input.type = type;
      input.value = defaultValue;
      input.addEventListener("input", (event) => onChange(event.target.value));
      
      container.appendChild(label);
      container.appendChild(input);
      return container;
    };
    
    wrapper.appendChild(createInput("BG Color:", "color", this.data.backgroundColor, (value) => {
      this.data.backgroundColor = value;
      this.wrapper.style.backgroundColor = value;
    }));

    wrapper.appendChild(createInput("Padding:", "text", this.data.padding, (value) => {
      this.data.padding = value;
      this.wrapper.style.padding = value;
    }));
    
    wrapper.appendChild(createInput("Border Radius:", "text", this.data.borderRadius, (value) => {
      this.data.borderRadius = value;
      this.wrapper.style.borderRadius = value;
    }));

    return wrapper;
  }

  save() {
    return this.data;
  }
}

export default CustomSectionTool;
