import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TextField,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";

const FilterList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const filterItem = location.state?.filterItem || {}; // Retrieve the passed item (filter data)

  // State to hold the filter name, type, and the list of filters
  const [filterName, setFilterName] = useState(""); // Only one filter name now
  const [filterType, setFilterType] = useState(filterItem.name || ""); // Set filterType to filterItem.name if available
  const [filters, setFilters] = useState([]); // State to hold filters list
  const [typeId, setTypeId] = useState(null);
  console.log("Filter Type:", filterType);
  // Fetch filters from API
  const fetchFilters = async () => {
    try {
      // Fetch the typeId from the backend using the filterType
      const typeResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/getTypeIdByType`,
        { type: filterType }
      );

      if (typeResponse.data.status === 1 && typeResponse.data.typeId) {
        const fetchedTypeId = typeResponse.data.typeId;
        setTypeId(fetchedTypeId); // Set the fetched typeId

        // Fetch filters using the retrieved typeId
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/GetFilterList`,
          { typeId: fetchedTypeId }
        );

        if (response.data.status === 1) {
          setFilters(response.data.data); // Set filters state with the fetched data
        } else {
          swal(
            "Error",
            response.data.errors.message || "Failed to fetch filters",
            "error"
          );
        }
      } else {
        swal(
          "Error",
          typeResponse.data.errors.message || "Failed to fetch typeId",
          "error"
        );
      }
    } catch (error) {
      console.error("Error fetching filters:", error);
      swal("Error", "Failed to fetch filters.", "error");
    }
  };

  // Fetch filters when component mounts or filterType changes
  useEffect(() => {
    if (filterType) {
      fetchFilters();
    }
  }, [filterType]);
  // Fetch filters when component mounts or filterType changes
  useEffect(() => {
    if (filterType) {
      fetchFilters();
    }
  }, [filterType]); // Depend on filterType to fetch new data when it changes

  // Handle change for the filter name input field
  const handleInputChange = (e) => {
    setFilterName(e.target.value); // Update the single filter name
  };

  // Handle filter type input change
  const handleTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleAddFilters = async () => {
    // Validate the filter name and type
    if (!filterName.trim() || !filterType.trim()) {
      swal("Error", "All fields are required", "error");
      return;
    }

    try {
      // Fetch typeId from Filter table based on filterType
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/getTypeIdByType`,
        { type: filterType }
      );

      // Check if the response is valid and has the typeId
      if (response.data.status !== 1 || !response.data.typeId) {
        swal("Error", "Invalid filter type or type not found", "error");
        return;
      }

      const typeId = response.data.typeId;

      // Prepare the data for API call
      const filtersData = {
        name: filterName,
        type: filterType,
        typeId, // Use the fetched typeId
      };

      // Make the API call to add filter list
      const addFilterResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/AddFilterList`,
        filtersData
      );

      if (addFilterResponse.data.status === 1) {
        swal("Success", "Filter List added successfully!", "success");

        // Clear the filterName field after successful addition
        setFilterName("");

        // Fetch filters again to update the list
        fetchFilters(); // Re-fetch filters after adding
      } else {
        swal(
          "Error",
          addFilterResponse.data.errors.message || "Failed to add filter",
          "error"
        );
      }
    } catch (error) {
      console.error("Error fetching typeId or adding filters:", error);
      swal("Error", "Failed to add filters.", "error");
    }
  };

  const handleDeleteFilter = async (filterId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/deleteFilterList`,
        { id: filterId }
      );
      if (response.data.status === 1) {
        swal("Success", "Filter deleted successfully!", "success");
        fetchFilters(); // Re-fetch filters after deletion
      } else {
        swal(
          "Error",
          response.data.errors.message || "Failed to delete filter",
          "error"
        );
      }
    } catch (error) {
      console.error("Error deleting filter:", error);
      swal("Error", "Failed to delete filter.", "error");
    }
  };

  return (
    <>
      <div className="container">
        <div className="dd">
          <div className="BackBtn">
            <Button
              variant="contained"
              onClick={() => navigate(-1)} // Go back to the previous page
              style={{ marginTop: "16px", width: "10%", color: "#fff" }} // Style the Back button
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </div>

          <section className="FilterSec">
            <div className="container">
              <div className="Add_Guide_Sec">
                <div className="FilterHead">
                  <h2>Add Filter List</h2>
                </div>

                <MDBox p={3}>
                  <div className="d">
                    <TextField
                      label="Filter Type"
                      className="Indt"
                      value={filterType}
                      onChange={handleTypeChange} // Optional, if you don't want to change the default type
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small" // Make the TextField smaller
                      InputProps={{
                        readOnly: true,
                        style: { fontSize: "21px" },
                      }} // Makes the input read-only
                      InputLabelProps={{ style: { fontWeight: "bold" } }} // Make the label bold
                    />

                    <TextField
                      label="Filter Name"
                      value={filterName} // This will hold the single filter name
                      onChange={handleInputChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small" // Make the TextField smaller
                    />

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px 0px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddFilters}
                        size="small" // Make the Button smaller
                        sx={{ color: "#fff" }}
                      >
                        Add Filter List
                      </Button>
                    </div>
                  </div>

                  <Box sx={{ marginTop: "16px" }}>
                    <h2 style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                      {filterType} List:
                    </h2>
                    <TableContainer
                      style={{ maxHeight: "400px", overflow: "auto" }}
                    >
                      <Table className="FilterTable">
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            {/* <TableCell>Type</TableCell> */}
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filters.length > 0 ? (
                            filters.map((filter, index) => (
                              <TableRow key={filter._id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{filter.name}</TableCell>
                                {/* <TableCell>{filter.type}</TableCell> */}
                                <TableCell>{filter.status}</TableCell>
                                <TableCell>
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    onClick={() =>
                                      handleDeleteFilter(filter._id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3} align="center">
                                No data available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </MDBox>
              </div>
            </div>
          </section>
        </div>
      </div>

      
    </>
  );
};

export default FilterList;
