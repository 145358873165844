import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Editor from "components/Custom/Editor";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

export default function EditContent() {
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [content, setContent] = useState("");
    let navigate = useNavigate();
    let { id } = useParams();
    const [errors, setErrors] = useState(false);
    const [data, setData] = useState(false);
    useEffect(() => {
        setEditorLoaded(true);
        if(id){
            axios.post("/api/admin/getFaqById", {id}).then((res) => {
                const response = res.data;
                setData(response.faqsList);
            });
        }
    }, [id]);

    const changeData = (e) => {
        var name=e.target.name;
        var value=e.target.value;
        setData({name: value, 'content': 'test'});
    };

    const submitData = (e) => {
        e.preventDefault();
        var data = {
            id,
            question: e.target.faq_question.value,
            answer: e.target.faq_answer.value,
        };
        axios.post("/api/admin/editFaqs", data).then((res) => {
            const response = res.data;
            if (response.status == 0) {
                setErrors(response.errors);
            } else {
                swal("success", "Faq edited successfully!", "success");
                navigate("/faqs");
            }
        });
    };  
     
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                            Edit Content
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <div className="formContainer">
                                <form onSubmit={submitData}>


                                <div class="form-group">
                                        <label for="faq_question">Question:</label>
                                        <input type="text" class="form-control" id= "faq_question" name="faq_question" value={data?.question} onChange={changeData}/>
                                        <span className="redError">{errors.faq_question ? errors.faq_question : ""}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="faq_answer">Answer:</label>
                                        <textarea class="form-control" id="faq_answer" name="faq_answer" rows="4" cols="50" value={data?.answer} onChange={changeData}/> 
                                        
                                        <span className="redError">{errors.faq_answer ? errors.faq_answer : ""}</span>
                                    </div>


                                   
                                    <button type="submit" class="btn btn-primary float-end mt-2 mb-2">Update</button>
                                </form> 
                            </div>
                        </MDBox>
                    </Card>
                </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
