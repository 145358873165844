class CustomTitleTool {
  constructor({ data, api }) {
    this.api = api;
    this.data = {
      text: data.text || "",
      margin: data.margin || "0px",
      padding: data.padding || "0px",
      textColor: data.textColor || "",
      backgroundColor: data.backgroundColor || "",
      fontSize: data.fontSize || "22px",
      fontStyle: data.fontStyle || "normal",
      fontWeight: data.fontWeight || "400",
      lineHeight: data.lineHeight || "22px",
    };
    this.wrapper = null;
  }

  static get toolbox() {
    return {
      title: "Title",
      icon: "T",
    };
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.contentEditable = true;
    this.wrapper.classList.add("custom-title-tool");
    this.wrapper.innerHTML = this.data.text;

    this.applyStyles();

    this.wrapper.addEventListener("input", (event) => {
      this.data.text = event.target.innerHTML;
    });

    return this.wrapper;
  }

  applyStyles() {
    this.wrapper.style.margin = this.data.margin;
    this.wrapper.style.padding = this.data.padding;
    this.wrapper.style.color = this.data.textColor;
    this.wrapper.style.backgroundColor = this.data.backgroundColor;
    this.wrapper.style.fontSize = this.data.fontSize; // Default title size
    this.wrapper.style.fontStyle = this.data.fontStyle; // Apply dynamic font style
    this.wrapper.style.fontWeight = this.data.fontWeight;
    this.wrapper.style.lineHeight = this.data.lineHeight;
    
  }

  renderSettings() {
    const wrapper = document.createElement("div");

    const createColorInput = (labelText, defaultValue, onChange) => {
      const container = document.createElement("div");
      container.style.display = "flex";
      container.style.alignItems = "center";
      container.style.marginBottom = "5px";
      container.style.gap = "5px";

      const label = document.createElement("label");
      label.textContent = labelText;
      label.style.fontSize = "12px";
      label.style.marginRight = "5px";
      label.style.width = "110px";

      // 🔹 Manual text input for color
      const textInput = document.createElement("input");
      textInput.type = "text";
      textInput.value = defaultValue;
      textInput.style.width = "70px";
      textInput.style.fontSize = "12px";
      textInput.style.textAlign = "center";

      textInput.addEventListener("input", (event) => {
        onChange(event.target.value);
        this.applyStyles();
      });

      // 🔹 Color picker input
      const colorInput = document.createElement("input");
      colorInput.type = "color";
      colorInput.value = defaultValue.startsWith("#") ? defaultValue : ""; // Default if not hex
      colorInput.style.width = "40px";
      colorInput.style.cursor = "pointer";

      colorInput.addEventListener("input", (event) => {
        textInput.value = event.target.value; // Sync text input
        onChange(event.target.value);
        this.applyStyles();
      });

      container.appendChild(label);
      container.appendChild(textInput);
      container.appendChild(colorInput);
      return container;
    };

    const createTextInput = (labelText, defaultValue, onChange) => {
      const container = document.createElement("div");
      container.style.display = "flex";
      container.style.alignItems = "center";
      container.style.marginBottom = "5px";

      const label = document.createElement("label");
      label.textContent = labelText;
      label.style.fontSize = "12px";
      label.style.marginRight = "5px";
      label.style.width = "110px";

      const input = document.createElement("input");
      input.type = "text";
      input.value = defaultValue;
      input.style.width = "80px";
      input.style.textAlign = "center";
      input.style.fontSize = "12px";

      input.addEventListener("input", (event) => {
        onChange(event.target.value);
        this.applyStyles();
      });

      container.appendChild(label);
      container.appendChild(input);
      return container;
    };

    const createDropdown = (labelText, options, defaultValue, onChange) => {
      const container = document.createElement("div");
      container.style.display = "flex";
      container.style.alignItems = "center";
      container.style.marginBottom = "5px";

      const label = document.createElement("label");
      label.textContent = labelText;
      label.style.fontSize = "12px";
      label.style.marginRight = "5px";
      label.style.width = "110px";

      const select = document.createElement("select");
      select.style.width = "90px";
      select.style.fontSize = "12px";
      select.style.textAlign = "center";

      options.forEach((option) => {
        const opt = document.createElement("option");
        opt.value = option;
        opt.textContent = option.charAt(0).toUpperCase() + option.slice(1);
        if (option === defaultValue) opt.selected = true;
        select.appendChild(opt);
      });

      select.addEventListener("change", (event) => {
        onChange(event.target.value);
        this.applyStyles();
      });

      container.appendChild(label);
      container.appendChild(select);
      return container;
    };

    wrapper.appendChild(createTextInput("Margin:", this.data.margin, (value) => {
      this.data.margin = value;
    }));

    wrapper.appendChild(createTextInput("Padding:", this.data.padding, (value) => {
      this.data.padding = value;
    }));

    wrapper.appendChild(createColorInput("Text Color:", this.data.textColor, (value) => {
      this.data.textColor = value;
    }));

    wrapper.appendChild(createColorInput("Background Color:", this.data.backgroundColor, (value) => {
      this.data.backgroundColor = value;
    }));

    wrapper.appendChild(createTextInput("Font Size:", this.data.fontSize, (value) => {
        this.data.fontSize = value.endsWith("px") ? value : `${value}px`; 
    }));

    wrapper.appendChild(createDropdown("Font Style:", ["normal", "bold", "italic"], this.data.fontStyle, (value) => {
        //this.data.fontStyle = value;
        if (value === "bold") {
          this.data.fontWeight = "bold"; // ✅ Set bold as fontWeight
          this.data.fontStyle = "normal"; // ✅ Prevent conflict with fontStyle
        } else {
          this.data.fontStyle = value; // ✅ Set italic or normal correctly
          this.data.fontWeight = "400"; // ✅ Reset bold when switching to normal/italic
        }
        this.applyStyles();
    }));

    wrapper.appendChild(createTextInput("Font Weight:", this.data.fontWeight, (value) => {
      this.data.fontWeight = value;
    }));

    wrapper.appendChild(createTextInput("Line Height:", this.data.lineHeight, (value) => {
      this.data.lineHeight = value.endsWith("px") || value.endsWith("em") ? value : `${value}px`;
    }));

    return wrapper;
  }

  save() {
    return this.data;
  }
}

export default CustomTitleTool;
