import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, Link, Router, } from "react-router-dom";
import AddCommentIcon from '@mui/icons-material/AddComment';
import swal from "sweetalert";
import { FaSearch , FaChevronDown } from "react-icons/fa";

const CustomEditor = (props) => {
  console.log("props.....",props);
  const { id } = useParams();
  const { setRefreshAdd } = props;
  const { geometry } = props.annotation;
  const [guidelines, setGuidelines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompliant, setSelectedCompliant] = useState("");
  const [annotationlist, setAnnotationlist] = useState([]);
  const [triggerApiCall, setTriggerApiCall] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [formErrors, setFormErrors] = useState({
    guideline: "",
    compliant: "",
    heading: "",
    subtext: "",
    impact: "",
    effort: "",
  });

  const complianceColors = {
    "Compliant High": "#2E9892", // Green
    "Compliant Low": "#2E9892", // Orange
    "Breach Moderate": "#E3A020", // Yellow
    "Breach High": "#D0463E", // Red
  };
  const compliancePoints = {
    "Compliant High": 0,
    "Compliant Low": -1,
    "Breach Moderate": -3,
    "Breach High": -5,
  };
   
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState(null); // State to track selected value
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownOpen(true); // Open dropdown when typing
  };

  const handleOptionSelect = (guideline) => {
    //console.log("guidelineguideline",guideline);
    // Update the selected guideline
    setSelectedGuideline(guideline);

    // Update annotations
   

    props.onChange({
      ...props.annotation,
      data: { 
        ...props.annotation.data, 
        guideline: guideline._id,
        guidelineHashNumber: guideline.hashNumber,
      },
    });

    setIsDropdownOpen(false); // Close dropdown after selection
  };
  
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  // Fetch guidelines from backend API
  useEffect(() => {
    const fetchGuidelines = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getAnnotationGuidelines`); // Replace with your actual API endpoint
        setGuidelines(response.data.guidelines); // Assuming response.data is an array of guidelines
        setLoading(false);
      } catch (error) {
        console.error("Error fetching guidelines:", error);
        setLoading(false);
      }
    };

    fetchGuidelines();
  }, []);

  // Filter guidelines based on the search term
  // const filteredGuidelines = guidelines.filter((guideline) =>
  //   guideline.title.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  const filteredGuidelines = guidelines.filter((guideline) =>
    guideline.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    guideline.hashNumber.toString().toLowerCase().includes(searchTerm.toLowerCase()) // ✅ Check hashNumber too
  );

  // Check if all required fields are filled
  const validateForm = () => {
    const errors = {};
    if (!props.annotation.data?.guideline) errors.guideline = "Guideline is required";
    if (!selectedCompliant) errors.compliant = "Compliance is required";
    if (!props.annotation.data?.heading) errors.heading = "Heading is required";
    //if (!props.annotation.data?.subtext) errors.subtext = "Subtext is required";
    if (!props.annotation.data?.impact) errors.impact = "Impact is required";
    if (!props.annotation.data?.effort) errors.effort = "Effort is required";
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmitComment = () => {
    if (validateForm()) {
      // Submit form if validation passes
      console.log("propsssssssssss",props);
      //props.onSubmit();

      const { geometry, data } = props.annotation;
     

      const newAnnotationData = {
        geometry,
        data: {
          ...data,
          id: Math.random(),
          imageId: props.imageId,
          number: props.number + 1,
        },
      };
      //setAnnotationlist([...annotationlist, newAnnotationData]);
      // Use a functional update for state
      // setAnnotationlist((prevAnnotationList) => {
      //   const updatedList = [...prevAnnotationList, newAnnotationData];
      //   console.log("Updated annotationlist", updatedList); // Logs updated state
      //   return updatedList;
      // });

      // Update the state
      setAnnotationlist((prevAnnotationList) => [...prevAnnotationList, newAnnotationData]);

      // Set trigger for API call
      setTriggerApiCall(true);
      
      
      }
  };

  // API call effect, triggers when `annotationlist` is updated
  useEffect(() => {
    if (triggerApiCall) {
      // Ensure state is updated before making the API call
      const sendAnnotations = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append("main_id", id);
        //formDataToSend.append("newAnnotation", annotationlist);
        formDataToSend.append("newAnnotation", JSON.stringify(annotationlist));
        try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/AddResearchAnnotationSub`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Important for file upload
            },
          }
        );
        //console.log("responseresponse",response);
        if (response.data.status == 1) {
          swal("Success!", "Data saved successfully", "success");
          //navigate(`/annotations-list/${mainid}`);
          
         // setRefreshAdd((prev) => !prev); 
          window.location.reload();
        } else {
          swal("Error!", response.data.message, "error");
        }
        } catch (error) {
          console.error("Error saving data:", error); // Log error for debugging
          swal("Error!", error.message, "error");
        }
      };
      sendAnnotations();
    }
  }, [triggerApiCall, annotationlist]); // Dependencies for effect


  if (!geometry) {
    return null; // Ensure this conditional logic is outside of hooks
  }
  const handleSelection = (value) => {
    setSelectedCompliant(value); // Update the selected value
    const point = compliancePoints[value];
    props.onChange({
      ...props.annotation,
      data: { 
        ...props.annotation.data, 
        compliant: value ,
        compliantPoint: point,
      },
    });
  };

   

  // Dynamically adjust the position
  let adjustedLeft = geometry.x; 
  if(geometry.x > 75)
  {
    let minusleft = geometry.x - 75;
    adjustedLeft = geometry.x - minusleft;
  }
  
 

 // console.log("old-left", geometry.x);
  //console.log("new-left", adjustedLeft);

  

  return (
    <div className="AssignGuidlineDiv" style={{
       //left: `${geometry.x}%`,  
       top: `${geometry.y + geometry.height}%`,
       left: `${adjustedLeft}%`,
       //top: `${adjustedTop}%`,


       }}>
      {/* Dropdown for Assign Guideline */}

      <div className="TopAssignEdit">
        <h6>Assign Guideline</h6>
        {loading ? (
          <p>Loading guidelines...</p>
        ) : (
        <>
        <div className="InputTag"  onClick={toggleDropdown}>
           <h4>{selectedGuideline
            ? `Guideline #${selectedGuideline.hashNumber} - ${selectedGuideline.title}`
            : `Search Guideline`}</h4>
           
           <FaChevronDown
            style={{ cursor: "pointer", marginLeft: "8px" }}
            // Toggle dropdown on click
          />
        
        </div>
        {isDropdownOpen && (
          <ul className="SerchTagUl">
            <div className="InputserchTag">
              <FaSearch />
              <input
                type="text"
                placeholder="Search by Guideline Name"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            {filteredGuidelines.map((guideline) => (
              <li
                key={guideline._id}
                // style={{
                //   padding: "8px",
                //   cursor: "pointer",
                //   backgroundColor: annotations.data.guideline === guideline._id ? "#f0f0f0" : "white",
                // }}
                onClick={() => handleOptionSelect(guideline)}
              >
                Guideline #{guideline.hashNumber} - {guideline.title}
              </li>
            ))}
          </ul>
        )}
        {/* <select
        style={{
          width: "100%",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
        onChange={(e) => {
          const selectedGuideline = guidelines.find(
            (guideline) => guideline._id === e.target.value
          );
          props.onChange({
            ...props.annotation,
            data: {
              ...props.annotation.data,
              guideline: e.target.value,
              guidelineHashNumber: selectedGuideline ? selectedGuideline.hashNumber : "",
            },
          });
        }}
      >
        <option value="">Select Guideline</option>
        {filteredGuidelines.map((guideline) => (
          <option key={guideline._id} value={guideline._id}>
            Guideline #{guideline.hashNumber} - {guideline.title}
          </option>
        ))}
        </select> */}
        </>
        
        )}
        {formErrors.guideline && (
          <span style={{ color: "red", fontSize: "12px" }}>{formErrors.guideline}</span>
        )}

    
        <div className="ComplaintDiv">
          <h6>Choose Compliant</h6>
          <div className="CompltbtnDiv">
              {["Compliant High", "Compliant Low", "Breach Moderate", "Breach High"].map(
              (compliance, index) => (
                <button
                  key={index}
                  type="button"
                  style={{
                    background: selectedCompliant === compliance ? complianceColors[compliance] : "transparent",
                    color: selectedCompliant === compliance ? '#f0f0f0' : complianceColors[compliance],
                    border: "1px solid  ",
                    borderRadius: "4px",
                    padding: "8px",
                    
                  }}
                  onClick={() => handleSelection(compliance)}
                >
                  {compliance}
                </button>
              )
            )}
          </div>
          {formErrors.compliant && (
              <span style={{ color: "red", fontSize: "12px" }}>{formErrors.compliant}</span>
            )}
        </div>

      </div>

      {/* Input for Heading and Subtext */}
      <div className="AssignGuidHead">

        <div className="indivs">
          <textarea
          
            placeholder="Enter Heading"
            onChange={(e) =>
              props.onChange({
                ...props.annotation,
                data: { ...props.annotation.data, heading: e.target.value },
              })
            }
          />
          {formErrors.heading && (
            <span style={{ color: "red", fontSize: "12px" }}>{formErrors.heading}</span>
          )}
        </div>
        <div className="indivs">
          <textarea
            
            placeholder="Enter Sub Text"
            onChange={(e) =>
              props.onChange({
                ...props.annotation,
                data: { ...props.annotation.data, subtext: e.target.value },
              })
            }
          />
          {formErrors.subtext && (
            <span style={{ color: "red", fontSize: "12px" }}>{formErrors.subtext}</span>
          )}
        </div>
       
        
        <div className="AstImpact" >

          <div className="indivs">
            <div className="impact">
              <p>Impact:</p>
              <select  onChange={(e) =>
                  props.onChange({
                    ...props.annotation,
                    data: { ...props.annotation.data, impact: e.target.value },
                  })
                }>
                  <option key={0} value="">Select</option>
                  <option key={1} value={'High'}>High</option>
                  <option key={2} value={'Medium'}>Medium</option>
                  <option key={3} value={'Low'}>Low</option>
              </select>
            </div>
            {formErrors.impact && ( <span style={{ color: "red", fontSize: "12px" }}>{formErrors.impact}</span>)}
          </div>

          <div className="indivs">

            <div className="impact">
              <p>Efforts:</p>
              <select onChange={(e) =>
                props.onChange({
                  ...props.annotation,
                  data: { ...props.annotation.data, effort: e.target.value },
                }) } >
                <option key={0} value="">Select</option>
                  <option key={1} value={'High'}>High</option>
                  <option key={2} value={'Medium'}>Medium</option>
                  <option key={3} value={'Low'}>Low</option>
              </select>
            </div>
            {formErrors.effort && ( <span style={{ color: "red", fontSize: "12px" }}>{formErrors.effort}</span> )}
          </div>


        </div>

        

        <div className="Comntbtn" >
          <button type="button" onClick={handleSubmitComment}> Add <AddCommentIcon/> </button>
        </div>



      </div>

    </div>
  );
};

export default CustomEditor;
