import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Quote from '@editorjs/quote';
import ImageTool from '@editorjs/image';
import Table from '@editorjs/table';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Box } from '@mui/material';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";



export default function AddGuideline() {
   
    const navigate = useNavigate();
    const editorInstance = useRef(null);

    // State for dynamic filterTypes fetched from the API
    const [filterTypes, setFilterTypes] = useState([]);

    const [formData, setFormData] = useState({
        guidelinecategory: "",
        subcategory: "",
        filters: {}, // Will be populated after filterTypes are fetched
        description: {},
        title: '', // New state for title
        titleDescription: '' // New state for title description
    });

    const [filterCategories, setFilterCategories] = useState({});
    const [guidelinecategories, setGuidelinecategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [showSubcategory, setShowSubcategory] = useState(false);
    const [showEditor, setShowEditor] = useState(false);

    // Fetch dynamic filter types from the API and set the initial state for filters
    useEffect(() => {
        async function fetchFilterTypes() {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/fetchFilterTypes`);
                if (response.data.status === 1) {
                    setFilterTypes(response.data.filterTypes);
    
                    // Initialize filters object dynamically
                    const filtersInit = response.data.filterTypes.reduce((acc, filter) => {
                        acc[filter.name] = [];
                        return acc;
                    }, {});
                    setFormData((prevFormData) => ({ ...prevFormData, filters: filtersInit }));
    
                    // Fetch filters for each filter type
                    response.data.filterTypes.forEach((filter) => {
                        fetchFilters(filter._id, filter.name); // Make sure to use filter.name here
                    });
                }
            } catch (error) {
                console.error("Error fetching filter types:", error);
            }
        }
    
        async function fetchFilters(filterId, filterName) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/fetchFilters`, { filters: filterId });
                if (response.data.status === 1) {
                    setFilterCategories((prevCategories) => {
                        const updatedCategories = {
                            ...prevCategories,
                            [filterName]: response.data.subfilters,
                        };
                        console.log("Updated filterCategories:", updatedCategories); // Log the updated value here
                        return updatedCategories;
                    });
                }
            } catch (error) {
                console.error(`Error fetching filters for ${filterName}:`, error);
            }
        }

        async function fetchCategories() {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/fetchCategories`);
                if (response.data.status === 1) {
                    setGuidelinecategories(response.data.categories);
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        }
    
        // Fetch filter types and filters
        fetchFilterTypes();
        fetchCategories();
    }, []);
    

    // Handle Editor.js initialization when the editor is visible
    useEffect(() => {
        if (showEditor && !editorInstance.current) {
            const style = document.createElement('style');
            style.innerHTML = `div#editorjs .ce-tooltip { color: black !important; }`;
            document.head.appendChild(style);

            editorInstance.current = new EditorJS({
                holder: 'editorjs',
                tools: {
                    header: Header,
                    list: List,
                    quote: Quote,
                    table: Table,
                    image: {
                        class: ImageTool,
                        config: {
                            uploader: {
                                async uploadByFile(file) {
                                    const formData = new FormData();
                                    formData.append('image', file);
                                    try {
                                        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/uploadImage`, formData, {
                                            headers: { 'Content-Type': 'multipart/form-data' }
                                        });
                                        return { success: 1, file: { url: data.file.url } };
                                    } catch (error) {
                                        console.error('Image upload failed:', error);
                                        return { success: 0 };
                                    }
                                },
                                async uploadByUrl(url) {
                                    return { success: 1, file: { url: url } };
                                }
                            }
                        }
                    },
                },
                onChange: async () => {
                    const savedData = await editorInstance.current.save();
                    setFormData((prevData) => ({ ...prevData, description: savedData }));
                },
            });

            return () => {
                document.head.removeChild(style);
            };
        }

        return () => {
            if (editorInstance.current) {
                editorInstance.current.destroy();
                editorInstance.current = null;
            }
        };
    }, [showEditor]);

    const handleCategoryChange = async (e) => {
        const selectedCategory = e.target.value;
        setFormData((prevData) => ({ ...prevData, guidelinecategory: selectedCategory }));
        if (selectedCategory) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/guideCategoryWithId`, { id: selectedCategory });
                setSubcategories(response.data.categorydetail.subCategories);
                setShowSubcategory(true);
            } catch (error) {
                console.error("Failed to fetch subcategories:", error);
            }
        } else {
            setShowSubcategory(false);
        }
    };

    const handleFilterChange = (e, type) => {
        const { target: { value } } = e;
        setFormData((prevFormData) => ({
            ...prevFormData,
            filters: {
                ...prevFormData.filters,
                [type]: typeof value === 'string' ? value.split(',') : value
            }
        }));
    };

    const handleSubcategoryChange = (e) => {
        const selectedSubcategory = e.target.value;
        setFormData((prevData) => ({ ...prevData, subcategory: selectedSubcategory }));
        setShowEditor(!!selectedSubcategory);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, image: file }); // Set the selected file in formData
        }
    };

    const handleSubmit = async (e) => {
     
        e.preventDefault();
        
        if (!formData.title) {
            toast.error("Title is required");
            return;
        }
        if (!formData.image) {
            toast.error("Image is required");
            return;
         }
         //else if (formData.image.size > 5 * 1024 * 1024) { // Validate if image size is greater than 5MB
        //     toast.error("Image size should not exceed 5MB");
        //     return;
        // }
        if (!formData.titleDescription) {
            toast.error("Title description is required");
            return;
        }
        if (!formData.guidelinecategory) {
            toast.error("Please select a category");
            return;
        }
        if (!formData.subcategory) { // Only check subcategory if it's visible
            toast.error("Please select a subcategory");
            return;
        }
        if (!formData.description || Object.keys(formData.description).length === 0) {
            toast.error("Please enter a description");
            return;
        }
        
       
        console.log("formData",formData);
        const formDataToSend = new FormData();
        formDataToSend.append('title', formData.title);
        formDataToSend.append('titleDescription', formData.titleDescription);
        formDataToSend.append('guidelinecategory', formData.guidelinecategory);
        formDataToSend.append('subcategory', formData.subcategory);
        formDataToSend.append('description', formData.description);
        if (formData.image) {
            formDataToSend.append('image', formData.image); // Append the image
        }
        console.log("formDataToSend", formDataToSend);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/addGuideline`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important for file upload
                },
            });
            swal("Success!", "Data saved successfully", "success");
            navigate("/guidelines");
        } catch (error) {
            console.error("Error saving data:", error); // Log error for debugging
            swal("Error!", "Failed to save data", "error");
        }
        
    };

    return (
        <DashboardLayout>
            {/* <DashboardNavbar /> */}

            <section className="FilterSec">
                <div className="container">
                    <div className="Add_Guide_Sec">
                        <div className="FilterHead">
                            <h2>Add Guideline</h2>
                        </div>

                        <MDBox p={3}>
                            <form onSubmit={handleSubmit}>
                            <Grid container spacing={3} py={0}>
                                    {/* Row for Title and Image Upload */}
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Title"
                                            value={formData.title} // Update the state value for title
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })} // Update the title in formData
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            fullWidth
                                            style={{ height: "56px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >
                                            Upload Icon/Image
                                            <input
                                                type="file"
                                                hidden
                                                accept="image/*" // Accept only images
                                                onChange={handleImageChange}
                                                // onChange={(e) => {
                                                //     // Handle file upload logic here
                                                //     const file = e.target.files[0];
                                                //     if (file) {
                                                //         // Update state with the selected file
                                                //         setFormData({ ...formData, image: file });
                                                //     }
                                                // }}
                                            />
                                        </Button>
                                    </Grid>
                                    
                                    {/* Row for Title Description */}
                                    <Grid item xs={12} md={12}><strong>Title Description</strong></Grid>    
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Title Description"
                                            value={formData.titleDescription} // Update the state value for titleDescription
                                            onChange={(e) => setFormData({ ...formData, titleDescription: e.target.value })} // Update the title description in formData
                                            multiline
                                            rows={4} // Optional: adjust the number of rows for a larger input field
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} py={0}>
                                    <Grid item xs={12} md={12}><strong>Filters</strong></Grid>
                                </Grid>
                                <Grid container spacing={3} py={3}>
                                    {filterTypes.map((filter) => (
                                        <Grid item xs={12} md={6} key={filter.name}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel>{filter.name}</InputLabel>
                                                <Select
                                                    multiple
                                                    value={formData.filters[filter.name] || []}
                                                    onChange={(e) => handleFilterChange(e, filter.name)}
                                                    label={filter.name}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    style={{ lineHeight: "3em" }}
                                                >
                                                    {filterCategories[filter.name]?.map((category) => (
                                                        <MenuItem key={category._id} value={category.name}>
                                                            <Checkbox checked={formData.filters[filter.name]?.indexOf(category.name) > -1} />
                                                            <ListItemText primary={category.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid container spacing={3} py={0} mb={2}>
                                    <Grid item xs={12} md={12}><strong>Categories</strong></Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Category</InputLabel>
                                            <Select
                                                name="category"
                                                value={formData.guidelinecategory}
                                                onChange={handleCategoryChange}
                                                label="Category"
                                                style={{ lineHeight: "3em" }}
                                            >
                                                <MenuItem value="">
                                                    <em>Select Category</em>
                                                </MenuItem>
                                                {guidelinecategories.map((category) => (
                                                    <MenuItem key={category._id} value={category._id}>
                                                        {category.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {showSubcategory && (
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel>Subcategory</InputLabel>
                                                <Select
                                                    name="subcategory"
                                                    value={formData.subcategory}
                                                    onChange={handleSubcategoryChange}
                                                    label="Subcategory"
                                                    style={{ lineHeight: "3em" }}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select Subcategory</em>
                                                    </MenuItem>
                                                    {subcategories.map((subcategory) => (
                                                        <MenuItem key={subcategory._id} value={subcategory._id}>
                                                            {subcategory.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                </Grid>

                                {showEditor && (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <div id="editorjs"></div>
                                        </Grid>
                                    </Grid>
                                )}

                                <Box mt={4} mb={1} >
                                    <Button type="submit" variant="contained" fullWidth  color="primary" sx={{ color: '#fff' }}>
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        </MDBox>
                        <ToastContainer />
                    </div>
                </div>
            </section>
        </DashboardLayout>
    );
}
