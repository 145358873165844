import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import ImageTool from "@editorjs/image";
import Table from "@editorjs/table";

import CodeTool from "@editorjs/code";
import Card from "@mui/material/Card";
import HorizontalLine from "components/Editor/HorizontalLine";
import CarouselTool from "components/Editor/CarouselTool/CarouselTool.js";
import CheckboxTool from "components/Editor/CheckboxTool/CheckboxTool.js";

import MDTypography from "components/MDTypography";
import ColorTool from 'components/Editor/ColorTool/ColorTool.js';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete icon
import IconButton from '@mui/material/IconButton'; // Import IconButton

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Avatar,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate,useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import ClearIcon from '@mui/icons-material/Clear';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";



export default function AddGuideline() {
  const { id } = useParams();
  const navigate = useNavigate();
 

 

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

 
  

  

 


 

  
  

  

 

  

  

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    console.log("Selected file:", file); // Log the selected file

    if (file) {
      // First, update the formData with the selected file
      setFormData((prevState) => {
        console.log("Previous formData before image update:", prevState); // Log the previous formData
        return { ...prevState, image: file };
      });

      // Then use FileReader to generate a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        // Ensure you use prevState to avoid stale state issues
        setFormData((prevState) => {
          console.log("Previous formData before preview update:", prevState); // Log formData before updating with image preview
          return { ...prevState, imageprev: reader.result }; // Add the base64 image preview to formData
        });
        console.log("Updated formData with image preview:", reader.result); // Log the base64 preview
      };

      // Read the file as base64 data for the preview
      reader.readAsDataURL(file);
      console.log("FileReader is reading the file...");
    } else {
      console.log("No file selected");
    }
  };


 

  


  

  



  
  
  
  
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!formData.forusers) {
    //   toast.error("Please select user type");
    //   return;
    // }
    if (!formData.name) {
        toast.error("Name is required");
        return;
    }
    // if (!formData.description) {
    //   toast.error("Please select page type");
    //   return;
    // }
    
    console.log("formData", formData);
    const formDataToSend = new FormData();
    formDataToSend.append("categoryId", id);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("description", formData.description);
    if (formData.image) {
      formDataToSend.append("icon", formData.image); // Append the image
    }
    console.log("formDataToSend", formDataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/addSubResearchType`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if(response.data.status == 1)
        {
          swal("Success!", "Data saved successfully", "success");
          navigate(`/research-sub-type-list/${id}`);
        }
        else
        {
            swal("Error!", response.data.message, "error");
        }
      
    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
  };

  return (
    <DashboardLayout>
      <section className="FilterSec">
        <div className="Add_Guide_Sec">
          <div className="FilterHead">
            <h2>Add Research Sub Type</h2>
          </div>

          <div className="GuidlineForm">
            <form onSubmit={handleSubmit}>

            

              
              <div className="">

                <div className="ResSubTyp">
                  <div className="guidFormControl">
                    <h6> <strong>Sub Type Name</strong></h6>
                      <TextField
                      fullWidth
                      variant="outlined"
                      label="Enter Name"
                      value={formData.name} // Update the state value for title
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      } // Update the title in formData
                      />
                  </div>
                </div>

                <div mt={4} mb={1} style={{display: "flex",justifyContent: "center",padding: "50px 0px", gap: '80px'}}>
                  <Button type="submit" class="myButton" onClick={() =>navigate(`/research-sub-type-list/${id}`)} data-label="Back"></Button>
                  <Button type="submit" class="myButton" data-label="Save"></Button>
                </div>

              </div>
              
            </form>
          </div>
          <ToastContainer />
        </div>
      </section>
    </DashboardLayout>
  );
}
