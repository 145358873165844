export default class QuoteInlineTool {
    static get isInline() {
      return true;
    }
  
    static get title() {
      return 'Quote';
    }
  
    constructor({ api }) {
      this.api = api;
      this.button = null;
      this.tag = 'QUOTE-INLINE';
    }
  
    render() {
      this.button = document.createElement('button');
      this.button.type = 'button';
      this.button.innerHTML = '“”'; // icon for quote
      this.button.classList.add(this.api.styles.inlineToolButton);
      return this.button;
    }
  
    surround(range) {
      if (!range || range.collapsed) {
        return;
      }
  
      const selectedText = range.toString();
      const quotedText = `“${selectedText}”`;
  
      const span = document.createElement('span');
      span.innerText = quotedText;
      range.deleteContents();
      range.insertNode(span);
  
      this.api.selection.expandToTag(span);
    }
  
    checkState() {
      // Not necessary for quote tool
    }
  
    clear() {
      // Optional: logic to remove quote formatting
    }
  }
  