import React, { useState } from 'react';
import { Link, useNavigate,useParams } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";


import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";


// Data
import researchTypeDetail from "layouts/researchs/data/researchTypeDetail";
import annotationsTableData from "layouts/researchTypes/data/annotationsTableData";
import uxInsightsTableData from "layouts/researchTypes/data/uxInsightsTableData";

function Tables() {
  const { id, subid } = useParams();
  const { singleResearchType } = researchTypeDetail(id, subid);
 
  
  console.log("singleResearchTypesingleResearchType",singleResearchType);
  let singlereserchtype = "";
  let subCategoryName = "";
  let pagetype = "";
 
  if (singleResearchType && singleResearchType.name && singleResearchType.subCategoryName && singleResearchType.pagetype) {
    singlereserchtype = singleResearchType.name;
    subCategoryName = singleResearchType.subCategoryName;
    pagetype = singleResearchType.pagetype;
  }
  const { columns, rows} = pagetype === "Yes" ? annotationsTableData(id, subid): annotationsTableData(id, subid);
  
  
  let linkComponent;
  if (pagetype === "Yes") {
    linkComponent = (
      <Link to={`/research-add-annotation/${id}/${subid}`}>
        <AddIcon/>Add Page Review
      </Link>
    );
  } else if (pagetype === "No") {
    linkComponent = (
      <Link to={`/research-add/${id}/${subid}`}>
        {/* <Icon fontSize="small">add</Icon>Add */}
        <AddIcon/>Add Insight
      </Link>
    );
  } else {
    // Default case if pagetype is neither "Yes" nor "No"
    linkComponent = (
      <Link to={`/`}>
        <Icon fontSize="small">add</Icon>Add
      </Link>
    );
  }


 

  //Sorting
  const [sortOrder, setSortOrder] = useState('latest');

  // Sort rows based on the selected order
  const sortedRows = [...rows].sort((a, b) => {
    if (sortOrder === 'latest') {
      return new Date(b.createdAt) - new Date(a.createdAt); // Descending by date
    } else {
      return new Date(a.createdAt) - new Date(b.createdAt); // Ascending by date
    }
  });

  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const rowsPerPage = 8; // Number of rows per page
  // Calculate paginated rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const paginatedRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);


  // Calculate total pages
  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value); // Update sort order
    setCurrentPage(1); // Reset to the first page
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };




  return (
    <DashboardLayout>
      <section className="FilterSec">
        <div className="Add_Guide_Sec">

          <div className="FilterHead">
            <h2>Researches List </h2>
          </div>

          <div className="GuidlineForm">

            <div className='ResrchTopbar'>
              <div className="pageInfo">
                <h4 className='mb-3'><span>{singlereserchtype}</span>/{subCategoryName} </h4>
              </div>
              <div className="ResrchBtn">
                <div className="guidLink" >
                  <Link to={`/researchs`}><ArrowBackIcon/> Back</Link>
                </div>
                <div className="guidLink" >
                  {linkComponent}
                </div>
              </div>
            </div>

            {/* <DataTable
              table={{ columns, rows }}
              //isSorted={false}
              canSearch={true}
              entriesPerPage={false}
              pagination={false}
              showTotalEntries={false}
              noEndBorder
            /> */}

            <div className="TopShortSelect ">
              <div></div>
              {/* <div class="ShortOrderDiv">
                <p>Page Type:</p>
                <select>
                  <option value="latest">All</option>
                  <option value="older">Latest</option>
                  <option value="older">Older</option>
                </select>
              </div> */}
               <div className="ShortOrderDiv">
                  <p>Sort:</p>
                  <select onChange={handleSortChange} value={sortOrder}>
                    <option value="latest">Latest</option>
                    <option value="older">Older</option>
                  </select>
                </div>
            </div>

            <div className="ShortResrchdata">
              {paginatedRows.length === 0 ? (
                <p>No records found</p>  // Message when there are no records
              ) : (
                paginatedRows.map((row, index) => (
                  <ResrchCard key={index} row={row} />
                ))
              )}
            </div>
            {paginatedRows.length > 0 ? (
            <nav className="ResrchPagination" aria-label="Page navigation">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                    &laquo;
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                  >
                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                >
                  <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                    &raquo;
                  </button>
                </li>
              </ul>
              <span>
                Page {currentPage} of {totalPages}
              </span>
            </nav>
             ) : (
              <></>
            )}

          </div>

        </div>
      </section>

    </DashboardLayout>
  );
}

export default Tables;


export function ResrchCard({row}) {
  const complianceColors = {
    "green": "#2E9892", // Green
    "yellow": "#E3A020", // Orange
    "yellow": "#E3A020", // Yellow
    "red": "#D0463E", //red
  };
  // Progbar 
  

console.log("row",row);
   
   let good=row.Compliant_High_count + row.Compliant_Low_count;
   let moderate=row.Breach_Moderate_count;
   let critical=row.Breach_High_count;
  //  let good =Math.abs(row.scoredetail.Compliant_Low);
  //  let moderate =Math.abs(row.scoredetail.Breach_Moderate);
  //  let critical =Math.abs(row.scoredetail.Breach_High);
  
   const total = good + moderate + critical;
 
   // Calculate percentages only if `total` > 0 to avoid division by zero
   let goodPercent = total > 0 ? (good / total) * 100 : 0;
   let moderatePercent = total > 0 ? (moderate / total) * 100 : 0;
   let criticalPercent = total > 0 ? (critical / total) * 100 : 0;

   if(goodPercent == 0 && moderatePercent == 0 && criticalPercent == 0)
   {
    good = 100;
    goodPercent = 100;
   }
   console.log("title",row.title);
   console.log("total",total);
   console.log("good",good,"goodPercent",goodPercent);
   console.log("moderate",moderate,"moderatePercent",moderatePercent);
   console.log("critical",critical,"criticalPercent",criticalPercent);

  return <div className="Resrchcard">

    <div className="RscrdImg">
      <img src={row.image_url} alt="" />
    </div>

    <div className="ResTitle">
      <h3>{row.title}</h3>
      <div className="Rsttimezone">
        <span>{row.annotation_count} highlights</span>
        <span> {row.date}</span>
      </div>
    </div>

    <div className="ResrchProgDiv">
    
      <div className="ProgResrchbarDiv">
        
        <div className="RestProgToken" style={{ background: complianceColors[row.scoredetail.Highest_Points_Compliance_Color] }}>
          <h6>{row.score}</h6>
        </div>
        <div className="ProgressLines">
          {/* Render Good Section */}
          {good > 0 && (
            <>
              <div className="ProgDiv" style={{ width: `${goodPercent}%` }}>
                <div className="Pgsbar" style={{
                  backgroundColor: "#2E9892",
                }}> </div>

              </div>

            </>
          )}
          {/* Render Moderate Section */}
          {moderate > 0 && (
            <>
              <div className="ProgDiv" style={{ width: `${moderatePercent}%` }}>
                <div className="Pgsbar" style={{
                  backgroundColor: "#E3A020",
                }}>
                </div>

              </div>
            </>
          )}
          {/* Render Critical Section */}
          {critical > 0 && (
            <div className="ProgDiv" style={{ width: `${criticalPercent}%` }}>
              <div className="Pgsbar" style={{
                backgroundColor: "#D0463E",
              }}> </div>

            </div>
          )}
        </div>
      </div>
    </div>

    {row.action}
    {row.annotations}


  </div>;
}

