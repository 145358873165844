// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-tool-button {
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
}

.picker-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ccc;
  background-color: white;
  padding: 10px;
  position: absolute;
  z-index: 1000; /* Make sure it's above the editor content /*/
}

.color-picker-label {
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
}

.color-picker-input {
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
}
.color-picker-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.color-picker-close-button:hover {
  color: red;
}

`, "",{"version":3,"sources":["webpack://./src/components/Editor/ColorTool/ColorTool.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,aAAa,EAAE,6CAA6C;AAC9D;;AAEA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".color-tool-button {\n  cursor: pointer;\n  padding: 5px 10px;\n  border: none;\n  background-color: #f0f0f0;\n  color: #333;\n}\n\n.picker-container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  border: 1px solid #ccc;\n  background-color: white;\n  padding: 10px;\n  position: absolute;\n  z-index: 1000; /* Make sure it's above the editor content /*/\n}\n\n.color-picker-label {\n  font-size: 12px;\n  color: #333;\n  margin-bottom: 5px;\n}\n\n.color-picker-input {\n  width: 40px;\n  height: 40px;\n  border: none;\n  cursor: pointer;\n}\n.color-picker-close-button {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  background: transparent;\n  border: none;\n  font-size: 18px;\n  color: #333;\n  cursor: pointer;\n}\n\n.color-picker-close-button:hover {\n  color: red;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
