import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, IconButton, Box } from '@mui/material';
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from 'react-bootstrap/Button';

function Tables() {
    const [newCategory, setNewCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [subCategoryInputs, setSubCategoryInputs] = useState({});

    // Function to fetch categories
    const fetchCategories = async () => {
        try {
            const response = await axios.post('/api/admin/fetchCategories');
            if (response.data.status === 1) {
                setCategories(response.data.categories);
            } else {
                console.error("Error fetching categories:", response.data.errors);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    // Fetch categories when the component mounts
    useEffect(() => {
        fetchCategories();
    }, []);

    const addCategory = async () => {
        if (newCategory.trim() === "") return;

        try {
            const response = await axios.post('/api/admin/guideCategory', { name: newCategory });
            if (response.data.status === 1) {
                setCategories(prevCategories => [...prevCategories, response.data.newCategory]);
                setNewCategory('');
            } else {
                console.error("Error adding category:", response.data.errors);
            }
        } catch (error) {
            console.error("Error adding category:", error);
        }
    };

    const editCategory = async (categoryId, newName) => {
        try {
            const response = await axios.post(`/api/admin/updateCategory`, { name: newName, categoryId });
            if (response.data.status === 1) {
                setCategories(categories.map(category => category._id === categoryId ? { ...category, name: newName } : category));
            } else {
                console.error("Error editing category:", response.data.errors);
            }
        } catch (error) {
            console.error("Error editing category:", error);
        }
    };

    const deleteCategory = async (categoryId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this category?");
        if (!confirmDelete) return;

        try {
            const response = await axios.post(`/api/admin/deleteCategory`, { categoryId });
            if (response.data.status === 1) {
                setCategories(categories.filter(category => category._id !== categoryId));
            } else {
                console.error("Error deleting category:", response.data.errors);
            }
        } catch (error) {
            console.error("Error deleting category:", error);
        }
    };

    const handleSubCategoryInputChange = (categoryId, value) => {
        setSubCategoryInputs(prevInputs => ({
            ...prevInputs,
            [categoryId]: value, // Maintain sub-category input value for each category
        }));
    };

    const addSubCategory = async (categoryId) => {
        const subCategoryName = subCategoryInputs[categoryId] || ""; // Get the input value for the specific category
        if (subCategoryName.trim() === "") return;

        try {
            const response = await axios.post('/api/admin/addSubCategory', { categoryId, name: subCategoryName });
            if (response.data.status === 1) {
                setCategories(categories.map(category =>
                    category._id === categoryId ? { ...category, subCategories: [...(category.subCategories || []), response.data.newSubCategory] } : category
                ));
                // Reset the sub-category input for the specific category after adding
                setSubCategoryInputs(prevInputs => ({
                    ...prevInputs,
                    [categoryId]: "",
                }));
            } else {
                console.error("Error adding sub-category:", response.data.errors);
            }
        } catch (error) {
            console.error("Error adding sub-category:", error);
        }
    };

    const deleteSubCategory = async (categoryId, subCategoryId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this sub-category?");
        if (!confirmDelete) return;

        try {
            const response = await axios.post('/api/admin/deleteSubCategory', { categoryId, subCategoryId });
            if (response.data.status === 1) {
                setCategories(categories.map(category =>
                    category._id === categoryId ? {
                        ...category,
                        subCategories: category.subCategories.filter(sub => sub._id !== subCategoryId)
                    } : category
                ));
            } else {
                console.error("Error deleting sub-category:", response.data.errors);
            }
        } catch (error) {
            console.error("Error deleting sub-category:", error);
        }
    };

    return (
        <DashboardLayout>
            <section className="FilterSec">
                <div className="container">
                    <div className="FilterDiv">

                        <div className="FilterHead">
                            <h2>Guideline Categories List</h2>
                        </div>

                        <div className='GuidlineDiv'>

                            <div className='addcateDiv' >
                                <TextField
                                    variant="outlined"
                                    label="Add Category"
                                    value={newCategory}
                                    onChange={(e) => setNewCategory(e.target.value)}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className="MainBtn" onClick={addCategory} type="submit"> Add Category</Button>
                                </Box>
                            </div>

                            <div className='GuideGate scrollbar' >
                                {Array.isArray(categories) && categories.map(category => (
                                    <div key={category._id} className='Subguidname'>
                                        <div className='guidename'>
                                            <h6>{category.name}</h6>
                                            <IconButton onClick={() => editCategory(category._id, prompt("Edit Category Name:", category.name))}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => deleteCategory(category._id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                label="Add Sub Category"
                                                value={subCategoryInputs[category._id] || ""} // Use specific input for each category
                                                onChange={(e) => handleSubCategoryInputChange(category._id, e.target.value)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            <Button className="MainBtn" onClick={() => addSubCategory(category._id)} type="submit">
                                                Add Sub Category
                                            </Button>
                                            {category.subCategories && category.subCategories.map(sub => (
                                                <div key={sub._id} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                    <MDTypography variant="body1">{sub.name}</MDTypography>
                                                    <IconButton onClick={() => deleteSubCategory(category._id, sub._id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </DashboardLayout>
    );
}

export default Tables;
