import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import MDAvatar from "components/MDAvatar";
import moment from 'moment';

import { Icon, Tooltip } from '@mui/material';
import {
  Button,
} from "@mui/material";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";


const ToggleSwitch = ({ id, isPublished, onToggle }) => {
  const switchStyles = {
    position: 'relative',
    display: 'inline-block',
    width: '40px',
    height: '20px'
  };

  const sliderStyles = {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: isPublished ? '#2196F3' : '#ccc',
    transition: '.4s',
    borderRadius: '20px'
  };

  const circleStyles = {
    position: 'absolute',
    content: '""',
    height: '12px',
    width: '12px',
    left: isPublished ? '24px' : '4px',  // Move the circle based on checked state
    bottom: '4px',
    backgroundColor: 'white',
    transition: '.4s',
    borderRadius: '50%'
  };

  return (
    <label style={switchStyles}>
      <input 
        type="checkbox" 
        checked={isPublished} 
        onChange={() => onToggle(id, !isPublished)} 
        style={{ display: 'none' }}  // Hide the default checkbox input
      />
      <span style={sliderStyles}>
        <span style={circleStyles}></span>
      </span>
    </label>
  );
};

// ActionButtons component defined in the same file
const ActionButtonsList = ({ id,count,onViewCat }) => {
  return (
    <>
      
      {/* <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onViewCat(id)}
      >
        visibility
      </Icon> */}

      <div className="anotviewbtn">
      {/* <Tooltip title="View More Detail" arrow>
        <Icon
          style={{ cursor: "pointer", color: "primary" }}
          onClick={() => onViewCat(id)}
        >
          edit
        </Icon>
      </Tooltip> */}

        {/* <button onClick={() => onViewCat(id)}><MdEdit /></button> */}

        {/* <Button
          style={{ cursor: "pointer", color: "#fff", marginLeft: "10px" }}
          onClick={() => onViewCat(id)}
          variant="contained" 
        >
          View ({count})
        </Button> */}

      </div>
       
      
      
    </>
  );
};
const ActionButtons = ({ id, onEdit, onView, onDelete }) => {
  return (
    <>
      <Tooltip title="Edit" arrow>
        <Icon
          style={{ cursor: "pointer", color: "primary" }}
          onClick={() => onEdit(id)}
        >
          edit
        </Icon>
      </Tooltip>
     
      {/* <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onView(id)}
      >
        visibility
      </Icon> */}
      <Tooltip title="Delete" arrow>
      <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onDelete(id)}
      >
        delete
      </Icon>

      </Tooltip>
      
    </>
  );
};

export default function Data(id, subid) {
  let navigate = useNavigate();
  const [data, setData] = useState([]);

  const LoadData = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getUxInsightsList`,{id, subid}).then((res) => {
        var response = res.data.uxinsights;
        var responseData = [];
        for (var i = 0; i < response.length; i++) {
          var inc = i + 1;

          responseData.push({
            id: inc,
            //icon: <MDAvatar src={`${process.env.REACT_APP_BASE_URL}${response[i].icon}`} />,
            hash: `# ${response[i].hashNumber}`,
            guidelineTitle: `# ${response[i].guidelineDetails.hashNumber}`,
            title: response[i].title,
            subtitle: response[i].subtitle,
            
            isPublished: response[i].isPublished,
            publishToggle: (
              <ToggleSwitch
                id={response[i]._id}
                isPublished={response[i].isPublished}
                onToggle={handleTogglePublish}
              />
            ),
            action: (
              <ActionButtons
                id={response[i]._id}
                onEdit={handleEdit}
                // onView={handleView}
                onDelete={handleDelete}
              />
            ), // Using the ActionButtons component
            createdAt: response[i].createdAt,
            date: formatUTCDate(response[i].createdAt),
            image_url: `${process.env.REACT_APP_BASE_URL}${response[i].icon}`,
          });
        }
        setData(responseData);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  const handleToggleForUser = async (id, newStatus) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/updateForUserStatus`, {
        id: id,
        forUsers: newStatus ? 1 : 0
      });
      setData(prevData =>
        prevData.map(item =>
          item.id === id ? { ...item, forUsers: newStatus } : item
        )
      );
      LoadData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleTogglePublish = async (id, newStatus) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/updateAnnotationPublishStatus`, {
        id: id,
        isPublished: newStatus
      });
      // Optionally update the UI after successful response
      setData(prevData => prevData.map(item => 
        item.id === id ? { ...item, isPublished: newStatus } : item
      ));
      console.log(`Guideline ${id} is now ${newStatus ? 'Published' : 'Unpublished'}`);
      LoadData();
    } catch (error) {
      console.log(error);
    }
  };

  
  // Handle view action
  const handleViewCat = (id) => {
    navigate(`/annotations-list/${id}`);
  };

  // Handle view action
  const handlePublicView = (id) => {
    //console.log("websitelink",`${process.env.REACT_APP_WEBSITE_URL}/annotations/${id}`);
    window.open(`${process.env.REACT_APP_WEBSITE_URL}/annotations/${id}`, '_blank');

    
  };

  // Handle edit action
  const handleEdit = (idedit) => {
    navigate(`/research-edit/${id}/${subid}/${idedit}`);
  };

  // Handle view action
  const handleView = (id) => {
    navigate(`/research-view-annotation/${id}`);
  };

  // Handle delete action
  const handleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Ux Insight!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deleteUxInsight`, { id });
          swal("Deleted!", "The Ux Insight has been deleted.", "success");
          LoadData(); // Reload the data after deletion
        } catch (error) {
          swal("Error!", "Failed to delete the Ux Insight.", "error");
        }
      } else {
        swal("Your Ux Insight is safe!");
      }
    });
  };

  return {
    columns: [
      { Header: "Sno", accessor: "id" },
      { Header: "Insight Number", accessor: "hash" },
      { Header: "action", accessor: "action" }, // Added the action columns
      
      { Header: "Guideline Number", accessor: "guidelineTitle" },
      //{ Header: "title", accessor: "title" },
      { 
        Header: "Title", 
        accessor: "title", 
        Cell: ({ value }) => (
          <div style={{ whiteSpace: "normal", wordBreak: "break-word", maxWidth: "200px" }}>
            {value.length > 30 ? value.substring(0, 30) + "..." : value}
          </div>
        ) 
      },
      //{ Header: "subtitle", accessor: "subtitle" },
      { 
        Header: "subtitle", 
        accessor: "subtitle", 
        Cell: ({ value }) => (
          <div style={{ whiteSpace: "normal", wordBreak: "break-word", maxWidth: "200px" }}>
            {value.length > 30 ? value.substring(0, 30) + "..." : value}
          </div>
        ) 
      },
      
      // { Header: "image", accessor: "icon" },
     
      
      // { Header: "Unpublish/Publish", accessor: "publishToggle" },
      
      { Header: "date", accessor: "date" },
      
      
    ],

    rows: data ? data : "",
  };
}

// Format date helper function
function formatUTCDate(utcDate) {
  const momentDate = moment(utcDate);
  return momentDate.format('MM/DD/YYYY, hh:mm A');
}


