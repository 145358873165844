import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import MDAvatar from "components/MDAvatar";
import moment from 'moment';
import { Icon, Tooltip } from '@mui/material';
import {
  Button,
} from "@mui/material";


const ToggleSwitch = ({ id, main_id, isPublished, onToggle }) => {
  const switchStyles = {
    position: 'relative',
    display: 'inline-block',
    width: '40px',
    height: '20px'
  };

  const sliderStyles = {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: isPublished ? '#2196F3' : '#ccc',
    transition: '.4s',
    borderRadius: '20px'
  };

  const circleStyles = {
    position: 'absolute',
    content: '""',
    height: '12px',
    width: '12px',
    left: isPublished ? '24px' : '4px',  // Move the circle based on checked state
    bottom: '4px',
    backgroundColor: 'white',
    transition: '.4s',
    borderRadius: '50%'
  };

  return (
    <label style={switchStyles}>
      <input 
        type="checkbox" 
        checked={isPublished} 
        onChange={() => onToggle(id, main_id, !isPublished)} 
        style={{ display: 'none' }}  // Hide the default checkbox input
      />
      <span style={sliderStyles}>
        <span style={circleStyles}></span>
      </span>
    </label>
  );
};

// ActionButtons component defined in the same file
const ActionButtonsList = ({ id,main_id,onViewCat }) => {
  return (
    <>
      
      {/* <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onViewCat(id)}
      >
        visibility
      </Icon> */}
       
      <Button
        style={{ cursor: "pointer", color: "#fff", marginLeft: "10px" }}
        onClick={() => onViewCat(id,main_id)}
        variant="contained" // You can change the variant to "outlined" if needed
      >
        Add/Edit More Details
      </Button>
      
    </>
  );
};
const ActionButtons = ({ id, main_id, onEdit, onView, onDelete }) => {
  return (
    <>
      <Tooltip title="Edit" arrow>
        <Icon
          style={{ cursor: "pointer", color: "primary" }}
          onClick={() => onEdit(id,main_id)}
        >
          edit
        </Icon>
      </Tooltip>
     
      {/* <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onView(id)}
      >
        visibility
      </Icon> */}
      <Tooltip title="Delete" arrow>
      <Icon
        style={{ cursor: "pointer", color: "primary", marginLeft: "10px" }}
        onClick={() => onDelete(id,main_id)}
      >
        delete
      </Icon>

      </Tooltip>
      
    </>
  );
};

export default function Data(id) {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [annotationTypesData, setAnnotationTypesData] = useState([]);

  const LoadData = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getSingleAnnotation`,{id}).then((res) => {
        setAnnotationTypesData({ researchtype_id: res.data.annotationdetail.researchtype_id, researchtypesub_id: res.data.annotationdetail.researchtypesub_id, annotation_id: res.data.annotationdetail._id});
        var response = res.data.annotationdetail.annotations;
        var responseData = [];
        for (var i = 0; i < response.length; i++) {
          var inc = i + 1;

          responseData.push({
            id: inc,
            guideline: `Guideline #${response[i].data.guidelineHashNumber}`,
            compliant: `${response[i].data.compliant} (${response[i].data.compliantPoint} point)` ,
            heading: response[i].data.heading,
            subtext: response[i].data.subtext,
            impact: response[i].data.impact,
            effort: response[i].data.effort,
            status: response[i].status,
            statusToggle: (
              <ToggleSwitch
                id={response[i]._id}
                main_id={id}
                isPublished={response[i].status}
                onToggle={handleTogglePublish}
              />
            ),
            moredetail: <ActionButtonsList
              id={response[i]._id}
              main_id={id}
              onViewCat={handleViewCat}
            />,
            action: (
              <ActionButtons
                id={response[i]._id}
                main_id={id}
                onEdit={handleEdit}
                // onView={handleView}
                onDelete={handleDelete}
              />
            ), // Using the ActionButtons component
          });
        }
        setData(responseData);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  const handleToggleForUser = async (id, newStatus) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/updateForUserStatus`, {
        id: id,
        forUsers: newStatus ? 1 : 0
      });
      setData(prevData =>
        prevData.map(item =>
          item.id === id ? { ...item, forUsers: newStatus } : item
        )
      );
      LoadData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleTogglePublish = async (id, main_id, newStatus) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/updateSingleAnnotationStatus`, {
        id: id,
        main_id,
        status: newStatus
      });
      // Optionally update the UI after successful response
      setData(prevData => prevData.map(item => 
        item.id === id ? { ...item, status: newStatus } : item
      ));
      console.log(`Keyword ${id} is now ${newStatus ? 'Active' : 'Inactive'}`);
      LoadData();
    } catch (error) {
      console.log(error);
    }
  };

  
  // Handle view action
  const handleViewCat = (id,main_id) => {
    navigate(`/annotattion-moredetail/${id}/${main_id}/no`);
  };

  // Handle edit action
  const handleEdit = (id,main_id) => {
    navigate(`/annotation-edit/${id}/${main_id}`);
  };

  // Handle view action
  // const handleView = (id) => {
  //   navigate(`/view-guideline/${id}`);
  // };

  // Handle delete action
  const handleDelete = async (id,main_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this annotation!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deleteResearchAnnotationSub`, { id,main_id });
          swal("Deleted!", "The annotation has been deleted.", "success");
          LoadData(); // Reload the data after deletion
        } catch (error) {
          swal("Error!", "Failed to delete the annotation.", "error");
        }
      } else {
        swal("Your annotation is safe!");
      }
    });
  };

  return {
    columns: [
      { Header: "Sno", accessor: "id" },
      { Header: "guideline", accessor: "guideline" },
      { Header: "compliant", accessor: "compliant" },
      { Header: "heading", accessor: "heading" },
      { Header: "subtext", accessor: "subtext" },
      { Header: "impact", accessor: "impact" },
      { Header: "effort", accessor: "effort" },
      { Header: "Inactive/Active", accessor: "statusToggle" },
      { Header: "More Detail", accessor: "moredetail" },
      { Header: "action", accessor: "action" },
    ],

    rows: data ? data : "",
    annotationTypesData
  };
}

// Format date helper function
function formatUTCDate(utcDate) {
  const momentDate = moment(utcDate);
  return momentDate.format('MM/DD/YYYY, hh:mm A');
}
