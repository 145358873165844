import { useState, useEffect } from "react";
import axios from "axios";

export default function Data() {

    const [researchTypeData, setResearchTypeData] = useState([]);

    async function researchTypeList() {
        var singlecatdata = [];
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/admin/getResearchTypes`
            );

            if (response.data.status === 1) {
                //setResearchTypeData([{ name: response.data.researchtypedetail.name }]);
                setResearchTypeData(response.data.researchtypes);
            }
        } catch (error) {
            console.error("Error fetching guideline:", error);
        }

    }

    useEffect(() => {
        researchTypeList();
    }, []);

    return {
        researchTypeData,
    };
}

