import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit"; // Import Edit icon
import {
  TextField,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
} from "@mui/material";
import MDBox from "components/MDBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast, ToastContainer } from "react-toastify";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const FilterList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const filterItem = location.state?.filterItem || {};
  console.log("filterItemfilterItem",filterItem);

  // State to hold the filter name, type, and the list of filters
  const [filterName, setFilterName] = useState("");
  const [filterDescription, setFilterDescription] = useState("");
  const [filterType, setFilterType] = useState(filterItem.name || "");
  const [filterTypeSelect, setFilterTypeSelect] = useState(filterItem.filtertype || "");
  const [filters, setFilters] = useState([]);
  const [typeId, setTypeId] = useState(null);

  // States for Edit functionality
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editFilterId, setEditFilterId] = useState(null);
  const [editFilterName, setEditFilterName] = useState("");
  const [editFilterIcon, setEditFilterIcon] = useState("");
  const [editFilterDescription, setEditFilterDescription] = useState("");

  const [formData, setFormData] = useState({
    name: "",
  });

  // Fetch filters from API
  const fetchFilters = async () => {
    try {
      const typeResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/getTypeIdByType`,
        { type: filterType, filterTypeSelect }
      );

      if (typeResponse.data.status === 1 && typeResponse.data.typeId) {
        const fetchedTypeId = typeResponse.data.typeId;
        setTypeId(fetchedTypeId);

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/GetFilterList`,
          { typeId: fetchedTypeId }
        );

        if (response.data.status === 1) {
          setFilters(response.data.data);
        } else {
          swal("Error", response.data.errors.message || "Failed to fetch Record", "error");
        }
      } else {
        swal("Error", typeResponse.data.errors.message || "Failed to fetch typeId", "error");
      }
    } catch (error) {
      console.error("Error fetching Record:", error);
      swal("Error", "Failed to fetch Record.", "error");
    }
  };

  // Fetch filters when component mounts or filterType changes
  useEffect(() => {
    if (filterType) {
      fetchFilters();
    }
  }, [filterType]);

  // Handle change for the filter name input field
  const handleInputChange = (e) => {
    setFilterName(e.target.value);
  };

  const handleTypeChange = (e) => {
    setFilterType(e.target.value);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    console.log("Selected file:", file); // Log the selected file

    if (file) {
      // First, update the formData with the selected file
      setFormData((prevState) => {
        console.log("Previous formData before image update:", prevState); // Log the previous formData
        return { ...prevState, image: file };
      });

      // Then use FileReader to generate a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        // Ensure you use prevState to avoid stale state issues
        setFormData((prevState) => {
          console.log("Previous formData before preview update:", prevState); // Log formData before updating with image preview
          return { ...prevState, imageprev: reader.result }; // Add the base64 image preview to formData
        });
        console.log("Updated formData with image preview:", reader.result); // Log the base64 preview
      };

      // Read the file as base64 data for the preview
      reader.readAsDataURL(file);
      console.log("FileReader is reading the file...");
    } else {
      console.log("No file selected");
    }
  };
  const handleImageChangeEdit = (e) => {
    const file = e.target.files[0]; // Get the selected file
    console.log("Selected file:", file); // Log the selected file

    if (file) {
      // First, update the formData with the selected file
      setFormData((prevState) => {
        return { ...prevState, image: file };
      });

     
      const reader = new FileReader();
      reader.onloadend = () => {
        // Ensure you use prevState to avoid stale state issues
        setFormData((prevState) => {
          
          return { ...prevState, imageprevedit: reader.result }; // Add the base64 image preview to formData
        });
        console.log("Updated formData with image preview:", reader.result); // Log the base64 preview
      };

      // Read the file as base64 data for the preview
      reader.readAsDataURL(file);
      console.log("FileReader is reading the file...");
    } else {
      console.log("No file selected");
    }
  };

  const handleAddFilters = async () => {
    if (!filterName) {
        toast.error("Name is required");
        return;
    }
    

    try {
      
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/getTypeIdByType`,
        { type: filterType, filterTypeSelect }
      );

      if (response.data.status !== 1 || !response.data.typeId) {
        swal("Error", "Invalid Record type or type not found", "error");
        return;
      }

      const typeId = response.data.typeId;

      const filtersData = {
        name: filterName,
        type: filterType,
        typeId,
      };

      const formDataToSend = new FormData();
      formDataToSend.append("typeId", typeId);
      formDataToSend.append("name", filterName);
      formDataToSend.append("description", filterDescription);
      formDataToSend.append("type", filterType);
      if (formData.image) {
        formDataToSend.append("icon", formData.image); // Append the image
      }

      // const addFilterResponse = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}api/admin/AddFilterList`,
      //   filtersData
      // );
      const addFilterResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/AddFilterList`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );

      if (addFilterResponse.data.status === 1) {
        swal("Success", "Record added successfully!", "success");
        setFilterName("");
        setFilterDescription("");
        fetchFilters();
      } else {
        swal("Error", addFilterResponse.data.errors.message || "Failed to add Record", "error");
      }
    } catch (error) {
      console.error("Error fetching typeId or adding Record:", error);
      swal("Error", "Failed to add Record.", "error");
    }
  };

  const handleDeleteFilter = async (filterId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/deleteFilterList`,
        { id: filterId }
      );
      if (response.data.status === 1) {
        swal("Success", "Record deleted successfully!", "success");
        fetchFilters();
      } else {
        swal("Error", response.data.errors.message || "Failed to delete Record", "error");
      }
    } catch (error) {
      console.error("Error deleting Record:", error);
      swal("Error", "Failed to delete Record.", "error");
    }
  };

  // Handle Edit Filter Dialog
  const handleEditOpen = (filter) => {
    setEditFilterId(filter._id);
    setEditFilterName(filter.name);
    setEditFilterDescription(filter.description);
    setEditFilterIcon(`${process.env.REACT_APP_BASE_URL}${filter.iconurl}`);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditSubmit = async () => {
    if (!editFilterName) {
        toast.error("Name is required");
        return;
    }
    try {

      const formDataToSend = new FormData();
      formDataToSend.append("id", editFilterId);
      formDataToSend.append("name", editFilterName);
      formDataToSend.append("description", editFilterDescription);
      if (formData.image) {
        formDataToSend.append("icon", formData.image); // Append the image
      }
      // const response = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}api/admin/EditFilterList`,
      //   { id: editFilterId, name: editFilterName }
      // );
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/EditFilterList`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );

      if (response.data.status === 1) {
        swal("Success", "Record updated successfully!", "success");
        fetchFilters(); // Refresh the filters list
        handleEditClose(); // Close the dialog
      } else {
        swal("Error", response.data.errors.message || "Failed to update Record", "error");
      }
    } catch (error) {
      console.error("Error updating Record:", error);
      swal("Error", "Failed to update Record.", "error");
    }
  };

  return (
    <>
    <DashboardLayout>
      <div className="DashPart">
          
          <section className="FilterSec">
            <div className="Add_Guide_Sec">
              <div className="FilterHead">
                <h2>Manage Filter Sub Type</h2>
              </div>

              <div className="BackBtn">
                <Button
                  variant="contained"
                  onClick={() => navigate(-1)}
                  style={{ marginTop: "16px", width: "10%", color: "#fff" }}
                  startIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
              </div>

              <div className="fltwidth">

                <div className="filterlistdiv">

                  
                  <div className="fttoppic">

                  <div className="guidFormControl">
                    <h6><strong>Filter Type</strong></h6>
                    <TextField   label="" className="Indtok"   value={filterType} fullWidth style={{background:"#227bea" ,color:"#fff ", borderRadius:"0.375rem"}} variant="outlined" margin="normal" size="small" InputProps={{ readOnly: true, style: { fontSize: "21px", color:"#fff" }, }}InputLabelProps={{ style: { fontWeight: "bold" ,color:"#fff" } }} />
                  </div>
                  <div className="UpldImgDiv">
                    
                    {formData.imageprev && (
                    <Avatar
                        src={formData.imageprev}
                        alt="Uploaded Icon"
                        style={{
                        width: "55px",
                        height: "55px",
                        border: "2px solid #439cf0",
                        padding: "5px",
                        marginRight: 8,
                        }}
                    />
                    )}

                    <Button component="label">Upload Icon<CloudUploadIcon />
                    <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                    </Button>
                    
                  </div>

                  </div>
                  <div className="guidFormControl">
                      <h6><strong>Sub Type Name</strong></h6>
                      <TextField label="Enter Name" value={filterName} onChange={handleInputChange} fullWidth variant="outlined"  margin="normal" size="small"/>
                    </div>

                  <div className="guidFormControl">
                    <h6><strong>Sub Type Description</strong></h6>
                    <TextField  fullWidth  variant="outlined" label="Enter Description" value={filterDescription}  onChange={(e) => setFilterDescription(e.target.value) }  multiline rows={2}/>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      padding: "10px 0px"
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddFilters}
                      size="small"
                      sx={{ color: "#fff" }}
                    >
                      Save
                    </Button>
                  </div>

                </div>

                <Box sx={{ marginTop: "16px" }}>
                  {/* <h2 style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                    {filterType} List:
                  </h2> */}
                  <TableContainer
                    
                  >
                    <Table className="FilterTable">
                      <TableHead>
                        <TableRow>
                          <TableCell>S.No</TableCell>
                          
                          <TableCell>Icon</TableCell>
                          <TableCell>Sub Type Name</TableCell>
                          <TableCell>Description</TableCell>
                          {/* <TableCell>Status</TableCell> */}
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filters.length > 0 ? (
                          filters.map((filter, index) => (
                            <TableRow key={filter._id}>
                              <TableCell>{index + 1}</TableCell>
                              
                              
                              <TableCell>
                              <Avatar
                                  src={`${process.env.REACT_APP_BASE_URL}${filter.iconurl}`}
                                  alt="Uploaded Icon"
                                  style={{
                                  width: "55px",
                                  height: "55px",
                                  border: "2px solid #439cf0",
                                  padding: "5px",
                                  marginRight: 8,
                                  }}
                              />
                              </TableCell>
                              <TableCell>{filter.name}</TableCell>
                              <TableCell>{filter.description}</TableCell>
                              {/* <TableCell>{filter.status}</TableCell> */}
                              <TableCell >
                                <IconButton
                                  onClick={() => handleEditOpen(filter)}
                                  color="secondary"
                                  style={{width:'fit-content'}}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleDeleteFilter(filter._id)}
                                  color="primary"
                                  style={{width:'fit-content'}}
                                  
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              No filters available.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </div>

              {/* Edit Filter Dialog */}
              <Dialog open={editDialogOpen} onClose={handleEditClose}>
                <DialogTitle style={{textAlign:"center"}}>Edit Filter Sub Type</DialogTitle>
                <DialogContent>
                  <div className="UpldImgDiv">
                    
                    <Avatar
                        src={formData.imageprevedit ? formData.imageprevedit : editFilterIcon} // If formData.imageprev exists, use it; otherwise, use a default image
                        alt="Uploaded Icon"
                        style={{
                            width: "55px",
                            height: "55px",
                            border: "2px solid #439cf0",
                            padding: "5px",
                            marginRight: 8,
                        }}
                    />

                    <Button component="label">
                    Upload Icon
                    <CloudUploadIcon />
                    <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChangeEdit}
                    />
                    </Button>
                  
                  </div>
                  <TextField
                    autoFocus
                    className="mb-3"
                    margin="dense"
                    label="Enter Sub Type Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={editFilterName}
                    onChange={(e) => setEditFilterName(e.target.value)}
                  />
                  <TextField
                        fullWidth
                        variant="outlined"
                        label="Description"
                        value={editFilterDescription} // Update the state value for title
                        onChange={(e) =>
                          setEditFilterDescription(e.target.value)
                        } // Update the title in formData
                        multiline
                        rows={2}
                        />
                  
                  

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleEditClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleEditSubmit} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
       
          </section>
      </div>
      </DashboardLayout>
    </>
  );
};

export default FilterList;
