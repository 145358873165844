import { useState, useEffect } from "react";
// react-router components
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import MDAvatar from "components/MDAvatar";
import Swal from 'sweetalert2';  // Import SweetAlert2


export default function Data() {
  const [data, setData] = useState([]);
  let navigate = useNavigate();
  const LoadData = async() => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getWhitelist_ip`).then((res) => {
        var response = res.data;
        console.log("response------>>>", response);
        response = response.data;
        console.log("responsekkkkkkkkkkkkk------>>>", response);
        var responseData = [];
        for (var i = 0; i < response.length; i++) {
          //console.log(assetImage);
          var inc=i+1;
          
          responseData.push(
            {
              id: inc,
              profilePic: <MDAvatar src={response[i].profilePhotoPathUser}/>,
              email: response[i].email,
              
              location: `${response[i].location.country}, ${response[i].location.region}, ${response[i].location.city}`,
              timezone: response[i].location.timezone || 'N/A',
              IP: response[i].IP,
              
              action: ActionList(navigate,response[i]._id,),
            }
          );
        }
        setData(responseData);
      });
    } catch (error) {
        console.log(error);
    }
  };
  useEffect(() => {
    LoadData();
  },[]); 

  return {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "image", accessor: "profilePic" },
      { Header: "email", accessor: "email" },
      
      { Header: "location", accessor: "location" },
      { Header: "timezone", accessor: "timezone" },
      { Header: "IP", accessor: "IP" },
      { Header: "action", accessor: "action" },
    ],

    rows: data ? data : "",
  };
  
}
// function ActionList(navigate,) {
//   const viewContent = async () => {
//     navigate("/getWhitelist_ip/");
//   };
// //   const editContent = (userId) => {
// //     navigate("/editStaff/" + userId);
// //   };


// return (
//   <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
//    <button className="btn btn-primary" onClick={() => viewContent()}>Delete</button>
   
//   </div>
// );
// }

function ActionList(navigate, id) {
  // Function to delete a record
  const deleteRecord = async () => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });

      if (result.isConfirmed) {
        await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deleteWhitelist_ip`, { id });
        Swal.fire(
          'Deleted!',
          'Your record has been deleted.',
          'success'
        );
        // Reload data after deletion
        window.location.reload();
      } else {
        Swal.fire(
          'Cancelled',
          'Your record is safe :)',
          'error'
        );
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      Swal.fire(
        'Error!',
        'Failed to delete record.',
        'error'
      );
    }
  };

  return (
    <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
      <button className="btn btn-primary" onClick={deleteRecord}>
        Delete
      </button>
    </div>
  );
}
  



