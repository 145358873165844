import React, { useState } from 'react';
import { Link, useNavigate,useParams } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";


import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";


// Data
import researchTypeDetail from "layouts/researchs/data/researchTypeDetail";

import uxInsightsTableData from "layouts/researchTypes/data/uxInsightsTableData";

function Tables() {
  const { id, subid } = useParams();
  const { singleResearchType } = researchTypeDetail(id, subid);
 
  
  console.log("singleResearchTypesingleResearchType",singleResearchType);
  let singlereserchtype = "";
  let subCategoryName = "";
  let pagetype = "";
 
  if (singleResearchType && singleResearchType.name && singleResearchType.subCategoryName && singleResearchType.pagetype) {
    singlereserchtype = singleResearchType.name;
    subCategoryName = singleResearchType.subCategoryName;
    pagetype = singleResearchType.pagetype;
  }
  const { columns, rows} = uxInsightsTableData(id, subid);
  console.log("rows",rows);
  
  
  let linkComponent;
  if (pagetype === "Yes") {
    linkComponent = (
      <Link to={`/research-add-annotation/${id}/${subid}`}>
        <AddIcon/>Add Page Review
      </Link>
    );
  } else if (pagetype === "No") {
    linkComponent = (
      <Link to={`/research-add/${id}/${subid}`}>
        {/* <Icon fontSize="small">add</Icon>Add */}
        <AddIcon/>Add Insight
      </Link>
    );
  } else {
    // Default case if pagetype is neither "Yes" nor "No"
    linkComponent = (
      <Link to={`/`}>
        <Icon fontSize="small">add</Icon>Add
      </Link>
    );
  }


 

  //Sorting
  const [sortOrder, setSortOrder] = useState('latest');

  // Sort rows based on the selected order
  const sortedRows = [...rows].sort((a, b) => {
    if (sortOrder === 'latest') {
      return new Date(b.createdAt) - new Date(a.createdAt); // Descending by date
    } else {
      return new Date(a.createdAt) - new Date(b.createdAt); // Ascending by date
    }
  });

  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const rowsPerPage = 8; // Number of rows per page
  // Calculate paginated rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const paginatedRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);


  // Calculate total pages
  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value); // Update sort order
    setCurrentPage(1); // Reset to the first page
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };




  return (
    <DashboardLayout>
      <section className="FilterSec">
        <div className="Add_Guide_Sec">

          <div className="FilterHead">
            <h2>Researches List </h2>
          </div>

          <div className="GuidlineForm">

            <div className='ResrchTopbar'>
              <div className="pageInfo">
                <h4 className='mb-3'><span>{singlereserchtype}</span>/{subCategoryName} </h4>
              </div>
              <div className="ResrchBtn">
                <div className="guidLink" >
                  <Link to={`/researchs`}><ArrowBackIcon/> Back</Link>
                </div>
                <div className="guidLink" >
                  {linkComponent}
                </div>
              </div>
            </div>

            <DataTable
              table={{ columns, rows }}
              //isSorted={false}
              canSearch={true}
              entriesPerPage={false}
              pagination={false}
              showTotalEntries={false}
              noEndBorder
            />

           

            
            

          </div>

        </div>
      </section>

    </DashboardLayout>
  );
}

export default Tables;




