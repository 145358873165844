import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Editor from "components/Custom/Editor";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import CountryCity from "../../assets/countriescities.json";

export default function AddAsset() {
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [furtherInfo, setFurtherInfo] = useState("");
    let navigate = useNavigate();
    const [errors, setErrors] = useState(false);
	const [users, setUsers] = useState(false);
	const [categories, setCategories] = useState(false);
	const [categoryFields, setCategoryFields] = useState(false);
	const [projectPic, setProjectPic] = useState("");
	const [projectDocument, setProjectDocument] = useState("");
    const [quantity, setQuantity] = useState(false);
    const [cityData, setCityData] = useState(false);
    const [assetCategoryId, setAssetCategoryId] = useState(false);
    useEffect(() => {
        setEditorLoaded(true);
		axios.post("/api/getAssetCategories").then((res) => {
            const response = res.data;
            if (response.status == 1) {
				setCategories(response.categoryList);
            }
        });
		axios.post("/api/allUsers").then((res) => {
            const response = res.data;
            if (response.status == 1) {
				setUsers(response.users);
            }
        });
    }, []);
	const changeCategory = (e) => {
        var value=e.target.value;
        document.querySelector(".AstForm").reset();
        document.querySelector('[name="assetCategory"]').value = value;
		var assetCategoryId=e.target.value;
        //var assetCategoryId = e.target.getAttribute("data-id");
        
        
		var data={
			assetCategoryId,
			userType:"Seller"
		};
		axios.post("/api/getCategoryFields", data).then((res) => {
            const response = res.data;
            if (response.status == 1) {
				setCategoryFields(response.data);
            }
        });
	};
	const handleChangeFile = (file, set) => {
        if (set == "picture") {
            setProjectPic(file);
        }
        else {
            setProjectDocument(file);
        }
    };
    const changeMaterialQuantity = (e) => {
        
        if(e.target.value=="Rough diamonds"){
           setQuantity(categoryFields.materialQuantityList[0].quantity);
        }
        else{
            setQuantity(categoryFields.materialQuantityList[1].quantity);
        }
    };
    const changeLocation = (e) => {
        var index=(e.target.selectedIndex)-1;
        
        setCityData(CountryCity[index].cities);
        setTimeout(() => {
            document.querySelector('[name="subLocation"]').value="";
        }, "1000");
    };
    
   
    
    const submitData = (e) => {
        e.preventDefault();
		//var { userId, assetName, assetCategory, investmentType, desiredYield, subCategory, material, quantity, location, saleType, sellingPrice  } = e.target;
        var { userId,assetCategory,investmentType,assetName,location,subLocation,sellingPrice,material,quantity,saleType,desiredEstimate,subCategory,furtherInfo } = e.target;
        var form = e.target;
        // Access and process each select element individually
        var assetCategorySelect = form.querySelector('#assetCategory');
        var assetCategorySelectedOption = assetCategorySelect.options[assetCategorySelect.selectedIndex];
        var assetCategoryName = assetCategorySelectedOption.textContent;

        var assetSubLocation = form.querySelector('#subLocation');
        var aassetSubLocationSelectedOption = assetSubLocation.options[assetSubLocation.selectedIndex];
        var SubLocationName = aassetSubLocationSelectedOption.textContent;
        
        const formData = new FormData();
		formData.append("userId",userId?.value);
        formData.append("assetCategory",assetCategoryName);
        formData.append("assetCategoryId",assetCategory?.value);
        formData.append("subCategory",subCategory?.value);
        formData.append("investmentType",investmentType?.value);
		formData.append("assetName",assetName?.value);
        formData.append("sellingPrice",sellingPrice?.value);
		formData.append("location",location?.value);
        formData.append("locationIndex",((location?.selectedIndex)-1));
        formData.append("subLocation",SubLocationName);
        //formData.append("furtherInfo",furtherInfo);
        formData.append("furtherInfo",furtherInfo?.value);
		formData.append("saleType",saleType?.value);
		formData.append("projectPic",projectPic);
		formData.append("projectDocument",projectDocument);
        formData.append("material",material?.value);
        formData.append("quantity",quantity?.value);
        formData.append("desiredEstimate",desiredEstimate?.value);
        //console.log([...formData]);
        //console.log(formData);
        axios.post("/api/addAsset1", formData).then((res) => {
            const response = res.data;
            if (response.status == 0) {
                setErrors(response.errors);
            } else {
                swal("success", "Asset added successfully!", "success");
                navigate("/assets");
            }
        });
    };        
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Add Asset
                            </MDTypography>
                        </MDBox>
                        <MDBox p={3}>
                        <form onSubmit={submitData} className="AstForm">

                            <div className="AssetTopForm">
                                <div className="AssetForms">
                                    
                                        <div class="form-group">
                                            <label for="email">Select User:</label>
                                            <select class="form-control" name="userId">
                                                <option value="">--Select--</option>
                                                {
                                                    users?users.map((user)=>
                                                        (<option value={user._id}>{user.firstName} {user.lastName}</option>)
                                                    ):""
                                                }
                                            </select>
                                            <span className="redError">{errors.userId ? errors.userId : ""}</span>
                                        </div>
                                        
                                        {
                                            (categories.length>0)?(
                                                <div class="form-group">
                                                    <label for="email">Choose A Category:</label>
                                                    <select class="form-control" id="assetCategory" name="assetCategory" onChange={changeCategory}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            categories?categories.map((category)=>
                                                                (<option data-id={category._id} data-name={category.categoryName} value={category._id}>{category.categoryName}</option>)
                                                            ):""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.assetCategory ? errors.assetCategory : ""}</span>
                                                </div>
                                            ):""
                                        }
                                        <div class="form-group">
                                            <label for="email">Asset Unique Name:</label>
                                            <input type="text" class="form-control" name="assetName"/>
                                            <span className="redError">{errors.assetName ? errors.assetName : ""}</span>
                                        </div>
                                        {/* {
                                            ((categoryFields?.investmentTypeList)?.length>0)?(
                                                <div class="form-group">
                                                    <label for="email">TYPE OF INVESTMENT:</label>
                                                    <select class="form-control" name="investmentType">
                                                        <option value="">--Select--</option>
                                                        {
                                                            categoryFields?(categoryFields.investmentTypeList).map((investmentType)=>
                                                                (<option>{investmentType.investmentType}</option>)
                                                            ):""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.investmentType ? errors.investmentType : ""}</span>
                                                </div>
                                            ):""
                                        } */}
                                        {
                                            ((categoryFields?.DesiredYieldList)?.length>0)?(
                                                <div class="form-group">
                                                    <label for="email">Yield Estimate:</label>
                                                    <select class="form-control" name="desiredEstimate">
                                                        <option value="">--Select--</option>
                                                        {
                                                            categoryFields?(categoryFields.DesiredYieldList).map((desiredYield)=>
                                                                (<option>{desiredYield.name}</option>)
                                                            ):""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.desiredEstimate ? errors.desiredEstimate : ""}</span>
                                                </div>
                                            ):""
                                        }
                                        {
                                            ((categoryFields?.assetTypeList)?.length>0)?(
                                                <div class="form-group">
                                                    <label for="email">CATEGORY:</label>
                                                    <select class="form-control" name="subCategory">
                                                        <option value="">--Select--</option>
                                                        {
                                                            categoryFields?(categoryFields.assetTypeList).map((assetType)=>
                                                                (<option value={assetType.assetType}>{assetType.assetType}</option>)
                                                            ):""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.subCategory ? errors.subCategory : ""}</span>
                                                </div>
                                            ):""
                                        }
                                        {
                                            ((categoryFields?.materialQuantityList)?.length>0)?(
                                                <div class="form-group">
                                                    <label for="email">MATERIAL:</label>
                                                    <select class="form-control" name="material" onChange={changeMaterialQuantity}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            categoryFields?(categoryFields.materialQuantityList).map((materialQuantity)=>
                                                                (<option>{materialQuantity._id}</option>)
                                                            ):""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.material ? errors.material : ""}</span>
                                                </div>
                                            ):""
                                        }
                                        {
                                            (quantity?.length>0)?(
                                                <div class="form-group">
                                                    <label for="email">QUANTITY:</label>
                                                    <select class="form-control" name="quantity" onChange={changeMaterialQuantity}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            quantity?quantity.map((qty)=>
                                                                (<option>{qty.name}</option>)
                                                            ):""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.quantity ? errors.quantity : ""}</span>
                                                </div>
                                            ):""
                                        }
                                        {
                                            (CountryCity?.length>0)?(
                                                <div class="form-group">
                                                    <label for="email">SELECT LOCATION:</label>
                                                    <select class="form-control" name="location" onChange={changeLocation}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            CountryCity?CountryCity.map((location)=>
                                                                (<option>{location.name}</option>)
                                                            ):""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.location ? errors.location : ""}</span>
                                                </div>
                                            ):""
                                        }
                                        {/* {
                                            (cityData?.length>0)?( */}
                                                <div class="form-group">
                                                    <label for="email">SELECT CITY:</label>
                                                    <select class="form-control" id="subLocation" name="subLocation">
                                                        <option value="">--Select--</option>
                                                       
                                                         
                                                         {
                                                             
                                                            cityData && cityData.length > 0 &&
                                                            
                                                            cityData.map((location) => (
                                                            <option key={location.id} value={location.id}>
                                                                {location.name}
                                                            </option>
                                                            ))
                                                        }
                                                    
                                                    </select>
                                                    <span className="redError">{errors.subLocation ? errors.subLocation : ""}</span>
                                                </div>
                                            {/* ):""
                                        } */}
                                        {
                                            ((categoryFields?.saleTypeList)?.length>0)?(
                                                <div class="form-group">
                                                    <label for="email">TYPE OF SALE:</label>
                                                    <select class="form-control" name="saleType">
                                                        <option value="">--Select--</option>
                                                        {
                                                            categoryFields?(categoryFields.saleTypeList).map((saleType)=>
                                                                (<option>{saleType.saleType}</option>)
                                                            ):""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.saleType ? errors.saleType : ""}</span>
                                                </div>
                                            ):""
                                        }
                                        <div class="form-group">
                                            <label for="email">Selling Price:</label>
                                            <input type="text" class="form-control" name="sellingPrice"/>
                                            <span className="redError">{errors.sellingPrice ? errors.sellingPrice : ""}</span>
                                        </div>                  
                                    
                                </div>
                                <div className="AssetUploade">
                                    <div class="form-group">
                                        <label for="email">Project Images:</label>
                                        <input type="file" class="form-control" name="projectPic" onChange={(e) => handleChangeFile(e.target.files[0], 'picture')}/>
                                        <span className="redError">{errors.projectPic ? errors.projectPic : ""}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Project Documents:</label>
                                        <input type="file" class="form-control" name="projectDocument" onChange={(e) => handleChangeFile(e.target.files[0], 'document')}/>
                                        <span className="redError">{errors.projectDocument ? errors.projectDocument : ""}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="AssetEdditor">
                                <div class="form-group">
                                    <label for="pwd">Details:</label>
                                    <textarea class="form-control" id="faq_answer" name="furtherInfo" rows="4" cols="50">
                                        
                                    </textarea>
                                    {/* <Editor
                                        name="furtherInfo"
                                        onChange={(data) => {
                                            setFurtherInfo(data);
                                        }}
                                        editorLoaded={editorLoaded}
                                        value={""}
                                    /> */}
                                    <span className="redError">{errors.furtherInfo ? errors.furtherInfo : ""}</span>
                                </div>
                            </div>
                            <div className="AssetAddButton">
                                <button type="submit" >Add</button>
                            </div>

                            </form> 

                        </MDBox>
                    </Card>
                </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
