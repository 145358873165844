import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import ImageTool from "@editorjs/image";
import Table from "@editorjs/table";
import Paragraph from "@editorjs/paragraph";
import CodeTool from "@editorjs/code";
import HorizontalLine from "components/Editor/HorizontalLine";
import CarouselTool from "components/Editor/CarouselTool/CarouselTool.js";
import CheckboxTool from "components/Editor/CheckboxTool/CheckboxTool.js";
import ColorTool from "components/Editor/ColorTool/ColorTool.js";
import CustomListTool from "components/Editor/CustomListTool/CustomListTool.js";
import CustomParagraphTool from "components/Editor/CustomParagraphTool/CustomParagraphTool.js";
import CustomTitleTool from "components/Editor/CustomTitleTool/CustomTitleTool.js";
import { RiFontSize } from "react-icons/ri";
import { RxFontFamily } from "react-icons/rx";
import { GrBold } from "react-icons/gr";
import { MdFormatColorText } from "react-icons/md";
import { IoMdColorFill } from "react-icons/io";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MyTitleTool from "components/Editor/EnhanceEditor/MyTitleTool.js";
import MyListTool from "components/Editor/EnhanceEditor/MyListTool.js";
import MyCheckboxListTool from "components/Editor/EnhanceEditor/MyCheckboxListTool.js";
import ImageGalleryTool from "components/Editor/EnhanceEditor/ImageGalleryTool.js";
import EmptyBlock from "components/Editor/EnhanceEditor/EmptyBlock.js";
import QuoteInlineTool from "components/Editor/EnhanceEditor/QuoteInlineTool.js";

import { Grid, TextField, Button, InputAdornment, Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Modal,
} from "@mui/material";

import { Link, useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import DeleteIcon from "@mui/icons-material/Delete"; // Import Delete icon
import IconButton from "@mui/material/IconButton"; // Import IconButton
import ClearIcon from "@mui/icons-material/Clear";

const notifySuccess = (message) => {
  toast.success(message);
};

const notifyError = (message) => {
  toast.error(message);
};

export default function EditGuideline() {
  const [loading, setLoading] = useState(true);
  const { id, dup } = useParams(); // Get the guideline ID from URL params
  const navigate = useNavigate();
  const editorInstance = useRef(null);
  const editorInstance2 = useRef(null);

  // State for dynamic filterTypes fetched from the APIs
  const [uxinsights, setUxinsights] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [pageTypes, setpageTypes] = useState([]);

  const [formData, setFormData] = useState({
    guidelinecategory: "",
    subcategory: "",
    filters: {},
    impacts: {},
    description: {},
    hashNumber: "",
    title: "",
    tagkeywords: "",
    subtitle: "",
    forusers: "",
    pagetype: "",
    titleDescription: "",
    annotationslist: [],
    selectedGoodOptions: [],
    selectedBadOptions: [],
    uxinsightslist: [],
    selectedDesktopFiles: [],
    selectedDesktopFileUrls: [],
    selectedDesktopFileUrlsShow: [],
    selectedMobileFiles: [],
    selectedMobileFilesShow: [],
    selectedMobileFileUrls: [],
    selectedDesktopVideoFiles: [],
    selectedDesktopVideoFileUrls: [],
    selectedDesktopVideoFileUrlShow: [],
    selectedMobileVideoFiles: [],
    selectedMobileVideoFileUrls: [],
    selectedMobileVideoFileUrlShow: [],
  });

  const [filterCategories, setFilterCategories] = useState({});
  const [guidelinecategories, setGuidelinecategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [showSubcategory, setShowSubcategory] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [pagetypeshow, setPagetypeshow] = useState(false);
  const [selectfeaturedesktop, setSelectfeaturedesktop] = useState(0);
  const [selectfeaturemobile, setSelectfeaturemobile] = useState(0);
  const [selectfontsize, setSelectfontsize] = useState("");
  const [selectfontfamily, setSelectfontfamily] = useState("Arial");
  const [history, setHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);

  const [annotationOptions, setAnnotationOptions] = useState({
    Desktop: { Good: [], Bad: [] },
    Mobile: { Good: [], Bad: [] },
    App: { Good: [], Bad: [] },
  });

  

  

  // const annotationOptions = {
  //   Desktop: { Good: ["aa", "bb", "cc"], Bad: ["zz", "yy"] },
  //   Mobile: { Good: ["11", "22", "33"], Bad: ["88", "99"] },
  //   App: { Good: ["mm", "nn", "oo"], Bad: ["xx", "yy"] },
  // };
  const annotations = ["Desktop", "Mobile", "App"];

  //   const annotationOptions = {
  //     Desktop: { Good: [], Bad: [] },
  //     Mobile: { Good: [], Bad: [] },
  //     App: { Good: [], Bad: [] }
  // };

  // const annotationOptions = {
  //   Desktop: {
  //     Good: [
  //       { id: "1", name: "aa" },
  //       { id: "2", name: "bb" },
  //       { id: "3", name: "cc" }
  //     ],
  //     Bad: [
  //       { id: "1", name: "zz" },
  //       { id: "2", name: "yy" }
  //     ]
  //   },
  //   Mobile: {
  //     Good: [
  //       { id: "1", name: "11" },
  //       { id: "2", name: "22" },
  //       { id: "3", name: "33" }
  //     ],
  //     Bad: [
  //       { id: "1", name: "88" },
  //       { id: "2", name: "99" }
  //     ]
  //   },
  //   App: {
  //     Good: [
  //       { id: "1", name: "mm" },
  //       { id: "2", name: "nn" },
  //       { id: "3", name: "oo" }
  //     ],
  //     Bad: [
  //       { id: "1", name: "xx" },
  //       { id: "2", name: "yy" }
  //     ]
  //   }
  // };

  //const uxinsights = ["UX Insight 1", "UX Insight 2", "UX Insight 3"];
  
  const handleChangeUXInsights = (event) => {
    const { value } = event.target;
    console.log("valuevalue", value);
    setFormData({
      ...formData,
      uxinsightslist: typeof value === "string" ? value.split(",") : value, // Update uxinsightslist
    });
  };
  const handleChangeAnnotation = (event) => {
    const { value } = event.target;
    console.log("valuevalue", value);
    setFormData({
      ...formData,
      annotationslist: typeof value === "string" ? value.split(",") : value, // Update annotationslist
    });
  };
  const handleGoodOptionChange = (annotation, event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      selectedGoodOptions: {
        ...prevState.selectedGoodOptions,
        [annotation]: typeof value === "string" ? value.split(",") : value,
      },
    }));
  };

  const handleCheckboxChange = (index, type) => {
    if (type == "desktop") {
      if (selectfeaturedesktop === index) {
        setSelectfeaturedesktop(0); // Deselect if the same index is clicked again
      } else {
        setSelectfeaturedesktop(index); // Select the new image by index
      }
    } else if (type == "mobile") {
      if (selectfeaturemobile === index) {
        setSelectfeaturemobile(0); // Deselect if the same index is clicked again
      } else {
        setSelectfeaturemobile(index); // Select the new image by index
      }
    }

    console.log(
      "selectfeaturedesktop",
      selectfeaturedesktop,
      "selectfeaturemobile",
      selectfeaturemobile
    );
  };

  const handleBadOptionChange = (annotation, event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      selectedBadOptions: {
        ...prevState.selectedBadOptions,
        [annotation]: typeof value === "string" ? value.split(",") : value,
      },
    }));
  };

  // Fetch dynamic filter types from the API and set the initial state for filters
  useEffect(() => {
    // async function fetchPageTypes() {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_BASE_URL}api/admin/getPageTypeGuideline`
    //     );
    //     if (response.data.status === 1) {
    //       setpageTypes(response.data.pageTypes);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching page Types:", error);
    //   }
    // }

    async function fetchFilterTypes() {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/fetchFilterTypes`,
          { filtertype: "guideline" }
        );
        if (response.data.status === 1) {
          setFilterTypes(response.data.filterTypes);

          // Initialize filters object dynamically
          const filtersInit = response.data.filterTypes.reduce(
            (acc, filter) => {
              acc[filter.name] = [];
              return acc;
            },
            {}
          );
          setFormData((prevFormData) => ({
            ...prevFormData,
            filters: filtersInit,
          }));

          // Fetch filters for each filter type
          response.data.filterTypes.forEach((filter) => {
            fetchFilters(filter._id, filter.name); // Make sure to use filter.name here
          });
        }
      } catch (error) {
        console.error("Error fetching filter types:", error);
      }
    }

    async function fetchFilters(filterId, filterName) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/fetchFilters`,
          { filters: filterId }
        );
        if (response.data.status === 1) {
          setFilterCategories((prevCategories) => {
            const updatedCategories = {
              ...prevCategories,
              [filterName]: response.data.subfilters,
            };
            console.log("Updated filterCategories:", updatedCategories); // Log the updated value here
            return updatedCategories;
          });
        }
      } catch (error) {
        console.error(`Error fetching filters for ${filterName}:`, error);
      }
    }

    
    async function fetchCategories() {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/fetchCategories`
        );
        if (response.data.status === 1) {
          setGuidelinecategories(response.data.categories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }

    async function fetchAnnotations() {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/fetchAnnotations`
        );

        if (response.data.status === 1) {
          console.log("annotationsannotations", response.data.annotations);

          const fetchedAnnotations = response.data.annotations;

          const updatedOptions = {
            Desktop: { Good: [], Bad: [] },
            Mobile: { Good: [], Bad: [] },
            App: { Good: [], Bad: [] },
          };

          fetchedAnnotations.forEach((annotation) => {
            const { title, devicetype, annotation_status, _id } = annotation;
            const name = title; // Assuming `name` is provided or fallback to `_id`

            if (
              updatedOptions[devicetype] &&
              updatedOptions[devicetype][annotation_status]
            ) {
              updatedOptions[devicetype][annotation_status].push({
                id: _id,
                name: name,
              });
            }
          });

          setAnnotationOptions(updatedOptions);
        }
      } catch (error) {
        console.error("Error fetching annotations:", error);
      }
    }

    async function fetchGuideline() {
      if (id) {
        // Only fetch if editing

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/admin/getSingleGuideline`,
            { id }
          );
          console.log("response",response);
          if (response.data.status === 1) {
            const description =
              typeof response.data.guideline.description === "string"
                ? JSON.parse(response.data.guideline.description)
                : response.data.guideline.description || {};
            const impacts =
              typeof response.data.guideline.impacts === "string"
                ? JSON.parse(response.data.guideline.impacts)
                : response.data.guideline.impacts || {};

            setFormData((prevData) => ({
              ...prevData,
              ...response.data.guideline, // Merge fetched data with current form data
              description: description, // Use the processed description
              impacts: impacts, // Use the processed description
              image: `${process.env.REACT_APP_BASE_URL}${response.data.guideline.guideicon}`,
              imageprev: `${process.env.REACT_APP_BASE_URL}${response.data.guideline.guideicon}`,
              selectedDesktopFileUrlsShow:
                response.data.guideline.desktopImages || [], // Prefill with existing desktop images
              selectedMobileFilesShow:
                response.data.guideline.mobileImages || [], // Prefill with existing mobile images
              selectedDesktopVideoFileUrlShow:
                response.data.guideline.desktopVideos || [], // Prefill with existing desktop videos
              selectedMobileVideoFileUrlShow:
                response.data.guideline.mobileVideos || [],
              annotationslist: response.data.guideline.annotationslist,
              selectedGoodOptions:
                response.data.guideline.selectedGoodOptions[0] || {},
              selectedBadOptions:
                response.data.guideline.selectedBadOptions[0] || {},
              uxinsightslist: response.data.guideline.uxinsightslist,
            }));
            setSelectfeaturedesktop(
              response.data.guideline.selectfeaturedesktop
            );
            setSelectfeaturemobile(response.data.guideline.selectfeaturemobile);

            setShowEditor(true);
            handleCategoryChangeAPI(response.data.guideline.guidelinecategory);
            fetchPageTypes(response.data.guideline.guidelinecategory,response.data.guideline.subcategory);
            // if (response.data.guideline.pagetype && response.data.guideline.pagetype === "Yes") {
            //   setPagetypeshow(true);
            // }
          }
        } catch (error) {
          console.error("Error fetching guideline:", error);
        } finally {
          setLoading(false); // Hide loader after fetching
        }
      }
    }

    async function fetchUxInsightsAttached() {
      if (id) {
        // Only fetch if editing

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/admin/fetchUxInsightsAttached`,
            { id }
          );
          if (response.data.status === 1) {
            setUxinsights(response.data.uxinsightslist);
          }
        } catch (error) {
          console.error("Error fetching guideline:", error);
        }
      }
    }

    // Fetch filter types and filters
    //fetchPageTypes();
    fetchFilterTypes();
    fetchCategories();
    fetchGuideline();
    fetchAnnotations();
    fetchUxInsightsAttached();
  }, []);

  // Handle Editor.js initialization when the editor is visible
  useEffect(() => {
    
    if (showEditor && !editorInstance.current) {
      const style = document.createElement("style");
      style.innerHTML = `div#editorjs .ce-tooltip { color: black !important; }`;
      document.head.appendChild(style);

      const preprocessKeypoints = (keypoints) => {
        if (!keypoints || !keypoints.blocks) return keypoints;
  
        const updatedBlocks = keypoints.blocks.map((block) => {
          if (block.type === "list" || block.type === "checkboxlist" && block.data.items) {
            return {
              ...block,
              data: {
                ...block.data,
                items: block.data.items.map((item) =>
                  typeof item === "string" ? item : item.content || ""
                ),
              },
            };
          }
          
          return block;
        });
  
        return { ...keypoints, blocks: updatedBlocks };
      };
  
      const initialData = preprocessKeypoints(formData.description);

      editorInstance.current = new EditorJS({
        holder: "editorjs",
        tools: {
          title: {
            class: MyTitleTool,
            inlineToolbar: false,
          },
          list: {
            class: MyListTool,
            inlineToolbar: true,
          },
          header: {
            class: Header,
            inlineToolbar: true,
            config: {
              defaultLevel: 4, // Set default heading to H4
            }
          },
          checkbox: {
              class: CheckboxTool,
              inlineToolbar: true, // ✅ Enables inline tools inside checkbox text
          },
          checkboxlist: MyCheckboxListTool,
          // title: {
          //   class: CustomTitleTool, // ✅ Ensure this is a separate tool
          //   inlineToolbar: false,
          // },
          // header: {
          //   class: Header,
          //   inlineToolbar: true,
          // },
          paragraph: {
            class: CustomParagraphTool,
            inlineToolbar: ['bold', 'italic','quoteInline'],
            // config: {
            //   sanitize: {
            //     span: {
            //       'data-id': true,
            //       'data-type': true,
            //       'class': true,
            //       style: true
            //     }
            //   }
            // }
          },
          quoteInline: QuoteInlineTool,
          textColor: {
            class: ColorTool, // ✅ Register custom color tool
            shortcut: "CTRL+M",
          },
          imageGallery: {
            class: ImageGalleryTool,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
          },
          empty: EmptyBlock,
          // list: {
          //   class: CustomListTool,
          //   inlineToolbar: ["bold", "italic", "color"],
          //   config: {
          //     defaultStyle: 'ordered',
          //     nested: true,
          //     sanitize: {
          //       span: {
          //         'data-id': true,
          //         'data-type': true,
          //         style: true
          //       }
          //     }
          //   }
          // },
          // image: {
          //   class: ImageTool,
          //   config: {
          //     uploader: {
          //       async uploadByFile(file) {
          //         const formData = new FormData();
          //         formData.append("image", file);
          //         try {
          //           const { data } = await axios.post(
          //             `${process.env.REACT_APP_BASE_URL}api/admin/uploadImage`,
          //             formData,
          //             {
          //               headers: { "Content-Type": "multipart/form-data" },
          //             }
          //           );
          //           return { success: 1, file: { url: data.file.url } };
          //         } catch (error) {
          //           console.error("Image upload failed:", error);
          //           return { success: 0 };
          //         }
          //       },
          //       async uploadByUrl(url) {
          //         return { success: 1, file: { url: url } };
          //       },
          //     },
          //   },
          // },
           

          // quote: Quote,
          // table: {
          //   class: Table,
          //   inlineToolbar: ["bold", "italic", "color"], // Default options
          // },
          
          // delimiter: HorizontalLine,
          // checkbox: CheckboxTool,
          // code: CodeTool,
          
        },
        data: initialData,
        onChange: async () => {
          const savedData = await editorInstance.current.save();
          console.log("savedData", savedData);
          setHistory((prevHistory) => [
            ...prevHistory.slice(0, currentHistoryIndex + 1),
            savedData,
          ]);
          setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
          setFormData((prevData) => ({ ...prevData, description: savedData }));
        },
      });
      return () => {
        if (editorInstance.current) {
          editorInstance.current.destroy();
          editorInstance.current = null;
        }
      };
    }

    return () => {
      if (editorInstance.current) {
        editorInstance.current.destroy();
        editorInstance.current = null;
      }
    };
  }, [showEditor]);

  // useEffect(() => {
  //   if (showEditor && !editorInstance2.current) {
  //     const style2 = document.createElement("style");
  //     style2.innerHTML = `div#editorjs2 .ce-tooltip { color: black !important; }`;
  //     document.head.appendChild(style2);

  //     const preprocessKeypoints = (keypoints) => {
  //       if (!keypoints || !keypoints.blocks) return keypoints;
  
  //       const updatedBlocks = keypoints.blocks.map((block) => {
  //         if (block.type === "list" && block.data.items) {
  //           return {
  //             ...block,
  //             data: {
  //               ...block.data,
  //               items: block.data.items.map((item) =>
  //                 typeof item === "string" ? item : item.content || ""
  //               ),
  //             },
  //           };
  //         }
          
  //         return block;
  //       });
  
  //       return { ...keypoints, blocks: updatedBlocks };
  //     };
  
  //     const initialData = preprocessKeypoints(formData.impacts);

  //     editorInstance2.current = new EditorJS({
  //       holder: "editorjs2",
  //       tools: {
  //         header: {
  //           class: Header,
  //           inlineToolbar: true,
  //         },
  //         paragraph: {
  //           class: Paragraph,
  //           inlineToolbar: true,
  //         },
  //         list: {
  //           class: List,
  //           inlineToolbar: ["bold", "italic", "color"],
  //         },
  //         image: {
  //           class: ImageTool,
  //           config: {
  //             uploader: {
  //               async uploadByFile(file) {
  //                 const formData = new FormData();
  //                 formData.append("image", file);
  //                 try {
  //                   const { data } = await axios.post(
  //                     `${process.env.REACT_APP_BASE_URL}api/admin/uploadImage`,
  //                     formData,
  //                     {
  //                       headers: { "Content-Type": "multipart/form-data" },
  //                     }
  //                   );
  //                   return { success: 1, file: { url: data.file.url } };
  //                 } catch (error) {
  //                   console.error("Image upload failed:", error);
  //                   return { success: 0 };
  //                 }
  //               },
  //               async uploadByUrl(url) {
  //                 return { success: 1, file: { url: url } };
  //               },
  //             },
  //           },
  //         },
  //         color: ColorTool,
  //         quote: Quote,
  //         table: {
  //           class: Table,
  //           inlineToolbar: ["bold", "italic", "color"], // Default options
  //         },
  //         //table: Table,
  //         delimiter: HorizontalLine,
  //         checkbox: CheckboxTool,
  //         code: CodeTool,
  //       },
  //       data: initialData,
  //       onChange: async () => {
  //         const savedData = await editorInstance2.current.save();
  //         console.log("savedData", savedData);
  //         setHistory((prevHistory) => [
  //           ...prevHistory.slice(0, currentHistoryIndex + 1),
  //           savedData,
  //         ]);
  //         setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
  //         setFormData((prevData) => ({ ...prevData, impacts: savedData }));
  //       },
  //     });

  //     return () => {
  //       document.head.removeChild(style2);
  //     };
  //   }

  //   return () => {
  //     if (editorInstance2.current) {
  //       editorInstance2.current.destroy();
  //       editorInstance2.current = null;
  //     }
  //   };
  // }, [showEditor]);

  const handleUndo = () => {
    if (currentHistoryIndex > 0) {
      const newIndex = currentHistoryIndex - 1;
      setCurrentHistoryIndex(newIndex);

      // Ensure we pass the correct data structure
      const previousData = history[newIndex];

      if (previousData && previousData.blocks) {
        editorInstance.current.render(previousData); // Load the previous state correctly
      } else {
        console.error("Invalid data format for render", previousData);
      }
    }
  };

  const handleRedo = () => {
    if (currentHistoryIndex < history.length - 1) {
      const newIndex = currentHistoryIndex + 1;
      setCurrentHistoryIndex(newIndex);

      // Ensure we pass the correct data structure
      const nextData = history[newIndex];

      if (nextData && nextData.blocks) {
        editorInstance.current.render(nextData); // Load the next state correctly
      } else {
        console.error("Invalid data format for render", nextData);
      }
    }
  };

  // Key event listeners for Ctrl+Z and Ctrl+Y
  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.ctrlKey && e.key === "z") {
        handleUndo();
      } else if (e.ctrlKey && e.key === "y") {
        handleRedo();
      }
    };

    window.addEventListener("keydown", handleKeydown);
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [currentHistoryIndex, history]);

  const handleFontSizeChange = async (size) => {
    console.log("size", size);
    const selection = window.getSelection();

    // Check if there's a valid selection
    if (!selection || selection.rangeCount === 0) {
      console.log("No text selected"); // Optional: You can show a message or do nothing
      //toast.error("No text is selected");
      return;
    }

    const range = selection.getRangeAt(0); // Get the selected range

    // If the range is collapsed (no selection), exit the function
    if (range.collapsed) {
      console.log("No text is selected");
      //toast.error("No text is selected");
      return;
    }

    // If text is selected, modify it
    const selectedText = range.extractContents(); // Extract the selected content
    const span = document.createElement("span");
    span.style.fontSize = size + "px"; // Apply the font size
    span.appendChild(selectedText); // Append the selected content inside the span

    range.insertNode(span); // Insert the new span back into the range

    // Clear selection to avoid multiple insertions
    selection.removeAllRanges();

    // Update the selected font size in state
    //setSelectfontsize(size);
    setSelectfontsize("");

    // Save the editor data and update history
    const savedData = await editorInstance.current.save();
    setHistory((prevHistory) => [
      ...prevHistory.slice(0, currentHistoryIndex + 1),
      savedData,
    ]);
    setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
  };

  const handleFontFamilyChange = async (family) => {
    const selection = window.getSelection();

    // Check if there's a valid selection
    if (!selection || selection.rangeCount === 0) {
      console.log("No text selected");
      toast.error("No text is selected");
      return;
    }

    const range = selection.getRangeAt(0); // Get the selected range

    // If the range is collapsed (no selection), exit the function
    if (range.collapsed) {
      console.log("No text is selected");
      toast.error("No text is selected");
      return;
    }

    // If text is selected, modify it
    const selectedText = range.extractContents(); // Extract the selected content
    const span = document.createElement("span");
    span.style.fontFamily = family; // Apply the font family
    span.appendChild(selectedText); // Append the selected content inside the span

    range.insertNode(span); // Insert the new span back into the range

    // Clear selection to avoid multiple insertions
    selection.removeAllRanges();

    // Update the selected font family in state
    setSelectfontfamily(family);

    // Save the editor data and update history
    const savedData = await editorInstance.current.save();
    setHistory((prevHistory) => [
      ...prevHistory.slice(0, currentHistoryIndex + 1),
      savedData,
    ]);
    setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
  };
  const handleFontStyleChange = async (style) => {
    console.log("Font style:", style);
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0); // Get the selected range
    const span = document.createElement("span");

    if (style === "bold") {
      span.style.fontWeight = "bold"; // Apply bold style
    } else if (style === "italic") {
      span.style.fontStyle = "italic"; // Apply italic style
    }

    range.surroundContents(span); // Wrap the selected content with the span
    // Save the editor data and update history
    const savedData = await editorInstance.current.save();
    setHistory((prevHistory) => [
      ...prevHistory.slice(0, currentHistoryIndex + 1),
      savedData,
    ]);
    setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
  };

  const handleColorChange = async (color) => {
    console.log("color", color);

    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) {
      toast.error("No text is selected");
      return; // No selection, nothing to change
    }

    const range = selection.getRangeAt(0); // Get the selected range

    // Extract the contents of the range (this will remove them from the document temporarily)
    const selectedContent = range.extractContents();

    // Create a new span element and set the color
    const span = document.createElement("span");
    span.style.color = color;

    // Append the selected content to the new span
    span.appendChild(selectedContent);

    // Insert the span back into the original range
    range.insertNode(span);

    // Clear the selection after applying the color
    selection.removeAllRanges();

    // Now, trigger the editor's onChange callback to update the history stack
    const savedData = await editorInstance.current.save();
    console.log("Saved Data after color change:", savedData); // Log to verify

    // Update the history for undo/redo functionality
    setHistory((prevHistory) => [
      ...prevHistory.slice(0, currentHistoryIndex + 1),
      savedData,
    ]);
    setCurrentHistoryIndex((prevIndex) => prevIndex + 1);

    // Update the editor data (description field or other)
    setFormData((prevData) => ({ ...prevData, description: savedData }));
  };

  const handleBackgroundColorChange = async (color) => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0); // Get the selected range

    // Extract the selected content from the range
    const selectedContent = range.extractContents();

    // Create a new span element with the background color
    const span = document.createElement("span");
    span.style.backgroundColor = color;

    // Append the extracted content to the span
    span.appendChild(selectedContent);

    // Insert the span back into the original range
    range.insertNode(span);

    // Clear the selection after applying the background color
    selection.removeAllRanges();
    // Optionally, save the editor content
    const savedData = await editorInstance.current.save();
    setHistory((prevHistory) => [
      ...prevHistory.slice(0, currentHistoryIndex + 1),
      savedData,
    ]);
    setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
  };

  const handleCategoryChange = async (e) => {
    const selectedCategory = e.target.value;
    await handleCategoryChangeAPI(selectedCategory);
  };
  const handleCategoryChangeAPI = async (selectedCategory) => {
    setPagetypeshow(false);
    setFormData((prevData) => ({
      ...prevData,
      guidelinecategory: selectedCategory,
    }));
    if (selectedCategory) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/guideCategoryWithId`,
          { id: selectedCategory }
        );
        setSubcategories(response.data.categorydetail.subCategories);
        setShowSubcategory(true);
        if (
          response.data.categorydetail.pagetype &&
          response.data.categorydetail.pagetype == "Yes"
        ) {
          setPagetypeshow(true);
        }
      } catch (error) {
        console.error("Failed to fetch subcategories:", error);
      }
    } else {
      setShowSubcategory(false);
    }
  };

  const handleFilterChange = (e, type) => {
    const {
      target: { value },
    } = e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      filters: {
        ...prevFormData.filters,
        [type]: typeof value === "string" ? value.split(",") : value,
      },
    }));
  };

  async function fetchPageTypes(mainid,subid) {
    try {
      let formDataToSend = {
        guidecategory:mainid,
        guidesubcategory:subid,
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/getPageTypeGuideline`,
        formDataToSend
      );
      if (response.data.status === 1) {
        setpageTypes(response.data.pageTypes);
      }
    } catch (error) {
      console.error("Error fetching page Types:", error);
    }
  }

  const handleSubcategoryChange = (e) => {
    const selectedSubcategory = e.target.value;
    fetchPageTypes(formData.guidelinecategory,selectedSubcategory);
    setFormData((prevData) => ({
      ...prevData,
      subcategory: selectedSubcategory,
    }));
    setShowEditor(!!selectedSubcategory);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    console.log("Selected file:", file); // Log the selected file

    if (file) {
      // First, update the formData with the selected file
      setFormData((prevState) => {
        console.log("Previous formData before image update:", prevState); // Log the previous formData
        return { ...prevState, image: file };
      });

      // Then use FileReader to generate a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        // Ensure you use prevState to avoid stale state issues
        setFormData((prevState) => {
          console.log("Previous formData before preview update:", prevState); // Log formData before updating with image preview
          return { ...prevState, imageprev: reader.result }; // Add the base64 image preview to formData
        });
        console.log("Updated formData with image preview:", reader.result); // Log the base64 preview
      };

      // Read the file as base64 data for the preview
      reader.readAsDataURL(file);
      console.log("FileReader is reading the file...");
    } else {
      console.log("No file selected");
    }
  };

  const handleDesktopImageChange = (e) => {
    const newFiles = Array.from(e.target.files); // Get newly selected files
    const newImageUrls = newFiles.map((file) => URL.createObjectURL(file)); // Create new image URLs for display

    setFormData((prevState) => ({
      ...prevState,
      // Append new files to existing files
      selectedDesktopFiles: [...prevState.selectedDesktopFiles, ...newFiles],
      // Append new URLs to existing URLs
      selectedDesktopFileUrls: [
        ...prevState.selectedDesktopFileUrls,
        ...newImageUrls,
      ],
    }));
  };

  const handleDeleteDesktopImage = (indexToDelete) => {
    const updatedFiles = formData.selectedDesktopFiles.filter(
      (_, index) => index !== indexToDelete
    );
    const updatedUrls = formData.selectedDesktopFileUrls.filter(
      (_, index) => index !== indexToDelete
    );

    setFormData((prevState) => ({
      ...prevState,
      selectedDesktopFiles: updatedFiles,
      selectedDesktopFileUrls: updatedUrls, // Update both files and URLs
    }));
  };

  const handleMobileImageChange = (e) => {
    const newFiles = Array.from(e.target.files); // Get newly selected files
    const newImageUrls = newFiles.map((file) => URL.createObjectURL(file)); // Create new image URLs for display

    setFormData((prevState) => ({
      ...prevState,
      // Append new files to existing files
      selectedMobileFiles: [...prevState.selectedMobileFiles, ...newFiles],
      // Append new URLs to existing URLs
      selectedMobileFileUrls: [
        ...prevState.selectedMobileFileUrls,
        ...newImageUrls,
      ],
    }));
  };

  const handleDeleteMobileImage = (indexToDelete) => {
    const updatedFiles = formData.selectedMobileFiles.filter(
      (_, index) => index !== indexToDelete
    );
    const updatedUrls = formData.selectedMobileFileUrls.filter(
      (_, index) => index !== indexToDelete
    );

    setFormData((prevState) => ({
      ...prevState,
      selectedMobileFiles: updatedFiles,
      selectedMobileFileUrls: updatedUrls, // Update both files and URLs
    }));
  };

  const handleDesktopVideoChange = (e) => {
    const newVideos = Array.from(e.target.files); // Get newly selected videos
    const newVideoUrls = newVideos.map((file) => URL.createObjectURL(file)); // Create new video URLs for display

    setFormData((prevState) => ({
      ...prevState,
      selectedDesktopVideoFiles: [
        ...prevState.selectedDesktopVideoFiles,
        ...newVideos,
      ],
      selectedDesktopVideoFileUrls: [
        ...prevState.selectedDesktopVideoFileUrls,
        ...newVideoUrls,
      ],
    }));
  };
  const handleMobileVideoChange = (e) => {
    const newVideos = Array.from(e.target.files);
    const newVideoUrls = newVideos.map((file) => URL.createObjectURL(file));

    setFormData((prevState) => ({
      ...prevState,
      selectedMobileVideoFiles: [
        ...prevState.selectedMobileVideoFiles,
        ...newVideos,
      ],
      selectedMobileVideoFileUrls: [
        ...prevState.selectedMobileVideoFileUrls,
        ...newVideoUrls,
      ],
    }));
  };
  const handleDeleteDesktopVideo = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      videosToDelete: [
        ...prevState.videosToDelete,
        prevState.selectedDesktopVideoFileUrls.filter((_, i) => i !== index),
      ],
      selectedDesktopVideoFiles: prevState.selectedDesktopVideoFiles.filter(
        (_, i) => i !== index
      ),
      selectedDesktopVideoFileUrls:
        prevState.selectedDesktopVideoFileUrls.filter((_, i) => i !== index),
    }));
  };

  const handleDeleteMobileVideo = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      videosToDelete: [
        ...prevState.videosToDelete,
        prevState.selectedMobileVideoFileUrls.filter((_, i) => i !== index),
      ],
      selectedMobileVideoFiles: prevState.selectedMobileVideoFiles.filter(
        (_, i) => i !== index
      ),
      selectedMobileVideoFileUrls: prevState.selectedMobileVideoFileUrls.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleDeleteDesktopImageShow = async (imageUrl, type) => {
    const confirmDelete = await swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    });

    if (confirmDelete) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/deleteImage`,
          { id, type, imageUrl }
        );

        if (response.data.status === 1) {
          // Update the state to remove the deleted image from the display
          if (type === "desktopimage") {
            setFormData((prevData) => ({
              ...prevData,
              selectedDesktopFileUrlsShow:
                prevData.selectedDesktopFileUrlsShow.filter(
                  (url) => url !== imageUrl
                ),
            }));
          } else if (type === "mobileimage") {
            setFormData((prevData) => ({
              ...prevData,
              selectedMobileFilesShow: prevData.selectedMobileFilesShow.filter(
                (url) => url !== imageUrl
              ),
            }));
          } else if (type === "desktopvideo") {
            setFormData((prevData) => ({
              ...prevData,
              selectedDesktopVideoFileUrlShow:
                prevData.selectedDesktopVideoFileUrlShow.filter(
                  (url) => url !== imageUrl
                ),
            }));
          } else if (type === "mobilevideo") {
            setFormData((prevData) => ({
              ...prevData,
              selectedMobileVideoFileUrlShow:
                prevData.selectedMobileVideoFileUrlShow.filter(
                  (url) => url !== imageUrl
                ),
            }));
          }

          // Optionally show a success message
          notifySuccess("File deleted successfully.");
        } else {
          // Handle error from API response
          notifyError("Failed to delete the image.");
        }
      } catch (error) {
        console.error("Error deleting image:", error);
        notifyError("An error occurred while deleting the image.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.hashNumber) {
      toast.error("# Number is required");
      return;
    }
    if (!formData.title) {
      toast.error("Title is required");
      return;
    }
    // if (!formData.subtitle) {
    //   toast.error("Sub Title is required");
    //   return;
    // }
    // if (!formData.pagetype) {
    //   toast.error("Please select page type");
    //   return;
    // }
    // if (!formData.forusers) {
    //     toast.error("Please select user type");
    //     return;
    // }
    // if (!formData.image) {
    //     toast.error("Image is required");
    //     return;
    //  }
    //else if (formData.image.size > 5 * 1024 * 1024) { // Validate if image size is greater than 5MB
    //     toast.error("Image size should not exceed 5MB");
    //     return;
    // }
    // if (!formData.titleDescription) {
    //   toast.error("Title description is required");
    //   return;
    // }
    if (!formData.guidelinecategory) {
      toast.error("Please select a category");
      return;
    }
    if (!formData.subcategory) {
      // Only check subcategory if it's visible
      toast.error("Please select a subcategory");
      return;
    }
    if (
      !formData.description ||
      Object.keys(formData.description).length === 0
    ) {
      toast.error("Please enter editor description");
      return;
    }

    console.log("formData", formData);
    const formDataToSend = new FormData();
    // Append existing form data

    formDataToSend.append("id", id);
    formDataToSend.append("dup", dup);
    formDataToSend.append("hashNumber", formData.hashNumber);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("subtitle", formData.subtitle);
    formDataToSend.append("forusers", formData.forusers);
    formDataToSend.append("pagetype", formData.pagetype);
    formDataToSend.append("tagkeywords", formData.tagkeywords);
    formDataToSend.append("titleDescription", formData.titleDescription);
    //formDataToSend.append('annotation', formData.annotation);
    //formDataToSend.append('uxinsights', formData.uxinsights);
    formDataToSend.append("guidelinecategory", formData.guidelinecategory);
    formDataToSend.append("subcategory", formData.subcategory);
    //formDataToSend.append('description', formData.description);
    formDataToSend.append("impacts", JSON.stringify(formData.impacts));
    formDataToSend.append("description", JSON.stringify(formData.description));
    formDataToSend.append("filters", JSON.stringify(formData.filters));
    formDataToSend.append("selectfeaturedesktop", selectfeaturedesktop);
    formDataToSend.append("selectfeaturemobile", selectfeaturemobile);
    formDataToSend.append(
      "annotationslist",
      JSON.stringify(formData.annotationslist)
    );
    formDataToSend.append(
      "selectedGoodOptions",
      JSON.stringify(formData.selectedGoodOptions)
    );
    formDataToSend.append(
      "selectedBadOptions",
      JSON.stringify(formData.selectedBadOptions)
    );
    formDataToSend.append(
      "uxinsightslist",
      JSON.stringify(formData.uxinsightslist)
    );
    if (formData.image) {
      formDataToSend.append("image", formData.image); // Append the image
    }
    if (formData.selectedDesktopFiles.length > 0) {
      for (let i = 0; i < formData.selectedDesktopFiles.length; i++) {
        formDataToSend.append(
          "desktopImages",
          formData.selectedDesktopFiles[i]
        );
      }
    }

    if (formData.selectedMobileFiles.length > 0) {
      for (let i = 0; i < formData.selectedMobileFiles.length; i++) {
        formDataToSend.append("mobileImages", formData.selectedMobileFiles[i]);
      }
    }

    if (formData.selectedDesktopVideoFiles.length > 0) {
      for (let i = 0; i < formData.selectedDesktopVideoFiles.length; i++) {
        formDataToSend.append(
          "desktopVideos",
          formData.selectedDesktopVideoFiles[i]
        );
      }
    }
    if (formData.selectedMobileVideoFiles.length > 0) {
      for (let i = 0; i < formData.selectedMobileVideoFiles.length; i++) {
        formDataToSend.append(
          "mobileVideos",
          formData.selectedMobileVideoFiles[i]
        );
      }
    }

    console.log("formDataToSend", formDataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/updateGuideline`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if (response.data.status == 1) {
        swal("Success!", "Data saved successfully", "success");
        navigate("/guidelines");
      } else {
        swal("Error!", response.data.message, "error");
      }
    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
  };

  const handleAddCategory = async (type,filter = {}) => {
      if(type === "addcategory")
      {
        navigate("/guidelinetype-add");
      }
      else if(type === "addsubcategory")
      {
        navigate(`/guidelinesubtype-add/${formData.guidelinecategory}`);
      }
      else if(type === "addpagetype")
      {
        if (!formData.subcategory) {
          toast.error("Please select a subcategory");
          return;
        }
        else
        {
          navigate(`/guidelinesubtypePage-edit/${formData.guidelinecategory}/${formData.subcategory}`);
        }
        
      }
      else if(type === "addfilter")
      {
        navigate("/filters");
        
      }
      else if(type === "addfilterlist")
      {
        navigate("/filterList", { state: { filterItem: filter } });
        
      }
    };

   

  return (
    <DashboardLayout>
      <section className="FilterSec">
      {loading ? (
        <div>Loading...</div> // Display loader while fetching data
      ) : (
        <div className="Add_Guide_Sec">
          <div className="FilterHead">
            <h2>Edit Guideline</h2>
          </div>

          <div className="guidLink mt-5" >
            <Link to={`/guidelines`}><ArrowBackIcon/> Back</Link>
          </div>

          <div className="GuidlineForm">
            <form onSubmit={handleSubmit}>
              <div className="Filters mb-5">
                <div className="FilterGridupdate">
                  <div className="guidFormControl">
                    <h6> <strong>Categories</strong> </h6>
                    <div className="inptbtn">
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Select</InputLabel>
                        <Select
                          name="category"
                          value={formData.guidelinecategory}
                          onChange={handleCategoryChange}
                          label="Category"
                          style={{ lineHeight: "3em" }}
                        >
                          <MenuItem value="">
                            <em>Select </em>
                          </MenuItem>
                          {guidelinecategories.map((category) => (
                            <MenuItem key={category._id} value={category._id}>
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        type="button"
                        class="myButton"
                        onClick={() =>
                          handleAddCategory('addcategory')
                        }
                        data-label="+ Add "
                      ></Button>
                    </div>
                  </div>

                  {showSubcategory && (
                    <div className="guidFormControl">
                      <h6><strong>Subcategory</strong> </h6>
                      <div className="inptbtn">
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>Select</InputLabel>
                          <Select
                            name="subcategory"
                            value={formData.subcategory}
                            onChange={handleSubcategoryChange}
                            label="Subcategory"
                            style={{ lineHeight: "3em" }}
                          >
                            <MenuItem value="">
                              <em>Select </em>
                            </MenuItem>
                            {subcategories.map((subcategory) => (
                              <MenuItem
                                key={subcategory._id}
                                value={subcategory._id}
                              >
                                {subcategory.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          type="button"
                          class="myButton"
                          onClick={() =>
                            handleAddCategory('addsubcategory')
                          }
                          data-label="+ Add "
                        ></Button>
                      </div>
                    </div>
                  )}

                  {pagetypeshow && (
                    <div className="guidFormControl">
                      <h6><strong>Screens</strong></h6>
                      <div className="inptbtn">

                        <FormControl fullWidth variant="outlined">
                          <InputLabel>Select</InputLabel>
                          <Select
                            name="pagetype"
                            value={formData.pagetype}
                            //onChange={handleSubcategoryChange}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                pagetype: e.target.value,
                              })
                            }
                            label="Page Type"
                            style={{ lineHeight: "3em" }}
                          >
                            <MenuItem value="">
                              <em>Select Screens</em>
                            </MenuItem>
                            {pageTypes.map((pageType) => (
                              <MenuItem key={pageType._id} value={pageType.name}>
                                {pageType.name}
                              </MenuItem>
                            ))}
                          
                            {/* <MenuItem key="Homepage" value="Homepage">
                              Homepage
                            </MenuItem>
                            <MenuItem
                              key=" Product_Detail"
                              value=" Product Detail"
                            >
                              Product Detail
                            </MenuItem>
                            <MenuItem key="Cart" value="Cart">
                              Cart
                            </MenuItem>
                            <MenuItem key="Accounts" value="Accounts">
                              Accounts
                            </MenuItem>
                            <MenuItem key="CheckOut" value="CheckOut">
                              CheckOut
                            </MenuItem>
                            <MenuItem key="Landing_Page" value="Landing Page">
                              Landing Page
                            </MenuItem>
                            <MenuItem key="Search" value="Search">
                              Search
                            </MenuItem>
                            <MenuItem key="Comparision" value="Comparision">
                              Comparision
                            </MenuItem> */}
                          </Select>
                        </FormControl>
                        <Button
                          type="button"
                          class="myButton"
                          onClick={() =>
                            handleAddCategory('addpagetype')
                          }
                          data-label="+ Add "
                        ></Button>

                      </div>
                     
                      
                    </div>
                  )}
                </div>
              </div>

              <div className="TopFormDetail">
                <div className="UpldImgDiv">
                  {formData.imageprev && (
                    <Avatar
                      src={formData.imageprev}
                      alt="Uploaded Icon"
                      style={{ width: 40, height: 40, marginRight: 8 }}
                    />
                  )}
                  <Button component="label">
                    Upload Image
                    <CloudUploadIcon />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </Button>
                </div>

                <div className="Fomdetail">
                  <div className="TopForm">
                    <div className="guidFormControl">
                      <h6>
                        {" "}
                        <strong>Guideline Number</strong>
                      </h6>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="# Number"
                        type="number"
                        value={formData.hashNumber} // Update the state value for title
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            hashNumber: e.target.value,
                          })
                        } // Update the title in formData
                      />
                    </div>
                    <div className="guidFormControl">
                      <h6>
                        {" "}
                        <strong>Guideline Title</strong>
                      </h6>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Title"
                        value={formData.title} // Update the state value for title
                        onChange={(e) =>
                          setFormData({ ...formData, title: e.target.value })
                        } // Update the title in formData
                        multiline
                        rows={2}
                      />
                    </div>

                    {/* <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Sub Title"
                                                value={formData.subtitle} // Update the state value for title
                                                onChange={(e) => setFormData({ ...formData, subtitle: e.target.value })} // Update the title in formData
                                            /> */}

                    {/* <FormControl fullWidth variant="outlined">
                                                <InputLabel>For Users</InputLabel>
                                                <Select
                                                    name="forusers"
                                                    value={formData.forusers}
                                                    //onChange={handleSubcategoryChange}
                                                    onChange={(e) => setFormData({ ...formData, forusers: e.target.value })}
                                                    label="For Users"
                                                    style={{ lineHeight: "3em" }}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select User Type</em>
                                                    </MenuItem>
                                                    <MenuItem key="Guest" value="Guest">
                                                    Guest
                                                    </MenuItem>
                                                    <MenuItem key="Subscribers" value="Subscribers">
                                                    Subscribers
                                                    </MenuItem>
                                                    <MenuItem key="Paid" value="Paid">
                                                    Paid
                                                    </MenuItem>
                                                    
                                                </Select>
                                            </FormControl> */}
                  </div>

                  <div className="bottomForm">
                    <h6>
                      <strong>Title Description</strong>
                    </h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Title Description"
                      value={formData.titleDescription} // Update the state value for titleDescription
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          titleDescription: e.target.value,
                        })
                      } // Update the title description in formData
                      multiline
                      rows={4} // Optional: adjust the number of rows for a larger input field
                    />
                  </div>
                </div>
              </div>

              <div className="BottomFormDetail mt-5">
                <div className="Filters">
                  <div className="flthd mb-3">
                    <h6><strong>Filters</strong></h6>
                    <Button type="button"  class="myButton"  onClick={() =>handleAddCategory('addfilter') } data-label="+ Add Filter" ></Button>
                  </div>
                  
                  <div className="FilterGrid">
                    {filterTypes.map((filter) => (
                      <div key={filter.name} className="inptbtn">
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>{filter.name}</InputLabel>
                          <Select
                            multiple
                            value={formData.filters?.[filter.name] || []}
                            onChange={(e) => handleFilterChange(e, filter.name)}
                            label={filter.name}
                            renderValue={(selected) => selected.join(", ")}
                            style={{ lineHeight: "3em" }}
                          >
                            {filterCategories[filter.name]?.map((category) => (
                              <MenuItem
                                key={category._id}
                                value={category.name}
                              >
                                <Checkbox
                                  // checked={
                                  //   formData.filters[filter.name]?.indexOf(
                                  //     category.name
                                  //   ) > -1
                                  // }
                                  checked={formData.filters?.[filter.name]?.includes(category.name)}
                                />
                                <ListItemText primary={category.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          type="button"
                          class="myButton"
                          onClick={() =>
                            handleAddCategory('addfilterlist',filter)
                          }
                          data-label="+ Add 
                          "
                        ></Button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="Filters">
                  <h6>
                    {" "}
                    <strong>Upload Images</strong>
                  </h6>
                  <div className="FilterGrid">
                    <div className="DeskImgDiv">
                      <div className="DesktopImages">
                        {/* Display existing desktop images with delete option */}
                        {formData.selectedDesktopFileUrlsShow?.map(
                          (imageUrl, index) => (
                            <div key={index}>
                              <div className="ImgShow" key={index}>
                                <Avatar
                                  src={`${process.env.REACT_APP_BASE_URL}${imageUrl}`}
                                  alt={`Uploaded Icon ${index + 1}`}
                                  style={{
                                    width: 120,
                                    height: 100,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                />

                                <div
                                  className="ImgIcon"
                                  onClick={() =>
                                    handleDeleteDesktopImageShow(
                                      imageUrl,
                                      "desktopimage"
                                    )
                                  }
                                >
                                  <ClearIcon />
                                </div>
                                <div className="ImgCheckbox">
                                  <Checkbox
                                    checked={selectfeaturedesktop === index} // Compare index with selectedDesktopImage
                                    onChange={() =>
                                      handleCheckboxChange(index, "desktop")
                                    } // Pass index as parameter
                                    value={index} // Set the value to index
                                    inputProps={{
                                      "aria-label": `Image ${index + 1}`,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        {formData.selectedDesktopFileUrls?.map(
                          (imageUrl, index) => (
                            <div key={index}>
                              <div className="ImgShow">
                                <Avatar
                                  src={imageUrl}
                                  alt={`Uploaded Icon ${index + 1}`}
                                  style={{
                                    width: 120,
                                    height: 100,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                />

                                <div
                                  className="ImgIcon"
                                  onClick={() =>
                                    handleDeleteDesktopImage(index)
                                  }
                                >
                                  <ClearIcon />
                                </div>
                                <div className="ImgCheckbox">
                                  <Checkbox
                                    checked={selectfeaturedesktop === index} // Compare index with selectedDesktopImage
                                    onChange={() =>
                                      handleCheckboxChange(index, "desktop")
                                    } // Pass index as parameter
                                    value={index} // Set the value to index
                                    inputProps={{
                                      "aria-label": `Image ${index + 1}`,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="UplodeDiv">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleDesktopImageChange}
                        />
                        <div className="upldInner">
                          <span>
                            <CloudUploadIcon />
                          </span>
                          <h6>Drag & Drop files here</h6>
                          <p>or click to browse</p>
                        </div>
                      </div>
                      
                    </div>

                    <div className="DeskImgDiv">
                      <div className="DesktopImages">
                        {formData.selectedMobileFilesShow?.map(
                          (imageUrl, index) => (
                            <div key={index} className="ImgmobShow">
                              <Avatar
                                key={index}
                                src={`${process.env.REACT_APP_BASE_URL}${imageUrl}`}
                                alt={`Uploaded Icon ${index + 1}`}
                                style={{
                                  width: 80,
                                  height: 120,
                                  marginRight: 0,
                                  borderRadius: 2,
                                }}
                              />
                              <div
                                className="ImgIcon"
                                onClick={() =>
                                  handleDeleteDesktopImageShow(
                                    imageUrl,
                                    "mobileimage"
                                  )
                                }
                              >
                                <ClearIcon />
                              </div>
                              <div className="ImgCheckbox">
                                <Checkbox
                                  checked={selectfeaturemobile === index} // Compare index with selectedDesktopImage
                                  onChange={() =>
                                    handleCheckboxChange(index, "mobile")
                                  } // Pass index as parameter
                                  value={index} // Set the value to index
                                  inputProps={{
                                    "aria-label": `Image ${index + 1}`,
                                  }}
                                />
                              </div>
                            </div>
                          )
                        )}

                        {formData.selectedMobileFileUrls?.map(
                          (imageUrl, index) => (
                            <div className="ImgmobShow">
                              <Avatar
                                key={index}
                                src={imageUrl}
                                alt={`Uploaded Icon ${index + 1}`}
                                style={{
                                  width: 80,
                                  height: 120,
                                  marginRight: 0,
                                  borderRadius: 2,
                                }}
                              />
                              <div
                                className="ImgIcon"
                                onClick={() => handleDeleteMobileImage(index)}
                              >
                                <ClearIcon />
                              </div>
                              <div className="ImgCheckbox">
                                <Checkbox
                                  checked={selectfeaturemobile === index} // Compare index with selectedDesktopImage
                                  onChange={() =>
                                    handleCheckboxChange(index, "mobile")
                                  } // Pass index as parameter
                                  value={index} // Set the value to index
                                  inputProps={{
                                    "aria-label": `Image ${index + 1}`,
                                  }}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="UplodeDiv">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleMobileImageChange}
                        />
                        <div className="upldInner">
                          <span>
                            <CloudUploadIcon />
                          </span>
                          <h6>Drag & Drop files here</h6>
                          <p>or click to browse</p>
                        </div>
                      </div>
                      {/* <div className="uplbtn">
                          <Button component="label">
                            Upload Mobile Images
                            <CloudUploadIcon />
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              multiple
                              onChange={handleMobileImageChange}
                            />
                          </Button>
                        </div> */}
                    </div>
                  </div>
                </div>

                <div className="Filters">
                  <h6>
                    <strong>Upload Videos</strong>
                  </h6>
                  <div className="FilterGrid">
                    <div className="DeskImgDiv">
                      <div className="DesktopImages">
                        {/* Display existing desktop videos with delete option */}

                        {formData.selectedDesktopVideoFileUrlShow?.map(
                          (videoUrl, index) => (
                            <div className="ImgShow" key={index}>
                              <video width="120" height="100" controls>
                                <source
                                  src={`${process.env.REACT_APP_BASE_URL}${videoUrl}`}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>

                              <div
                                className="ImgIcon"
                                onClick={() =>
                                  handleDeleteDesktopImageShow(
                                    videoUrl,
                                    "desktopvideo"
                                  )
                                }
                              >
                                <ClearIcon />
                              </div>
                            </div>
                          )
                        )}
                        {formData.selectedDesktopVideoFileUrls?.map(
                          (videoUrl, index) => (
                            <div className="ImgShow" key={index}>
                              <video width="120" height="100" controls>
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              <div
                                className="ImgIcon"
                                onClick={() => handleDeleteDesktopVideo(index)}
                              >
                                <ClearIcon />
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="UplodeDiv">
                        <input
                          type="file"
                          accept="video/*"
                          multiple
                          onChange={handleDesktopVideoChange}
                        />
                        <div className="upldInner">
                          <span>
                            <CloudUploadIcon />
                          </span>
                          <h6>Drag & Drop files here</h6>
                          <p>or click to browse</p>
                        </div>
                      </div>

                      {/* <div className="uplbtn">
                          <Button component="label">
                            Upload Desktop Videos
                            <CloudUploadIcon />
                            <input
                              type="file"
                              hidden
                              accept="video/*"
                              multiple
                              onChange={handleDesktopVideoChange}
                            />
                          </Button>
                        </div> */}
                    </div>

                    <div className="DeskImgDiv">
                      <div className="DesktopImages">
                        {/* Display existing mobile videos with delete option */}

                        {formData.selectedMobileVideoFileUrlShow?.map(
                          (videoUrl, index) => (
                            <div className="ImgmobShow" key={index}>
                              <video width="80" height="120" controls>
                                <source
                                  src={`${process.env.REACT_APP_BASE_URL}${videoUrl}`}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                              <div
                                className="ImgIcon"
                                onClick={() =>
                                  handleDeleteDesktopImageShow(
                                    videoUrl,
                                    "mobilevideo"
                                  )
                                }
                              >
                                <ClearIcon />
                              </div>
                            </div>
                          )
                        )}

                        {formData.selectedMobileVideoFileUrls?.map(
                          (videoUrl, index) => (
                            <div className="ImgmobShow" key={index}>
                              <video width="80" height="120" controls>
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              <div
                                className="ImgIcon"
                                onClick={() => handleDeleteMobileVideo(index)}
                              >
                                <ClearIcon />
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div className="UplodeDiv">
                        <input
                          type="file"
                          accept="video/*"
                          multiple
                          onChange={handleMobileVideoChange}
                        />
                        <div className="upldInner">
                          <span>
                            <CloudUploadIcon />
                          </span>
                          <h6>Drag & Drop files here</h6>
                          <p>or click to browse</p>
                        </div>
                      </div>

                      {/* <div className="uplbtn">
                          <Button component="label">
                            Upload Mobile Videos
                            <CloudUploadIcon />
                            <input
                              type="file"
                              hidden
                              accept="video/*"
                              multiple
                              onChange={handleMobileVideoChange}
                            />
                          </Button>
                        </div> */}
                    </div>
                  </div>
                </div>

                {showEditor && (
                  <div className="guidFormControl">
                   
                    <h6>
                      {" "}
                      <strong>Description</strong>
                    </h6>
                    <div className="EditFontDiv">
                      <div className="FontFixed">
                        {/* Font Size Selection */}
                        {/* <div className="editfont">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change Font Size</Tooltip>}
                          >
                            <span>
                              <RiFontSize />
                            </span>
                          </OverlayTrigger>
                          <select
                            onChange={(e) =>
                              handleFontSizeChange(e.target.value)
                            }
                            value={selectfontsize}
                          >
                            <option key={0} value="">
                              Select
                            </option>
                            
                            {[...Array(13).keys()].map((i) => (
                              <option key={i + 12} value={i + 12}>
                                {i + 12} px
                              </option>
                            ))}
                          </select>
                        </div> */}

                        {/* Font Style Selection */}
                        {/* <div className="editfont">
                              <OverlayTrigger  placement="top" overlay={<Tooltip>Change Font Weight</Tooltip>} >
                                <span><GrBold /></span>
                              </OverlayTrigger>
                              
                              <select onChange={(e) => handleFontStyleChange(e.target.value)}>
                                <option value="normal">Normal</option>
                                <option value="bold">Bold</option>
                                <option value="italic">Italic</option>
                              </select>
                            </div> */}

                        {/* Font Family Selection */}
                        {/* <div className="editfont">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change Font Family</Tooltip>}
                          >
                            <span>
                              <RxFontFamily />
                            </span>
                          </OverlayTrigger>

                          <select
                            onChange={(e) =>
                              handleFontFamilyChange(e.target.value)
                            }
                            value={selectfontfamily}
                          >
                            <option value="Arial">Arial</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Tahoma">Tahoma</option>
                            <option value="Times New Roman">
                              Times New Roman
                            </option>
                            <option value="Georgia">Georgia</option>
                            <option value="Courier New">Courier New</option>
                            <option value="Comic Sans MS">Comic Sans MS</option>
                            <option value="Impact">Impact</option>
                            <option value="Roboto">Roboto</option>
                            <option value="Open Sans">Open Sans</option>
                            <option value="Lora">Lora</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Arial Black">Arial Black</option>
                            <option value="Tahoma">Tahoma</option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="Sans-serif">Sans-serif</option>
                            <option value="Serif">Serif</option>
                          </select>
                        </div> */}

                        {/* Text Color Selection */}
                        <div className="editfont">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change Font Color</Tooltip>}
                          >
                            <span>
                              <MdFormatColorText />
                            </span>
                          </OverlayTrigger>
                          <input
                            type="color"
                            onChange={(e) => handleColorChange(e.target.value)}
                          />
                        </div>

                        {/* Background Color Selection */}
                        <div className="editfont">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change Background Color</Tooltip>}
                          >
                            <span>
                              <IoMdColorFill />
                            </span>
                          </OverlayTrigger>

                          <input
                            type="color"
                            onChange={(e) =>
                              handleBackgroundColorChange(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="EditiorDiv" id="editorjs"></div>
                      {/* Internal CSS to ensure bullets/numbers show correctly */}
                      <style>
                        {`#editorjs ul.cdx-list--unordered li {list-style-type: disc !important; /* Force show bullets */ }
                                #editorjs ul.cdx-list--ordered li { list-style-type: decimal !important; /* Force show numbers for ordered lists */ } `}
                      </style>
                    </div>
                  </div>
                )}

<div className="guidFormControl">
                  <h6> <strong>Add Tags</strong></h6>
                    <TextField
                    fullWidth
                    variant="outlined"
                    label="Add Tags"
                    value={formData.tagkeywords} // Update the state value for title
                    onChange={(e) =>
                      setFormData({ ...formData, tagkeywords: e.target.value })
                    } // Update the title in formData
                    multiline
                    rows={2}
                    />
                </div>

                <div className="Filters">
                  <h6>
                    <strong>Annotations</strong>
                  </h6>
                  <div className="FilterGrid">
                    <div>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Annotations</InputLabel>
                        <Select
                          label="Annotations"
                          multiple
                          value={formData.annotationslist}
                          onChange={handleChangeAnnotation}
                          renderValue={(selected) => selected.join(", ")}
                          style={{ lineHeight: "3em" }}
                        >
                          {annotations.map((annotation) => (
                            <MenuItem key={annotation} value={annotation}>
                              <Checkbox
                                checked={
                                  formData.annotationslist.indexOf(annotation) >
                                  -1
                                }
                              />
                              <ListItemText primary={annotation} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* Show options based on the selected annotation */}
                  {formData.annotationslist.map((selectedAnnotation) => (
                    <div className="FilterGrid" key={selectedAnnotation}>
                      {/* Show Good options for the selected annotation */}
                      <div>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>{selectedAnnotation} Good</InputLabel>
                          <Select
                            label={`${selectedAnnotation} Good`}
                            multiple
                            value={
                              formData.selectedGoodOptions[
                                selectedAnnotation
                              ] || []
                            }
                            onChange={(e) =>
                              handleGoodOptionChange(selectedAnnotation, e)
                            }
                            renderValue={(selected) => {
                              // Display names in the UI, but save ids
                              return selected
                                .map(
                                  (id) =>
                                    annotationOptions[
                                      selectedAnnotation
                                    ]?.Good.find((option) => option.id === id)
                                      ?.name
                                )
                                .join(", ");
                            }}
                            style={{ lineHeight: "3em" }}
                          >
                            {annotationOptions[selectedAnnotation]?.Good.map(
                              (option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  <Checkbox
                                    checked={
                                      formData.selectedGoodOptions[
                                        selectedAnnotation
                                      ]?.indexOf(option.id) > -1
                                    }
                                  />
                                  <ListItemText primary={option.name} />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>

                      {/* Show Bad options for the selected annotation */}
                      <div>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>{selectedAnnotation} Bad</InputLabel>
                          <Select
                            label={`${selectedAnnotation} Bad`}
                            multiple
                            value={
                              formData.selectedBadOptions[selectedAnnotation] ||
                              []
                            }
                            onChange={(e) =>
                              handleBadOptionChange(selectedAnnotation, e)
                            }
                            renderValue={(selected) => {
                              // Display names in the UI, but save ids
                              return selected
                                .map(
                                  (id) =>
                                    annotationOptions[
                                      selectedAnnotation
                                    ]?.Bad.find((option) => option.id === id)
                                      ?.name
                                )
                                .join(", ");
                            }}
                            style={{ lineHeight: "3em" }}
                          >
                            {annotationOptions[selectedAnnotation]?.Bad.map(
                              (option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  <Checkbox
                                    checked={
                                      formData.selectedBadOptions[
                                        selectedAnnotation
                                      ]?.indexOf(option.id) > -1
                                    }
                                  />
                                  <ListItemText primary={option.name} />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="Filters">
                  <h6>
                    <strong>References</strong>
                  </h6>
                  <div className="FilterGrid">
                    <div>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>UX Insights</InputLabel>
                        <Select
                          label="UX Insights"
                          multiple
                          value={formData.uxinsightslist}
                          onChange={handleChangeUXInsights}
                          renderValue={(selected) => selected.join(", ")}
                          style={{ lineHeight: "3em", whiteSpace:"unset" }}
                        >
                          {uxinsights?.length > 0 &&
                            uxinsights.map((uxinsight) => (
                              <MenuItem key={uxinsight.hashNumber} value={uxinsight.hashNumber}>
                                <Checkbox
                                  checked={formData.uxinsightslist.includes(uxinsight.hashNumber)}
                                />
                                <ListItemText primary={`#${uxinsight.hashNumber} ${uxinsight.title}`} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>

              <div
                mt={4}
                mb={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "50px 0px",
                }}
              >
                <Button
                  type="submit"
                  class="myButton"
                  data-label="Save"
                ></Button>
                {/* <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ color: "#fff" }}
                  >
                    Save
                  </Button> */}
              </div>
            </form>
          </div>
        </div>
        )}
      </section>
    </DashboardLayout>
  );
}
