import {
  Link, Router,
} from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/guidelines/data/authorsTableData";

function Tables() {
  const { columns, rows } = authorsTableData();

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <div className="container">

        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card className="GuidLinesSec">
                <div className="FilterHead">
                    <h2>Guideline Categories List</h2>
                </div>
                <MDBox
                  py={3}
                  px={2}
                  className="GDDiv"
                >
                  <MDTypography variant="h6" color="white" className="contentList">
                    Guideline List 
                  </MDTypography>
                  <div className="guidLink">
                    <Link to="/guideline-add"><Icon fontSize="small">add</Icon>Add Guideline</Link>
                  </div>
                  
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    //isSorted={false}
                    canSearch={true}
                    entriesPerPage={false}
                    pagination={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>

      </div>

    </DashboardLayout>
  );
}

export default Tables;
