import { useState, useEffect } from "react";
// react-router components
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Icon from "@mui/material/Icon";
// Balearen Whisper React components
//import MDBox from "components/MDBox";
//import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
//import MDBadge from "components/MDBadge";

// Images
//import team2 from "assets/images/team-2.jpg";
//import team3 from "assets/images/team-3.jpg";
//import team4 from "assets/images/team-4.jpg";

export default function Data() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({});
  const LoadData = async() => {
    try {
      await axios.post("/api/admin/interests").then((res) => {
        var response = res.data;
        response = response.interestsList;
        var responseData = [];
        for (var i = 0; i < response.length; i++) {
          //console.log(assetImage);
          var inc=i+1;
          responseData.push(
            {
              id: inc,
              userName: response[i].userId.firstName + " " + response[i].userId.lastName,
              userType: response[i].userId.userType,
              //assetUserEmail: response[i].userId.email,
              //category: response[i].assetCategory+"("+response[i].subCategory+")",
              category: response[i].assetCategory,
              //location: response[i].location+"("+response[i].subLocation+")",
              location: response[i].location,
              saleType: response[i].saleType.join(', '),
              interests: renderInterests(response[i].interests),
              furtherInfo: <Link to={"/view-interest/"+response[i]._id} state={{id:response[i]._id}}>View</Link>,
              action: <Link to={"/edit-interest/"+response[i]._id} state={{id:response[i]._id}}>Edit</Link>,
              status: Dropdown(navigate, response[i]._id, response[i].isDelete, status, setStatus, LoadData),
            }
          );
        }
        setData(responseData);
      });
    } catch (error) {
        console.log(error);
    }
  };
  useEffect(() => {
    LoadData();
  },[]); 

  return {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "userName", accessor: "userName" },
      { Header: "userType", accessor: "userType" },
      //{ Header: "assetUserEmail", accessor: "assetUserEmail" },
      { Header: "category", accessor: "category" },
      { Header: "location", accessor: "location" },
      { Header: "saleType", accessor: "saleType" },
      { Header: "interests", accessor: "interests" },
      { Header: "furtherInfo", accessor: "furtherInfo" },
      { Header: "action", accessor: "action" },
      { Header: "status", accessor: "status" },
    ],

    rows: data ? data : "",
  };
}

function Dropdown(navigate, interestId, assetStatus, status, setStatus, LoadData) {
  //const [status, setStatus] = useState(assetStatus);
  //setStatus({...status, assetId: assetStatus});
  //console.log(status);
  const changeStatus = async (e) => {
    var isDelete=e.target.value;
    var data={
      interestId,
      isDelete,
    };
    try {
      axios.post("/api/admin/interestStatus", data).then((res) => {
        var response = res.data;
        if(response.status===1){
        //setStatus(response.isDelete);
        swal("success", "Status has been changed successfully!", "success");
        LoadData();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteAsset = async (e) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this asset!",
      icon: "warning",
      buttons: [
        'No',
        'Yes'
      ],
      dangerMode: true,
    }).then(function(isConfirm) {
      if (isConfirm) {
        var id=e.target.attributes.value.nodeValue;
        swal("success", "Asset deleted successfully!", "success");
		    navigate("/assets");
      }
    });
  };
  return (
    <span>
      <select className="form-control selectbutton" value={assetStatus} onChange={changeStatus}>
        <option value="0">Enable</option>
        <option value="1">Disable</option>
      </select>
	    {/* <a href="javascript:void(0);" onClick={deleteAsset}><Icon fontSize="small" value={assetId}>delete</Icon></a> */}
      {/* <Link to={"/assets/"+assetId}>View</Link> */}
      {/* <Link to={"/view-asset"} state={{id:assetId}}>View</Link> */}
    </span>
  );
}
const renderInterests = (interests) => {
  if (interests.length <= 2) {
    return interests.join(', ');
  } else {
    const firstTwoInterests = interests.slice(0, 2).join(', ');
    return `${firstTwoInterests} ...`;
  }
};

