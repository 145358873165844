import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Dashboard() {
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // Retrieve user data from localStorage
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const authUserData = JSON.parse(authUser);
      setUserRole(authUserData.role || ""); // Set the user role
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {/* Conditionally display welcome message based on user role */}
        {userRole && (
          <h1>Welcome {userRole === "Admin" ? "Admin" : userRole}!</h1>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;