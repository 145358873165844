import Paragraph from "@editorjs/paragraph";

class CustomParagraphTool extends Paragraph {
  constructor({ data, api, config }) {
    super({ data, api, config });
    this.api = api;
    this.config = config;

    this.enableBgColor = data?.backgroundColor ? true : false;
    this.backgroundColor = data?.backgroundColor || "";
    this.gap = data?.gap || "14px";
    this.padding = data?.padding || (this.enableBgColor ? "32px 32px 32px 32px" : "8px 0px 8px 0px");
    this.margin = data?.margin || (this.enableBgColor ? "10px 10px" : "0px");
    this.borderRadius = data?.borderRadius || "8px";
    this.listStyle = data?.style || "unordered";

    this.data = {
      ...data,
      backgroundColor: this.backgroundColor,
      gap: this.gap,
      padding: this.padding,
      margin: this.margin,
      borderRadius: this.borderRadius,
    };
  }

  render() {
    this.wrapper = super.render();
    this.applyCustomStyles();
    return this.wrapper;
  }

  applyCustomStyles() {
    if (this.wrapper) {
      this.wrapper.style.backgroundColor = this.backgroundColor;
      this.wrapper.style.padding = this.padding;
      this.wrapper.style.margin = this.margin;
      this.wrapper.style.gap = this.gap;
      this.wrapper.style.borderRadius = this.borderRadius;
    }
  }

  renderSettings() {
    const colorMap = {
      yellow: "#F0EEE4",
      red: "#F0E8E4",
      green: "#E5F3EB",
      blue: "#E5F2F3",
    };

    const wrapper = document.createElement("div");
    wrapper.className = "ce-settings";

    const settingsContainer = document.createElement("div");
    settingsContainer.style.display = "grid";
    settingsContainer.style.gridTemplateColumns = "1fr";
    settingsContainer.style.gap = "10px";
    //settingsContainer.style.marginBottom = "5px";

    let bgColorInput;

    settingsContainer.appendChild(
      this.createCheckbox("Enable BG Color", this.enableBgColor, (checked) => {
        this.enableBgColor = checked;
        if (checked) {
          this.backgroundColor = colorMap.yellow;
          this.padding = "32px 32px 32px 32px";
          this.margin = "10px 10px";
        } else {
          this.backgroundColor = "";
          this.padding = "8px 0px 8px 0px";
          this.margin = "0px";
        }
        this.updateDataAndStyle();

        if (bgColorInput) bgColorInput.querySelector("input").value = this.backgroundColor;
        if (colorRadioContainer) {
          colorRadioContainer.style.display = checked ? "block" : "none";
        }
      })
    );

    const colorRadioContainer = document.createElement("div");
    colorRadioContainer.style.display = this.enableBgColor ? "block" : "none";

    const createRadio = (labelText, value, isChecked) => {
      const radioWrapper = document.createElement("label");
      radioWrapper.style.display = "flex";
      radioWrapper.style.alignItems = "center";
      radioWrapper.style.fontSize = "12px";
      radioWrapper.style.marginBottom = "5px";
      radioWrapper.style.gap = "5px";

      const radio = document.createElement("input");
      radio.type = "radio";
      radio.name = "bgColorChoice";
      radio.value = value;
      radio.checked = isChecked;

      radio.addEventListener("change", () => {
        this.backgroundColor = value;
        this.updateDataAndStyle();
        if (bgColorInput) bgColorInput.querySelector("input").value = value;
      });

      const colorLabel = document.createElement("span");
      colorLabel.textContent = labelText;

      radioWrapper.appendChild(radio);
      radioWrapper.appendChild(colorLabel);

      return radioWrapper;
    };

    colorRadioContainer.appendChild(createRadio("Yellow", colorMap.yellow, this.backgroundColor === colorMap.yellow || true));
    colorRadioContainer.appendChild(createRadio("Red", colorMap.red, this.backgroundColor === colorMap.red));
    colorRadioContainer.appendChild(createRadio("Green", colorMap.green, this.backgroundColor === colorMap.green));
    colorRadioContainer.appendChild(createRadio("Blue", colorMap.blue, this.backgroundColor === colorMap.blue));

    settingsContainer.appendChild(colorRadioContainer);

    bgColorInput = this.createInput("BG-Color:", "text", this.backgroundColor, (value) => {
      this.backgroundColor = value;
      const radios = colorRadioContainer.querySelectorAll("input[type=radio]");
      radios.forEach((r) => (r.checked = false));
      this.updateDataAndStyle();
    });

    settingsContainer.appendChild(bgColorInput);

    // settingsContainer.appendChild(
    //   this.createDropdown("List Type:", ["unordered", "ordered"], this.listStyle, (value) => {
    //     this.listStyle = value;
    //     this.updateListType();
    //   })
    // );

    wrapper.appendChild(settingsContainer);
    return wrapper;
  }

  createCheckbox(labelText, defaultChecked, onChange) {
    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.alignItems = "center";
    container.style.marginBottom = "5px";

    const label = document.createElement("label");
    label.textContent = labelText;
    label.style.fontSize = "12px";
    label.style.marginRight = "5px";
    label.style.width = "110px";

    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.checked = defaultChecked;

    checkbox.addEventListener("change", (event) => {
      onChange(event.target.checked);
    });

    container.appendChild(label);
    container.appendChild(checkbox);
    return container;
  }

  createInput(labelText, type, defaultValue, onChange) {
    const wrapper = document.createElement("div");
    wrapper.style.display = "flex";
    wrapper.style.alignItems = "center";
    wrapper.style.gap = "5px";

    const label = document.createElement("label");
    label.textContent = labelText;
    label.style.fontSize = "10px";

    const input = document.createElement("input");
    input.type = type;
    input.value = defaultValue;
    input.style.fontSize = "10px";
    input.style.width = type === "color" ? "40px" : "50px";
    input.style.cursor = type === "color" ? "pointer" : "text";

    input.addEventListener("input", (event) => onChange(event.target.value));

    wrapper.appendChild(label);
    wrapper.appendChild(input);
    return wrapper;
  }

  createDropdown(labelText, options, defaultValue, onChange) {
    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.alignItems = "center";
    container.style.marginBottom = "5px";

    const label = document.createElement("label");
    label.textContent = labelText;
    label.style.fontSize = "12px";
    label.style.marginRight = "5px";
    label.style.width = "110px";

    const select = document.createElement("select");
    select.style.width = "90px";
    select.style.fontSize = "12px";

    options.forEach((option) => {
      const opt = document.createElement("option");
      opt.value = option;
      opt.textContent = option.charAt(0).toUpperCase() + option.slice(1);
      if (option === defaultValue) opt.selected = true;
      select.appendChild(opt);
    });

    select.addEventListener("change", (event) => {
      onChange(event.target.value);
    });

    container.appendChild(label);
    container.appendChild(select);
    return container;
  }

  updateDataAndStyle() {
    this.data.backgroundColor = this.backgroundColor;
    this.data.padding = this.padding;
    this.data.margin = this.margin;
    this.data.gap = this.gap;
    this.data.borderRadius = this.borderRadius;

    this.applyCustomStyles();

    setTimeout(() => {
      this.api.saver.save().then((savedData) => {
        console.log("Auto-saved with new style data:", savedData);
      });
    }, 0);
  }

  updateListType() {
    const blockIndex = this.api.blocks.getCurrentBlockIndex();
    const block = this.api.blocks.getBlockByIndex(blockIndex);
    console.log("List style updated:", this.listStyle, block);
  }

  save(blockContent) {
    const originalData = super.save(blockContent);
    return {
      ...originalData,
      backgroundColor: this.backgroundColor,
      gap: this.gap,
      padding: this.padding,
      margin: this.margin,
      borderRadius: this.borderRadius,
      style: this.listStyle,
    };
  }
}

export default CustomParagraphTool;
