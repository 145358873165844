import List from "@editorjs/list";

class CustomListTool extends List {
  constructor({ data, api, config }) {
    super({ data, api, config });
    this.api = api;
    
    this.bgcolor = data?.backgroundColor || ""; //#F8F7F4
    this.gap = data?.gap || "0px";
    this.padding = data?.padding || "0px";
    this.margin = data?.margin || "0px 0px 0px 0px";
    this.borderRadius = data?.borderRadius || "0px";

    this.data = {
      ...data,
      backgroundColor: this.bgcolor,
      gap: this.gap,
      padding: this.padding,
      margin: this.margin,
      borderRadius: this.borderRadius,
    };
  }

  render() {
    const wrapper = super.render();
    
    // if (this.data.backgroundColor) {
    //   wrapper.style.backgroundColor = this.data.backgroundColor;
    // }
    wrapper.style.backgroundColor = this.data.backgroundColor;
    wrapper.style.padding = this.data.padding;
    wrapper.style.margin = this.data.margin;
    wrapper.style.gap = this.data.gap;
    wrapper.style.borderRadius = this.data.borderRadius;
   

    const listEl = wrapper.querySelector("ul, ol");
    if (listEl) {
      listEl.style.backgroundColor = this.data.backgroundColor;
      listEl.style.padding = this.data.padding;
      listEl.style.margin = this.data.margin;
      listEl.style.gap = this.data.gap;
      listEl.style.borderRadius = this.data.borderRadius;
    }

    return wrapper;
  }

  renderSettings() {
    const wrapper = document.createElement("div");
    wrapper.className = "ce-settings";

    // Settings Container with Two Rows
    const settingsContainer = document.createElement("div");
    settingsContainer.style.display = "grid";
    settingsContainer.style.gridTemplateColumns = "1fr 1fr"; // Two columns
    settingsContainer.style.gap = "10px"; // Adjust spacing
    settingsContainer.style.marginBottom = "5px";

    const createInput = (labelText, type, defaultValue, onChange) => {
      const wrapper = document.createElement("div");
      wrapper.style.display = "flex";
      wrapper.style.alignItems = "center";
      wrapper.style.gap = "5px";

      const label = document.createElement("label");
      label.textContent = labelText;
      label.style.fontSize = "10px";

      const input = document.createElement("input");
      input.type = type;
      input.value = defaultValue;
      input.style.fontSize = "10px";
      if(labelText !== "BG-Color:")
      {
        input.style.padding = "0px 5px";
      }
      if(labelText == "Margin:")
      {
        wrapper.style.width = "75px";
        input.style.width = "95px";
      }
      
      input.style.width = type === "color" ? "40px" : "50px";
      input.style.cursor = type === "color" ? "pointer" : "text";

      input.addEventListener("input", (event) => onChange(event.target.value));

      wrapper.appendChild(label);
      wrapper.appendChild(input);
      return wrapper;
    };

    // First Row: Color & Padding
    settingsContainer.appendChild(createInput("BG-Color:", "text", this.bgcolor, (value) => {
      this.bgcolor = value;
      this.data.backgroundColor = value;
      this.updateStyles();
    }));

    settingsContainer.appendChild(createInput("Padding:", "text", this.padding, (value) => {
      this.padding = value;
      this.data.padding = value;
      this.updateStyles();
    }));

    // Second Row: Gap & Border Radius
    settingsContainer.appendChild(createInput("Gap:", "text", this.gap, (value) => {
      this.gap = value;
      this.data.gap = value;
      this.updateStyles();
    }));

    settingsContainer.appendChild(createInput("Radius:", "text", this.borderRadius, (value) => {
      this.borderRadius = value;
      this.data.borderRadius = value;
      this.updateStyles();
    }));

     // Third Row: Margin
     settingsContainer.appendChild(createInput("Margin:", "text", this.margin, (value) => {
      this.margin = value;
      this.data.margin = value;
      this.updateStyles();
    }));

    wrapper.appendChild(settingsContainer);

    return wrapper;
  }

  updateStyles() {
    const blockId = this.api.blocks.getCurrentBlockIndex();
    const block = this.api.blocks.getBlockByIndex(blockId);
    if (block?.holder) {
      const listEl = block.holder.querySelector("ul, ol");
      if (listEl) {
        listEl.style.backgroundColor = this.bgcolor;
        listEl.style.padding = this.padding;
        listEl.style.margin = this.margin; 
        listEl.style.gap = this.gap;
        listEl.style.borderRadius = this.borderRadius;
      }
    }
    this.api.saver.save().then((savedData) => {
      console.log("Updated savedData:", savedData);
    });
  }

  save(blockContent) {
    const originalData = super.save(blockContent);
    return {
      ...originalData,
      backgroundColor: this.bgcolor,
      padding: this.padding,
      margin: this.margin,
      gap: this.gap,
      borderRadius: this.borderRadius,
    };
  }
}

export default CustomListTool;
