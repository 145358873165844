import List from "@editorjs/list";

class MyListTool extends List {
  constructor({ data, api, config }) {
    // ✅ Call super FIRST before using `this`
    super({ data, api, config });

    this.api = api;
    this.config = config; // ✅ Store config if needed

    this.enableBgColor = data?.backgroundColor ? true : false;
    this.backgroundColor = data?.backgroundColor || "";
    this.gap = data?.gap || "14px";
    this.padding = data?.padding || (this.enableBgColor ? "32px 16px 32px 16px" : "8px 0px 8px 0px");
    this.margin = data?.margin || "0px 0px 0px 0px";
    this.borderRadius = data?.borderRadius || "8px";
    this.listStyle = data?.style || "unordered";

    this.data = {
      ...data,
      backgroundColor: this.backgroundColor,
      gap: this.gap,
      padding: this.padding,
      margin: this.margin,
      borderRadius: this.borderRadius,
    };
  }

  // ✅ Add Toolbox Icon for Editor.js
  static get toolbox() {
    return {
      title: "List",
      icon: "📋",
    };
  }

  render() {
    const wrapper = super.render();

    if (wrapper) {
      wrapper.style.backgroundColor = this.data.backgroundColor;
      wrapper.style.padding = this.data.padding;
      wrapper.style.margin = this.data.margin;
      wrapper.style.gap = this.data.gap;
      wrapper.style.borderRadius = this.data.borderRadius;

      const listEl = wrapper.querySelector("ul, ol");
      if (listEl) {
        listEl.style.backgroundColor = this.data.backgroundColor;
        listEl.style.padding = this.data.padding;
        listEl.style.margin = this.data.margin;
        listEl.style.gap = this.data.gap;
        listEl.style.borderRadius = this.data.borderRadius;
      }
    }

    return wrapper;
  }

  renderSettings() {
    const colorMap = {
      yellow: "#F0EEE4",
      red: "#F0E8E4",
      green: "#E5F3EB",
      blue: "#E5F2F3",
      
    };

    const wrapper = document.createElement("div");
    wrapper.className = "ce-settings";

    const settingsContainer = document.createElement("div");
    settingsContainer.style.display = "grid";
    settingsContainer.style.gridTemplateColumns = "1fr";
    settingsContainer.style.gap = "10px";
    settingsContainer.style.marginBottom = "5px";

    let bgColorInput; // Will hold the reference to color input

    settingsContainer.appendChild(
      this.createCheckbox("Enable BG Color", this.enableBgColor, (checked) => {
        this.enableBgColor = checked;
        if (checked) {
          this.backgroundColor = colorMap.yellow; // default yellow
          this.padding = "32px 16px 32px 16px";
        } else {
          this.backgroundColor = "";
          this.padding = "8px 0px 8px 0px";
        }
        this.data.backgroundColor = this.backgroundColor;
        this.data.padding = this.padding;

        // Update color input
        if (bgColorInput) bgColorInput.querySelector("input").value = this.backgroundColor;

        // Re-render radio group visibility
        if (colorRadioContainer) {
          colorRadioContainer.style.display = checked ? "block" : "none";
          // Default to yellow if enabled
          if (checked && this.backgroundColor === "") {
            this.backgroundColor = colorMap.yellow;
            bgColorInput.querySelector("input").value = this.backgroundColor;
          }
        }

        this.updateStyles();
      })
    );

    // ✅ Radio group for predefined colors
    const colorRadioContainer = document.createElement("div");
    colorRadioContainer.style.display = this.enableBgColor ? "block" : "none";

    const createRadio = (labelText, value, isChecked) => {
      const radioWrapper = document.createElement("label");
      radioWrapper.style.display = "flex";
      radioWrapper.style.alignItems = "center";
      radioWrapper.style.fontSize = "12px";
      radioWrapper.style.marginBottom = "5px";
      radioWrapper.style.gap = "5px";

      const radio = document.createElement("input");
      radio.type = "radio";
      radio.name = "bgColorChoice";
      radio.value = value;
      radio.checked = isChecked;

      radio.addEventListener("change", () => {
        this.backgroundColor = value;
        this.data.backgroundColor = value;
        if (bgColorInput) bgColorInput.querySelector("input").value = value;
        this.updateStyles();
      });

      const colorLabel = document.createElement("span");
      colorLabel.textContent = labelText;

      radioWrapper.appendChild(radio);
      radioWrapper.appendChild(colorLabel);

      return radioWrapper;
    };

    colorRadioContainer.appendChild(createRadio("Yellow", colorMap.yellow, this.backgroundColor === colorMap.yellow || true));
    colorRadioContainer.appendChild(createRadio("Red", colorMap.red, this.backgroundColor === colorMap.red));
    colorRadioContainer.appendChild(createRadio("Green", colorMap.green, this.backgroundColor === colorMap.green));
    colorRadioContainer.appendChild(createRadio("Blue", colorMap.blue, this.backgroundColor === colorMap.blue));

    settingsContainer.appendChild(colorRadioContainer);

    // ✅ Color hex input
    bgColorInput = this.createInput("BG-Color:", "text", this.backgroundColor, (value) => {
      this.backgroundColor = value;
      this.data.backgroundColor = value;

      // uncheck all radios if user enters custom color
      const radios = colorRadioContainer.querySelectorAll("input[type=radio]");
      radios.forEach(r => (r.checked = false));

      this.updateStyles();
    });

    settingsContainer.appendChild(bgColorInput);

    settingsContainer.appendChild(
      this.createDropdown("List Type:", ["unordered", "ordered"], this.listStyle, (value) => {
        this.listStyle = value;
        this.updateListType();
      })
    );

    wrapper.appendChild(settingsContainer);
    return wrapper;
  }

  createCheckbox(labelText, defaultChecked, onChange) {
    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.alignItems = "center";
    container.style.marginBottom = "5px";

    const label = document.createElement("label");
    label.textContent = labelText;
    label.style.fontSize = "12px";
    label.style.marginRight = "5px";
    label.style.width = "110px";

    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.checked = defaultChecked;

    checkbox.addEventListener("change", (event) => {
      onChange(event.target.checked);
    });

    container.appendChild(label);
    container.appendChild(checkbox);
    return container;
  }

  createInput(labelText, type, defaultValue, onChange) {
    const wrapper = document.createElement("div");
    wrapper.style.display = "flex";
    wrapper.style.alignItems = "center";
    wrapper.style.gap = "5px";

    const label = document.createElement("label");
    label.textContent = labelText;
    label.style.fontSize = "10px";

    const input = document.createElement("input");
    input.type = type;
    input.value = defaultValue;
    input.style.fontSize = "10px";
    input.style.width = type === "color" ? "40px" : "50px";
    input.style.cursor = type === "color" ? "pointer" : "text";

    input.addEventListener("input", (event) => onChange(event.target.value));

    wrapper.appendChild(label);
    wrapper.appendChild(input);
    return wrapper;
  }

  createDropdown(labelText, options, defaultValue, onChange) {
    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.alignItems = "center";
    container.style.marginBottom = "5px";

    const label = document.createElement("label");
    label.textContent = labelText;
    label.style.fontSize = "12px";
    label.style.marginRight = "5px";
    label.style.width = "110px";

    const select = document.createElement("select");
    select.style.width = "90px";
    select.style.fontSize = "12px";

    options.forEach((option) => {
      const opt = document.createElement("option");
      opt.value = option;
      opt.textContent = option.charAt(0).toUpperCase() + option.slice(1);
      if (option === defaultValue) opt.selected = true;
      select.appendChild(opt);
    });

    select.addEventListener("change", (event) => {
      onChange(event.target.value);
    });

    container.appendChild(label);
    container.appendChild(select);
    return container;
  }

  updateStyles() {
    const blockId = this.api.blocks.getCurrentBlockIndex();
    const block = this.api.blocks.getBlockByIndex(blockId);
    console.log("bbbb",block);
    if (!block) {
      console.error("No block found at index", blockId);
      return;
    }

    if (!block.holder || block.holder == undefined) {
      console.error("Block holder is undefined for block", block);
      return;
    }

    if (block && block.holder) {
      const listEl = block.holder.querySelector("ul, ol");
      if (listEl) {
        listEl.style.backgroundColor = this.backgroundColor;
        listEl.style.padding = this.padding;
        listEl.style.margin = this.margin;
        listEl.style.gap = this.gap;
        listEl.style.borderRadius = this.borderRadius;
      }
    } else {
      console.error("Block or block holder is undefined:", block);
    }
    setTimeout(() => {
      this.api.saver.save().then((savedData) => {
        console.log("Updated savedData:", savedData);
      });
    }, 0);
  }

  updateListType() {
    const blockIndex = this.api.blocks.getCurrentBlockIndex();
    const block = this.api.blocks.getBlockByIndex(blockIndex);
    console.log("kkkk",block);
    if (!block) {
      console.error("No block found at index", blockIndex);
      return;
    }

    if (!block.holder || block.holder == undefined) {
      console.error("Block holder is undefined for block", block);
      return;
    }

    const currentList = block.holder.querySelector("ul, ol");
    console.log("currentList.children.length111111111111",currentList.children.length);
    if (currentList && currentList.children.length > 0) {
      currentList.style.listStyleType = this.listStyle === "ordered" ? "decimal" : "disc";
    }
  }

  save(blockContent) {
    console.log("list saved...");
    const originalData = super.save(blockContent);
    return {
      ...originalData,
      backgroundColor: this.backgroundColor,
      gap: this.gap,
      padding: this.padding,
      margin: this.margin,
      borderRadius: this.borderRadius,
    };
  }
}

export default MyListTool;
