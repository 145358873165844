import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, Link, Router, } from "react-router-dom";
import EditorJsRenderer from "editorjs-react-renderer";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ClearIcon from '@mui/icons-material/Clear';
import EditorData from "./EditorData.js";


import AddCommentIcon from '@mui/icons-material/AddComment';
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";

import { FaSearch , FaChevronDown } from "react-icons/fa";




import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Button,
  Avatar
} from "@mui/material";

const notifySuccess = (message) => {
  toast.success(message);
};

const notifyError = (message) => {
  toast.error(message);
};

const CustomContent = ({ key,annotation, setAnnotation, setRefresh, isAnnotationEdit, setIsAnnotationEdit}) => {
  //console.log("annotationannotation",annotation);
  const { id } = useParams();
  const navigate = useNavigate();
  const { geometry, data } = annotation;
  const idIntegerPart = Math.floor(geometry?.height);

  // State for modal visibility and modal data
  const [annotations, setAnnotations] = useState(annotation);
  console.log("annotations------------------",annotations);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMore, setIsModalOpenMore] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  //const [isAnnotationEdit, setIsAnnotationEdit] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  

  useEffect(() => {

    if (isAnnotationEdit === 0) {
      setAnnotations(annotation); // Update annotations when isAnnotationEdit is 0
    }
  }, [isAnnotationEdit, annotation]);
  // Update parent component's annotation state when annotations change
  useEffect(() => {
    setAnnotation(annotations); // Pass updated annotations to parent component
  }, [annotations, setAnnotation]);

  //editor 
  const [guidelines, setGuidelines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompliant, setSelectedCompliant] = useState(annotations.data.compliant);
  const [formErrors, setFormErrors] = useState({
    guideline: "",
    compliant: "",
    heading: "",
    subtext: "",
    impact: "",
    effort: "",
  });

  // const complianceColors = {
  //   "green": "#2E9892", // Green
  //   "green": "#2E9892", // Orange
  //   "yellow": "#E3A020", // Yellow
  //   "red": "#D0463E", //red
  // };
  const complianceColors = {
    "Compliant High": "#2E9892", // Green
    "Compliant Low": "#2E9892", // Orange
    "Breach Moderate": "#E3A020", // Yellow
    "Breach High": "#D0463E", // Red
  };
  const compliancePoints = {
    "Compliant High": 0,
    "Compliant Low": -1,
    "Breach Moderate": -3,
    "Breach High": -5,
  };
 
  const editorRef = useRef(null);
  const [editorData, setEditorData] = useState(null);
  const [formDataMore, setFormDataMore] = useState({
    title: "",
    subTitle: "",
    titleDescription: "",
    selectedDesktopFiles: [],
    selectedDesktopFileUrls: [],
    selectedDesktopFileUrlsShow: [],
    description: {},
    uxinsightslist: [],
  });

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index, imageUrl) => {
    setSelectedImageIndex(index);
    setSelectedImage(`${process.env.REACT_APP_BASE_URL}${imageUrl}`);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [selectedGuideline, setSelectedGuideline] = useState(null); // State to track selected value

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownOpen(true); // Open dropdown when typing
  };

  const handleOptionSelect = (guideline) => {
    //console.log("guidelineguideline",guideline);
    // Update the selected guideline
    setSelectedGuideline(guideline);

    // Update annotations
    setAnnotations((prevAnnotations) => ({
      ...prevAnnotations,
      data: {
        ...prevAnnotations.data,
        guideline: guideline._id,
        guidelineHashNumber: guideline.hashNumber,
      },
    }));

    setIsDropdownOpen(false); // Close dropdown after selection
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const uxinsights = ['UX Insight 1', 'UX Insight 2', 'UX Insight 3'];
  const handleChangeUXInsights = (event) => {
    const { value } = event.target;
    console.log("valuevalue",value);
    setFormDataMore({
      ...formDataMore,
      uxinsightslist: typeof value === 'string' ? value.split(',') : value, // Update uxinsightslist
    });
  };

 

 

  const handleDesktopImageChange = (e) => {
    const newFiles = Array.from(e.target.files); // Get newly selected files
    
    setFormDataMore((prevState) => {
      // Get the existing files and calculate the total
      const existingFiles = prevState.selectedDesktopFiles || [];
      const totalFiles = existingFiles.length + newFiles.length;
  
      // Check if total exceeds 5
      if (totalFiles+formDataMore.selectedDesktopFileUrlsShow.length > 5) {
        toast.error("You can only upload up to 5 images.");
        return prevState;
     
      }
  
      const newImageUrls = newFiles.map((file) => URL.createObjectURL(file)); // Create new image URLs for display
  
      return {
        ...prevState,
        // Append new files to existing files
        selectedDesktopFiles: [...existingFiles, ...newFiles],
        // Append new URLs to existing URLs
        selectedDesktopFileUrls: [
          ...(prevState.selectedDesktopFileUrls || []),
          ...newImageUrls,
        ],
      };
    });
  };
  const handleDeleteDesktopImage = (indexToDelete) => {
    const updatedFiles = formDataMore.selectedDesktopFiles.filter((_, index) => index !== indexToDelete);
    const updatedUrls = formDataMore.selectedDesktopFileUrls.filter((_, index) => index !== indexToDelete);
  
    setFormDataMore((prevState) => ({
      ...prevState,
      selectedDesktopFiles: updatedFiles,
      selectedDesktopFileUrls: updatedUrls, // Update both files and URLs
    }));
  };
  const handleDeleteDesktopImageShow = async (imageUrl, type) => {
    const confirmDelete = await swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    });

    if (confirmDelete) {
      notifySuccess("File deleted successfully.");
      // try {
      //   const response = await axios.post(
      //     `${process.env.REACT_APP_BASE_URL}api/admin/deleteImageAnnotationMoreDetail`,
      //     { id, mainid, type, imageUrl }
      //   );

      //   if (response.data.status === 1) {
      //     // Update the state to remove the deleted image from the display
      //     if (type === "desktopimage") {
      //       setFormDataMore((prevData) => ({
      //         ...prevData,
      //         selectedDesktopFileUrlsShow:
      //           prevData.selectedDesktopFileUrlsShow.filter(
      //             (url) => url !== imageUrl
      //           ),
      //       }));
      //     } 

      //     // Optionally show a success message
      //     notifySuccess("File deleted successfully.");
      //   } else {
      //     // Handle error from API response
      //     notifyError("Failed to delete the image.");
      //   }
      // } catch (error) {
      //   console.error("Error deleting image:", error);
      //   notifyError("An error occurred while deleting the image.");
      // }
    }
  };
  // Fetch guidelines from backend API
  useEffect(() => {
    const fetchGuidelines = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getAnnotationGuidelines`); // Replace with your actual API endpoint
        setGuidelines(response.data.guidelines); // Assuming response.data is an array of guidelines
        setLoading(false);
        const selectedGuidelinedata = response.data.guidelines.find(
          (guideline) => guideline._id === annotations.data.guideline
        );
        handleOptionSelect(selectedGuidelinedata);
      } catch (error) {
        console.error("Error fetching guidelines:", error);
        setLoading(false);
      }
    };

    fetchGuidelines();
  }, []);
    // Filter guidelines based on the search term
    const filteredGuidelines = guidelines.filter((guideline) =>
      guideline.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleSubmitComment = async () => {
    if (validateForm()) {
      
    // Submit form if validation passes
   
    const formDataToSend = new FormData();
    formDataToSend.append("id", annotations._id);
    formDataToSend.append("mainid", id);
    formDataToSend.append("guideline", annotations.data.guideline);
    formDataToSend.append("guidelineHashNumber",annotations.data.guidelineHashNumber);
    formDataToSend.append("compliant", annotations.data.compliant);
    formDataToSend.append("compliantPoint", annotations.data.compliantPoint);
    formDataToSend.append("heading", annotations.data.heading);
    formDataToSend.append("subtext", annotations.data.subtext);
    formDataToSend.append("impact", annotations.data.impact);
    formDataToSend.append("effort", annotations.data.effort);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/updateSingleAnnotationSub`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if (response.data.status == 1) {
        swal("Success!", "Data updated successfully", "success");
        //navigate(`/annotations-list/${mainid}`);
        setIsAnnotationEdit(0);
        setRefresh(prev => !prev);
      } else {
        swal("Error!", response.data.message, "error");
      }
    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
    console.log("success");
   }
  };

  useEffect(() => {
    if (isModalOpenMore) 
    {
    // Ensure the DOM element exists before initializing the editor
    if (!document.getElementById("editorjs")) {
      console.error("EditorJS holder element is missing!");
      return;
    }

    // Initialize Editor.js
    editorRef.current = new EditorJS({
      holder: "editorjs",
      tools: {
        header: Header,
        list: List,
      },
      data: editorData || {}, // Use initial data if available
      onChange: async () => {
        const savedData = await editorRef.current.save();
        setEditorData(savedData); // Save data to state
      },
    });

    // Cleanup on component unmount
    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy(); // Destroy the editor instance
        editorRef.current = null;
      }
    };
  }
  }, [isModalOpenMore,editorData]);


  // const handleAddMoreDetail = async () => {
  //   setIsModalOpenMore(true);
  //   //alert("open more details");
  // };
  const handleAddMoreDetail = async () => {
    //navigate(`/annotations-list/${annotations._id}`);
    navigate(`/annotattion-moredetail/${annotations._id}/${id}/yes`);
  };

  

  if (!geometry) {
    return null; // Ensure this conditional logic is outside of hooks
  }
  const validateForm = () => {
    console.log("annotation..............",annotation);
    const errors = {};
    if (!annotations.data?.guideline) errors.guideline = "Guideline is required";
    if (!selectedCompliant) errors.compliant = "Compliance is required";
    if (!annotations.data?.heading) errors.heading = "Heading is required";
    //if (!annotation.annotation.data?.subtext) errors.subtext = "Subtext is required";
    if (!annotations.data?.impact) errors.impact = "Impact is required";
    if (!annotations.data?.effort) errors.effort = "Effort is required";
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };
  const handleSelection = (value) => {
    console.log("valuevaluevalue",value);
    setSelectedCompliant(value); // Update the selected value
    const point = compliancePoints[value];
    setAnnotations((prevAnnotations) => ({
      ...prevAnnotations,
      data: {
        ...prevAnnotations.data,
        compliant: value ,
        compliantPoint: point,
      },
    }));
  };

  const handleSeeMoreClick = async (number) => {
    try {
      // Show the modal
      setIsModalOpen(true);
      setIsAnnotationEdit(0);

      // Fetch data from backend API
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getSingleAnnotationMoreDetailWithNumber`, {id,number});
      const { annotationdetail, annotationmore } = response.data;

      //console.log("annotationdetailannotationdetail",annotationdetail);

      let updatemodeldata = {
        //title:annotationmore.title,
        title:annotationdetail.annotations[0].data.heading,
        //subTitle:annotationmore.subTitle,
        subTitle:`Illustrates the implementation of #${annotationdetail.annotations[0].data.guidelineHashNumber}`,
        //titleDescription:annotationmore.titleDescription,
        titleDescription:annotationdetail.annotations[0].data.subtext,
        description:annotationmore.description,
        uxinsightslist:annotationmore.uxinsightslist,
        compliant:annotationdetail.annotations[0].data.compliant,
        guideline:annotationdetail.annotations[0].data.guideline,
        guidelineHashNumber:annotationdetail.annotations[0].data.guidelineHashNumber,
        desktopImages:annotationmore.desktopImages,
        
      }
      if(annotationmore.desktopImages.length > 0)
        {
          setSelectedImage(`${process.env.REACT_APP_BASE_URL}${annotationmore.desktopImages[0]}`);
        }
     
      setModalData(updatemodeldata);
      //setModalData({title:"test title", description:"test description", additionalInfo:"test additionalInfo"});
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleRedirect = (guidelineid) => {
    navigate(`/edit-guideline/${guidelineid}/no`);
  };

  const handleEditAnno = (number) => {
    setIsAnnotationEdit(number);
    handleSelection(annotations.data.compliant);
  };
  
  const handleDeleteAnno = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Annotation!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const formDataToSend = new FormData();
          formDataToSend.append("id", annotations._id);
          formDataToSend.append("main_id", id);
          await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deleteResearchAnnotationSub`,  
            formDataToSend,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Important for file upload
              },
            });
          
          swal("Deleted!", "Annotation has been deleted.", "success");
          setIsAnnotationEdit(false);
          setRefresh(prev => !prev);
         // LoadData(); // Reload the data after deletion
        } catch (error) {
          swal("Error!", "Failed to delete the Annotation.", "error");
        }
       }
      //else {
      //   swal("Your Annotation is safe!");
      // }
    });
  };

  // Dynamically adjust the position
  let adjustedLeft = geometry.x; 
  if(geometry.x > 75)
  {
    let minusleft = geometry.x - 75;
    adjustedLeft = geometry.x - minusleft;
  }
console.log("isAnnotationEdit",isAnnotationEdit);
// Prevent mouse from leaving the div when editing
const handleMouseLeave = (e) => {
  console.log("isAnnotationEdit=================",isAnnotationEdit);
  if (isAnnotationEdit) {
    console.log("dddddddddddddddddddddddddddd",isAnnotationEdit);
    return; // Prevent leaving the modal area when editing
  }
};
  return (
    <>
    {isAnnotationEdit && isAnnotationEdit == data.number ? 
    (
      <>
      <div className="AssignGuidlineDiv" style={{ 
       //left: `${geometry.x}%`,  
       top: `${geometry.y + geometry.height}%`,
       left: `${adjustedLeft}%`,
       //top: `${adjustedTop}%`,
      
      
        }}
        onMouseLeave={handleMouseLeave} // Prevent mouse leave
        >


      {/* Dropdown for Assign Guideline */}

      <div className="TopAssignEdit">
        <h6>Assign Guideline</h6>
        {loading ? (
          <p>Loading guidelines...</p>
        ) : (
          <>
          
          <div className="InputTag"  onClick={toggleDropdown}>
            {/* <FaSearch /> */}
            {/* <input type="text" placeholder="Search Guideline" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/> */}

            <h4>{selectedGuideline
                ? `Guideline #${selectedGuideline.hashNumber} - ${selectedGuideline.title}`
                : `Search Guideline`}</h4>
            
            {/* <input
              type="text"
              readonly
              placeholder="Search Guideline"
              value={selectedGuideline
                ? `Guideline #${selectedGuideline.hashNumber} - ${selectedGuideline.title}`
                : `Guideline #${annotations.data.guidelineHashNumber} - `}
              onChange={handleSearchChange}
            /> */}
          
            <FaChevronDown
              style={{ cursor: "pointer", marginLeft: "8px" }}
              // Toggle dropdown on click
            />

          </div>
       
        

            {isDropdownOpen && (
            <ul className="SerchTagUl">
              <div className="InputserchTag">
                <FaSearch />
                <input
                  type="text"
                  placeholder="Search by Guideline Name"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              {filteredGuidelines.map((guideline) => (
                <li
                  key={guideline._id}
                  // style={{
                  //   padding: "8px",
                  //   cursor: "pointer",
                  //   backgroundColor: annotations.data.guideline === guideline._id ? "#f0f0f0" : "white",
                  // }}
                  onClick={() => handleOptionSelect(guideline)}
                >
                  Guideline #{guideline.hashNumber} - {guideline.title}
                </li>
              ))}
            </ul>
          )}


        {/* <select
        
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
          onChange={(e) => {
            const selectedGuideline = guidelines.find(
              (guideline) => guideline._id === e.target.value
            );
            
            // Update the state by setting annotations
            setAnnotations((prevAnnotations) => ({
              ...prevAnnotations,
              data: {
                ...prevAnnotations.data,
                guideline: e.target.value,
                guidelineHashNumber: selectedGuideline ? selectedGuideline.hashNumber : "",
              },
            }));
          }}
        
          value={annotations.data.guideline}
          >
            
          <option value="">Select Guideline</option>
          {filteredGuidelines.map((guideline) => (
            <option key={guideline._id} value={guideline._id}>
              Guideline #{guideline.hashNumber} - {guideline.title}
            </option>
          ))}
        </select> */}
          </>
          
        )}
        {formErrors.guideline && (
          <span style={{ color: "red", fontSize: "12px" }}>{formErrors.guideline}</span>
        )}

    
        <div className="ComplaintDiv">
          <h6>Choose Compliant</h6>
          <div className="CompltbtnDiv">
              {["Compliant High", "Compliant Low", "Breach Moderate", "Breach High"].map(
              (compliance, index) => (
                <button
                  key={index}
                  type="button"
                  style={{
                    background: selectedCompliant === compliance ? complianceColors[compliance] : "transparent",
                    color: selectedCompliant === compliance ? '#f0f0f0' : complianceColors[compliance],
                    border: "1px solid  ",
                    borderRadius: "4px",
                    padding: "8px",
                    
                  }}
                   
                  onClick={() => handleSelection(compliance)}
                >
                  {compliance}
                </button>
              )
            )}
          </div>
          {formErrors.compliant && (
              <span style={{ color: "red", fontSize: "12px" }}>{formErrors.compliant}</span>
            )}
        </div>

      </div>

      {/* Input for Heading and Subtext */}
      <div className="AssignGuidHead">

        <div className="indivs">
          <textarea
            type="text"
            placeholder="Enter Heading"
            value={annotations.data.heading} 
            onChange={(e) =>
              setAnnotations((prevAnnotations) => ({
                ...prevAnnotations,
                data: { ...prevAnnotations.data, heading: e.target.value },
              }))
            }
          />
          {formErrors.heading && (
            <span style={{ color: "red", fontSize: "12px" }}>{formErrors.heading}</span>
          )}
        </div>
        <div className="indivs">
            <textarea
              type="text"
              placeholder="Enter Sub Text"
              value={annotations.data.subtext} 
              onChange={(e) =>
                setAnnotations((prevAnnotations) => ({
                  ...prevAnnotations,
                  data: { ...prevAnnotations.data, subtext: e.target.value },
                }))
              }
            />
          
          {formErrors.subtext && (
            <span style={{ color: "red", fontSize: "12px" }}>{formErrors.subtext}</span>
          )}
        </div>
       
        
        <div className="AstImpact" >

          <div className="indivs">
            <div className="impact">
              <p>Impact:</p>
              
              <select  
              value={annotations.data.impact} 
              onChange={(e) =>
                setAnnotations((prevAnnotations) => ({
                  ...prevAnnotations,
                  data: { ...prevAnnotations.data, impact: e.target.value },
                }))
              }>
                  <option key={0} value="">Select</option>
                  <option key={1} value={'High'}>High</option>
                  <option key={2} value={'Medium'}>Medium</option>
                  <option key={3} value={'Low'}>Low</option>
              </select>
            </div>
            {formErrors.impact && ( <span style={{ color: "red", fontSize: "12px" }}>{formErrors.impact}</span>)}
          </div>

          <div className="indivs">

            <div className="impact">
              <p>Efforts:</p>
              <select  
              value={annotations.data.effort} 
              onChange={(e) =>
                setAnnotations((prevAnnotations) => ({
                  ...prevAnnotations,
                  data: { ...prevAnnotations.data, effort: e.target.value },
                }))
              }>
                <option key={0} value="">Select</option>
                  <option key={1} value={'High'}>High</option>
                  <option key={2} value={'Medium'}>Medium</option>
                  <option key={3} value={'Low'}>Low</option>
              </select>
            </div>
            {formErrors.effort && ( <span style={{ color: "red", fontSize: "12px" }}>{formErrors.effort}</span> )}
          </div>


        </div>

        

        <div className="Comntbtn twocomptbtn" >
          <button type="button" onClick={handleAddMoreDetail}> Edit More Details<AddCommentIcon/> </button>
          <button type="button" onClick={handleSubmitComment}> Save<AddCommentIcon/> </button>
        </div>



      </div>

    </div>
      </>
    ) 
    : 
    (<>
    <div className="CustonContentDiv" key={data.id} style={{  
      //left: `${geometry.x}%`,  
      top: `${geometry.y + geometry.height}%`,
      left: `${adjustedLeft}%`,
      //top: `${adjustedTop}%`,

     
     
      position: "absolute"
      }}>
      <div className="CustTopHead " style={{background:complianceColors[data.compliant]}}>
        <h6>{data.compliant}. Guideline #{data.guidelineHashNumber}</h6>
        <div className="DeskImgDid">

        </div>

        <div className="AnotationIon">
        
          <button  onClick={() => handleEditAnno(data.number)}><MdEdit /></button>
          <button onClick={() => handleDeleteAnno()}><MdDeleteForever /></button>
    
        </div>

        {/* <Button style={{ cursor: "pointer", color: "#fff" }}
          onClick={() => handleEditAnno()}
          >
          Edit
        </Button>

        <Button style={{ cursor: "pointer", color: "#fff" }}
          onClick={() => handleDeleteAnno()}
          >
          Delete
        </Button> */}
      </div>

      <div className="CustContInner">
        <h4>{data.heading}</h4>
        <p>{data.subtext}</p>
        <div className="ImpactPerview">
          <p>Impact: <span>{data.impact}</span></p>
          <p>Efforts: <span>{data.effort}</span></p>
        </div>
        <p className="contpara">{data.additional}</p>
        <p>Improvements advice & more examples.</p>
        <div className="Comntbtn">
          <button onClick={() => handleSeeMoreClick(data.number)}type="button">  See More Details <DisplaySettingsIcon/> </button>
        </div>

      </div>

    </div>
    </>)}
    
    
      {/* Modal Implementation */}
      {isModalOpen && (
        <div className="ContentModalDiv"  >

          <div className="LeftModalPerview">
            <div className="innerPerviewimg">
              <img src={selectedImage} alt="" />
            { /* <Avatar key={0} src={selectedImage}/> */}
            </div>
          </div>

          <div className="RytModalDetails">
          
            {modalData ? (
            <div className="ModalInnerData">

              <div className="CustContHead">
                <div className="custTital">
                  <h4>{modalData.title}</h4>
                  <p>{modalData.subTitle}</p>
                </div>
                <div className="CustHeading">
                  <h2>{modalData.titleDescription}</h2>
                </div>
                <div className="Custbtn">
                  <span style={{background:"#2E9892"}}>{modalData.compliant}</span>
                </div>
              </div>
              

              <div className="CustImages">
                {modalData.desktopImages?.map((imageUrl, index) => (
                  <div
                    key={index}
                    className={`image-item ${selectedImageIndex === index ? 'active' : ''}`}
                    onClick={() => handleImageClick(index, imageUrl)}
                  >
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${imageUrl}`}
                      alt={`Uploaded Icon ${index + 1}`}
                    />
                  </div>
                ))}
              </div>

              <div className="CustWhyUx">
                {modalData && (
                  <EditorData modalData={modalData}/>
                )}
              </div>
              <div className="CustInfo">
                  <h4>Related Information</h4>
                  <ul>
                  {modalData.uxinsightslist.map((item, index) => (
                    <li key={index}>
                      {item}
                    </li>
                  ))}
                </ul> 
              </div>

              <div className="Comntbtn">
                <button onClick={() => handleRedirect(modalData.guideline)}> Guideline #{modalData.guidelineHashNumber}</button>
              </div>

            </div>

            ) : (
              <p>Loading...</p>
            )}
            <button className="CrossBtn" onClick={() => setIsModalOpen(false)}><ClearIcon/></button>

          </div>
            
        </div>
      )}

        {/* Modal Overlay */}
        {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={() => setIsModalOpen(false)}
        />
      )}


      {/* Modal Add More data */}
      {isModalOpenMore && (
        <div className="ContentModalDiv" style={{ position: "fixed", top: "50%",left: "50%",transform: "translate(-50%, -50%)",boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", zIndex: 1000,}}>

          
          <div className="GuidlineForm">
          <button className="CrossBtn" onClick={() => setIsModalOpenMore(false)}><ClearIcon/></button>
            <form>
              <div className="row mb-3">
              <div className="col-md-6">
                  <div className="guidFormControl">
                    <h6> <strong>Title</strong></h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Title"
                      value={formDataMore.title}
                      onChange={(e) =>
                        setFormDataMore({ ...formDataMore, title: e.target.value })
                      } 
                      multiline
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="guidFormControl">
                    <h6> <strong>Sub Title</strong></h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Sub Title"
                      value={formDataMore.subTitle}
                      onChange={(e) =>
                        setFormDataMore({ ...formDataMore, subTitle: e.target.value })
                      } 
                      multiline
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="guidFormControl">
                    <h6> <strong>Title Description</strong></h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Title Description"
                      value={formDataMore.titleDescription} 
                      onChange={(e) =>
                        setFormDataMore({ ...formDataMore, titleDescription: e.target.value })
                      } 
                      multiline
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="guidFormControl">
                    <h6> <strong>References</strong></h6>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>UX Insights</InputLabel>
                      <Select
                        label="UX Insights"
                        multiple
                        value={formDataMore.uxinsightslist}
                        onChange={handleChangeUXInsights}
                        renderValue={(selected) => selected.join(', ')}
                        style={{ lineHeight: "3em" }}
                      >
                        {uxinsights.map((uxinsight) => (
                          <MenuItem key={uxinsight} value={uxinsight}>
                            <Checkbox checked={formDataMore.uxinsightslist.indexOf(uxinsight) > -1} />
                            <ListItemText primary={uxinsight} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="Filters">
                  <h6> <strong>Upload Images</strong></h6>
                  <div className="FilterGrid">

                    <div className="DeskImgDiv">
                      <div className="DesktopImages">
                        {/* Display existing desktop images with delete option */}
                        {formDataMore.selectedDesktopFileUrlsShow?.map(
                          (imageUrl, index) => (
                            <div key={index} >
                              <div className="ImgShow" key={index}>
                                <Avatar
                                  src={`${process.env.REACT_APP_BASE_URL}${imageUrl}`}
                                  alt={`Uploaded Icon ${index + 1}`}
                                  style={{
                                    width: 120,
                                    height: 100,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                />
                              
                                <div
                                  className="ImgIcon"
                                  onClick={() =>
                                    handleDeleteDesktopImageShow(
                                      imageUrl,
                                      "desktopimage"
                                    )
                                  }
                                  >
                                  <ClearIcon />
                                  
                                </div>
                                
                              </div>
                              
                              
                            </div>
                          )
                        )}
                        {formDataMore.selectedDesktopFileUrls?.map((imageUrl, index) => (
                          <>
                            <div className="ImgShow">
                              <Avatar key={index} src={imageUrl}  alt={`Uploaded Icon ${index + 1}`}style={{ width: 120, height: 100, marginRight: 0 , borderRadius:2 }}/>
                              <div className="ImgIcon" onClick={() => handleDeleteDesktopImage(index)}>
                                <ClearIcon />
                              </div>
                            </div>
                          </>
                        
                        ))}
                      </div>
                      <div className="uplbtn">
                        <Button component="label">
                          Upload Images
                        <CloudUploadIcon />
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            multiple
                            onChange={handleDesktopImageChange}
                        />
                        </Button>
                      </div>
                    </div>

                    
                  </div>
              </div>

              <div className="Filters">
              <div className="guidFormControl">
                  <h6> <strong>Description</strong></h6>
                  <div className="EditFontDiv">
                     <div id="editorjs" style={{ border: "1px solid #ccc", padding: "10px" }}></div>
                  </div>

                </div>
              </div>
            </form>
            
          </div>
         
          
            
            

            
            

         
            
        </div>
      )}

        {/* Modal Overlay */}
        {isModalOpenMore && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={() => setIsModalOpenMore(false)}
        />
      )}
    </>
    
  );
};

export default CustomContent;
