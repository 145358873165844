/**
=========================================================
* Balearen Whisper React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import swal from 'sweetalert';
// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Link, useNavigate } from "react-router-dom";


const Overview = () => {
  const navigate = useNavigate();
  const [adminDetails, setAdminDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(""); // State for the OTP input
  const [newPassword, setNewPassword] = useState(""); // State for the new password input
  const [confirmPassword, setConfirmPassword] = useState(""); // State for the confirm password input
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false); // State to track if email is submitted
  const [isOtpVerified, setIsOtpVerified] = useState(false); // State to track if OTP is verified
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages


  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    if (!authUser) {
      navigate("/login");
    } else {
      const authUserData = JSON.parse(authUser);
      const adminId = authUserData._id;

      const fetchAdminDetails = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/loginAdminDetail`, { id: adminId });
          if (response.data.status === 1) {
            setAdminDetails(response.data.admin);
          } else {
            console.error(response.data.errors);
          }
        } catch (error) {
          console.error("Error fetching admin details:", error);
        }
      };

      fetchAdminDetails();
    }
  }, [navigate]);


  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message

    if (email !== adminDetails.email) {
      setErrorMessage("Entered email does not match the logged-in user's email.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/resetPassword`, { email });
      if (response.data.status === 1) {
        swal("Success", "Verification code sent on your email. Please check and verify", "success");
        setIsEmailSubmitted(true); // Set the state to true to show the OTP input
      } else {
        setErrorMessage(response.data.errors.join(", ")); // Set error message if email does not match
      }
    } catch (error) {
      setErrorMessage("Error resetting password: " + error.message); // Set error message for other errors
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/verifyOTPAndUpdatePassword`, { email, otp, type: "otp" });
      if (response.data.status === 1) {
        swal("Success", "OTP verified successfully.", "success");
        setIsOtpVerified(true); // Show password inputs
      } else {
        const errors = response.data.errors
          ? Object.values(response.data.errors).join(", ")
          : 'An unknown error occurred.';
        setErrorMessage(errors);
      }
    } catch (error) {
      setErrorMessage("Error verifying OTP: " + (error.response ? error.response.data.message : error.message));
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/verifyOTPAndUpdatePassword`, { email, otp, newPassword, confirmPassword, type: "password" });
      if (response.data.status === 1) {
        swal("Success", "Password updated successfully.", "success");

        // Reset states to go back to the email input form
        setEmail(""); // Clear email field
        setOtp(""); // Clear OTP field
        setNewPassword(""); // Clear new password field
        setConfirmPassword(""); // Clear confirm password field
        setIsEmailSubmitted(false); // Reset to show email input
        setIsOtpVerified(false); // Reset OTP verification status
      } else {
        const errors = response.data.errors
          ? typeof response.data.errors === 'string'
            ? response.data.errors
            : Array.isArray(response.data.errors)
              ? response.data.errors.join(", ")
              : Object.values(response.data.errors).join(", ")
          : 'An unknown error occurred.';
        setErrorMessage(errors);
      }
    } catch (error) {
      setErrorMessage("Error updating password: " + (error.response ? error.response.data.message : error.message));
    }
  };


  const handleEditClick = (userId) => {
    console.log("Edit Profile button clicked!");
    //navigate("/editStaff/669a153f9dbab6b12af628d0");
    navigate("/editStaff/" + userId);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header
        name={adminDetails?.name || "Unknown User"}
        role={adminDetails?.role || "Unknown Role"}
        profilePic={adminDetails?.profilePic} // Pass the profile picture URL
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>

            <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              {adminDetails ? (
                <ProfileInfoCard
                  title="Profile Information"
                  description={adminDetails.bio || "No bio available."}
                  info={{
                    fullName: adminDetails.name,
                    mobile: adminDetails.phone,
                    email: adminDetails.email,
                    location: adminDetails.country,
                    role: adminDetails.role,

                  }}
                  social={[
                    {
                      link: "",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                  onEditClick={() => {
                    handleEditClick(adminDetails?.id);
                  }}
                />

              ) : (
                <MDTypography>Loading...</MDTypography>
              )}
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>

            <Grid item xs={12} sm={5}  lg={3} sx={{ display: "flex", flexDirection: "column" }}>
              {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
              <MDTypography variant="h6" sx={{ ml: 2, mb: 2 }}>
                Change Your Password
              </MDTypography>
              <MDBox component="form" onSubmit={isOtpVerified ? handlePasswordSubmit : (isEmailSubmitted ? handleOtpSubmit : handleEmailSubmit)} sx={{ ml: 2, mb: 2 }}>
                {!isEmailSubmitted ? (
                  <>
                    <label htmlFor="email">Enter Your Email</label>
                    <input type="email" className="form-control" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  </>
                ) : !isOtpVerified ? (
                  <>
                    <label htmlFor="otp">Enter OTP</label>
                    <input type="text" className="form-control" name="otp" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  </>
                ) : (
                  <>
                    <label htmlFor="newPassword">New Password</label>
                    <input type="password" className="form-control" name="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />

                    <label htmlFor="confirmPassword">Confirm New Password</label>
                    <input type="password" className="form-control" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  </>
                )}
                <button type="submit" className="btn btn-primary float-end mt-2 mb-2">Submit</button>
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
};

export default Overview;







