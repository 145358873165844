import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams, Link, Router, } from "react-router-dom";
import EditorJsRenderer from "editorjs-react-renderer";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ClearIcon from '@mui/icons-material/Clear';
import EditorData from "./EditorData.js";
import { Avatar } from "@mui/material";

const CustomContent = ({ annotation }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { geometry, data } = annotation;
  const idIntegerPart = Math.floor(geometry?.height);

  // State for modal visibility and modal data
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");

  // const complianceColors = {
  //   "green": "#2E9892", // Green
  //   "green": "#2E9892", // Orange
  //   "yellow": "#E3A020", // Yellow
  //   "red": "#D0463E", //red
  // };
  const complianceColors = {
    "Compliant High": "#2E9892", // Green
    "Compliant Low": "#2E9892", // Orange
    "Breach Moderate": "#E3A020", // Yellow
    "Breach High": "#D0463E", // Red
  };

  const handleSeeMoreClick = async (number) => {
    try {
      // Show the modal
      setIsModalOpen(true);

      // Fetch data from backend API
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getSingleAnnotationMoreDetailWithNumber`, {id,number});
      const { annotationdetail, annotationmore } = response.data;

      //console.log("annotationdetailannotationdetail",annotationdetail);

      let updatemodeldata = {
        //title:annotationmore.title,
        title:annotationdetail.annotations[0].data.heading,
        //subTitle:annotationmore.subTitle,
        subTitle:`Illustrates the implementation of #${annotationdetail.annotations[0].data.guidelineHashNumber}`,
        //titleDescription:annotationmore.titleDescription,
        titleDescription:annotationdetail.annotations[0].data.subtext,
        description:annotationmore.description,
        uxinsightslist:annotationmore.uxinsightslist,
        compliant:annotationdetail.annotations[0].data.compliant,
        guideline:annotationdetail.annotations[0].data.guideline,
        guidelineHashNumber:annotationdetail.annotations[0].data.guidelineHashNumber,
        desktopImages:annotationmore.desktopImages,
        
      }
      if(annotationmore.desktopImages.length > 0)
        {
          setSelectedImage(`${process.env.REACT_APP_BASE_URL}${annotationmore.desktopImages[0]}`);
        }
     
      setModalData(updatemodeldata);
      //setModalData({title:"test title", description:"test description", additionalInfo:"test additionalInfo"});
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleRedirect = (guidelineid) => {
    navigate(`/edit-guideline/${guidelineid}/no`);
  };

  return (
    <>
    <div className="CustonContentDiv" key={data.id} style={{  
      //left: `${geometry.x}%`,top: `${geometry.y - 5}%`, 
      position: "absolute"
      }}>
      <div className="CustTopHead" style={{background:complianceColors[data.compliant]}}>
        <h6>{data.number} {data.compliant}. Guideline #{data.guidelineHashNumber}</h6>
      </div>

      <div className="CustContInner">
        <h4>{data.heading}</h4>
        <p>{data.subtext}</p>
        <div className="ImpactPerview">
          <p>Impact: <span>{data.impact}</span></p>
          <p>Efforts: <span>{data.effort}</span></p>
        </div>
        <p className="contpara">{data.additional}</p>
        <p>Improvements advice & more examples.</p>

        <div className="Comntbtn">
          <button onClick={() => handleSeeMoreClick(data.number)}type="button">  See More Details <DisplaySettingsIcon/> </button>
        </div>

      </div>

    </div>
    
     {/* Modal Implementation */}
     {isModalOpen && (
        <div className="ContentModalDiv"  style={{ position: "fixed", top: "50%",left: "50%",transform: "translate(-50%, -50%)",boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", zIndex: 1000,}}>

          <div className="LeftModalPerview">
            <div className="innerPerviewimg">
            
          <Avatar
            key={0}
            src={selectedImage}
            
            style={{
              width: '100%',
              height: '100%',
            }}
          />
            </div>
          </div>

          <div className="RytModalDetails">
          
            {modalData ? (
            <div className="ModalInnerData">

              <div className="CustContHead">
                <div className="custTital">
                  <h4>{modalData.title}</h4>
                  <p>{modalData.subTitle}</p>
                </div>
                <div className="CustHeading">
                  <h2>{modalData.titleDescription}</h2>
                </div>
                <div className="Custbtn">
                  <span style={{background:"#2E9892"}}>{modalData.compliant}</span>
                </div>
              </div>

              <div className="CustImages">
              {modalData.desktopImages?.map((imageUrl, index) => (
                  <div key={index} className="image-item" onClick={() => setSelectedImage(`${process.env.REACT_APP_BASE_URL}${imageUrl}`)}>
                    
                    <Avatar
                      key={index}
                      src={`${process.env.REACT_APP_BASE_URL}${imageUrl}`}
                      alt={`Uploaded Icon ${index + 1}`}
                      style={{
                        width: 140,
                        height: 84,
                      }}
                    />
                  </div>
                ))}
              </div>

              <div className="CustWhyUx">
                {modalData && (
                  <EditorData modalData={modalData}/>
                )}
              </div>
              <div className="CustInfo">
                  <h4>Related Information</h4>
                  <ul>
                  {modalData.uxinsightslist.map((item, index) => (
                    <li key={index}>
                      {item}
                    </li>
                  ))}
                </ul> 
              </div>

              <div className="Comntbtn">
                <button onClick={() => handleRedirect(modalData.guideline)}> Guideline #{modalData.guidelineHashNumber}</button>
              </div>

            </div>

            ) : (
              <p>Loading...</p>
            )}
            <button className="CrossBtn" onClick={() => setIsModalOpen(false)}><ClearIcon/></button>

          </div>
            
        </div>
      )}

          {/* Modal Overlay */}
          {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={() => setIsModalOpen(false)}
        />
      )}
    </>
    
  );
};

export default CustomContent;
