import "./EditorEnhance.css"
class EmptyBlock {
    static get toolbox() {
      return {
        title: 'Empty Space',
        icon: '<svg>...</svg>' // some simple icon
      };
    }
  
    render() {
      const div = document.createElement('div');
      div.className = 'empty-block';
      div.innerHTML = '&nbsp;'; // visually empty
      return div;
    }
  
    save() {
      return {}; // no data
    }
  }

export default EmptyBlock;
