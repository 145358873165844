import {
  Link, Router,
} from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useNavigate, useParams } from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

// Data
import authorsTableData from "layouts/guidelineSubTypeList/data/authorsTableData";

function Tables() {
  const { id } = useParams();
  const { columns, rows, singleCategory } = authorsTableData(id);

  
  const categoryName = singleCategory.length > 0 ? singleCategory[0].name : "";

  return (
    <DashboardLayout>
      <section className="FilterSec">

        <div className="Add_Guide_Sec">

          <div className="FilterHead">
            <h2>Sub Types List</h2>
          </div>

          <div className="ResrchBtn mt-5">
            <div className="guidLink" >
              <Link to={`/guideline-types`}><ArrowBackIcon/> Back</Link>
            </div>
            <div className="guidLink" >
              <Link to={`/guidelinesubtype-add/${id}`}><AddIcon/>Add Sub Type</Link>
            </div>
          </div>

          <div className="GuidlineForm">

            <div className='GuideCateDiv'>
              <DataTable
                table={{ columns, rows }}
                //isSorted={false}
                canSearch={true}
                entriesPerPage={false}
                pagination={false}
                showTotalEntries={false}
                noEndBorder
              />
            </div>

          </div>

        </div>

      </section>

    </DashboardLayout>
  );
}

export default Tables;
