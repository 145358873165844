/**
=========================================================
* Balearen Whisper React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useNavigate, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Balearen Whisper React components
import MDBox from "components/MDBox";

// Balearen Whisper React context
import { useMaterialUIController, setLayout } from "context";

function DashboardLayout({ children }) {
  let navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    if (!authUser) {
      navigate("/authentication/sign-in");
    }
  }, []);

  return (

    <MDBox
          sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
            position: "relative",
            width: "calc(100vw - 250px)", // Adjust width to account for the sidebar
            height: "100vh", // Full height of the viewport
            marginLeft: "250px", // Sidebar width offset
            overflowY: "auto", // Enables vertical scrolling if content exceeds height
            overflowX: "hidden", // Prevent horizontal scrolling

            [breakpoints.up("xl")]: {
              marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
              width: miniSidenav ? `calc(100vw - ${pxToRem(120)})` : `calc(100vw - ${pxToRem(274)})`,
              transition: transitions.create(["margin-left", "margin-right"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
              }),
            },
          })}
        >
          <div className="DashContent">
            {children}
          </div>

      </MDBox>



    // <MDBox
    //   sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        
    //     position: "relative",

    //     [breakpoints.up("xl")]: {
    //       marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
    //       transition: transitions.create(["margin-left", "margin-right"], {
    //         easing: transitions.easing.easeInOut,
    //         duration: transitions.duration.standard,
    //       }),
    //     },
    //   })}
    // >
    //   {children}
    // </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
