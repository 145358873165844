import './ColorTool.css';

class ColorTool {
    constructor({ api }) {
        this.api = api;
        this.button = null;
        this.state = false; // To track tool's active state
        this.currentColor = '#000000'; // Default text color
        this.currentBackgroundColor = '#FFFFFF'; // Default background color
        this.colorPickerInput = null; // To store the color input element
        this.backgroundPickerInput = null; // To store the background color input element
        this.isBackgroundColor = false; // To toggle between text color and background color mode
    }

    static get isInline() {
        return true; // Indicates that this tool is an inline tool//
    }

    render() {
        //this.button = document.createElement('button');
        //this.button.type = 'button';
        // this.button.classList.add('color-tool-button');
        // this.button.innerHTML = 'A'; // Text icon for color picker

        // this.button.addEventListener('click', (event) => {
        //     event.stopPropagation(); // Prevents closing the modal when clicking inside
        //     this.state = !this.state;
        //     this.state ? this.showColorPicker(event) : this.hideColorPicker();
        // });

        //return this.button;
        const container = document.createElement('div');
        return container;
    }

    showColorPicker(event) {
        console.log("showColorPickershowColorPicker");

        // Remove any existing picker container to avoid duplicates
        const existingPicker = document.querySelector('.picker-container');
        if (existingPicker) {
            existingPicker.remove();
        }

        // Create the container for the color pickers
        const pickerContainer = document.createElement('div');
        pickerContainer.classList.add('picker-container');

        const textLabel = document.createElement('span');
        textLabel.classList.add('color-picker-label');
        textLabel.innerText = 'Text Color';

        const backgroundLabel = document.createElement('span');
        backgroundLabel.classList.add('color-picker-label');
        backgroundLabel.innerText = 'Background Color';

        // Create input elements for color pickers
        this.colorPickerInput = document.createElement('input');
        this.colorPickerInput.type = 'color';
        this.colorPickerInput.value = this.currentColor;
        this.colorPickerInput.classList.add('color-picker-input');
        this.colorPickerInput.addEventListener('input', () => {
            console.log("text color selected");
            this.currentColor = this.colorPickerInput.value;
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                this.surround(range);
            }
        });

        this.backgroundPickerInput = document.createElement('input');
        this.backgroundPickerInput.type = 'color';
        this.backgroundPickerInput.value = this.currentBackgroundColor;
        this.backgroundPickerInput.classList.add('color-picker-input');
        this.backgroundPickerInput.addEventListener('input', () => {
            console.log("background color selected");
            this.currentBackgroundColor = this.backgroundPickerInput.value;
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                this.surround(range);
            }
        });

        // Append the color pickers and labels to the picker container
        pickerContainer.appendChild(textLabel);
        pickerContainer.appendChild(this.colorPickerInput);
        pickerContainer.appendChild(backgroundLabel);
        pickerContainer.appendChild(this.backgroundPickerInput);

        // Create a close button (cross icon)
        const closeButton = document.createElement('button');
        closeButton.classList.add('color-picker-close-button');
        closeButton.innerHTML = '×'; // "X" symbol for close
        closeButton.addEventListener('click', (event) => {
            event.stopPropagation(); // Prevents closing on click outside
            this.hideColorPicker(); // Hide the color picker when clicked
        });

        pickerContainer.appendChild(closeButton);

        // Position the picker container below the button
        const rect = this.button.getBoundingClientRect();
        pickerContainer.style.position = 'absolute';
        pickerContainer.style.top = `${rect.bottom + window.scrollY}px`;
        pickerContainer.style.left = `${rect.left + window.scrollX}px`;

        document.body.appendChild(pickerContainer);
    }

    surround(range) {
        console.log("Surround method called");

        // Create the span element for wrapping the selected text
        const span = document.createElement('span');
        span.style.color = this.currentColor || 'rgb(197, 22, 22)'; // Set text color
        span.style.backgroundColor = this.currentBackgroundColor || '#FFFFFF'; // Set background color

        // Wrap the selected content in the span element
        range.surroundContents(span);

        console.log("Wrapped text with color:", span.outerHTML);
    }

    unwrap(range) {
        console.log("Unwrap method called");

        const startContainer = range.startContainer;
        const endContainer = range.endContainer;

        // Check if the range is already wrapped in a <span> element
        const wrappedStart = startContainer.parentElement;
        const wrappedEnd = endContainer.parentElement;

        if (wrappedStart && wrappedEnd && wrappedStart === wrappedEnd && wrappedStart.tagName === 'SPAN') {
            const unwrappedContent = document.createDocumentFragment();

            // Move all child nodes of the span element to the document fragment
            while (wrappedStart.firstChild) {
                unwrappedContent.appendChild(wrappedStart.firstChild);
            }

            // Replace the span with the unwrapped content
            wrappedStart.replaceWith(unwrappedContent);
        }
    }

    hideColorPicker() {
        const pickerContainer = document.querySelector('.picker-container');
        if (pickerContainer) {
            pickerContainer.remove(); // Remove the picker container entirely
        }

        this.state = false; // Reset the state
    }

    save(blockContent) {
        const text = blockContent.innerHTML;
        console.log("texttext",text);
        return {
            text: text,
            color: this.currentColor,
            backgroundColor: this.currentBackgroundColor
        };
    }

    static get sanitize() {
        return {
            b: {}, // Allow bold tags
            i: {}, // Allow italic tags
            span: {
                class: true,
                style: true, // Allow styles (color) on span
            },
        };
    }

    checkState() {
        const selection = window.getSelection();
        if (selection.rangeCount === 0) return;

        const parentElement = selection.getRangeAt(0).commonAncestorContainer;
        const color = parentElement.nodeType === 3 ? parentElement.parentNode.style.color : parentElement.style.color;

        this.state = !!color;
    }

    get shortcut() {
        return 'CMD+M';
    }
}

export default ColorTool;
