import { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/Signbg.jpg";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Basic() {
  let navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false); // State to control OTP field visibility
  const [otpErrors, setOtpErrors] = useState(""); // State for OTP errors
  const [email, setEmail] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [password, setPassword] = useState(""); // State for the password in modal


  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const res = await axios.get("https://api64.ipify.org?format=json");
        //console.log("Fetched IP Address:", res.data.ip); // Verify the property name
        setIpAddress(res.data.ip); // Update state with fetched IP
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
  
    fetchIpAddress();
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email: emailInput, password, IP } = e.target.elements;
    let details = {
      email: emailInput.value,
      password: password.value,
      IP: IP.value,
    };
    //console.log("Form details:", details); 
    
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/login`, details);
      console.log("res.data", res.data);
      const response = res.data;

      if (response.status === 0) {
        setErrors(response.errors);

        if (response.errors && response.errors.IP) {
          swal("Error", "Wrong IP address. You are not authorized to access this system from this IP.", "error");
          setShowModal(true); // Show modal to add IP address
          return;
        }
      } else {
        setEmail(details.email);
        //localStorage.setItem("authUser", JSON.stringify(response.data));
        swal("success", "Login Successful! Please verify OTP.", "success");
        setShowOtp(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      swal("Error", "An error occurred during login.", error.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setOtpErrors("");

    try {
      //console.log("Verifying OTP for:", email, "with OTP:", otp);

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/verifyOtp`, { email, otp });
      // console.log("OTP verification response:", JSON.stringify(response));
      // console.log("OTP verification response22:", JSON.stringify(response.data.data));

      if (response.data.status === 1) {
        swal("Success", "OTP verified successfully!", "success");
        localStorage.setItem("authUser", JSON.stringify(response?.data?.data));
        localStorage.setItem("otpVerified", "true");
        navigate("/dashboard");
      } else {
        setOtpErrors("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      setOtpErrors("An error occurred during OTP verification.");
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/resendOtp`, { email });
     
      if (response.data.status === 1) {
        swal("Success", "OTP has been resent to your email.", "success");
      } else {
        setOtpErrors("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      setOtpErrors("An error occurred while resending OTP.");
    }
  };
  const handleWhitelistIp = async () => {
    try {
      // Validation: Ensure email and password are not empty
      if (!email || !password) {
        swal("Error", "Please enter both email and password.", "error");
        return;
      }
  
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/addWhitelist_ip`, {
        email,
        password,
        IP: ipAddress,
      });
  
      if (response.data.status === 1) {
        swal("Success", "IP address added successfully. You can now log in.", "success");
        setShowModal(false); // Close modal after successful IP addition
      } else if (response.data.errors) {
        // If there are specific errors (like wrong email or password)
        if (response.data.errors.email) {
          swal("Error", "Invalid email. Please try again.", "error");
        } else if (response.data.errors.password) {
          swal("Error", "Invalid password. Please try again.", "error");
        } else {
          swal("Error", "Failed to add IP address. Please try again.", "error");
        }
      }
    } catch (error) {
      console.error("Error adding IP address:", error);
      swal("Error", "An error occurred while adding IP address.", "error");
    }
  };

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    const authUserData = authUser ? JSON.parse(authUser) : null;
    const otpVerified = localStorage.getItem("otpVerified");
    
    // console.log("authUserData------>>>>", authUserData);
    // console.log("otpVerified------>>>>", otpVerified);

    //console.log("authUserData && !otpVerified------>>>>", authUserData && !otpVerified);

    
    if (authUserData && !otpVerified) {
      setShowOtp(true);
      navigate("/verify-otp");
    } else if (authUserData && otpVerified) {
      navigate("/dashboard");
    } else {
      // Clear any existing authUser if OTP is not verified
      localStorage.removeItem("authUser");
      localStorage.removeItem("otpVerified"); // Ensure to remove otpVerified as well if exists
      navigate("/authentication/sign-in");
    }
  }, [navigate]);
  


  return (
    <>
      <BasicLayout image={bgImage}>


        <div className="SignDiv">

          <div className="proHead">
            <h2>PRO UX Admin</h2>
          </div>

          <MDBox className="FormBox" component="form" fullWidth role="form" onSubmit={showOtp ? handleVerifyOtp : handleLogin}>
              {!showOtp ? (
                <>
                  <MDBox mb={3}>
                    <MDInput name="email" type="email" label="Email" fullWidth />
                    <span className="redError">{errors.email || ""}</span>
                  </MDBox>
                  <MDBox mb={1}>
                    <MDInput name="password" type="password" label="Password" fullWidth />
                    <span className="redError">{errors.password || ""}</span>
                  </MDBox>

                  <div className="Remembrdiv">
                    <Form.Group className="SignGroup" controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    <Button type="submit"> Forgot Password?</Button>
                  </div>

                  {/* Hidden field for IP address */}
                  <input type="hidden" name="IP" value={ipAddress} />
                  <div className="SignBTN">
                    <Button className="MainBtn" type="submit"> Sign In</Button>
                  </div>
                </>
              ) : (
                <>
                  <MDBox mb={2}>
                    <MDInput
                      name="otp"
                      type="text"
                      label="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      fullWidth
                      required
                    />
                    <span className="redError">{otpErrors}</span>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" fullWidth type="submit">
                      Verify OTP
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={handleResendOtp}>
                    Resend OTP
                  </MDButton>
                </MDBox>
                </>
              )}
          </MDBox>



          

        </div>

        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
            }}
          >
            <MDBox mb={2}>
              <MDTypography variant="h5" fontWeight="medium">
                Add IP Address
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="IP Address"
                fullWidth
                value={ipAddress}
                onChange={(e) => setIpAddress(e.target.value)}
                disabled
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleWhitelistIp}>
                Add IP
              </MDButton>
            </MDBox>
          </Box>
        </Modal>

   
      </BasicLayout>


    </>
  );
}

export default Basic;