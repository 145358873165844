import Paragraph from "@editorjs/paragraph";

class MyTitleTool extends Paragraph {
  constructor({ data, api, config }) {
    super({ data, api, config }); // ✅ Call super() first

    this.api = api;
    this.data = {
      text: data?.text || "",
      margin: data?.margin || "0px",
      padding: data?.padding || "40px 0px 10px 0px",
      textColor: data?.textColor || "#B55331",
      backgroundColor: data?.backgroundColor || "",
      fontSize: data?.fontSize || "22px",
      fontStyle: data?.fontStyle || "normal",
      fontWeight: data?.fontWeight || "700",
      lineHeight: data?.lineHeight || "30px",
    };

    this.wrapper = null;
  }

  static get toolbox() {
    return {
      title: "Title",
      icon: "T",
    };
  }

  /**
   * ✅ Enables Editor.js to Convert "Title" Blocks
   */
  static get conversion() {
    return {
      export: (data) => data.text, // ✅ Convert title block to plain text
      import: (text) => ({ text }), // ✅ Convert plain text back to title block
    };
  }

  render() {
    this.wrapper = super.render(); // ✅ Uses the default paragraph renderer
    this.wrapper.classList.add("custom-title-tool");

    this.applyStyles();

    this.wrapper.addEventListener("input", (event) => {
      this.data.text = event.target.innerHTML;
    });

    return this.wrapper;
  }

  applyStyles() {
    if (!this.wrapper) return;

    this.wrapper.style.margin = this.data.margin;
    this.wrapper.style.padding = this.data.padding;
    this.wrapper.style.color = this.data.textColor;
    this.wrapper.style.backgroundColor = this.data.backgroundColor;
    this.wrapper.style.fontSize = this.data.fontSize;
    this.wrapper.style.fontStyle = this.data.fontStyle;
    this.wrapper.style.fontWeight = this.data.fontWeight;
    this.wrapper.style.lineHeight = this.data.lineHeight;
  }

  renderSettings() {
    const wrapper = document.createElement("div");

    const createColorInput = (labelText, defaultValue, onChange) => {
      const container = document.createElement("div");
      container.style.display = "flex";
      container.style.alignItems = "center";
      container.style.marginBottom = "5px";
      container.style.gap = "5px";

      const label = document.createElement("label");
      label.textContent = labelText;
      label.style.fontSize = "12px";
      label.style.marginRight = "5px";
      label.style.width = "110px";

      const textInput = document.createElement("input");
      textInput.type = "text";
      textInput.value = defaultValue;
      textInput.style.width = "70px";
      textInput.style.fontSize = "12px";
      textInput.style.textAlign = "center";

      textInput.addEventListener("input", (event) => {
        onChange(event.target.value);
        this.applyStyles();
      });

      const colorInput = document.createElement("input");
      colorInput.type = "color";
      colorInput.value = defaultValue.startsWith("#") ? defaultValue : "";
      colorInput.style.width = "40px";
      colorInput.style.cursor = "pointer";

      colorInput.addEventListener("input", (event) => {
        textInput.value = event.target.value;
        onChange(event.target.value);
        this.applyStyles();
      });

      container.appendChild(label);
      container.appendChild(textInput);
      container.appendChild(colorInput);
      return container;
    };

    wrapper.appendChild(
      createColorInput("Text Color:", this.data.textColor, (value) => {
        this.data.textColor = value;
      })
    );

    return wrapper;
  }

  save(blockContent) {
    return {
      text: this.data.text,
      margin: this.data.margin,
      padding: this.data.padding,
      textColor: this.data.textColor,
      backgroundColor: this.data.backgroundColor,
      fontSize: this.data.fontSize,
      fontStyle: this.data.fontStyle,
      fontWeight: this.data.fontWeight,
      lineHeight: this.data.lineHeight,
    };
  }
}

export default MyTitleTool;
