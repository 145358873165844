import {
  Link, Router,
} from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Data
import authorsTableData from "layouts/annotations/data/authorsTableData";

function Tables() {
  const { id } = useParams();
  const { columns, rows, annotationTypesData } = authorsTableData(id);
  const { researchtype_id, researchtypesub_id, annotation_id } = annotationTypesData;
  

  return (
    <DashboardLayout>
      
      <section className="FilterSec">
        <div className="Add_Guide_Sec">

          <div className="FilterHead">
            <h2>Annotations List</h2>
          </div>

          {/* Back Button */}
          <div className=" guidLink mt-5">
            <Link to={`/research-list/${researchtype_id}/${researchtypesub_id}`}>
              <ArrowBackIcon /> Back
            </Link>
          </div>

          <div className="GuidlineForm">
            <div className="GuideCateDiv">
              <DataTable table={{ columns, rows }} canSearch={true} entriesPerPage={false} pagination={false} showTotalEntries={false} noEndBorder />
            </div>
          </div>

        </div>
      </section>

    </DashboardLayout>
  );
}

export default Tables;
