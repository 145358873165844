import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Annotation from "react-image-annotation";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CustomContent from "./annotation/CustomContent";
import CustomContentView from "./annotation/CustomContentView";
import CustomEditor from "./annotation/CustomEditor";
import CustomEditorView from "./annotation/CustomEditorView";
import ProgressBar from "./ProgressBar.js";
import { position } from "stylis";
import borders from "assets/theme/base/borders";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import Accordion from "react-bootstrap/Accordion";
import EditorJsRenderer from "editorjs-react-renderer";
import ClearIcon from '@mui/icons-material/Clear';
import EditorData from "./annotation/EditorData.js";
import { Avatar, FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import swal from "sweetalert";

export default function ViewAsset() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [annotationscoredata, setAnnotationscoredata] = useState({});
  const [annotationlist, setAnnotationlist] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [annotation, setAnnotation] = useState({});
  const undoStackRef = useRef([]);
  const redoStackRef = useRef([]);
  const [sortOrder, setSortOrder] = useState("latest");
  const [sortedAnnotations, setSortedAnnotations] = useState([]);
  const [activeKey, setActiveKey] = useState(null); // Track the active accordion item
 



  const [modalDataList, setModalDataList] = useState(null);
  const [isModalOpenList, setIsModalOpenList] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [highestNumber, setHighestNumber] = useState(0);

  const [isAnnotationEdit, setIsAnnotationEdit] = useState(0);
  

 

  const [formData, setFormData] = useState({
      title: "",
      devicetype: "",
      researchtype_id: "",
      researchtypesub_id: "",
    });

  const complianceColors = {
    "Compliant High": "#2E9892", // Green
    "Compliant Low": "#2E9892", // Orange
    "Breach Moderate": "#E3A020", // Yellow
    "Breach High": "#D0463E", // Red
  };
  const complianceColorsBackground = {
    "Compliant High": "#2E989240", // Green
    "Compliant Low": "#2E989240", // Orange
    "Breach Moderate": "#E3A02040", // Yellow
    "Breach High": "#FF714E40", // Red
  };

  const handleToggle = (key, item) => {
    console.log("activeKey",activeKey,"key",key);
    if (activeKey === key) {
      setActiveKey(null); // Collapse if already expanded
      
      setAnnotationselected(null);
      setAnnotationselectedlist(null);
    } else {
      setActiveKey(key); // Set the new active key
      handleAnnotationClick(item, item.data.id); // Trigger your functi

     
      const targetElement = document.getElementById(`Point_${key}`);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else {
        console.error(`Element with ID "Point_${key}" not found`);
      }

      
    }
  };

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/getSingleAnnotation`,
          { id }
        );
        const annotationDetail = response.data.annotationdetail;
        const annotations = annotationDetail.annotations;

        setFormData(
          { ...formData, 
            name: annotationDetail.name, 
            title: annotationDetail.title, 
            devicetype: annotationDetail.devicetype, 
            researchtype_id: annotationDetail.researchtype_id, 
            researchtypesub_id: annotationDetail.researchtypesub_id, 

          })
        // Filter annotations with status === 1
        //const annotations = annotationDetail.annotations.filter(annotation => annotation.status === 1);

        let Compliant_High_count = 0;
        let Compliant_Low_count = 0;
        let Breach_Moderate_count = 0;
        let Breach_High_count = 0;
        let highestNumberCheck = 0;

        annotations.forEach((annotation) => {
          const compliantType = annotation.data.compliant;
          // Check and update the highest number
          if (annotation.data.number > highestNumberCheck) {
            highestNumberCheck = annotation.data.number;
          }

          switch (compliantType) {
            case "Compliant High":
              Compliant_High_count++;
              break;
            case "Compliant Low":
              Compliant_Low_count++;
              break;
            case "Breach Moderate":
              Breach_Moderate_count++;
              break;
            case "Breach High":
              Breach_High_count++;
              break;
            default:
              break;
          }
        });

        setHighestNumber(highestNumberCheck);

        let updatedScoreData = {
          ano_score: annotationDetail.score,
          ano_score_color:
            annotationDetail.scoredetail.Highest_Points_Compliance_Color,
          updatedate: new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
          }).format(new Date(annotationDetail.updatedAt)),
          Compliant_High_count,
          Compliant_Low_count,
          Breach_Moderate_count,
          Breach_High_count,
          scoredetail: annotationDetail.scoredetail,
        };

        setAnnotationscoredata(updatedScoreData);
        setAnnotationlist(annotations);
        //setImageUrl(annotations[0]?.data.imageId || "");
        setImageUrl(`${process.env.REACT_APP_BASE_URL}${annotationDetail.imageUrls[0]}`);
      } catch (error) {
        toast.error(error.message);
      }
    };
   
    fetchData();
    
    
  }, [id,refresh,refreshAdd]);

  const Box = ({ id,children, geometry, style }) => (
    <div
    id={id} // Unique ID for scrolling
      style={{
        ...style,
        position: "absolute",
        left: `${geometry.x}%`,
        top: `${geometry.y}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
      }}
    >
      {children}
    </div>
  );

  const onChange = (newAnnotation) => {
    
    setAnnotation(newAnnotation);
  };

  const onSubmit = (newAnnotation) => {
    
    const { geometry, data } = newAnnotation;
    redoStackRef.current = [];

    const newAnnotationData = {
      geometry,
      data: {
        ...data,
        id: Math.random(),
        imageId: imageUrl,
        number: annotationlist.length + 1,
      },
    };

    setAnnotationlist([...annotationlist, newAnnotationData]);
    undoStackRef.current.push(annotationlist);
    setAnnotation({});
  };

  const [annotationselected, setAnnotationselected] = useState(null);
  const [annotationselectedlist, setAnnotationselectedlist] = useState(null);
  const handleAnnotationClick = (newAnnotation, key) => {
    console.log("clickedddd");
    
    //setAnnotationselected({ key, annotation: newAnnotation });
    //setAnnotationselectedlist({ key, annotation: newAnnotation });
  };

  const handleRedirect = (guidelineid) => {
    navigate(`/edit-guideline/${guidelineid}/no`);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  useEffect(() => {
    const sorted = [...annotationlist].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      if (sortOrder === "latest") return dateB - dateA;
      return dateA - dateB;
    });
    setSortedAnnotations(sorted);
  }, [annotationlist, sortOrder]);

  function renderSelector({ annotation }) {
    console.log("renderSelectorrenderSelector", annotation);

    const { geometry } = annotation;

    if (!geometry) return null;

    return (
      <Box
        geometry={geometry}
        style={{
          //border: `solid 2px #00bcd4`,
          background: complianceColorsBackground[annotation?.data?.compliant] || '#fff',
          border: `dashed 2px ${complianceColors[annotation?.data?.compliant] || '#00bcd4'}`,
        }}
      />
    );
  }

  function renderHighlight({ annotation }) {
   
    // if (isAnnotationEdit) return;
    //console.log("renderHighlightrenderHighlight", annotation);
    const { geometry } = annotation;
    const idIntegerPart = Math.floor(geometry?.height);

    if (!geometry) return null;

    return (
      <>
       
       <Box
          className="PicnumbDiv"
          id={`Point_${annotation.data.number}`}
          key={Math.random()}
          geometry={geometry}
          style={{
          position: "relative",
          background: complianceColorsBackground[annotation.data.compliant],
          border: `dashed 2px ${complianceColors[annotation.data.compliant]}`,
          
            
          }}
        >
          <div
            //className="Numberbg"
            className={
              activeKey === annotation.data.number
                ? "Numberbg"
                : ""
            }
            style={{
              position: "absolute",
              background:
                activeKey === annotation.data.number
                ? complianceColorsBackground[annotation.data.compliant]
                : "none",
              //background: complianceColors[annotation.data.compliant],
              height: "52px",
              width: "52px",
              left: `-26px`,
              top: `-26px`,
            
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              border:
              activeKey === annotation.data.number
                ? `dashed 1.5px ${complianceColors[annotation.data.compliant]}`
                : "none",
             
            }}
          >
            {" "}
            <h6
              style={{
                borderRadius: "10000px 10000px 12px 10000px",
                background: complianceColors[annotation.data.compliant],
                height: "24px",
                width: "24px",
                // position: "absolute",
                // left: `${geometry.x-20}%`,
                // top: `${geometry.y-2}%`,
                
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #FFFFFF",
                margin: "0",
              }}
            >
              {" "}
              {annotation.data.number}
            </h6>{" "}
          </div>
          
        </Box>
        
      </>
    );
  }

  // Function to render content
    
  const [latestAnnotation, setLatestAnnotation] = useState(annotation);
  useEffect(() => {
    if(isAnnotationEdit === 0)
    {
      setLatestAnnotation({});
    }
    else
    {
      setLatestAnnotation(annotation);
    }
   
    
  }, [annotation]);
  function renderContent({ key, annotation }) {
    //setAnnotationselected(null); //because page hang one click
    //setAnnotationselectedlist({ key, annotation }); //because page hang one click
    console.log("renderContentrenderContent", annotation);

    //setActiveKey(annotation?.data?.number); // Set the new active key
    
   console.log("latestAnnotationlatestAnnotation",latestAnnotation);
   console.log("isAnnotationEdit",isAnnotationEdit,"annotation_data_number",annotation.data.number);
    // Directly pass the key and annotation as separate props to CustomContent
    const annotationToUse = latestAnnotation && Object.keys(latestAnnotation).length > 0 ? latestAnnotation : annotation;
    //const annotationToUse = latestAnnotation && Object.keys(latestAnnotation).length > 0 ? annotation : annotation;
  return <CustomContent 
  key={key} 
  annotation={annotationToUse} 
  setAnnotation={setAnnotation} 
  setRefresh={setRefresh} 
  isAnnotationEdit={isAnnotationEdit} 
  setIsAnnotationEdit={setIsAnnotationEdit}

  />;
  }
  const isOverlapping = (newAnnotation, existingAnnotations) => {
    // Ensure existingAnnotations is an array
    if (!Array.isArray(existingAnnotations) || existingAnnotations.length === 0) {
      return false; // No existing annotations, no overlap
    }
  
    return existingAnnotations.some((annotation) => {
      const rect1 = newAnnotation.geometry;
      const rect2 = annotation.geometry;
  
      // Check if rectangles overlap
      return !(
        rect1.x + rect1.width < rect2.x || // rect1 is to the left of rect2
        rect1.x > rect2.x + rect2.width || // rect1 is to the right of rect2
        rect1.y + rect1.height < rect2.y || // rect1 is above rect2
        rect1.y > rect2.y + rect2.height // rect1 is below rect2
      );
    });
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  
    const handleImageClick = (index, imageUrl) => {
      setSelectedImageIndex(index);
      setSelectedImage(`${process.env.REACT_APP_BASE_URL}${imageUrl}`);
    };

  function renderEditor({ key, annotation }) {
    console.log("renderEditorrenderEditor",annotation);
    if (isOverlapping(annotation, annotationlist)) {
      console.log("This area is already annotated.");
      return;
    }
    const enhancedAnnotation = {
      ...annotation,
      onChange,
    };
    

    return <CustomEditorView key={key} annotation={enhancedAnnotation} setRefreshAdd={setRefreshAdd} onChange={onChange} number={highestNumber} imageId={imageUrl}/>;
  }

  const handleSeeMoreClickList = async (number) => {
    try {
      // Show the modal
      setIsModalOpenList(true);

      // Fetch data from backend API
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getSingleAnnotationMoreDetailWithNumber`, {id,number});
      console.log("responseresponse",response);
      const { annotationdetail, annotationmore } = response.data;

      //console.log("annotationdetailannotationdetail",annotationdetail);

      let updatemodeldata = {
        //title:annotationmore.title,
        title:annotationdetail.annotations[0].data.heading,
        //subTitle:annotationmore.subTitle,
        subTitle:`Illustrates the implementation of #${annotationdetail.annotations[0].data.guidelineHashNumber}`,
        //titleDescription:annotationmore.titleDescription,
        titleDescription:annotationdetail.annotations[0].data.subtext,
        description:annotationmore.description,
        uxinsightslist:annotationmore.uxinsightslist,
        compliant:annotationdetail.annotations[0].data.compliant,
        guideline:annotationdetail.annotations[0].data.guideline,
        guidelineHashNumber:annotationdetail.annotations[0].data.guidelineHashNumber,
        desktopImages:annotationmore.desktopImages,
        
      }
      if(annotationmore.desktopImages.length > 0)
      {
        setSelectedImage(`${process.env.REACT_APP_BASE_URL}${annotationmore.desktopImages[0]}`);
      }
      setModalDataList(updatemodeldata);
      //setModalData({title:"test title", description:"test description", additionalInfo:"test additionalInfo"});
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleSubmit = async () => {
   
    // Check if title is provided
    if (!formData.name) {
      toast.error("Name is required");
      return;
    }
    if (!formData.title) {
      toast.error("Title is required");
      return;
    }
    
    if (!formData.devicetype) {
      toast.error("Device type is required");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("id", id);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("devicetype", formData.devicetype);
    
    

   
    try {
      // Send data to your backend
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/editResearchTitle`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === 1) {
        swal("Success!", "Annotation updated successfully", "success");
        //navigate(`/research-list/${formData.researchtype_id}/${formData.researchtypesub_id}`);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error saving Annotation:", error);
      toast.error("An error occurred while saving the Annotation.");
    }
   
  };
  return (
    <DashboardLayout>
      <section className="ReserchViewSec">


        <div className="ResInptF">
        
          <div className="guidFormControl">
            <h6> <strong>Research Name</strong></h6>
            <TextField  fullWidth variant="outlined" label="Name"
              value={formData.name} // Update the state value for title
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              } // Update the title in formData
              //multiline

            />
          </div>
          <div className="guidFormControl">
            <h6> <strong>Research Title</strong></h6>
            <TextField  fullWidth variant="outlined" label="Title"
              value={formData.title} // Update the state value for title
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              } // Update the title in formData
              multiline

            />
          </div>

          

          <div className="guidFormControl">
            <h6> <strong>Device Type</strong></h6>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select</InputLabel>
              <Select
                  name="devicetype"
                  value={formData.devicetype}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      devicetype: e.target.value,
                    })
                  }
                  label="For Users"
                  style={{ lineHeight: "3em" }}
                >
                <MenuItem value="">
                  <em>Select Device Type</em>
                </MenuItem>
                <MenuItem key="Desktop" value="Desktop">
                Desktop
                </MenuItem>
                <MenuItem key="Mobile" value="Mobile">
                  Mobile
                </MenuItem>
                
              </Select>
            </FormControl>
          </div>
          <div className="updtbtn">
            <Button type="button" class="myButton" data-label="Update"  onClick={() => handleSubmit()}></Button>
          </div>
          
        </div>




        <div className="TopSec">
          <ProgressBar
            good={
              annotationscoredata.Compliant_Low_count +
              annotationscoredata.Compliant_High_count
            }
            moderate={annotationscoredata.Breach_Moderate_count}
            critical={annotationscoredata.Breach_High_count}
            score={annotationscoredata.ano_score}
            score_color={annotationscoredata.ano_score_color}
            updatedDate={annotationscoredata.updatedate}
            scoredetail={annotationscoredata.scoredetail}
          />
        </div>

        <div className="BootomSec">
          <div className="LftViewAnnotate">
            <div className="topViewAnot">
              <h6>{annotationlist.length} Annotations</h6>
              <div className="ShortOrderDiv">
                <p>Sort:</p>
                <select onChange={handleSortChange} value={sortOrder}>
                  <option value="latest">Latest</option>
                  <option value="older">Older</option>
                </select>
              </div>
            </div>

            <div className="BotmViewAnot">
              <Accordion
                activeKey={activeKey}
                onSelect={(key) => setActiveKey(key)}
                
              >
                {sortedAnnotations.map((item, index) => {
                  //const eventKey = index.toString();
                  const eventKey = item?.data?.number;
                  //console.log("eventKey", eventKey);
                  return (
                    <Accordion.Item
                      style={{
                        "--dynamic-border-color":
                          complianceColors[item?.data?.compliant],
                        "--dynamic-text-color":
                          complianceColorsBackground[item?.data?.compliant],
                      }}
                      eventKey={eventKey}
                      key={item._id}
                      onClick={() => handleToggle(eventKey, item)}
                    >
                       
                      <Accordion.Header
                        style={{
                          "--dynamic-border-color":
                            complianceColors[item?.data?.compliant],
                          "--dynamic-text-color":
                            complianceColorsBackground[item?.data?.compliant],
                        }}
                      >
                        <h6
                          style={{
                            "--dynamic-border-color":
                              complianceColors[item?.data?.compliant],
                            "--dynamic-text-color":
                              complianceColors[item?.data?.compliant],
                          }}
                        >
                          {item?.data?.number}
                        </h6>
                        {activeKey === eventKey ? (
                            <>
                              {item?.data?.compliant}. Guideline #{item?.data?.guidelineHashNumber}
                            </>
                          ) : (
                            <>
                              {item?.data?.heading}
                            </>
                          )}
                        

                        
                      </Accordion.Header>
                      <Accordion.Body
                        style={{
                          "--dynamic-border-color":
                            complianceColors[item?.data?.compliant],
                          "--dynamic-text-color":
                            complianceColorsBackground[item?.data?.compliant],
                        }}
                      >
                        <div className="Acorbodyinner">
                          <div className="tpdx">
                            <h2>
                            
                            {item?.data?.heading}
                              
                            </h2>
                            <p>
                            {item?.data?.subtext}
                            </p>

                            <div className="impactefft">
                              <h4>
                                Impact: <span>{item?.data?.impact}</span>
                              </h4>
                              <h4>
                                Efforts: <span>{item?.data?.effort}</span>
                              </h4>
                            </div>
                          </div>

                          <h6>Improvements advice & more examples.</h6>

                          <div className="Comntbtn">
                            <button type="button" onClick={() => handleSeeMoreClickList(item?.data?.number)}>  See More Details <DisplaySettingsIcon/> </button>
                            
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>

          <div
            className="RytViewAnnotate"
            style={{ position: "relative", flex: 2 }}
           
          >
            
           {imageUrl && (
            <Annotation
              src={imageUrl}
              alt="Annotated Image"
              annotations={sortedAnnotations}
              className="h-[300px]"
              value={annotation}
              onChange={onChange}
              onSubmit={onSubmit}
              renderSelector={renderSelector}
              renderHighlight={renderHighlight} 
              renderContent={renderContent}
              renderEditor={renderEditor}
            />
          )}

            {/* Display content of the selected annotation */}
            {annotationselected && (
              <CustomContent
                key={annotationselected.key}
                annotation={annotationselected.annotation}
                setRefresh={setRefresh}
              />
            )}
          </div>
        </div>
      </section>


     {/* Modal Implementation */}
     {isModalOpenList && (
        <div className="ContentModalDiv"  >

          <div className="LeftModalPerview">
            <div className="innerPerviewimg">
              <img src={selectedImage} alt="" />
            </div>
          </div>

          <div className="RytModalDetails">
          
            {modalDataList ? (
            <div className="ModalInnerData">

              <div className="CustContHead">
                <div className="custTital">
                  <h4>{modalDataList.title}</h4>
                  <p>{modalDataList.subTitle}</p>
                </div>
                <div className="CustHeading">
                  <h2>{modalDataList.titleDescription}</h2>
                </div>
                <div className="Custbtn">
                  <span style={{background:"#2E9892"}}>{modalDataList.compliant}</span>
                </div>
              </div>

              <div className="CustImages">
              {modalDataList.desktopImages?.map((imageUrl, index) => (
                  <div
                  key={index}
                  className={`image-item ${selectedImageIndex === index ? 'active' : ''}`}
                  onClick={() => handleImageClick(index, imageUrl)}
                >

                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${imageUrl}`}
                      alt={`Uploaded Icon ${index + 1}`}
                    />
                  </div>
                ))}
              </div>

              <div className="CustWhyUx">
              {modalDataList && (
                  <EditorData modalData={modalDataList}/>
                )}
              </div>
              <div className="CustInfo">
                  <h4>Related Information1515</h4>
                  <ul>
                  {modalDataList.uxinsightslist.map((item, index) => (
                    <li key={index}>
                      {item}
                    </li>
                  ))}
                </ul> 
              </div>

              <div className="Comntbtn">
                <button onClick={() => handleRedirect(modalDataList.guideline)}> Guideline #{modalDataList.guidelineHashNumber}</button>
              </div>

            </div>

            ) : (
              <p>Loading...</p>
            )}
            <button className="CrossBtn" onClick={() => setIsModalOpenList(false)}><ClearIcon/></button>

          </div>
            
        </div>
      )}

          {/* Modal Overlay */}
          {isModalOpenList && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={() => setIsModalOpenList(false)}
        />
      )}

      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
}

//  {/* Display content of the selected annotation */}
//  {annotationselected && (
//   <CustomContent key={annotationselected.key} annotation={annotationselected.annotation} />
// )}
