import React from "react";
import DOMPurify from "dompurify";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Assuming you're using MUI for icons

const EditorData = ({ modalData }) => {
  return (
    <div>
     
        {modalData.description.blocks.map((block, index) => {
          const { type, data } = block;
          const sanitizedContent = DOMPurify.sanitize(data.text, { ALLOWED_ATTR: ['style'] });

          switch (type) {
            case "header":
              const HeaderTag = `h${data.level || 1}`;
              return (
                <HeaderTag
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data.text, { ALLOWED_ATTR: ['style'] })
                  }}
                />
              );

            case "paragraph":
              return (
                <p key={index} dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
              );

            case "list":
              const ListTag = data.style === "ordered" ? "ol" : "ul";
              return (
                <ListTag key={index} style={{ paddingLeft: "1.5rem", listStyleType: data.style === "ordered" ? "decimal" : "disc" }}>
                  {data.items.map((item, itemIndex) => (
                    <li key={itemIndex} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item, { ALLOWED_ATTR: ['style'] }) }} />
                  ))}
                </ListTag>
              );

            case "image":
              return (
                <img
                  key={index}
                  src={data.file.url}
                  alt={data.caption || "Image"}
                  style={{ maxWidth: "100%" }}
                />
              );

            case "quote":
              return (
                <blockquote key={index} style={{ fontStyle: "italic", margin: "1rem 0", padding: "1rem", borderLeft: "4px solid #ccc" }}>
                  {DOMPurify.sanitize(data.text, { ALLOWED_ATTR: ['style'] })}
                </blockquote>
              );

            case "table":
              return (
                <table key={index} style={{ width: "100%", borderCollapse: "collapse", margin: "1rem 0" }}>
                  <thead>
                    <tr>
                      {data.content[0].map((cell, cellIndex) => (
                        <th key={cellIndex} style={{ border: "1px solid #ccc", padding: "0.5rem" }}>
                          {DOMPurify.sanitize(cell, { ALLOWED_ATTR: ['style'] })}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.content.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex} style={{ border: "1px solid #ccc", padding: "0.5rem" }}>
                            {DOMPurify.sanitize(cell, { ALLOWED_ATTR: ['style'] })}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              );

            case "checkbox":
              return (
                <div key={index} className="ModalCheckdiv" >
                  <CheckCircleIcon style={{ fill: "#2E9892" }} />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data.checkboxText, { ALLOWED_ATTR: ['style'] })
                    }}
                  />
                </div>
              );

            default:
              return (
                <div key={index} style={{ backgroundColor: "#f5f5f5", padding: "1rem", margin: "1rem 0" }}>
                  <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
              );
          }
        })}
     
    </div>
  );
};

export default EditorData;
