import { useState, useEffect } from "react";
import axios from "axios";

export default function Data(id,subid) {

    const [singleResearchType, setSingleResearchType] = useState([]);

    async function researchTypeList() {
        var singlecatdata = [];
        if(id)
        {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}api/admin/singleResearchType`,
                    {id}
                );
    
                if (response.data.status === 1) {
                    console.log("researchtypedetail...........",response.data.researchtypedetail);
                     
                    const researchTypeName = response.data.researchtypedetail.name;
                    const pagetype = response.data.researchtypedetail.pagetype;
                    const subCategory = response.data.researchtypedetail.subCategories.find(sub => sub._id === subid);
                    const subCategoryName = subCategory ? subCategory.name : "";
                    setSingleResearchType({
                        name: researchTypeName,
                        subCategoryName: subCategoryName,
                        pagetype:pagetype,
                    });
                    
                }
            } catch (error) {
                console.error("Error fetching guideline:", error);
            }
        }
    }

    useEffect(() => {
        researchTypeList();
    }, []);

    return {
        singleResearchType,
    };
}

