import React, { useEffect, useRef } from "react";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

function Editor({ onChange, editorLoaded, name, value }) {
  useEffect(() => {
    onChange(value);
  }, []);
  return (
    <div>
      {editorLoaded ? (
        <SunEditor 
			defaultValue={value}
			setOptions={{
			  buttonList: [["bold","italic","underline","blockquote","image","link","video","audio","align","font","fontColor","fontSize","formatBlock","horizontalRule","lineHeight","list","paragraphStyle","table","textStyle","codeView"]],
		    }}
			onChange={(event) => {
				onChange(event);
			}}
		/>
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  );
}

export default Editor;