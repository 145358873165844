/**
=========================================================
* Balearen Whisper React - v2.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Import necessary libraries and components
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";

// Define the Header component
function Header({ children, name, role, profilePic }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation(); // Set initial value

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          {/* Use the dynamic profile picture URL here */}
          <MDAvatar
             src={profilePic } // Fallback to a default image if not available
            alt="profile-image"
            size="xl"
            shadow="sm"
          />
        </Grid>
        <Grid item>
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {role}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      {children}
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
  name: "Unknown User",
  role: "Unknown Role",
  // profilePic: "path/to/default" // Default profile picture path
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  role: PropTypes.string,
  profilePic: PropTypes.string, // Add profilePicture prop type
};

export default Header;
