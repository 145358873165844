import React, { useState, useEffect } from "react";
import { Tabs, Tab, Grid, Card, Button, TextField, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import swal from "sweetalert";
import Switch from "@mui/material/Switch";

import ToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/system";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

function Tables() {
  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [fetchedData, setFetchedData] = useState([]);
  const [editingFilter, setEditingFilter] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    filter: { name: "" },
  });

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#4CAF50 !important", // Green color for active state
      "& + .MuiSwitch-track": {
        backgroundColor: "#4CAF50 !important", // Green track for active state
      },
    },
    "& .MuiSwitch-switchBase": {
      color: "#f44336 !important", // Red color for inactive state
      "& + .MuiSwitch-track": {
        backgroundColor: "#f44336 !important", // Red track for inactive state
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
    },
  }));
  


  useEffect(() => {
    const savedTab = localStorage.getItem("activeTab");
    if (savedTab !== null) {
      setActiveTab(parseInt(savedTab));
    }
  }, []);

  useEffect(() => {
    fetchDataByType();
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    localStorage.setItem("activeTab", newValue);
  };

  const handleInputChange = (e, tab) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [tab]: { ...prevState[tab], [name]: value },
    }));
  };

  const handleSubmit = (tab) => {
    let apiUrl;

    if (tab === "filter") {
      apiUrl = "/api/admin/AddFilter";
    }

    if (!formData[tab].name || formData[tab].name.trim() === "") {
      swal("Error", "Name is required!", "error");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}${apiUrl}`, formData[tab])
      .then((res) => {
        const response = res.data;

        if (response.status === 1) {
          swal("Success", `${tab} added successfully!`, "success").then(() => {
            setFetchedData((prevData) => [...(prevData || []), response.industry]);
            setFormData({ filter: { name: "" } });
          });
        } else {
          setErrors(response.errors);
          swal("Error", `A ${tab} with this name already exists`, "error");
        }
      })
      .catch((error) => {
        console.error(`Error adding ${tab}:`, error);
        swal("Error", `Failed to add ${tab}.`, "error");
      });
  };

  const fetchDataByType = async () => {
    const type = "filter";

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getFilter`, { type });
      
      if (response.data.status === 1) {
        setFetchedData(response.data.filters);
      } else {
        setFetchedData([]);
        swal("Error", response.data.message || "Failed to fetch filter data.", "error");
      }
    } catch (error) {
      console.error("Error fetching filter data:", error);
      swal("Error", "An error occurred while fetching filter data.", "error");
    }
  };
  const handleAdd = (item) => {
    navigate("/filterList", { state: { filterItem: item } }); // Passing the selected item as state
  };

  const handleEdit = (item) => {
    setEditingFilter(item);
    setFormData({ filter: { name: item.name } });
    setIsEditing(true);
  };

  const handleEditSubmit = () => {
    if (!formData.filter.name.trim()) {
      swal("Error", "Name is required!", "error");
      return;
    }
  
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/admin/editFilter`, {
        id: editingFilter._id,
        name: formData.filter.name,
      })
      .then((res) => {
        if (res.data.status === 1) {
          swal("Success", "Filter updated successfully!", "success").then(() => {
            setIsEditing(false);
            setEditingFilter(null); // Clear the editing state
            setFormData({ filter: { name: "" } }); // Clear the form data
            fetchDataByType(); // Refresh the data
          });
        } else {
          swal("Error", res.data.errors.message || "Failed to update filter.", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating filter:", error);
        swal("Error", "Failed to update filter.", "error");
      });
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: {
        cancel: { text: "No", visible: true },
        confirm: { text: "Yes", visible: true, className: "btn btn-danger" },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/deleteFilter`, { id })
          .then((response) => {
            if (response.data.status === 1) {
              swal("Deleted!", "Your item has been deleted.", "success");
              fetchDataByType();
            } else {
              swal("Error", response.data.errors.message || "Failed to delete item.", "error");
            }
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            swal("Error", "Failed to delete item.", "error");
          });
      }
    });
  };

  const handleToggleStatus = (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/updateFilterStatus`, { id, status: newStatus })
      .then((response) => {
        if (response.data.status === 1) {
          swal("Success", "Status updated successfully!", "success");
          fetchDataByType();
        } else {
          swal("Error", response.data.errors.message || "Failed to update status.", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        swal("Error", "Failed to update status.", "error");
      });
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <section className="FilterSec">
        <div className="container">
          <div className="FilterDiv">

            <div className="FilterHead">
              <h2>Manage Filters</h2>
            </div>

            <Box py={3} px={2}>
              {activeTab === 0 && (
                <MDBox>
                  <TextField
                    label="Filter Name"
                    name="name"
                    value={formData.filter.name}
                    onChange={(e) => handleInputChange(e, "filter")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit("filter")}
                      sx={{ color: '#fff' }}
                    >
                      Add Filter
                    </Button>
                  </Box>

                </MDBox>
              )}
            </Box>

            <MDBox pt={4} px={4}>

              <div className="tableHead">
                <h4>List of Filters:</h4>
              </div>

              <TableContainer className="scrollbar" style={{ maxHeight: '440px', overflow: 'auto', boxShadow: 'none' }}>
                <Table className="FilterTable scrollbar">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchedData && fetchedData.length > 0 ? (
                      fetchedData.map((item, index) => (
                        <TableRow key={item._id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <MDTypography>{item.name}</MDTypography>
                          </TableCell>
                          <TableCell>
                              {/* Toggle Switch */}
                              <CustomSwitch
                                checked={item.status === 1}
                                onChange={() => handleToggleStatus(item._id, item.status)}
                                inputProps={{ "aria-label": "status toggle" }}
                              />
                              
                              {/* Status Text */}
                              {item.status === 1 ? (
                                <span style={{ marginLeft: "10px", color: "green" }}>Active</span>
                              ) : (
                                <span style={{ marginLeft: "10px", color: "red" }}>Inactive</span>
                              )}
                            </TableCell>
                          <TableCell>
                          <Button
                              onClick={() => handleAdd(item)}
                              variant="contained"
                              style={{ marginRight: '8px' }}
                              sx={{ color: '#fff' }}
                              startIcon={<AddIcon />}
                            >
                              List  {/* Update the button text to "List" */}
                            </Button>
                            <Button
                              onClick={() => handleEdit(item)}
                              variant="contained"
                              style={{ marginRight: '8px' }}
                              sx={{ color: '#fff' }}
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => handleDelete(item._id)}
                              variant="contained"
                              style={{ backgroundColor: 'red', color: 'white' }}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

            </MDBox>






          </div>


        </div>
      </section>

      
      <Dialog maxWidth="md" fullWidth open={isEditing} onClose={() => setIsEditing(false)}>
        <DialogTitle>Edit Filter</DialogTitle>
        <DialogContent>
          <TextField
            label="Filter Name"
            name="name"
            value={formData.filter.name}
            onChange={(e) => handleInputChange(e, "filter")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)} variant="contained" style={{ backgroundColor: 'red', color: 'white' }}>
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} variant="contained" sx={{ color: '#fff' }} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;