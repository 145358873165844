// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-with-text {
    margin: 10px 0;
  }
  
  .icon-text-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .icon-dropdown {
    padding: 5px;
    width: 120px;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #fff;
    font-size: 14px;
    outline: none;
  }
  
  .icon-dropdown option {
    padding: 5px;
    display: flex;
    align-items: center;
  }
  
  .icon-dropdown img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .editable-text {
    border: 1px solid #ccc;
    padding: 6px 10px;
    border-radius: 4px;
    min-width: 150px;
    outline: none;
    flex-grow: 1;
  }
.ChooseIconSelect {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.ChooseIconSelect label {
  font-size: 14px;
  font-weight: 600;
  color: #262626;
  letter-spacing: 1.1;
}`, "",{"version":3,"sources":["webpack://./src/components/Editor/CheckboxTool/CheckboxTool.css"],"names":[],"mappings":"AAAA;IACI,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,YAAY;EACd;AACF;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".icon-with-text {\n    margin: 10px 0;\n  }\n  \n  .icon-text-wrapper {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n  }\n  \n  .icon-dropdown {\n    padding: 5px;\n    width: 120px;\n    height: 32px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n    background: #fff;\n    font-size: 14px;\n    outline: none;\n  }\n  \n  .icon-dropdown option {\n    padding: 5px;\n    display: flex;\n    align-items: center;\n  }\n  \n  .icon-dropdown img {\n    width: 20px;\n    height: 20px;\n    margin-right: 10px;\n  }\n  \n  .editable-text {\n    border: 1px solid #ccc;\n    padding: 6px 10px;\n    border-radius: 4px;\n    min-width: 150px;\n    outline: none;\n    flex-grow: 1;\n  }\n.ChooseIconSelect {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n.ChooseIconSelect label {\n  font-size: 14px;\n  font-weight: 600;\n  color: #262626;\n  letter-spacing: 1.1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
