import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

// react-router-dom components
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from 'moment';

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import user from "assets/images/user.png"


export default function ViewAsset() {
    //let navigate = useNavigate();
    let { id } = useParams();
    const [data, setData] = useState(false);
    useEffect(() => {
        if(id){
            try {
                axios.post("/api/admin/getSingleInvestorDetail", {id}).then((res) => {
                    const response = res.data;
                    setData(response.investorsList);
                });
			} catch (error) {
                console.log(error);
            }
        }
    }, [id]);
    //const [status, setStatus] = useState(assetStatus);
    //setStatus({...status, assetId: assetStatus});
    //console.log(data);  
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
								View Interest Detail
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <div className="AssetViewData">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="leftSide_info">
                                                <div className="selerDetail">
                                                    <div className="profileDiv">
                                                        <USERIMG Profil={data?data[0].profilePicfUrl:user}/>
                                                        <div className="social">
                                                            <h6>Investor Info</h6>                                                    
                                                        </div>
                                                    </div>
                                                    <div className="detailsDiv">
                                                        <UserDetails UserName=" User Name:" ProfilName={data?data[0].userId.firstName+' '+data[0].userId.lastName:""}/>
                                                        <UserDetails UserName=" User Email:" ProfilName={data?data[0].userId.email:""}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="BuyerAsset updatescrollbar">
                                                <div className="buyItems">
                                                    <div className="detailsDiv">
														<UserDetails UserName=" Category:" ProfilName={data?data[0].assetCategory:""}/>
														<UserDetails UserName=" Sub Category:" ProfilName={data?data[0].subCategory.join(', '):""}/>
                                                        <UserDetails UserName=" Investment Type:" ProfilName={data?data[0].investmentType.join(', '):""}/>
                                                        <UserDetails UserName="Location:" ProfilName={data?data[0].location:""}/>
                                                        <UserDetails UserName="Sub Location:" ProfilName={data?data[0].subLocation.join(', '):""}/>
                                                        <UserDetails UserName="Sale Type:" ProfilName={data?data[0].saleType.join(', '):""}/>
                                                        <UserDetails UserName="Volume Investment:" ProfilName={data?data[0].volumeInvestment.join(', '):""}/>
                                                        <UserDetails UserName="Interests:" ProfilName={data?data[0].interests.join(', '):""}/>
                                                        
                                                        
                                                        {data && data[0].assetCategory === "Rough diamonds / Raw gold" && (
                                                        <>
                                                            <UserDetails UserName="Material:" ProfilName={data[0].material.join(', ') || ""} />
                                                            <UserDetails UserName="Quantity:" ProfilName={data[0].quantity.join(', ') || ""} />
                                                        </>
                                                        )}
                                                        

                                                        {(data && (data[0].assetCategory === "Real Estate" || data[0].assetCategory === "Yield assets")) && (
                                                        <>
                                                            <UserDetails UserName="Desired Estimate:" ProfilName={data ? data[0].desiredEstimate.join(', ') : ""} />
                                                        </>
                                                        )}
                                                        <UserDetails UserName="createdAt:" ProfilName={data?formatUTCDate(data[0].createdAt):""}/>
														
                                                        <UserDetails UserName="Detail:" ProfilName={data?data[0].furtherInfo:""}/>
													</div>
                                                </div> 
                                            </div>                                         
                                        </div>
                                    </div>
								</div>
                            </div>
                        </MDBox>
                    </Card>
                </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

function UserDetails({UserName,ProfilName}) {
    return (ProfilName!="undefined")?(<div className="userInfo">
        <h4>{UserName}</h4>
        <h6>{ProfilName}</h6>
    </div>):"";
}

function USERIMG({Profil}) {
    return <div className="userImg">
        <img src={Profil} alt="" />
    </div>;
}
function formatUTCDate(utcDate) {
    // Parse the UTC date string into a moment object
    const momentDate = moment(utcDate);
  
    // Format the date with AM/PM format
    const formattedDate = momentDate.format('MM/DD/YYYY, hh:mm A');
  
    return formattedDate;
  }