import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import swal from "sweetalert";

import Icon from "@mui/material/Icon";

import { Button, MenuItem, Select, TextField } from "@mui/material";

import { useNavigate, useParams, Link, Router } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function EditAnnotation() {
  const { id, mainid } = useParams();
  const navigate = useNavigate();

  const [guidelines, setGuidelines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompliant, setSelectedCompliant] = useState("");

  const [formData, setFormData] = useState({
    guideline: "",
    guidelineHashNumber: "",
    compliant: "",
    compliantPoint: "",
    heading: "",
    subtext: "",
    impact: "",
    effort: "",
  });

  const [formErrors, setFormErrors] = useState({
    guideline: "",
    compliant: "",
    heading: "",
    subtext: "",
    impact: "",
    effort: "",
  });

 
  const complianceColors = {
    "Compliant High": "#2E9892", // Green
    "Compliant Low": "#2E9892", // Orange
    "Breach Moderate": "#E3A020", // Yellow
    "Breach High": "#D0463E", // Red
  };
  const compliancePoints = {
    "Compliant High": 0,
    "Compliant Low": -1,
    "Breach Moderate": -3,
    "Breach High": -5,
  };

  useEffect(() => {
    // Fetch guidelines from backend API
    const fetchGuidelines = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/getAnnotationGuidelines`
        ); // Replace with your actual API endpoint
        setGuidelines(response.data.guidelines); // Assuming response.data is an array of guidelines
        setLoading(false);
      } catch (error) {
        console.error("Error fetching guidelines:", error);
        setLoading(false);
      }
    };

    // Fetch existing annotation data
    const fetchSingleAnnotation = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/getSingleAnnotationSub`,
          { id, mainid }
        );
        const { data } = response.data.annotation;

        setFormData({
          ...formData,
          guideline: data.guideline,
          guidelineHashNumber: data.guidelineHashNumber,
          compliant: data.compliant,
          compliantPoint: data.compliantPoint,
          heading: data.heading,
          subtext: data.subtext,
          impact: data.impact,
          effort: data.effort,
        });
        setSelectedCompliant(data.compliant);
      } catch (error) {
        toast.error(error);
      }
    };

    fetchGuidelines();
    fetchSingleAnnotation();
  }, [id]);

  // Check if all required fields are filled
  const validateForm = () => {
    const errors = {};
    if (!formData?.guideline) errors.guideline = "Guideline is required";
    if (!selectedCompliant) errors.compliant = "Compliance is required";
    if (!formData?.heading) errors.heading = "Heading is required";
    if (!formData?.subtext) errors.subtext = "Subtext is required";
    if (!formData?.impact) errors.impact = "Impact is required";
    if (!formData?.effort) errors.effort = "Effort is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };
  const handleSelection = (value) => {
    setSelectedCompliant(value); // Update the selected value
    const point = compliancePoints[value];
    setFormData({ ...formData, compliant: value, compliantPoint: point });
  };

  const handleSubmitComment = async () => {
    if (validateForm()) {
      // Submit form if validation passes
      console.log(id, mainid);
      const formDataToSend = new FormData();
      formDataToSend.append("id", id);
      formDataToSend.append("mainid", mainid);
      formDataToSend.append("guideline", formData.guideline);
      formDataToSend.append(
        "guidelineHashNumber",
        formData.guidelineHashNumber
      );
      formDataToSend.append("compliant", formData.compliant);
      formDataToSend.append("compliantPoint", formData.compliantPoint);
      formDataToSend.append("heading", formData.heading);
      formDataToSend.append("subtext", formData.subtext);
      formDataToSend.append("impact", formData.impact);
      formDataToSend.append("effort", formData.effort);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/updateSingleAnnotationSub`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Important for file upload
            },
          }
        );
        if (response.data.status == 1) {
          swal("Success!", "Data updated successfully", "success");
          navigate(`/annotations-list/${mainid}`);
        } else {
          swal("Error!", response.data.message, "error");
        }
      } catch (error) {
        console.error("Error saving data:", error); // Log error for debugging
        swal("Error!", "Failed to save data", "error");
      }
      console.log("success");
    }
  };

  return (
    <DashboardLayout>
      <section className="FilterSec">
        <div className="Add_Guide_Sec">
          <div className="FilterHead">
            <h2>Edit Annotation</h2>
          </div>

          {/* Back Button */}
          <div className=" guidLink mt-5">
            <Link to={`/annotations-list/${mainid}`}>
              <ArrowBackIcon /> Back
            </Link>
          </div>

          <div className="GuidlineForm">
            <form>

              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="guidFormControl">
                    <h6> <strong>Enter Heading</strong></h6>
                    <TextField
                    fullWidth
                    variant="outlined"
                    label=""
                    value={formData.heading}
                    onChange={(e) =>
                      setFormData({ ...formData, heading: e.target.value })
                    }
                    />
                    {formErrors.heading && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formErrors.heading}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="guidFormControl">
                    <h6> <strong>Enter Sub Heading</strong></h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label=""
                      value={formData.subtext}
                      onChange={(e) =>
                        setFormData({ ...formData, subtext: e.target.value })
                      }
                    />
                    {formErrors.subtext && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formErrors.subtext}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="guidFormControl">
                    <h6> <strong>Select Impact</strong></h6>
                    <Select
                      label=""
                      value={formData.impact}
                      onChange={(e) =>
                        setFormData({ ...formData, impact: e.target.value })
                      }
                      style={{ lineHeight: "3em" }}
                    >
                      <MenuItem value="">
                        <em>Select Impact</em>
                      </MenuItem>
                      <MenuItem key={1} value={"High"}>
                        High
                      </MenuItem>
                      <MenuItem key={2} value={"Medium"}>
                        Medium
                      </MenuItem>
                      <MenuItem key={3} value={"Low"}>
                        Low
                      </MenuItem>
                    </Select>

                    {formErrors.impact && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formErrors.impact}
                      </span>
                    )} 
                    
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="guidFormControl">
                    <h6> <strong>Select Efforts</strong></h6>
                    <Select
                      label=""
                      value={formData.effort}
                      onChange={(e) =>
                        setFormData({ ...formData, effort: e.target.value })
                      }
                      style={{ lineHeight: "3em" }}
                    >
                      <MenuItem value="">
                        <em>Select Efforts</em>
                      </MenuItem>
                      <MenuItem key={1} value={"High"}>
                        High
                      </MenuItem>
                      <MenuItem key={2} value={"Medium"}>
                        Medium
                      </MenuItem>
                      <MenuItem key={3} value={"Low"}>
                        Low
                      </MenuItem>
                    </Select>
                    {formErrors.effort && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formErrors.effort}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="guidFormControl mb-3">
                <h6> <strong>Assign Guideline</strong></h6>
                {loading ? (
                  <p>Loading guidelines...</p>
                ) : (
                <Select
                  label=""
                  value={formData.guideline}
                  onChange={(e) => {
                    const selectedGuideline = guidelines.find(
                      (guideline) => guideline._id === e.target.value
                    );
                    setFormData({
                      ...formData,
                      guideline: e.target.value,
                      guidelineHashNumber: selectedGuideline,
                    });
                  }}
                  style={{ lineHeight: "3em" }}
                >
                  <MenuItem value="">
                    <em>Select Assign Guideline</em>
                  </MenuItem>
                  {guidelines.map((guideline) => (
                    <MenuItem key={guideline._id} value={guideline._id}>
                      Guideline #{guideline.hashNumber}
                    </MenuItem>
                  ))}
                </Select>
                )}
                {formErrors.guideline && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formErrors.guideline}
                  </span>
                )}
              </div>

              {/* Buttons for Compliance Selection */}
              <div className="mb-3" >
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "4px",
                    display: "block",
                  }}
                >
                  Choose Compliant
                </label>
                <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
                  {[
                    "Compliant High",
                    "Compliant Low",
                    "Breach Moderate",
                    "Breach High",
                  ].map((compliance, index) => (
                    <button
                      key={index}
                      type="button"
                      style={{
                        background:
                          selectedCompliant === compliance
                            ? complianceColors[compliance]
                            : "transparent",
                        color:
                          selectedCompliant === compliance ? "#fff" : `${complianceColors[compliance]}`,
                        border: `1px solid ${complianceColors[compliance]}`,
                        borderRadius: "4px",
                        padding: "8px",
                        cursor: "pointer",
                        flex: "1",
                      }}
                      onClick={() => handleSelection(compliance)}
                    >
                      {compliance}
                    </button>
                  ))}
                </div>
                {formErrors.compliant && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {formErrors.compliant}
                  </span>
                )}
              </div>

              <div mt={4}  mb={1} style={{ display: "flex", justifyContent: "center", padding: "50px 0px"}}>
                <Button type="submit" class="myButton"onClick={handleSubmitComment} data-label="Update"></Button>
              </div>

            </form>
          </div>
          <ToastContainer />
        </div>
      </section>
    </DashboardLayout>
  );
}
