import { Link } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

// Data
import researchTypeList from "layouts/researchs/data/researchTypeList";

function Tables() {
  const { researchTypeData } = researchTypeList();
  console.log("researchTypeData.......", researchTypeData);

  return (
    <DashboardLayout>

      <section className="FilterSec">
        <div className="Add_Guide_Sec">

          <div className="FilterHead">
            <h2>Research Types List</h2>
          </div>
          <div className="GuidlineForm">

            <div className="ReserchData">

              {researchTypeData.map((research) => (
                <div key={research._id} className="ResrchGuide" >

                  <div className="ResrchMainCategory" >
                    {/* Display research icon if available */}
                    {research.iconurl && (
                      <img src={research.iconurl} alt={research.name} />
                    )}
                    <h2>{research.name}</h2>
                  </div>
                  
                  {/* Subcategory buttons */}

                  <ul className="ResrchSubCate">
                    {research.subCategories.map((subCategory) => (
                      <li key={subCategory._id} >
                        {research?.pagetype == "Yes" ? (
                          <Link to={`/research-list/${research._id}/${subCategory._id}`}>{`${subCategory.name}`}</Link>
                        ) : (
                          <Link to={`/uxinsights-list/${research._id}/${subCategory._id}`}>{`${subCategory.name}`}</Link>
                        )}
                        
                       
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>



          </div>
        </div>
      </section>


        
    </DashboardLayout>
  );
}

export default Tables;
