import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Multiselect } from "multiselect-react-dropdown";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import CountryCity from "../../assets/countriescities.json";

export default function AddInterest() {
    let navigate = useNavigate();
    const [multiSelect, setMultiSelect] = useState(false);
    const [errors, setErrors] = useState(false);
    const [users, setUsers] = useState(false);
    const [categories, setCategories] = useState(false);
    const [categoryFields, setCategoryFields] = useState(false);
    const [quantity, setQuantity] = useState(false);
    const [cityData, setCityData] = useState(false);
    useEffect(() => {
        axios.post("/api/getAssetCategories").then((res) => {
            const response = res.data;
            if (response.status == 1) {
                setCategories(response.categoryList);
            }
        });
        axios.post("/api/allUsers").then((res) => {
            const response = res.data;
            if (response.status == 1) {
                setUsers(response.users);
            }
        });
    }, []);
    const changeCategory = (e) => {
        var value=e.target.value;
        // Wrap your code inside an event listener for DOMContentLoaded
        document.addEventListener("DOMContentLoaded", function () {
            document.querySelector(".AstForm").reset();
            document.querySelector('[name="assetCategory"]').value = value;
        });
        
        
        // setTimeout(() => {
        //     document.querySelectorAll("img.closeIcon").forEach(el=>el.click());
        // }, "1000"); 
        var assetCategoryId = e.target.value;
        var data = {
            assetCategoryId,
            userType: "Investor"
        };
        axios.post("/api/getCategoryFields", data).then((res) => {
            const response = res.data;
            if (response.status == 1) {
                var data=response.data;
                const entries = Object.entries(data);
                var responseData=[];
                for(var i=0; i<entries.length; i++){
                    console.log(entries[i]);
                    var key=entries[i][0];
                    if(key=="investmentTypeList"){
                        var value=entries[i][1];
                        var val=[];
                        for(var j=0; j<value.length; j++){
                            val.push(value[j].investmentType);
                        }
                        responseData[key]=val;
                    }
                    if(key=="investmentVolumeList"){
                        var value=entries[i][1];
                        var val=[];
                        for(var j=0; j<value.length; j++){
                            val.push(value[j].investmentVolume);
                        }
                        responseData[key]=val;
                    }
                    if(key=="DesiredYieldList"){
                        var value=entries[i][1];
                        var val=[];
                        for(var j=0; j<value.length; j++){
                            val.push(value[j].name);
                        }
                        responseData[key]=val;
                    }
                    if(key=="assetTypeList"){
                        var value=entries[i][1];
                        var val=[];
                        for(var j=0; j<value.length; j++){
                            val.push(value[j].assetType);
                        }
                        responseData[key]=val;
                    }
                    if(key=="materialQuantityList"){
                        var value=entries[i][1];
                        var val=[];
                        var val1=[];
                        for(var j=0; j<value.length; j++){
                            val.push(value[j]._id);
                            val1[value[j]._id]=value[j].quantity;
                        }
                        responseData[key]=val;
                        responseData["quantity"]=val1;
                    }
                    if(key=="saleTypeList"){
                        var value=entries[i][1];
                        var val=[];
                        for(var j=0; j<value.length; j++){
                            val.push(value[j].saleType);
                        }
                        responseData[key]=val;
                    }
                    if(key=="serviceList"){
                        var value=entries[i][1];
                        var val=[];
                        for(var j=0; j<value.length; j++){
                            val.push(value[j].service);
                        }
                        responseData[key]=val;
                    }
                }
                setCategoryFields(responseData);
            }
        });
    };
    const changeMaterialQuantity = (e) => {
        if (e.target.value == "Rough diamonds") {
            setQuantity(categoryFields.materialQuantityList[0].quantity);
        }
        else {
            setQuantity(categoryFields.materialQuantityList[1].quantity);
        }
    };
    const changeLocation = (e) => {
        var index = (e.target.selectedIndex) - 1;
        setCityData((CountryCity[index].cities).map((val, key) => (val.name)));
        setTimeout(() => {
            document.addEventListener("DOMContentLoaded", function () {
                document.querySelector('[name="subLocation"]').value="";
            });
            
        }, "1000");
    };
    const setPreMultiSelect = (e) => {
        var qty=[];
        for(var i=0; i<e.length; i++){
            var material=categoryFields.quantity[e[i]];
            for(var j=0; j<material.length; j++){
                qty.push(material[j].name);
            }
        }
        setQuantity(qty);
        setMultiSelect({...multiSelect, material: e});
    };
    const submitData = (e) => {
        e.preventDefault();
        //var { userId, assetName, assetCategory, investmentType, desiredYield, subCategory, material, quantity, location, saleType, sellingPrice  } = e.target;
        var { userId, assetCategory, assetCategoryId, investmentType, volumeInvestment, material, quantity, desiredEstimate, subCategory, location, subLocation, saleType, interests } = e.target;
        var form = e.target;
        // Access and process each select element individually
        var assetCategorySelect = form.querySelector('#assetCategory');
        var assetCategorySelectedOption = assetCategorySelect.options[assetCategorySelect.selectedIndex];
        var assetCategoryName = assetCategorySelectedOption.textContent;

        const formData = new FormData();
        formData.append("userId", userId?.value);
        formData.append("assetCategory", assetCategoryName);
        formData.append("assetCategoryId", assetCategory?.value);
        //formData.append("subCategory", subCategory?.value);
        //formData.append("investmentType", investmentType?.value);
        formData.append("location", location?.value);
        formData.append("locationIndex", ((location?.selectedIndex) - 1));
        //formData.append("saleType", saleType?.value);
        //formData.append("material", material?.value);
        //formData.append("quantity", quantity?.value);
        //formData.append("desiredEstimate", desiredEstimate?.value);
        //formData.append("volumeInvestment", volumeInvestment?.value);
        //formData.append("subLocation", subLocation?.value);
        //formData.append("interests", interests?.value);
        
        formData.append("volumeInvestment", multiSelect.volumeInvestment?multiSelect.volumeInvestment:"");
        formData.append("subLocation", multiSelect.subLocation?multiSelect.subLocation:"");
        formData.append("subCategory", multiSelect.subCategory?multiSelect.subCategory:"");
        formData.append("investmentType", multiSelect.investmentType?multiSelect.investmentType:"");
        formData.append("saleType", multiSelect.saleType?multiSelect.saleType:"");
        formData.append("material", multiSelect.material?multiSelect.material:"");
        formData.append("quantity", multiSelect.quantity?multiSelect.quantity:"");
        formData.append("desiredEstimate", multiSelect.desiredEstimate?multiSelect.desiredEstimate:"");
        formData.append("interests", multiSelect.interests?multiSelect.interests:"");
        //console.log(formData);
        axios.post("/api/addInterest1", formData).then((res) => {
            const response = res.data;
            console.log(response);
            if (response.status == 0) {
                if(response.alreadyAdded){
                    swal("error", response.errors.userId, "error");
                }
                setErrors(response.errors);
            } else {
                swal("success", "Interest added successfully!", "success");
                navigate("/interests");
            }
        });
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Add Interest
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <div className="formContainer">
                                    <form onSubmit={submitData} className="AstForm">
                                        {
                                            (categories.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">CHOOSE AN INVESTMENT:</label>
                                                    <select class="form-control" id="assetCategory" name="assetCategory" onChange={changeCategory}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            categories ? categories.map((category) =>
                                                                (<option data-id={category._id} data-name={category.categoryName} value={category._id}>{category.categoryName}</option>)
                                                            ) : ""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.assetCategory ? errors.assetCategory : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.investmentTypeList)?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">Select User:</label>
                                                    <select class="form-control" name="userId">
                                                        <option value="">--Select--</option>
                                                        {
                                                            users ? users.map((user) =>
                                                                (<option value={user._id}>{user.firstName} {user.lastName}</option>)
                                                            ) : ""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.userId ? errors.userId : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.investmentTypeList)?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">TYPE OF INVESTMENT:</label>
                                                    <Multiselect options={categoryFields.investmentTypeList} isObject={false} onSelect={(e) => setMultiSelect({...multiSelect, investmentType: e})} onRemove={(e) => setMultiSelect({...multiSelect, investmentType: e})}/>
                                                    {/* <select class="form-control" name="investmentType" multiple>
                                                        {
                                                            categoryFields ? (categoryFields.investmentTypeList).map((investmentType) =>
                                                                (<option>{investmentType.investmentType}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.investmentType ? errors.investmentType : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.DesiredYieldList)?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">Desired Return:</label>
                                                    <Multiselect options={categoryFields.DesiredYieldList} isObject={false} onSelect={(e) => setMultiSelect({...multiSelect, desiredEstimate: e})} onRemove={(e) => setMultiSelect({...multiSelect, desiredEstimate: e})}/>
                                                    {/* <select class="form-control" name="desiredEstimate" multiple>
                                                        {
                                                            categoryFields ? (categoryFields.DesiredYieldList).map((desiredYield) =>
                                                                (<option>{desiredYield.name}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.desiredEstimate ? errors.desiredEstimate : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.investmentVolumeList)?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">VOLUME OF INVESTMENT:</label>
                                                    <Multiselect options={categoryFields.investmentVolumeList} isObject={false} onSelect={(e) => setMultiSelect({...multiSelect, volumeInvestment: e})} onRemove={(e) => setMultiSelect({...multiSelect, volumeInvestment: e})}/>
                                                    {/* <select class="form-control" name="volumeInvestment" multiple>
                                                        {
                                                            categoryFields ? (categoryFields.investmentVolumeList).map((desiredYield) =>
                                                                (<option>{desiredYield.name}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.volumeInvestment ? errors.volumeInvestment : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.assetTypeList)?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">CATEGORY:</label>
                                                    <Multiselect options={categoryFields.assetTypeList} isObject={false} onSelect={(e) => setMultiSelect({...multiSelect, subCategory: e})} onRemove={(e) => setMultiSelect({...multiSelect, subCategory: e})}/>
                                                    {/* <select class="form-control" name="subCategory" multiple>
                                                        {
                                                            categoryFields ? (categoryFields.assetTypeList).map((assetType) =>
                                                                (<option value={assetType.assetType}>{assetType.assetType}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.subCategory ? errors.subCategory : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.materialQuantityList)?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">MATERIAL:</label>
                                                    <Multiselect options={categoryFields.materialQuantityList} isObject={false} onSelect={(e) => setPreMultiSelect(e)} onRemove={(e) => setPreMultiSelect(e)}/>
                                                    {/* <select class="form-control" name="material" onChange={changeMaterialQuantity} multiple>
                                                        {
                                                            categoryFields ? (categoryFields.materialQuantityList).map((materialQuantity) =>
                                                                (<option>{materialQuantity._id}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.material ? errors.material : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            (quantity?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">QUANTITY:</label>
                                                    <Multiselect options={quantity} isObject={false} onSelect={(e) => setMultiSelect({...multiSelect, quantity: e})} onRemove={(e) => setMultiSelect({...multiSelect, quantity: e})}/>
                                                    {/* <select class="form-control" name="quantity" onChange={changeMaterialQuantity} multiple>
                                                        {
                                                            quantity ? quantity.map((qty) =>
                                                                (<option>{qty.name}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.quantity ? errors.quantity : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.investmentTypeList)?.length > 0 && CountryCity?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">SELECT LOCATIONS:</label>
                                                    <select class="form-control" name="location" onChange={changeLocation}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            CountryCity ? CountryCity.map((location) =>
                                                                (<option>{location.name}</option>)
                                                            ) : ""
                                                        }
                                                    </select>
                                                    <span className="redError">{errors.location ? errors.location : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            (cityData?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">SELECT CITY:</label>
                                                    <Multiselect options={cityData} isObject={false} onSelect={(e) => setMultiSelect({...multiSelect, subLocation: e})} onRemove={(e) => setMultiSelect({...multiSelect, subLocation: e})}/>
                                                    {/* <select class="form-control" name="subLocation">
                                                        <option value="">--Select--</option>
                                                        {
                                                            cityData ? cityData.map((location) =>
                                                                (<option>{location.name}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.subLocation ? errors.subLocation : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.saleTypeList)?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">TYPE OF SALE:</label>
                                                    <Multiselect options={categoryFields.saleTypeList} isObject={false} onSelect={(e) => setMultiSelect({...multiSelect, saleType: e})} onRemove={(e) => setMultiSelect({...multiSelect, saleType: e})}/>
                                                    {/* <select class="form-control" name="saleType" multiple>
                                                        {
                                                            categoryFields ? (categoryFields.saleTypeList).map((saleType) =>
                                                                (<option>{saleType.saleType}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.saleType ? errors.saleType : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        {
                                            ((categoryFields?.serviceList)?.length > 0) ? (
                                                <div class="form-group">
                                                    <label for="email">SERVICES:</label>
                                                    <Multiselect options={categoryFields.serviceList} isObject={false} onSelect={(e) => setMultiSelect({...multiSelect, interests: e})} onRemove={(e) => setMultiSelect({...multiSelect, interests: e})}/>
                                                    {/* <select class="form-control" name="interests" multiple>
                                                        {
                                                            categoryFields ? (categoryFields.serviceList).map((service) =>
                                                                (<option>{service.service}</option>)
                                                            ) : ""
                                                        }
                                                    </select> */}
                                                    <span className="redError">{errors.interests ? errors.interests : ""}</span>
                                                </div>
                                            ) : ""
                                        }
                                        <button type="submit" class="btn btn-primary float-end mt-2 mb-2">Add</button>
                                    </form>
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
