import React from "react";
import { FiInfo } from "react-icons/fi";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ProgressBar = ({ good, moderate, critical, score, score_color, updatedDate, scoredetail }) => {
  const total = good + moderate + critical;

  // Calculate percentages only if `total` > 0 to avoid division by zero
  const goodPercent = total > 0 ? (good / total) * 100 : 0;
  const moderatePercent = total > 0 ? (moderate / total) * 100 : 0;
  const criticalPercent = total > 0 ? (critical / total) * 100 : 0;

  // console.log("total",total);
  // console.log("good",good,"goodPercent",goodPercent);
  // console.log("moderate",moderate,"moderatePercent",moderatePercent);
  // console.log("critical",critical,"criticalPercent",criticalPercent);
  
  const complianceColors = {
    "green": "#2E9892", // Green
    "yellow": "#E3A020", // Orange
    "yellow": "#E3A020", // Yellow
    "red": "#D0463E", //red
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return ''; // Handle empty or undefined text
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const renderTooltip = (props) => (
    
    <Tooltip id="custom-tooltip" {...props} className="custom-tooltip">
      <div className="ProgToolTipDiv" >

        <h5>Scoring Logic</h5>

        <div className="TooltipBtnDiv" >
          <div className="BtnInner">
            <h4>Compliant High</h4>
            <p>No penalty</p>
          </div>
          <div className="BtnInner">
            <h4>Compliant Low</h4>
            <p>-1 point</p>
          </div>
          <div className="BtnInner">
            <h4>Breach Moderate</h4>
            <p>-3 points</p>
          </div>
          <div className="BtnInner">
            <h4>Breach High</h4>
            <p>-5 points</p>
          </div>
        </div>

        <div className="MeasureToolp">
          <h3>How we measure</h3>
          <p>Every page starts with <strong>100 points.</strong> Subtract penalties based on instances:</p>
        </div>

        <div className="ToltipProgLine">

          <div className="ProgDiv" style={{width: "33%"}}>
            <div className="Pgsbar"  style={{  
              backgroundColor: "#2E9892",}} > 
            </div>
            <div className="toolProginner">
              <h6 style={{color:"#2E9892"}}>{Math.abs(scoredetail.Compliant_Low)/1}x Compliant Low:</h6>
              <p>-{Math.abs(scoredetail.Compliant_Low)} points</p>
            </div>
          </div>

          <div className="ProgDiv" style={{width: "33%"}}>
            <div className="Pgsbar" style={{ 
                backgroundColor: "#E3A020",}}> 
            </div>
            <div className="toolProginner">
              <h6 style={{color:"#E3A020"}}>{Math.abs(scoredetail.Breach_Moderate)/3}x Breach Low:</h6>
              <p>-{Math.abs(scoredetail.Breach_Moderate)} points</p>
            </div>
                  
          </div>

          <div className="ProgDiv" style={{width: "33%"}}>
            <div className="Pgsbar" style={{ 
              backgroundColor: "#D0463E", }}> 
            </div>
            <div className="toolProginner">
              <h6 style={{color:"#D0463E"}}>{Math.abs(scoredetail.Breach_High)/5}x Breach High:</h6>
              <p>-{Math.abs(scoredetail.Breach_High)} points</p>
            </div>
            
          </div>

        </div>

        <div className="FinalScoreDiv"  >
          <h6>Final score:</h6>
          <span style={{background:complianceColors[score_color]}}>{score}</span> 
        </div>
        <div className="finalColor" >
          <p>Final Color: {capitalizeFirstLetter(score_color)} (due to "{scoredetail.Highest_Points_Compliance}" issues).</p>
        </div>

      </div>
    </Tooltip>
  );





  return (
    <>

    <div className="Progressdata">

      <div className="ProgressbarDiv" >
        <div className="progressToken" style={{background:complianceColors[score_color]}}>
          <h6>{score}</h6>
        </div>
        <div className="Topprogress">
          <div className="ProgressLines" >
            {/* Render Good Section */}
            {good > 0 && (
              <>
                <div className="ProgDiv" style={{width: `${goodPercent}%`}}>
                  <div className="Pgsbar"  style={{  // width: `${goodPercent}%`,
                    backgroundColor: "#2E9892",}} > </div>
                  {good > 0 && <span>{goodPercent.toFixed(0)}% Good ({good})</span>}
                </div>
              
              </>
            )}
            {/* Render Moderate Section */}
            {moderate > 0 && (
              <> 
                <div className="ProgDiv" style={{width: `${moderatePercent}%`}}>
                  <div className="Pgsbar" style={{ //width: `${moderatePercent}%`,
                      backgroundColor: "#E3A020",
                    }}> 
                  </div>
                  {moderate > 0 && (<span>{moderatePercent.toFixed(0)}% Moderate ({moderate})</span>)}
                </div>
              </>  
            )}
            {/* Render Critical Section */}
            {critical > 0 && (
              <div className="ProgDiv" style={{width: `${criticalPercent}%`}}>
                <div className="Pgsbar" style={{ // width: `${criticalPercent}%`, 
                  backgroundColor: "#D0463E", }}> </div>
                {critical > 0 && ( <span>{criticalPercent.toFixed(0)}% Critical ({critical})</span>)}
              </div>
            )}
          </div>
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <button aria-describedby="custom-tooltip"><FiInfo /></button>
          </OverlayTrigger>
        </div>
      </div>

      <div className="BottomProgress">
        <h6> <CheckCircleIcon/>  Last Updated on {updatedDate}.</h6>
      </div>


    </div>

    

    </>
  );
};

export default ProgressBar;
