import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import ImageTool from "@editorjs/image";
import Table from "@editorjs/table";

import CodeTool from "@editorjs/code";
import Card from "@mui/material/Card";
import HorizontalLine from "components/Editor/HorizontalLine";
import CarouselTool from "components/Editor/CarouselTool/CarouselTool.js";
import CheckboxTool from "components/Editor/CheckboxTool/CheckboxTool.js";

import MDTypography from "components/MDTypography";
import ColorTool from 'components/Editor/ColorTool/ColorTool.js';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete icon
import IconButton from '@mui/material/IconButton'; // Import IconButton

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Avatar,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MDBox from "components/MDBox";
import ClearIcon from '@mui/icons-material/Clear';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";



export default function EditGuidelineType() {
  const { id } = useParams();
  const navigate = useNavigate();
 

 

  const [formData, setFormData] = useState({
    name: "",
  });

 
  
  // Fetch dynamic filter types from the API and set the initial state for filters
  useEffect(() => {
  async function fetchGuidelineType() {
    if (id) {
      // Only fetch if editing
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/singlekeywordId`,
          { id }
        );
        
        if (response.data.status === 1) {
          setFormData((prevData) => ({
            ...prevData,
            ...response.data.keyworddetail, // Merge fetched data with current form data
          }));
        }
      } catch (error) {
        console.error("Error fetching guideline:", error);
      }
    }
  }
  fetchGuidelineType();
}, []);
  


 


 

  
  

  

 

  

  

  


 

  


  

  



  
  
  
  
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    if (!formData.name) {
        toast.error("Name is required");
        return;
    }
    
    console.log("formData", formData);
    const formDataToSend = new FormData();
    formDataToSend.append("id", id);
    formDataToSend.append("name", formData.name);
    
    console.log("formDataToSend", formDataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/updateKeyword`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if(response.data.status == 1)
        {
          swal("Success!", "Data updated successfully", "success");
          navigate("/search-keyword");
        }
        else
        {
            swal("Error!", response.data.message, "error");
        }
      
    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <section className="FilterSec">
        <div className="container">
          <div className="Add_Guide_Sec">
            <div className="FilterHead">
              <h2>Edit Keyword</h2>
            </div>
            <div className="guidLink mt-5" >
            <Link to={`/search-keyword`}><ArrowBackIcon/> Back</Link>
          </div>

            <div className="GuidlineForm">
              <form onSubmit={handleSubmit}>

              

                
                <div className="ThreeFormDetail">
                  
                  <div className="Fomdetail">
                    <div className="TopForm">
                      <div className="guidFormControl">
                        <h6> <strong>Name</strong></h6>
                          <TextField
                          fullWidth
                          variant="outlined"
                          label="Name"
                          value={formData.name} // Update the state value for title
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          } // Update the title in formData
                          />
                      </div>
                    </div>
                  </div>

                 
                </div>
                <div mt={4} mb={1} style={{display: "flex",justifyContent: "center",padding: "20px 0px",}}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ color: "#fff" }}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
}
