import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import EditorjsList from '@editorjs/list';
import Quote from "@editorjs/quote";
import ImageTool from "@editorjs/image";
import Table from "@editorjs/table";
import Paragraph from "@editorjs/paragraph";
import CodeTool from "@editorjs/code";
import HorizontalLine from "components/Editor/HorizontalLine";
import ColorTool from "components/Editor/ColorTool/ColorTool.js";
import CustomSpanTool from "components/Editor/CustomSpanTool/CustomSpanTool.js";
import CustomListTool from "components/Editor/CustomListTool/CustomListTool.js";
import CustomParagraphTool from "components/Editor/CustomParagraphTool/CustomParagraphTool.js";
import CustomTitleTool from "components/Editor/CustomTitleTool/CustomTitleTool.js";
import CheckboxTool from "components/Editor/CheckboxTool/CheckboxTool.js"; 

import MyTitleTool from "components/Editor/EnhanceEditor/MyTitleTool.js";
import MyListTool from "components/Editor/EnhanceEditor/MyListTool.js";
import MyCheckboxListTool from "components/Editor/EnhanceEditor/MyCheckboxListTool.js";
import ImageGalleryTool from "components/Editor/EnhanceEditor/ImageGalleryTool.js";
import EmptyBlock from "components/Editor/EnhanceEditor/EmptyBlock.js";
import QuoteInlineTool from "components/Editor/EnhanceEditor/QuoteInlineTool.js";

//import Paragraph from 'editorjs-paragraph-with-alignment';
//import Select from "react-select";


import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListItemText,
  Avatar,
  Grid,
  TextField,
  Button,
  Checkbox
} from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { RiFontSize } from "react-icons/ri";
import { RxFontFamily } from "react-icons/rx";
import { MdFormatColorText } from "react-icons/md";
import { IoMdColorFill } from "react-icons/io";
import { MdAssignment } from "react-icons/md";
import { GrBold } from "react-icons/gr";

import { 
  handleFontSizeChange, 
  handleFontFamilyChange, 
  handleFontStyleChange, 
  handleColorChange, 
  handleBackgroundColorChange,
  handleAssignGuideline,
} from "components/Editor/textEditorUtils";

export default function AddGuideline() {
  const { id, subid } = useParams();
  const navigate = useNavigate();
  const editorInstance1 = useRef(null);
  const editorInstance2 = useRef(null);

  const [formData, setFormData] = useState({
    guidelinecategory: "",
    subcategory: "",
    hashNumber: '',
    title: "",
    subtitle: '',
    pagetype: '',
    guideline: '',
    guidelineTitle: '',
    titleDescription: '',
    selectedDesktopFiles: [],
    selectedDesktopFileUrls: [],
    selectedMobileFiles: [],
    selectedMobileFileUrls: [],
    keypoints: {},
    impacts: {},
    filters: {},
    tagkeywords: "",
  });

  //const [guidelinesGlobal, setguidelinesGlobal] = useState([]);
  const [optionsGlobal, setOptionsGlobal] = useState([]);
  const [selectedOptionGlobal, setSelectedOptionGlobal] = useState(null);
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterCategories, setFilterCategories] = useState({});
  const [pageTypes, setpageTypes] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [selectfeaturedesktop, setSelectfeaturedesktop] = useState(0);
  const [selectfeaturemobile, setSelectfeaturemobile] = useState(0);
    const [selectfontsize, setSelectfontsize] = useState("");
    const [selectfontfamily, setSelectfontfamily] = useState("Arial");
    const [history, setHistory] = useState([]);
    const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);

  useEffect(() => {
    async function getGuidelinesWithCustom() {
      try {
        let fields = {
          "_id": 1,
          "title": 1,
          "guidelinecategory": 1,
          "hashNumber": 1,
          "createdAt": 1
      }
      let filters = {}
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/getGuidelinesWithCustom`,  //only global type guidelines
        { fields, filters, type:"uxglobal" }
      );
     
        if (response.data.status === 1) {
          
          
          const formattedOptions = response.data.guidelines.map((item) => ({
            value: item._id, // Use the unique identifier for the value
            label: `# ${item.hashNumber} ${item.title}`, // Use the display text for the label
          }));

         
  
          setOptionsGlobal(formattedOptions);
          

          
        }
      } catch (error) {
        console.error("Error fetching guidelines:", error);
      }
    }

    async function fetchFilterTypes() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/fetchFilterTypes`,
        { filtertype: "uxinsight" }
      );
      if (response.data.status === 1) {
        setFilterTypes(response.data.filterTypes);

        // Initialize filters object dynamically
        const filtersInit = response.data.filterTypes.reduce(
          (acc, filter) => {
            acc[filter.name] = [];
            return acc;
          },
          {}
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          filters: filtersInit,
        }));

        // Fetch filters for each filter type
        response.data.filterTypes.forEach((filter) => {
          fetchFilters(filter._id, filter.name); // Make sure to use filter.name here
        });
      }
    } catch (error) {
      console.error("Error fetching filter types:", error);
    }
  }
  async function fetchFilters(filterId, filterName) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/admin/fetchFilters`,
            { filters: filterId }
          );
          if (response.data.status === 1) {
            setFilterCategories((prevCategories) => {
              const updatedCategories = {
                ...prevCategories,
                [filterName]: response.data.subfilters,
              };
              console.log("Updated filterCategories:", updatedCategories); // Log the updated value here
              return updatedCategories;
            });
          }
        } catch (error) {
          console.error(`Error fetching filters for ${filterName}:`, error);
        }
      }

    getGuidelinesWithCustom();
    fetchFilterTypes();
    fetchPageTypes(id,subid);
  }, []);

  const handleFilterChange = (e, type) => {
    const {
      target: { value },
    } = e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      filters: {
        ...prevFormData.filters,
        [type]: typeof value === "string" ? value.split(",") : value,
      },
    }));
  };
  async function fetchPageTypes(mainid,subid) {
      try {
        let formDataToSend = {
          guidecategory:mainid,
          guidesubcategory:subid,
        }
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/getPageTypeUxinsights`,
          formDataToSend
        );
        if (response.data.status === 1) {
          const formattedOptionspagetype = response.data.pageTypes.map((item) => ({
            value: `${item.name}`, // Use the unique identifier for the value
            label: `${item.name}`, // Use the display text for the label
          }));

          setpageTypes(formattedOptionspagetype);
          
        }
      } catch (error) {
        console.error("Error fetching page Types:", error);
      }
    }
    const handleScreenChange = (selectedOption) => {
      setSelectedScreen(selectedOption);
      console.log("Selected Option:", selectedOption);
    };
  const handleGuidelineChange = (selectedOption) => {
    setSelectedOptionGlobal(selectedOption);
    console.log("Selected Option:", selectedOption);
  };

  

  // Handle Editor.js initialization when the editor is visible
  useEffect(() => {
    if (!editorInstance1.current) {
      const style1 = document.createElement("style");
      style1.innerHTML = `div#editorjs .ce-tooltip { color: black !important; }`;
      document.head.appendChild(style1);
      

      editorInstance1.current = new EditorJS({
        holder: "editorjs",
        tools: {
          title: {
            class: MyTitleTool,
            inlineToolbar: false,
          },
          list: {
            class: MyListTool,
            inlineToolbar: true,
          },
          header: {
            class: Header,
            inlineToolbar: true,
            config: {
              defaultLevel: 4, // Set default heading to H4
            }
          },
          checkbox: {
              class: CheckboxTool,
              inlineToolbar: true, // ✅ Enables inline tools inside checkbox text
          },
          checkboxlist: MyCheckboxListTool,
          // title: {
          //   class: CustomTitleTool, // ✅ Ensure this is a separate tool
          //   inlineToolbar: false,
          // },
          paragraph: {
            class: CustomParagraphTool,
              inlineToolbar: ['bold', 'italic','customSpan','quoteInline'],
            config: {
              sanitize: {
                span: {
                  'data-id': true,
                  'data-type': true,
                  'class': true,
                  style: true
                }
              }
            }
          },
          quoteInline: QuoteInlineTool,
          textColor: {
            class: ColorTool, // ✅ Register custom color tool
            shortcut: "CTRL+M",
          },
          customSpan: CustomSpanTool,
          imageGallery: {
            class: ImageGalleryTool,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
          },
          empty: EmptyBlock,
          // header: {
          //   class: Header,
          //   inlineToolbar: true,
          // },
          // list: {
          //   class: CustomListTool,
          //   inlineToolbar: ['bold', 'italic','customSpan'],
          //   config: {
          //       defaultStyle: 'ordered',
          //       nested: true,
          //   },
          // },
          // image: {
          //   class: ImageTool,
          //   config: {
          //     uploader: {
          //       async uploadByFile(file) {
          //         const formData = new FormData();
          //         formData.append("image", file);
          //         try {
          //           const { data } = await axios.post(
          //             `${process.env.REACT_APP_BASE_URL}api/admin/uploadImage`,
          //             formData,
          //             {
          //               headers: { "Content-Type": "multipart/form-data" },
          //             }
          //           );
          //           return { success: 1, file: { url: data.file.url } };
          //         } catch (error) {
          //           console.error("Image upload failed:", error);
          //           return { success: 0 };
          //         }
          //       },
          //       async uploadByUrl(url) {
          //         return { success: 1, file: { url: url } };
          //       },
          //     },
          //   },
          // },
          
          // //nestedList: NestedListTool,
          // color: ColorTool,
          // paragraph: {
          //   class: CustomParagraphTool,
          //   inlineToolbar: ['bold', 'italic','customSpan'],
          // },
        },
        onChange: async () => {
          const savedData = await editorInstance1.current.save();
          console.log("savedData1", savedData);
          setFormData((prevData) => ({ ...prevData, keypoints: savedData }));

          
        },
      });

      return () => {
        document.head.removeChild(style1);
      };
    }

    return () => {
      if (editorInstance1.current) {
        editorInstance1.current.destroy();
        editorInstance1.current = null;
      }
    };
  }, []);

  // useEffect(() => {
  //   if (!editorInstance2.current) {
  //     const style2 = document.createElement("style");
  //     style2.innerHTML = `div#editorjs2 .ce-tooltip { color: black !important; }`;
  //     document.head.appendChild(style2);

  //     editorInstance2.current = new EditorJS({
  //       holder: "editorjs2",
  //       tools: {
  //         header: {
  //           class: Header,
  //           inlineToolbar: true,
  //         },
  //         customSpan: CustomSpanTool,
  //         color: ColorTool,
  //         paragraph: {
  //           class: Paragraph,
  //           inlineToolbar: ['bold', 'italic','customSpan'],
  //         },
          
         
  //         list: {
  //           class: CustomListTool,
  //           inlineToolbar: ['bold', 'italic','customSpan'],
  //           config: {
  //               defaultStyle: 'ordered',
  //               nested: true,
  //           },
  //       },
          
          
  //       },
  //       onChange: async () => {
  //         const savedData = await editorInstance2.current.save();
  //         console.log("savedData2", savedData);
  //         //setFormData((prevData) => ({ ...prevData, impacts: savedData }));

  //         //Check if savedData is an array or an object
  //         if (Array.isArray(savedData)) {
  //           const updatedSavedData = savedData.map(block => {
  //             if (block.type === 'list' && block.backgroundColor) {
  //               block.backgroundColor = this.data?.backgroundColor || 'transparent';
  //             }
  //             return block;
  //           });

  //           console.log("Updated impacts with background color:", updatedSavedData);
  //           setFormData((prevData) => ({ ...prevData, impacts: updatedSavedData }));
  //         } else {
  //           console.log("savedData is not an array", savedData);
  //         }
  //       },
  //     });

  //     return () => {
  //       document.head.removeChild(style2);
  //     };
  //   }

  //   return () => {
  //     if (editorInstance2.current) {
  //       editorInstance2.current.destroy();
  //       editorInstance2.current = null;
  //     }
  //   };
  // }, []);

  //console.log("formDataformData",formData);
  
 

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    console.log("Selected file:", file); // Log the selected file

    if (file) {
      // First, update the formData with the selected file
      setFormData((prevState) => {
        console.log("Previous formData before image update:", prevState); // Log the previous formData
        return { ...prevState, image: file };
      });

      // Then use FileReader to generate a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        // Ensure you use prevState to avoid stale state issues
        setFormData((prevState) => {
          console.log("Previous formData before preview update:", prevState); // Log formData before updating with image preview
          return { ...prevState, imageprev: reader.result }; // Add the base64 image preview to formData
        });
        console.log("Updated formData with image preview:", reader.result); // Log the base64 preview
      };

      // Read the file as base64 data for the preview
      reader.readAsDataURL(file);
      console.log("FileReader is reading the file...");
    } else {
      console.log("No file selected");
    }
  };

  const handleDesktopImageChange = (e) => {
    const newFiles = Array.from(e.target.files); // Get newly selected files
    const newImageUrls = newFiles.map((file) => URL.createObjectURL(file)); // Create new image URLs for display

    setFormData((prevState) => ({
      ...prevState,
      // Append new files to existing files
      selectedDesktopFiles: [...prevState.selectedDesktopFiles, ...newFiles],
      // Append new URLs to existing URLs
      selectedDesktopFileUrls: [
        ...prevState.selectedDesktopFileUrls,
        ...newImageUrls,
      ],
    }));
  };

  const handleDeleteDesktopImage = (indexToDelete) => {
    const updatedFiles = formData.selectedDesktopFiles.filter(
      (_, index) => index !== indexToDelete
    );
    const updatedUrls = formData.selectedDesktopFileUrls.filter(
      (_, index) => index !== indexToDelete
    );

    setFormData((prevState) => ({
      ...prevState,
      selectedDesktopFiles: updatedFiles,
      selectedDesktopFileUrls: updatedUrls, // Update both files and URLs
    }));
  };

  const handleMobileImageChange = (e) => {
    const newFiles = Array.from(e.target.files); // Get newly selected files
    const newImageUrls = newFiles.map((file) => URL.createObjectURL(file)); // Create new image URLs for display

    setFormData((prevState) => ({
      ...prevState,
      // Append new files to existing files
      selectedMobileFiles: [...prevState.selectedMobileFiles, ...newFiles],
      // Append new URLs to existing URLs
      selectedMobileFileUrls: [
        ...prevState.selectedMobileFileUrls,
        ...newImageUrls,
      ],
    }));
  };
  const handleDeleteMobileImage = (indexToDelete) => {
    const updatedFiles = formData.selectedMobileFiles.filter(
      (_, index) => index !== indexToDelete
    );
    const updatedUrls = formData.selectedMobileFileUrls.filter(
      (_, index) => index !== indexToDelete
    );

    setFormData((prevState) => ({
      ...prevState,
      selectedMobileFiles: updatedFiles,
      selectedMobileFileUrls: updatedUrls, // Update both files and URLs
    }));
  };

  const handleCheckboxChange = (index, type) => {
    if (type == "desktop") {
      if (selectfeaturedesktop === index) {
        setSelectfeaturedesktop(0); // Deselect if the same index is clicked again
      } else {
        setSelectfeaturedesktop(index); // Select the new image by index
      }
    } else if (type == "mobile") {
      if (selectfeaturemobile === index) {
        setSelectfeaturemobile(0); // Deselect if the same index is clicked again
      } else {
        setSelectfeaturemobile(index); // Select the new image by index
      }
    }

    console.log(
      "selectfeaturedesktop",
      selectfeaturedesktop,
      "selectfeaturemobile",
      selectfeaturemobile
    );
  };

  const handleAddCategory = async (type,filter = {}) => {
        if(type === "addpagetype")
        {
          navigate(`/uxinsightsubtypePage-edit/${id}/${subid}`);
          
        }
        else if(type === "addfilter")
        {
          navigate("/filters");
          
        }
        else if(type === "addfilterlist")
        {
          navigate("/filterList", { state: { filterItem: filter } });
          
        }
      };


  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("formData.keypoints",formData.keypoints);

    // if (!selectedOptionGlobal) {
    //   toast.error("# Please select guideline");
    //   return;
    // }
    if (!formData.guideline) {
      toast.error("# Please select guideline");
      return;
    }
    if (!formData.hashNumber) {
      toast.error("# Number is required");
      return;
    }
    if (!formData.title) {
      toast.error("Title is required");
      return;
    }

    if (!formData.titleDescription) {
      toast.error("Title description is required");
      return;
    }
    

    console.log("formData", formData);
    const formDataToSend = new FormData();
    formDataToSend.append("researchtypeid", id);
    formDataToSend.append("subresearchtypeid", subid);
    // formDataToSend.append("guideline", selectedOptionGlobal.value);
    // formDataToSend.append("guidelineTitle", selectedOptionGlobal.label);
    formDataToSend.append("guideline", formData.guideline);
    formDataToSend.append("guidelineTitle", formData.guidelineTitle);
    formDataToSend.append("pagetype", formData.pagetype);
    formDataToSend.append("hashNumber", formData.hashNumber);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("tagkeywords", formData.tagkeywords);
    formDataToSend.append('subtitle', formData.subtitle);
    formDataToSend.append("titleDescription", formData.titleDescription);
    formDataToSend.append("selectfeaturedesktop", selectfeaturedesktop);
    formDataToSend.append("selectfeaturemobile", selectfeaturemobile);
    formDataToSend.append("keypoints", JSON.stringify(formData.keypoints));
    formDataToSend.append("impacts", JSON.stringify(formData.impacts));
    formDataToSend.append("filters", JSON.stringify(formData.filters));

    
    if (formData.selectedDesktopFiles.length > 0) {
      for (let i = 0; i < formData.selectedDesktopFiles.length; i++) {
        formDataToSend.append("desktopFiles", formData.selectedDesktopFiles[i]);
      }
    }

    if (formData.selectedMobileFiles.length > 0) {
      for (let i = 0; i < formData.selectedMobileFiles.length; i++) {
        formDataToSend.append("mobileFiles", formData.selectedMobileFiles[i]);
      }
    }

    if (formData.image) {
      formDataToSend.append("image", formData.image); // Append the image
    }

    console.log("formDataToSend", formDataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/addResearch`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if (response.data.status == 1) {
        swal("Success!", "Data saved successfully", "success");
        navigate(`/uxinsights-list/${id}/${subid}`);
      }
      else {
        swal("Error!", response.data.message, "error");
      }

    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <section className="FilterSec">
        <div className="container-fluid">
          <div className="Add_Guide_Sec">
            <div className="FilterHead">
              <h2>Add Insight</h2>
            </div>
            <div className="guidLink mt-5" >
              <Link to={`/uxinsights-list/${id}/${subid}`}><ArrowBackIcon/> Back</Link>
            </div>

            <div className="GuidlineForm">
              <form onSubmit={handleSubmit}>

                
              <div className="guidFormControl mb-3">
                  <h6> <strong>Select Guideline</strong></h6>
                  <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ display: "flex" }}
                >
                <InputLabel>Select </InputLabel>
                <Select
                  name="guideline"
                  value={formData.guideline}
                  // onChange={(e) =>
                  //   setFormData({
                  //     ...formData,
                  //     guideline: e.target.value,
                  //   })
                  // }
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedOption = optionsGlobal.find((option) => option.value === selectedValue); // ✅ Find selected option
              
                    setFormData({
                      ...formData,
                      guideline: selectedValue, // ✅ Store value
                      guidelineTitle: selectedOption ? selectedOption.label : "", // ✅ Store option text
                    });
                  }}
                  label="For Users"
                  style={{ lineHeight: "3em" }}
                >
                  {/* Static Options */}
                  <MenuItem value="">
                    <strong>Select Guideline  </strong>
                  </MenuItem>
                  {optionsGlobal.map((pageType) => (
                    <MenuItem key={pageType.value} value={pageType.value}>
                      {pageType.label}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                  {/* <Select
                    options={optionsGlobal} // Dynamically fetched options
                    value={selectedOptionGlobal}
                    onChange={handleGuidelineChange}
                    isSearchable={true}
                    placeholder="Select an option"
                  /> */}
              </div>
             
              <div className="Filters mb-3">
                <h6> {" "}
                  <strong>Upload Images</strong>
                </h6>
                <div className="FilterGrid">
                  
                  <div className="DeskImgDiv">

                    {/* <div className="DesktopImages">
                      {formData.selectedDesktopFileUrls?.map(
                        (imageUrl, index) => (
                          <>
                            <div className="ImgShow">
                              <Avatar
                                key={index}
                                src={imageUrl}
                                alt={`Uploaded Icon ${index + 1}`}
                                style={{
                                  width: 120,
                                  height: 100,
                                  marginRight: 0,
                                  borderRadius: 2,
                                }}
                              />
                              <div
                                className="ImgIcon"
                                onClick={() =>
                                  handleDeleteDesktopImage(index)
                                }
                              >
                                <ClearIcon />
                              </div>
                              <div className="ImgCheckbox">
                                <Checkbox
                                  checked={selectfeaturedesktop === index} 
                                  onChange={() =>
                                    handleCheckboxChange(index, "desktop")
                                  } 
                                  value={index} 
                                  inputProps={{
                                    "aria-label": `Image ${index + 1}`,
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div> */}

                    <div className="UplodeDiv">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleDesktopImageChange}
                      />
                      <div className="upldInner">
                        <span>
                          <CloudUploadIcon />
                        </span>
                        <h6>Drag & Drop files here for Desktop</h6>
                        <p>or click to browse</p>
                      </div>
                    </div>

                    
                  </div>

                  <div className="DeskImgDiv">

                    {/* <div className="DesktopImages">
                      {formData.selectedMobileFileUrls?.map(
                        (imageUrl, index) => (
                          <>
                            <div className="ImgmobShow">
                              <Avatar
                                key={index}
                                src={imageUrl}
                                alt={`Uploaded Icon ${index + 1}`}
                                style={{
                                  width: 80,
                                  height: 120,
                                  marginRight: 0,
                                  borderRadius: 2,
                                }}
                              />
                              <div
                                className="ImgIcon"
                                onClick={() => handleDeleteMobileImage(index)}
                              >
                                <ClearIcon />
                              </div>
                              <div className="ImgCheckbox">
                                <Checkbox
                                  checked={selectfeaturemobile === index} 
                                  onChange={() =>
                                    handleCheckboxChange(index, "mobile")
                                  } 
                                  value={index}
                                  inputProps={{
                                    "aria-label": `Image ${index + 1}`,
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div> */}

                    <div className="UplodeDiv">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleMobileImageChange}
                      />
                      <div className="upldInner">
                        <span>
                          <CloudUploadIcon />
                        </span>
                        <h6>Drag & Drop files here for Mobile</h6>
                        <p>or click to browse</p>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>

                  
              <div className="Fomdetail">
                <div className="TopForm">
                  
                  <div className="guidFormControl">
                    <h6> <strong>Insight Number</strong></h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="# Number"
                      type="number"
                      value={formData.hashNumber} // Update the state value for title
                      onChange={(e) =>
                        setFormData({ ...formData, hashNumber: e.target.value })
                      } // Update the title in formData
                    />
                  </div>
                  <div className="guidFormControl">
                    <h6> <strong>Insight Title</strong></h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Title"
                      value={formData.title} // Update the state value for title
                      onChange={(e) =>
                        setFormData({ ...formData, title: e.target.value })
                      } // Update the title in formData
                      multiline
                      rows={2}
                    />
                  </div>
                  <div className="guidFormControl">
                    <h6> <strong>Insight Sub Title</strong></h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Sub Title"
                      value={formData.subtitle} // Update the state value for title
                      onChange={(e) =>
                        setFormData({ ...formData, subtitle: e.target.value })
                      } // Update the subtitle in formData
                      multiline
                      rows={2}
                    />
                  </div>
                  
                </div>

                <div className="bottomForm">
                  <h6>
                    <strong>Title Description</strong>
                  </h6>
                  <TextField
                    fullWidth
                    style={{ padding: '6px 0px' }}
                    variant="outlined"
                    label="Add your description"
                    value={formData.titleDescription} // Update the state value for titleDescription
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        titleDescription: e.target.value,
                      })
                    } // Update the title description in formData
                    multiline
                    rows={4} // Optional: adjust the number of rows for a larger input field
                  />
                </div>

                <div className="Filters mb-5">

                  <div className="flthd mb-3">
                    <h6><strong>Filters</strong></h6>
                      {/* <Button type="button" class="myButton"onClick={() => handleAddCategory('addfilter')}   data-label="+ Add Filter"></Button> */}
                  </div>

                  <div className="">
                    {filterTypes.map((filter) => (
                      <div key={filter.name} className="inptbtn">
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>{filter.name}</InputLabel>
                          <Select
                            multiple
                            value={formData.filters[filter.name] || []}
                            onChange={(e) => handleFilterChange(e, filter.name)}
                            label={filter.name}
                            renderValue={(selected) => selected.join(", ")}
                            style={{ lineHeight: "3em" }}
                          >
                            {filterCategories[filter.name]?.map((category) => (
                              <MenuItem
                                key={category._id}
                                value={category.name}
                              >
                                <Checkbox
                                  checked={
                                    formData.filters[filter.name]?.indexOf(
                                      category.name
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={category.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* <Button
                          type="button"
                          class="myButton"
                          onClick={() =>
                            handleAddCategory('addfilterlist',filter)
                          }
                          data-label="+ Add"></Button> */}
                        
                      </div>
                    ))}
                  </div>

                </div>
            
              </div>
               


                <div className="guidFormControl">
                    <h6>
                      {" "}
                      <strong>Description</strong>
                    </h6>
                    <div className="EditFontDiv">
                    <div className="FontFixed">
                    
                        {/* Assign Guideline */}
                        <div className="editfont">
                          <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Assign Guideline</Tooltip>} >
                              <span>
                                <MdAssignment />
                              </span>
                          </OverlayTrigger>
                          <select onChange={(e) => handleAssignGuideline(e.target.value, editorInstance1, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setFormData,"keypoints")}>
                            <option value="">Assign Guideline</option>
                            {optionsGlobal.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                          
                          </select> 
                          
                        </div>
                      
                        {/* Font Size Selection */}
                        {/* <div className="editfont">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change Font Size</Tooltip>}
                          >
                            <span>
                              <RiFontSize />
                            </span>
                          </OverlayTrigger>
                          <select
                            onChange={(e) =>
                              handleFontSizeChange(e.target.value,editorInstance1, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setSelectfontsize, setFormData,"keypoints" )
                            }
                            value={selectfontsize}
                          >
                            <option key={0} value="">
                              Select
                            </option>
                            
                            {[...Array(13).keys()].map((i) => (
                              <option key={i + 12} value={i + 12}>
                                {i + 12} px
                              </option>
                            ))}
                          </select>
                        </div> */}

                        {/* Font Style Selection */}
                        {/* <div className="editfont">
                          <OverlayTrigger  placement="top" overlay={<Tooltip>Change Font Weight</Tooltip>} >
                            <span><GrBold /></span>
                          </OverlayTrigger>
                          <select onChange={(e) => handleFontStyleChange(e.target.value, editorInstance1, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setFormData,"keypoints")}>
                            <option value="normal">Normal</option>
                            <option value="bold">Bold</option>
                            <option value="italic">Italic</option>
                          </select>
                        </div> */}

                        {/* Font Family Selection */}
                        {/* <div className="editfont">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change Font Family</Tooltip>}
                          >
                            <span>
                              <RxFontFamily />
                            </span>
                          </OverlayTrigger>
                          <select
                            onChange={(e) =>
                              handleFontFamilyChange(e.target.value, editorInstance1, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setSelectfontfamily, setFormData,"keypoints")
                            }
                            value={selectfontfamily}
                          >
                            <option value="Arial">Arial</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Tahoma">Tahoma</option>
                            <option value="Times New Roman">
                              Times New Roman
                            </option>
                            <option value="Georgia">Georgia</option>
                            <option value="Courier New">Courier New</option>
                            <option value="Comic Sans MS">Comic Sans MS</option>
                            <option value="Impact">Impact</option>
                            <option value="Roboto">Roboto</option>
                            <option value="Open Sans">Open Sans</option>
                            <option value="Lora">Lora</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Arial Black">Arial Black</option>
                            <option value="Tahoma">Tahoma</option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="Sans-serif">Sans-serif</option>
                            <option value="Serif">Serif</option>
                          </select>
                        </div> */}

                        {/* Text Color Selection */}
                        <div className="editfont">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change Font Color</Tooltip>}
                          >
                            <span>
                              <MdFormatColorText />
                            </span>
                          </OverlayTrigger>
                          <input
                            type="color"
                            onChange={(e) => handleColorChange(e.target.value, editorInstance1, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setFormData,"keypoints")}
                          />
                        </div>

                        {/* Background Color Selection */}
                        <div className="editfont">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change Background Color</Tooltip>}
                          >
                            <span>
                              <IoMdColorFill />
                            </span>
                          </OverlayTrigger>
                          <input
                            type="color"
                            onChange={(e) =>
                              handleBackgroundColorChange(e.target.value, editorInstance1, setHistory, currentHistoryIndex, setCurrentHistoryIndex, setFormData,"keypoints")
                            }
                          />
                        </div>
                      </div>
                    <div className="EditiorDiv" id="editorjs"></div>
                      {/* Internal CSS to ensure bullets/numbers show correctly */}
                      <style>
                        {`
                        #editorjs ul.cdx-list--unordered li {
                        list-style-type: disc !important; /* Force show bullets */
                        }

                        #editorjs ul.cdx-list--ordered li {
                        list-style-type: decimal !important; /* Force show numbers for ordered lists */
                        }`}
                      </style>
                    </div>
                   
                </div>

                <div className="guidFormControl mt-5">
                  <h6>
                    {" "}
                    <strong>Add Tags</strong>
                  </h6>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Add Tags"
                    value={formData.tagkeywords} // Update the state value for title
                    onChange={(e) =>
                      setFormData({ ...formData, tagkeywords: e.target.value })
                    } // Update the title in formData
                    multiline
                    rows={2}
                  />
                </div>
                



                <div
                  mt={4}
                  mb={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ color: "#fff" }}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
}
