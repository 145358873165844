import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import ImageTool from "@editorjs/image";
import Table from "@editorjs/table";

import CodeTool from "@editorjs/code";
import Card from "@mui/material/Card";
import HorizontalLine from "components/Editor/HorizontalLine";
import CarouselTool from "components/Editor/CarouselTool/CarouselTool.js";
import CheckboxTool from "components/Editor/CheckboxTool/CheckboxTool.js";

import MDTypography from "components/MDTypography";
import ColorTool from 'components/Editor/ColorTool/ColorTool.js';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete icon
import IconButton from '@mui/material/IconButton'; // Import IconButton
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Avatar,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate,useParams,Link } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import ClearIcon from '@mui/icons-material/Clear';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import authorsTableData from "layouts/uxinsightSubTypeList/data/pageTypeTableData";

export default function EditSubGuideline() {
  const { id, subid} = useParams();
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData( id, subid);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });


  const [formDataPage, setFormDataPage] = useState({
    name: "",
  });
 


  useEffect(() => {
    async function fetchGuidelineType() {
      if (id) {
        // Only fetch if editing
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/admin/guideSubCategoryWithId`,
            { id,subid }
          );
          
          if (response.data.status === 1) {
            setFormData((prevData) => ({
              ...prevData,
             name: `${response.data.subcategory.name}`,
              description: `${response.data.subcategory.description}`,
              imageprev: `${process.env.REACT_APP_BASE_URL}${response.data.subcategory.iconurl}`,
            }));
          }
        } catch (error) {
          console.error("Error fetching guideline:", error);
        }
      }
    }
    fetchGuidelineType();
  }, []);
 
  

  

 


 

  
  

  

 

  

  

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    console.log("Selected file:", file); // Log the selected file

    if (file) {
      // First, update the formData with the selected file
      setFormData((prevState) => {
        console.log("Previous formData before image update:", prevState); // Log the previous formData
        return { ...prevState, image: file };
      });

      // Then use FileReader to generate a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        // Ensure you use prevState to avoid stale state issues
        setFormData((prevState) => {
          console.log("Previous formData before preview update:", prevState); // Log formData before updating with image preview
          return { ...prevState, imageprev: reader.result }; // Add the base64 image preview to formData
        });
        console.log("Updated formData with image preview:", reader.result); // Log the base64 preview
      };

      // Read the file as base64 data for the preview
      reader.readAsDataURL(file);
      console.log("FileReader is reading the file...");
    } else {
      console.log("No file selected");
    }
  };


 

  


  

  



  
  
  
  
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!formData.forusers) {
    //   toast.error("Please select user type");
    //   return;
    // }
    if (!formData.name) {
        toast.error("Name is required");
        return;
    }
    // if (!formData.description) {
    //   toast.error("Please select page type");
    //   return;
    // }
    
    console.log("formData", formData);
    const formDataToSend = new FormData();
    formDataToSend.append("categoryId", id);
    formDataToSend.append("subid", subid);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("description", formData.description);
    if (formData.image) {
      formDataToSend.append("icon", formData.image); // Append the image
    }
    console.log("formDataToSend", formDataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/editSubCategory`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if(response.data.status == 1)
        {
          swal("Success!", "Data saved successfully", "success");
          navigate(`/guideline-sub-types/${id}`);
        }
        else
        {
            swal("Error!", response.data.message, "error");
        }
      
    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
  };

  const handleSubmitPageType = async (e) => {
    e.preventDefault();


    if (!formDataPage.name) {
      toast.error("Name is required");
      return;
    }

    console.log("formDataPage", formDataPage);
    const formDataToSend = new FormData();
    formDataToSend.append("guidecategory", id);
    formDataToSend.append("guidesubcategory", subid);
    formDataToSend.append("name", formDataPage.name);
    console.log("formDataToSend", formDataToSend);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/addUxinsightScreens`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if (response.data.status == 1) {
        swal("Success!", "Data saved successfully", "success");
        window.location.reload();
        //navigate("/page-types");
      }
      else {
        swal("Error!", response.data.message, "error");
      }

    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      

      <section className="FilterSec">
     
        <div className="Add_Guide_Sec">
        
          <div className="FilterHead">
            <h2>Add Screens</h2>
          </div>

          <div className="guidLink mt-5" >
            <Link to={`/research-sub-type-list/${id}`}><ArrowBackIcon/> Back</Link>
          </div>

          <div className="GuidlineForm" style={{padding:"50px 0px"}}>

            <div className="GuidSubtypeDiv">

              

              <div className="RytSubtyp">

                <form onSubmit={handleSubmitPageType}>
                  <div className="guidFormControl mb-3">
                    <h6> <strong>Screen Name</strong></h6>
                      <TextField
                      fullWidth
                      variant="outlined"
                      label="Enter Name"
                      value={formDataPage.name}
                      onChange={(e) => setFormDataPage({ ...formDataPage, name: e.target.value }) }
                      />
                  </div>
                  <Button type="submit" variant="contained" color="primary" sx={{ color: "#fff" }}> + Add Screen </Button>
                </form>

                <div className='GuideCateDiv mt-5'>
                  <DataTable  table={{ columns, rows }}  canSearch={true}  entriesPerPage={false} pagination={false}  showTotalEntries={false} noEndBorder  />
                </div>

              </div>

            </div>

            

          </div>

          
          <ToastContainer />
        </div>

      </section>
    </DashboardLayout>
  );
}
