import GroupsIcon from '@mui/icons-material/Groups';
import SignIn from "layouts/authentication/sign-in";
import Dashboard from "layouts/dashboard";


///import Documents from "layouts/documents";
// import DocumentView from "layouts/documents/DocumentView";
// import Vehicles from "layouts/vehicles";
// import VehicleType from "layouts/VehicleType";
// import VehicleTypeAdd from "layouts/VehicleType/AddVehicle";
// import VehicleTypeEdit from "layouts/VehicleType/EditVehicle";
// import Users from "layouts/users";
// import ViewUser from "layouts/users/ViewUser";
// import Contents from "layouts/contents";
import AddContent from "layouts/contents/AddContent";
import EditContent from "layouts/contents/EditContent";
import ViewContent from "layouts/contents/ViewContent";
// import Rides from "layouts/rides";
// import ViewRide from "layouts/rides/ViewRide";

import Faqs from "layouts/faqs";
import AddFaqs from "layouts/faqs/AddFaqs";
import EditFaq from "layouts/faqs/EditFaq";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
//import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";

import SignUp from "layouts/authentication/sign-up";


import Assets from "layouts/assets";
import AddAsset from "layouts/assets/AddAsset";
import EditAsset from "layouts/assets/EditAsset";
import ViewAsset from "layouts/assets/ViewAsset";
import Interests from "layouts/interests";
import AddInterest from "layouts/interests/AddInterest";
import EditInterest from "layouts/interests/EditInterest";
import ViewInterest from "layouts/interests/ViewInterest";


// import Staff from "layouts/staff";
// import AddAStaff from "layouts/staff/AddStaff";
// import ViewStaff from "layouts/staff/ViewStaff";
// import Editstaff from "layouts/staff/Editstaff";


import IpWhitelist from "layouts/ipwhitelist";

import Filters from "layouts/filters";
import FilterList from "layouts/filters/filterList";

import SearchKeyword from "layouts/searchKeyword";
import SearchKeywordAdd from "layouts/searchKeyword/AddsearchKeyword";
import SearchKeywordEdit from "layouts/searchKeyword/EditsearchKeyword";

import PageTypes from "layouts/pagetypes";
import PageTypesAdd from "layouts/pagetypes/AddpageTypes";
import PageTypesEdit from "layouts/pagetypes/EditpageTypes";


import GuidelineTypes from "layouts/guidelineTypes";
import GuidelineTypeAdd from "layouts/guidelineTypes/Addguidelinetype";
import GuidelineTypeEdit from "layouts/guidelineTypes/Editguidelinetype";
import GuidelineSubTypeList from "layouts/guidelineSubTypeList";
import GuidelineSubTypeAdd from "layouts/guidelineSubTypeList/Addguidelinesubtype";
import GuidelineSubTypeEdit from "layouts/guidelineSubTypeList/Editguidelinesubtype";
import GuidelineSubTypePageEdit from "layouts/guidelineSubTypeList/Editguidelinesubtypepage";


import GuidelinesCategories from "layouts/guidelinesCategories";
import Guidelines from "layouts/guidelines";
import GuidelineAdd from "layouts/guidelines/Addguideline";
import ViewGuideline from "layouts/guidelines/ViewGuideline";
import EditGuideline from "layouts/guidelines/EditGuideline";


import ResearchTypes from "layouts/researchTypes";
import ResearchsList from "layouts/researchTypes/ResearchsList";
import ResearchTypesAdd from "layouts/researchTypes/AddresearchType";
import ResearchTypesEdit from "layouts/researchTypes/Editresearchtype";
import ResearchSubTypeList from "layouts/researchSubTypes";
import ResearchSubTypeAdd from "layouts/researchSubTypes/Addresearchsubtype";
import ResearchSubTypeEdit from "layouts/researchSubTypes/Editresearchsubtype";

import UxinsightsList from "layouts/researchTypes/UxinsightsList";
import UxinsightsSubTypePageEdit from "layouts/uxinsightSubTypeList/Edituxinsightsubtypepage";
import UxinsightsScreenEdit from "layouts/uxinsightSubTypeList/Edituxinsightscreen";

import Researchs from "layouts/researchs";
import ResearchAdd from "layouts/researchs/AddResearch";
import ResearchEdit from "layouts/researchs/EditResearch";
import ResearchAddAnnotation from "layouts/researchs/AddResearchAnnotation";
import ResearchEditAnnotation from "layouts/researchs/EditResearchAnnotation";
import ResearchViewAnnotation from "layouts/researchs/ViewResearchAnnotation";

import AnnotationsList from "layouts/annotations";
import AnnotationEdit from "layouts/annotations/EditAnnotation";
import AnnotationMoreDetail from "layouts/annotations/AnnotationMoreDetail";
// import ViewResearch from "layouts/guidelines/ViewGuideline";
// import EditResearch from "layouts/guidelines/EditGuideline";






// @mui icons
import Icon from "@mui/material/Icon";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Filters",
    key: "filters",
    icon: <Icon fontSize="small">filter_alt</Icon>,
    route: "/filters",
    component: <Filters />,
  },
  {
    type: "route",
    name: "FilterList",
    key: "filterlist",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/filterList",
    component: <FilterList />,
  },
  {
    type: "collapse",
    name: "Search Keyword",
    key: "search-keyword",
    icon: <Icon fontSize="small">search</Icon>,
    route: "/search-keyword",
    component: <SearchKeyword />,
  },
  {
    type: "route",
    name: "Search Keyword Add",
    key: "search-keyword-add",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/search-keyword-add",
    component: <SearchKeywordAdd />,
  },
  {
    type: "route",
    name: "Search Keyword Edit",
    key: "search-keyword-edit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/search-keyword-edit/:id",
    component: <SearchKeywordEdit />,
  },

  // {
  //   type: "collapse",
  //   name: "Page Types",
  //   key: "page-types",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/page-types",
  //   component: <PageTypes />,
  // },
  {
    type: "route",
    name: "Page Type Add",
    key: "page-type-add",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/page-type-add",
    component: <PageTypesAdd />,
  },
  {
    type: "route",
    name: "Page Type Edit",
    key: "page-type-edit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/page-type-edit/:id/:mainid/:subid",
    component: <PageTypesEdit />,
  },
 


  {
    type: "collapse",
    name: "Guideline Types",
    key: "guideline-types",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/guideline-types",
    component: <GuidelineTypes />,
  },
  {
    type: "route",
    name: "GuidelineTypeAdd",
    key: "guidelinetypeadd",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guidelinetype-add",
    component: <GuidelineTypeAdd />,
  },
  {
    type: "route",
    name: "GuidelineTypeEdit",
    key: "guidelinetypeedit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guidelinetype-edit/:id",
    component: <GuidelineTypeEdit />,
  },

  {
    type: "route",
    name: "Guideline Sub Types",
    key: "guideline-sub-types",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guideline-sub-types/:id",
    component: <GuidelineSubTypeList />,
  },
  {
    type: "route",
    name: "GuidelineSubTypeAdd",
    key: "guidelinesubtypeadd",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guidelinesubtype-add/:id",
    component: <GuidelineSubTypeAdd />,
  },
  {
    type: "route",
    name: "GuidelineSubTypeEdit",
    key: "guidelinesubtypeedit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guidelinesubtype-edit/:id/:subid",
    component: <GuidelineSubTypeEdit />,
  },
  {
    type: "route",
    name: "GuidelineSubTypeEdit",
    key: "guidelinesubtypeedit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guidelinesubtypePage-edit/:id/:subid",
    component: <GuidelineSubTypePageEdit />,
  },
  {
    type: "route",
    name: "UxinsightSubTypeEdit",
    key: "uxinsightsubtypeedit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/uxinsightsubtypePage-edit/:id/:subid",
    component: <UxinsightsSubTypePageEdit />,
  },
  {
    type: "route",
    name: "Uxinsight Screen Edit",
    key: "uxinsight-screen-edit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/uxinsight-screen-edit/:id/:mainid/:subid",
    component: <UxinsightsScreenEdit />,
  },




  



  
  // {
  //   type: "collapse",
  //   name: "Guideline Categories",
  //   key: "guidelines-categories",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/guidelines-categories",
  //   component: <GuidelinesCategories />,
  // },
  
  {
    type: "collapse",
    name: "Guidelines",
    key: "guidelines",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guidelines",
    component: <Guidelines />,
  },
  
  {
    type: "route",
    name: "GuidelineAdd",
    key: "guidelineadd",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/guideline-add",
    component: <GuidelineAdd />,
  },
  {
    type: "route",
    name: "View Guideline",
    key: "view-guideline",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/view-guideline/:id",
    component: <ViewGuideline />,
  },
  {
    type: "route",
    name: "Edit Guideline",
    key: "edit-guideline",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/edit-guideline/:id/:dup",
    component: <EditGuideline />,
  },



  {
    type: "collapse",
    name: "Research Types",
    key: "research-types",
    icon: <Icon fontSize="small">science</Icon>,
    route: "/research-types",
    component: <ResearchTypes />,
  },
  {
    type: "route",
    name: "Research Type Add",
    key: "research-type-add",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-type-add",
    component: <ResearchTypesAdd />,
  },
  {
    type: "route",
    name: "Research Type Edit",
    key: "research-type-edit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-type-edit/:id",
    component: <ResearchTypesEdit />,
  },

  {
    type: "route",
    name: "Research Sub Type List",
    key: "research-sub-type-list",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-sub-type-list/:id",
    component: <ResearchSubTypeList />,
  },
  {
    type: "route",
    name: "Research Sub Type Add",
    key: "research-sub-type-add",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-sub-type-add/:id",
    component: <ResearchSubTypeAdd />,
  },
  {
    type: "route",
    name: "Research Sub Type Edit",
    key: "research-sub-type-edit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-sub-type-edit/:id/:subid",
    component: <ResearchSubTypeEdit />,
  },
  

  {
    type: "collapse",
    name: "Researches",
    key: "researchs",
    icon: <Icon fontSize="small">science</Icon>,
    route: "/researchs",
    component: <Researchs />,
  },
  {
    type: "route",
    name: "Researchs List",
    key: "research-list",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-list/:id/:subid",
    component: <ResearchsList />,
  },
  {
    type: "route",
    name: "Ux Insights List",
    key: "uxinsights-list",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/uxinsights-list/:id/:subid",
    component: <UxinsightsList />,
  },
  {
    type: "route",
    name: "Research Add",
    key: "research-add",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-add-annotation/:id/:subid",
    component: <ResearchAddAnnotation />,
  },
  {
    type: "route",
    name: "Research Edit",
    key: "research-edit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-edit-annotation/:id",
    component: <ResearchEditAnnotation />,
  },
  {
    type: "route",
    name: "Research View",
    key: "research-view",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-view-annotation/:id",
    component: <ResearchViewAnnotation />,
  },
  {
    type: "route",
    name: "Annotations List",
    key: "annotations-list",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/annotations-list/:id",
    component: <AnnotationsList />,
  },
  {
    type: "route",
    name: "Research Add",
    key: "research-add",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-add/:id/:subid",
    component: <ResearchAdd />,
  },
  {
    type: "route",
    name: "Research Edit",
    key: "research-edit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/research-edit/:id/:subid/:uxid",
    component: <ResearchEdit />,
  },
  {
    type: "route",
    name: "Annotation Edit",
    key: "annotation-edit",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/annotation-edit/:id/:mainid",
    component: <AnnotationEdit />,
  },
  {
    type: "route",
    name: "Annotation More Detail",
    key: "annotation-moredetail",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/annotattion-moredetail/:id/:mainid/:isview",
    component: <AnnotationMoreDetail />,
  },
  // {
  //   type: "collapse",
  //   name: "Assets",
  //   key: "assets",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/assets",
  //   component: <Assets />,
  // },
  // {
  //   type: "route",
  //   name: "Add Asset",
  //   key: "addAsset",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/addAsset",
  //   component: <AddAsset />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Asset",
  //   key: "edit-asset",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/edit-asset/:id",
  //   component: <EditAsset />,
  // },
  // {
  //   type: "route",
  //   name: "View Asset",
  //   key: "view-asset",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/view-asset/:id",
  //   component: <ViewAsset />,
  // },
  // {
  //   type: "collapse",
  //   name: "Investment Details",
  //   key: "interests",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/interests",
  //   component: <Interests />,
  // },
  // {
  //   type: "route",
  //   name: "Add Interest",
  //   key: "addInterest",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/addInterest",
  //   component: <AddInterest />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Interest",
  //   key: "edit-interest",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/edit-interest/:id",
  //   component: <EditInterest />,
  // },
  // {
  //   type: "route",
  //   name: "View Interest",
  //   key: "view-interest",
  //   icon: <Icon fontSize="small">webasset</Icon>,
  //   route: "/view-interest/:id",
  //   component: <ViewInterest />,
  // },
  
  // {
  //   type: "collapse",
  //   name: "Users",
  //   key: "users",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/users",
  //   component: <Users />,
  // },
  // {
  //   type: "route",
  //   name: "Users",
  //   key: "users",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/users/:type",
  //   component: <Users />,
  // },
  // {
  //   type: "route",
  //   name: "Users",
  //   key: "users",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/viewUser/:id",
  //   component: <ViewUser />,
  // },
  
  // {
  //   type: "collapse",
  //   name: "Vehicle Types",
  //   key: "vehicle_types",
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/vehicleTypes",
  //   component: <VehicleType />,
   
  // },
  // {
  //   type: "route",
  //   name: "Add Vehicle",
  //   key: "add_vehicle",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/addVehicle",
  //   component: <VehicleTypeAdd />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Vehicle",
  //   key: "edit_vehicle",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/editVehicle/:id",
  //   component: <VehicleTypeEdit />,
  // },

  // {
  //   type: "collapse",
  //   name: "Vehicle/Documents",
  //   key: "driver_documents",
  //   icon: <Icon fontSize="small">description</Icon>,
  //   route: "/vehicles/:id",
  //   component: <Vehicles />,
  // },
  // {
  //   type: "route",
  //   name: "Vehicle/Documents",
  //   key: "driver_documents",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/viewDocuments/:id",
  //   component: <DocumentView />,
  // },
  // {
  //   type: "collapse",
  //   name: "Staff",
  //   key: "staff",
  //   icon: <Icon fontSize="small"><GroupsIcon /></Icon>,
  //   route: "/staff",
  //   component: <Staff />,
  // },
  // {
  //   type: "route",
  //   name: "Add Staff",
  //   key: "add-staff",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/staff/add",
  //   component: <AddAStaff />,
  // },
  // {
  //   type: "route",
  //   name: "View Staff",
  //   key: "view-staff",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/viewStaff/:id",
  //   component: <ViewStaff />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Staff",
  //   key: "Edit-staff",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/editStaff/:id",
  //   component: <Editstaff />,
  // },
  // {
  //   type: "collapse",
  //   name: "Contents",
  //   key: "contents",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/contents",
  //   component: <Contents />,
  // },
  // {
  //   type: "route",
  //   name: "Add Content",
  //   key: "add-content",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/contents/add",
  //   component: <AddContent />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Content",
  //   key: "edit-content",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/editContent/:id",
  //   component: <EditContent />,
  // },
  // {
  //   type: "route",
  //   name: "View Content",
  //   key: "view-content",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/viewContent/:id",
  //   component: <ViewContent />,
  // },
  // {
  //   type: "collapse",
  //   name: "Rides",
  //   key: "rides",
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/rides",
  //   component: <Rides />,
  // },
  // {
  //   type: "route",
  //   name: "View Ride",
  //   key: "view-ride",
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/viewRide/:id",
  //   component: <ViewRide />,
  // },
  // {
  //   type: "collapse",
  //   name: "Rides",
  //   key: "rides",
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/rides/:type",
  //   component: <Rides />,
  // },
  // {
  //   type: "collapse",
  //   name: "IpWhitelist",
  //   key: "ipwhitelist",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/ipwhitelist",
  //   component: <IpWhitelist />,
  
  // },
  // {
  //   type: "route",
  //   name: "View Whitelist",
  //   key: "view-Whitelist",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/ViewWhitelistIp/",
  //   component: <ViewWhitelistIp />,
  // },
  // {
  //   type: "route",
  //   name: "Edit Faq",
  //   key: "edit-faq",
  //   icon: <Icon fontSize="small">source</Icon>,
  //   route: "/faqs/:id",
  //   component: <EditFaq/>,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  //  {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  //  },
  
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },


];

export default routes;
