import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import swal from "sweetalert";
import { TextField, IconButton, Box,Avatar,Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from 'react-bootstrap/Button';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function Tables() {
    const navigate = useNavigate();
    const editorInstance = useRef(null);
    const [open, setOpen] = useState(false);
    const [editedCategoryName, setEditedCategoryName] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [subCategoryInputs, setSubCategoryInputs] = useState({});
    const [iconFile, setIconFile] = useState(null);
    const [currentCategoryId, setCurrentCategoryId] = useState('');
    const [imagePrev, setImagePrev] = useState('');
    const [imagePrevCategory, setImagePrevCategory] = useState('');
    const [showprevDB, setShowprevDB] = useState(true);

   
    const handleIconUploadCategory = (e) => {
        const file = e.target.files[0];
        if (file) {
            setIconFile(file); 
            setImagePrevCategory(URL.createObjectURL(file)); 
            
        }
    };
    const handleIconUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setShowprevDB(false);
            setIconFile(file);  // Save the selected file
            setImagePrev(URL.createObjectURL(file));
            
        }
    };
    const openEditModal = (category) => {
        setEditedCategoryName(category.name); // Set the name of the selected category
        setCurrentCategoryId(category._id); // Set the ID of the category to edit
        setImagePrev(category.iconurl || ''); // Set the existing icon for preview
        setOpen(true); // Open the modal
    };

    const closeEditModal = () => {
        setOpen(false);
        setEditedCategoryName('');
        setImagePrev('');
        setIconFile(null);
        setCurrentCategoryId('');
        navigate("/guidelines-categories");
    };

    // Function to fetch categories
    const fetchCategories = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/fetchCategories`);
            if (response.data.status === 1) {
                setCategories(response.data.categories);
            } else {
                console.error("Error fetching categories:", response.data.errors);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    // Fetch categories when the component mounts
    useEffect(() => {
        fetchCategories();
    }, []);

    const addCategory = async () => {
        
            if (!iconFile) {
                toast.error("Icon image is required");
                return;
            }
            if (newCategory.trim() === "") 
            {
                toast.error("Category field is required");
                return;
            }

        try {
            //const response = await axios.post('/api/admin/guideCategory', { name: newCategory });
            
            const formData = new FormData();
            formData.append('name', newCategory);
            if (iconFile) {
                formData.append('icon', iconFile);  // Append the icon file if it's selected
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/guideCategory`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',  // Set content type for file upload
                },
            });
            if (response.data.status === 1) {
                setCategories(prevCategories => [...prevCategories, response.data.newCategory]);
                setNewCategory('');
                swal("Success!", "Category saved successfully", "success");
                navigate("/guidelines-categories");
            } else {
                console.error("Error adding category:", response.data.errors);
                swal("Error!", response.data.errors, "error");
            }
        } catch (error) {
            console.error("Error adding category:", error);
            swal("Error!", error, "error");
        }
    };

    const editCategory = async () => {
            if (editedCategoryName.trim() === "") 
            {
                
                toast.error("Category field is required");
                return;
            }
        try {
            //const response = await axios.post(`/api/admin/updateCategory`, { name: newName, categoryId });
            let categoryId = currentCategoryId;
            let newName = editedCategoryName;
            const formData = new FormData();
            formData.append('name', newName);
            formData.append('categoryId', categoryId);
            if (iconFile) {
                formData.append('icon', iconFile);  // Append the icon file if it's selected
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/updateCategory`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',  // Set content type for file upload
                },
            });
            if (response.data.status === 1) {
                setCategories(categories.map(category => category._id === categoryId ? { ...category, name: newName } : category));
                closeEditModal();
                swal("Success!", "Category updated successfully", "success");
                navigate("/guidelines-categories");
                
            } else {
                console.error("Error editing category:", response.data.errors);
                swal("Error!", response.data.errors, "error");
            }
        } catch (error) {
            console.error("Error editing category:", error);
            swal("Error!", error, "error");
        }
    };

    const deleteCategory = async (categoryId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this category?");
        if (!confirmDelete) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deleteCategory`, { categoryId });
            if (response.data.status === 1) {
                setCategories(categories.filter(category => category._id !== categoryId));
                swal("Success!", "Category deleted successfully", "success");
                navigate("/guidelines-categories");
            } else {
                console.error("Error deleting category:", response.data.errors);
                swal("Error!", response.data.errors, "error");
            }
        } catch (error) {
            console.error("Error deleting category:", error);
            swal("Error!", error, "error");
        }
    };

    const handleSubCategoryInputChange = (categoryId, value) => {
        setSubCategoryInputs(prevInputs => ({
            ...prevInputs,
            [categoryId]: value, // Maintain sub-category input value for each category
        }));
    };

    const addSubCategory = async (categoryId) => {
        const subCategoryName = subCategoryInputs[categoryId] || ""; // Get the input value for the specific category
        if (subCategoryName.trim() === "") 
        {
            toast.error("Sub Category field is required");
            return;
        }
       

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/addSubCategory`, { categoryId, name: subCategoryName });
            if (response.data.status === 1) {
                setCategories(categories.map(category =>
                    category._id === categoryId ? { ...category, subCategories: [...(category.subCategories || []), response.data.newSubCategory] } : category
                ));
                // Reset the sub-category input for the specific category after adding
                setSubCategoryInputs(prevInputs => ({
                    ...prevInputs,
                    [categoryId]: "",
                }));
                swal("Success!", "Sub Category added successfully", "success");
                navigate("/guidelines-categories");
            } else {
                console.error("Error adding sub-category:", response.data.errors);
                swal("Error!", response.data.errors, "error");
            }
        } catch (error) {
            console.error("Error adding sub-category:", error);
            swal("Error!", error, "error");
        }
    };

    const deleteSubCategory = async (categoryId, subCategoryId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this sub-category?");
        if (!confirmDelete) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deleteSubCategory`, { categoryId, subCategoryId });
            if (response.data.status === 1) {
                setCategories(categories.map(category =>
                    category._id === categoryId ? {
                        ...category,
                        subCategories: category.subCategories.filter(sub => sub._id !== subCategoryId)
                    } : category
                ));
                swal("Success!", "Sub Category deleted successfully", "success");
                navigate("/guidelines-categories");
            } else {
                console.error("Error deleting sub-category:", response.data.errors);
                swal("Error!", response.data.errors, "error");
            }
        } catch (error) {
            console.error("Error deleting sub-category:", error);
            swal("Error!", error, "error");
        }
    };

    return (
        <DashboardLayout>
            <section className="FilterSec">
                <div className="container">
                    <div className="FilterDiv">

                        <div className="FilterHead">
                            <h2>Guideline Categories List </h2>
                        </div>

                        <div className='GuidlineDiv'>

                            <div className='addcateDiv' >
                            <div>
                            {imagePrevCategory && (
                                <Avatar
                                    src={imagePrevCategory}
                                    alt="Uploaded Icon"
                                    style={{
                                    width: "55px",
                                    height: "55px",
                                    border: "2px solid #439cf0",
                                    padding: "5px",
                                    marginRight: 8,
                                    }}
                            />
                            )}
                           
                            
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleIconUploadCategory}
                                />
                            </div>
                               
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Add Category"
                                    value={newCategory}
                                    onChange={(e) => setNewCategory(e.target.value)}
                                />
                                <Button className="MainBtn" onClick={addCategory} type="submit"> Add Category</Button>
                            </div>

                            <div className='GuideGate scrollbar' >
                                {Array.isArray(categories) && categories.map(category => (
                                    <div key={category._id} className='Subguidname'>
                                        <div className='guidename'>
                                        <Avatar
                                            src={showprevDB ? `${process.env.REACT_APP_BASE_URL}${category.iconurl}` : imagePrev}
                                            alt="Uploaded Icon"
                                            style={{
                                                width: "55px",
                                                height: "55px",
                                                border: "2px solid #439cf0",
                                                padding: "5px",
                                                marginRight: 8,
                                            }}
                                        />
                                            
                                            <h6>{category.name}</h6>
                                            <IconButton onClick={() => openEditModal(category)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => deleteCategory(category._id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                        {/* <div className='guidename'>
                                            <h6>{category.name}</h6>
                                            <IconButton onClick={() => editCategory(category._id, prompt("Edit Category Name:", category.name))}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => deleteCategory(category._id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div> */}
                                        <div className='GuideCateInner'>
                                            <div className="catefield">
                                                <TextField
                                                    variant="outlined"
                                                    label="Add Sub Category"
                                                    fullWidth
                                                    value={subCategoryInputs[category._id] || ""} // Use specific input for each category
                                                    onChange={(e) => handleSubCategoryInputChange(category._id, e.target.value)}
                                                    style={{ marginRight: '20px' }}
                                                />
                                                <Button className="MainBtn" onClick={() => addSubCategory(category._id)} type="submit">
                                                    Add Sub Category
                                                </Button>
                                            </div>
                                            
                                            {category.subCategories && category.subCategories.map(sub => (
                                                <div className='Cateinner' key={sub._id} >
                                                    <p>{sub.name}</p>
                                                    {/* <MDTypography variant="body1">{sub.name}</MDTypography> */}
                                                    <IconButton onClick={() => deleteSubCategory(category._id, sub._id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            ))}
                                        </div>
                                        {/* Modal for Editing Category */}
                                        <Dialog open={open} onClose={closeEditModal}>
                                            <DialogTitle>Edit Category</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    label="Category Name"
                                                    fullWidth
                                                    value={editedCategoryName}
                                                    onChange={(e) => setEditedCategoryName(e.target.value)}
                                                />
                                                {/* <Avatar
                                            src={imagePrev}
                                            alt="Uploaded Icon"
                                            style={{
                                                width: "55px",
                                                height: "55px",
                                                border: "2px solid #439cf0",
                                                padding: "5px",
                                                marginRight: 8,
                                            }}
                                        /> */}
                                                <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleIconUpload}
                                                    />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={closeEditModal} color="primary">
                                                    Cancel
                                                </Button>
                                                <Button onClick={editCategory} color="primary">
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                <ToastContainer />
            </section>
        </DashboardLayout>
    );
}

export default Tables;
