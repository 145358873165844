import List from "@editorjs/list";

class MyCheckboxListTool extends List {
  constructor({ data, api, config }) {
    super({ data, api, config });
    this.api = api;

    this.enableBgColor = data?.backgroundColor ? true : false; // ✅ Default: Background enabled
    this.backgroundColor = data?.backgroundColor || "";  //#F0EEE4
    this.gap = data?.gap || "0px";
    this.padding = data?.padding || (this.enableBgColor ? "5px 22px 0px 22px" : "5px 22px 0px 22px");
    this.margin = data?.margin || "0px 0px 0px 0px";
    this.borderRadius = data?.borderRadius || "0px";
    this.listStyle = data?.style || "unordered";

    this.data = {
      ...data,
      backgroundColor: this.backgroundColor,
      gap: this.gap,
      padding: this.padding,
      margin: this.margin,
      borderRadius: this.borderRadius,
    };
  }

  // ✅ Add Sub List Toolbox Icon for Editor.js
  static get toolbox() {
    return {
      title: "Sub List",
      icon: "📋",
    };
  }

  render() {
    const wrapper = super.render();

    wrapper.style.backgroundColor = this.data.backgroundColor;
    wrapper.style.padding = this.data.padding;
    wrapper.style.margin = this.data.margin;
    wrapper.style.gap = this.data.gap;
    wrapper.style.borderRadius = this.data.borderRadius;

    const listEl = wrapper.querySelector("ul, ol");
    if (listEl) {
      listEl.style.backgroundColor = this.data.backgroundColor;
      listEl.style.padding = this.data.padding;
      listEl.style.margin = this.data.margin;
      listEl.style.gap = this.data.gap;
      listEl.style.borderRadius = this.data.borderRadius;
    }

    return wrapper;
  }

  renderSettings() {
    const wrapper = document.createElement("div");
    wrapper.className = "ce-settings";

    const settingsContainer = document.createElement("div");
    settingsContainer.style.display = "grid";
    settingsContainer.style.gridTemplateColumns = "1fr";
    settingsContainer.style.gap = "10px";
    settingsContainer.style.marginBottom = "5px";

    // ✅ Checkbox for enabling/disabling background color
    const createCheckbox = (labelText, defaultChecked, onChange) => {
      const container = document.createElement("div");
      container.style.display = "flex";
      container.style.alignItems = "center";
      container.style.marginBottom = "5px";

      const label = document.createElement("label");
      label.textContent = labelText;
      label.style.fontSize = "12px";
      label.style.marginRight = "5px";
      label.style.width = "110px";

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.checked = defaultChecked;

      checkbox.addEventListener("change", (event) => {
        onChange(event.target.checked);
      });

      container.appendChild(label);
      container.appendChild(checkbox);
      return container;
    };

    // settingsContainer.appendChild(
    //   createCheckbox("Enable BG Color", this.enableBgColor, (checked) => {
    //     this.enableBgColor = checked;
    //     this.backgroundColor = checked ? "#F0EEE4" : "";
    //     this.padding = checked ? "32px 16px 32px 16px" : "8px 0px 8px 0px";
    //     this.data.backgroundColor = this.backgroundColor;
    //     this.data.padding = this.padding;
    //     this.updateStyles();
    //   })
    // );

    // ✅ Background color input (only if enabled)
    // const backgroundColorInput = this.createInput("BG-Color:", "text", this.backgroundColor, (value) => {
    //   this.backgroundColor = value;
    //   this.data.backgroundColor = value;
    //   this.updateStyles();
    // });

    // settingsContainer.appendChild(backgroundColorInput);

    // ✅ Dropdown for switching between Ordered & Unordered lists
    settingsContainer.appendChild(
      this.createDropdown("List Type:", ["unordered", "ordered"], this.listStyle, (value) => {
        this.listStyle = value;
        this.updateListType();
      })
    );

    wrapper.appendChild(settingsContainer);
    return wrapper;
  }

  createInput(labelText, type, defaultValue, onChange) {
    const wrapper = document.createElement("div");
    wrapper.style.display = "flex";
    wrapper.style.alignItems = "center";
    wrapper.style.gap = "5px";

    const label = document.createElement("label");
    label.textContent = labelText;
    label.style.fontSize = "10px";

    const input = document.createElement("input");
    input.type = type;
    input.value = defaultValue;
    input.style.fontSize = "10px";
    input.style.width = type === "color" ? "40px" : "50px";
    input.style.cursor = type === "color" ? "pointer" : "text";

    input.addEventListener("input", (event) => onChange(event.target.value));

    wrapper.appendChild(label);
    wrapper.appendChild(input);
    return wrapper;
  }

  createDropdown(labelText, options, defaultValue, onChange) {
    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.alignItems = "center";
    container.style.marginBottom = "5px";

    const label = document.createElement("label");
    label.textContent = labelText;
    label.style.fontSize = "12px";
    label.style.marginRight = "5px";
    label.style.width = "110px";

    const select = document.createElement("select");
    select.style.width = "90px";
    select.style.fontSize = "12px";

    options.forEach((option) => {
      const opt = document.createElement("option");
      opt.value = option;
      opt.textContent = option.charAt(0).toUpperCase() + option.slice(1);
      if (option === defaultValue) opt.selected = true;
      select.appendChild(opt);
    });

    select.addEventListener("change", (event) => {
      onChange(event.target.value);
    });

    container.appendChild(label);
    container.appendChild(select);
    return container;
  }

  updateStyles() {
    const blockId = this.api.blocks.getCurrentBlockIndex();
    const block = this.api.blocks.getBlockByIndex(blockId);
    console.log("jjjj",block);
    if (block?.holder) {
      const listEl = block.holder.querySelector("ul, ol");
      if (listEl) {
        listEl.style.backgroundColor = this.backgroundColor;
        listEl.style.padding = this.padding;
        listEl.style.margin = this.margin;
        listEl.style.gap = this.gap;
        listEl.style.borderRadius = this.borderRadius;
      }
    }
    setTimeout(() => {
      this.api.saver.save().then((savedData) => {
        console.log("Updated savedData:", savedData);
      });
    }, 0);
  }

  updateListType() {
    const blockIndex = this.api.blocks.getCurrentBlockIndex();
    const block = this.api.blocks.getBlockByIndex(blockIndex);
    console.log("jjjj",block);
    if (!block || !block.holder) return;
  
    
    const currentList = block.holder.querySelector("ul, ol");
    console.log("currentList.children.length",currentList.children.length);
    if (currentList && currentList.children.length > 0) {
      
      // ✅ Only update `listStyleType`, keeping other styles unchanged
      currentList.style.listStyleType = this.listStyle === "ordered" ? "decimal" : "disc";
  
      // ✅ Ensure class names remain consistent without removing existing styles
      currentList.classList.remove("cdx-list-unordered", "cdx-list-ordered");
      currentList.classList.add(this.listStyle === "ordered" ? "cdx-list-ordered" : "cdx-list-unordered");
    }
  }

  save(blockContent) {
    const originalData = super.save(blockContent);
    return {
      ...originalData,
      style: this.listStyle,
      backgroundColor: this.enableBgColor ? this.backgroundColor : "",
      padding: this.enableBgColor ? "0px 22px 0px 22px" : "0px 22px 0px 22px",
      margin: this.margin,
      gap: this.gap,
      borderRadius: this.borderRadius,
    };
  }
}

export default MyCheckboxListTool;
