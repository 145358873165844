import { useState, useEffect } from "react";
// react-router components
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Icon from "@mui/material/Icon";
// Balearen Whisper React components
//import MDBox from "components/MDBox";
//import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import moment from 'moment';
//import MDBadge from "components/MDBadge";

// Images
//import team2 from "assets/images/team-2.jpg";
//import team3 from "assets/images/team-3.jpg";
//import team4 from "assets/images/team-4.jpg";

export default function Data() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({});
  const LoadData = async() => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getGuidelines`).then((res) => {
        var response = res.data;
        response = response.guidelines;
        var responseData = [];
        for (var i = 0; i < response.length; i++) {
          //console.log(assetImage);
          var inc=i+1;
          
          responseData.push(
            {
              id: inc,
              icon: <MDAvatar src={response[i].guideicon}/>,
              title: response[i].title,
              description: response[i].titleDescription,
              date: formatUTCDate(response[i].createdAt),
             
            }
          );
        }
        setData(responseData);
      });
    } catch (error) {
        console.log(error);
    }
  };
  useEffect(() => {
    LoadData();
  },[]); 

  return {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "icon", accessor: "icon" },
      { Header: "title", accessor: "title" },
      { Header: "description", accessor: "description" },
      { Header: "date", accessor: "date" },
    ],

    rows: data ? data : "",
  };
}


function formatUTCDate(utcDate) {
  // Parse the UTC date string into a moment object
  const momentDate = moment(utcDate);

  // Format the date with AM/PM format
  const formattedDate = momentDate.format('MM/DD/YYYY, hh:mm A');

  return formattedDate;
}


