import React, { useState, useEffect } from "react";
import { Tabs, Tab, Grid, Card, Button, TextField, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import swal from "sweetalert";
import Switch from "@mui/material/Switch";

import ToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/system";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Form } from "react-bootstrap";

function Tables() {
  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [fetchedData, setFetchedData] = useState([]);
  const [editingFilter, setEditingFilter] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
 

  const [formData, setFormData] = useState({
    filter: { filtertype: "", name: "" },
  });

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#4CAF50 !important", // Green color for active state
      "& + .MuiSwitch-track": {
        backgroundColor: "#4CAF50 !important", // Green track for active state
      },
    },
    "& .MuiSwitch-switchBase": {
      color: "#f44336 !important", // Red color for inactive state
      "& + .MuiSwitch-track": {
        backgroundColor: "#f44336 !important", // Red track for inactive state
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
    },
  }));
  


  useEffect(() => {
    // const savedTab = localStorage.getItem("activeTab");
    // if (savedTab !== null) {
    //   console.log("savedTabsavedTabkkk",savedTab);
    //   setActiveTab(parseInt(savedTab));
    // }
  }, []);

  useEffect(() => {
    fetchDataByType();
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    console.log("savedTabsavedTab",newValue);
    setActiveTab(newValue);
    localStorage.setItem("activeTab", newValue);
  };

  const handleInputChange = (e, tab) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [tab]: { ...prevState[tab], [name]: value },
    }));
  };

  const handleSubmit = (tab) => {
    let apiUrl;

    if (tab === "filter") {
      apiUrl = "api/admin/AddFilter";
    }

    if (!formData[tab].filtertype || formData[tab].filtertype.trim() === "") {
      swal("Error", "Filter type is required!", "error");
      return;
    }
    if (!formData[tab].name || formData[tab].name.trim() === "") {
      swal("Error", "Name is required!", "error");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}${apiUrl}`, formData[tab])
      .then((res) => {
        const response = res.data;

        if (response.status === 1) {
          swal("Success", `${tab} added successfully!`, "success").then(() => {
            setFetchedData((prevData) => [...(prevData || []), response.industry]);
            setFormData({ filter: { filtertype:"", name: "" } });
          });
        } else {
          setErrors(response.errors);
          swal("Error", `A ${tab} with this name already exists`, "error");
        }
      })
      .catch((error) => {
        console.error(`Error adding ${tab}:`, error);
        swal("Error", `Failed to add ${tab}.`, "error");
      });
  };

  const fetchDataByType = async () => {
    const type = "filter";

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getFilter`, { type });
      
      if (response.data.status === 1) {
        setFetchedData(response.data.filters);
      } else {
        setFetchedData([]);
        swal("Error", response.data.message || "Failed to fetch filter data.", "error");
      }
    } catch (error) {
      console.error("Error fetching filter data:", error);
      swal("Error", "An error occurred while fetching filter data.", "error");
    }
  };
  const handleAdd = (item) => {
    console.log("itemitem",item);
    navigate("/filterList", { state: { filterItem: item } }); // Passing the selected item as state
  };

  const handleEdit = (item) => {
    setEditingFilter(item);
    setFormData({ filter: { filtertype:item.filtertype, name: item.name } });
    setIsEditing(true);
  };

  const handleEditSubmit = () => {
    
    if (!formData.filter.filtertype.trim()) {
      swal("Error", "Filter type is required!", "error");
      return;
    }
    if (!formData.filter.name.trim()) {
      swal("Error", "Name is required!", "error");
      return;
    }
  
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/admin/editFilter`, {
        id: editingFilter._id,
        name: formData.filter.name,
        filtertype: formData.filter.filtertype,
      })
      .then((res) => {
        if (res.data.status === 1) {
          swal("Success", "Filter updated successfully!", "success").then(() => {
            setIsEditing(false);
            setEditingFilter(null); // Clear the editing state
            setFormData({ filter: { filtertype:"", name: "" } }); // Clear the form data
            fetchDataByType(); // Refresh the data
          });
        } else {
          swal("Error", res.data.errors.message || "Failed to update filter.", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating filter:", error);
        swal("Error", "Failed to update filter.", "error");
      });
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: {
        cancel: { text: "No", visible: true },
        confirm: { text: "Yes", visible: true, className: "btn btn-danger" },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/deleteFilter`, { id })
          .then((response) => {
            if (response.data.status === 1) {
              swal("Deleted!", "Your item has been deleted.", "success");
              fetchDataByType();
            } else {
              swal("Error", response.data.errors.message || "Failed to delete item.", "error");
            }
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            swal("Error", "Failed to delete item.", "error");
          });
      }
    });
  };

  const handleToggleStatus = (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/admin/updateFilterStatus`, { id, status: newStatus })
      .then((response) => {
        if (response.data.status === 1) {
          swal("Success", "Status updated successfully!", "success");
          fetchDataByType();
        } else {
          swal("Error", response.data.errors.message || "Failed to update status.", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        swal("Error", "Failed to update status.", "error");
      });
  };

  console.log("activeTab",activeTab);

  const [filterType, setFilterType] = useState("All");
  // Filter data based on selected option
  const filteredRows = filterType === "All" ? fetchedData : fetchedData.filter((row) => row.filtertype === filterType);

  // Filter columns: Hide 'icon' column for domain-specific types
  // const filteredColumns = filterType === "Global" || filterType === "All"
  //   ? columns
  //   : columns.filter((col) => col.accessor !== "icon");

  const renderFilterType = (type) => {
    switch (type) {
      case "guideline":
        return "Guideline";
      case "uxinsight":
        return "Ux Insight";
      case "annotation":
        return "Annotation";
      default:
        return "";
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <section className="FilterSec">
        <div className="container">
          <div className="FilterDiv">

            <div className="FilterHead">
              <h2>Manage Filters</h2>
            </div>

            <Box py={3} px={2}>
              {activeTab === 0 && (
                <div className="FilterInputDiv">
                  <FormControl fullWidth margin="normal">
                    <InputLabel className="slctlbl">Filter Type</InputLabel>
                    <Select
                      name="filtertype"
                      value={formData.filter.filtertype}
                      onChange={(e) => handleInputChange(e, "filter")}
                      variant="outlined"
                      style={{ lineHeight: "3em" }}
                    >
                      <MenuItem value=""><em>Select Filter Type</em></MenuItem>
                      <MenuItem value="guideline">Guideline</MenuItem>
                      <MenuItem value="uxinsight">Ux Insight</MenuItem>
                      <MenuItem value="annotation">Annotation</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Filter Name"
                    name="name"
                    value={formData.filter.name}
                    onChange={(e) => handleInputChange(e, "filter")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit("filter")}
                      sx={{ color: '#fff' }}
                    >
                      Add Filter
                    </Button>
                </div>
              )}
            </Box>

            



            <MDBox pt={4} px={4}>

              <div className="FilterList">
                <h4>List of Filters:</h4>
                {/* Dropdown for filtering */}
                <div className="GuidFilters ">
                  <FormControl className="FilterControl">
                    <InputLabel>Filter By</InputLabel>
                    <Select value={filterType} onChange={(event) => setFilterType(event.target.value)} className="FilterSelect">
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="guideline">Guideline</MenuItem>
                      <MenuItem value="uxinsight">Ux Insight</MenuItem>
                      <MenuItem value="annotation">Annotation</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              

              <TableContainer className="scrollbar" style={{ maxHeight: '440px', overflow: 'auto', boxShadow: 'none' }}>
                <Table className="FilterTable scrollbar">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows && filteredRows.length > 0 ? (
                      filteredRows.map((item, index) => (
                        <TableRow key={item._id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <MDTypography>{renderFilterType(item.filtertype)}</MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography>{item.name}</MDTypography>
                          </TableCell>
                          <TableCell>
                              {/* Toggle Switch */}
                              <CustomSwitch
                                checked={item.status === 1}
                                onChange={() => handleToggleStatus(item._id, item.status)}
                                inputProps={{ "aria-label": "status toggle" }}
                              />
                              
                              {/* Status Text */}
                              {item.status === 1 ? (
                                <span style={{ marginLeft: "10px", color: "green" }}>Active</span>
                              ) : (
                                <span style={{ marginLeft: "10px", color: "red" }}>Inactive</span>
                              )}
                            </TableCell>

                          <TableCell>
                            <Button
                              fullWidth
                                onClick={() => handleAdd(item)}
                                variant="contained"
                                style={{ marginRight: '8px',  }}
                                sx={{ color: '#fff' }}
                                startIcon={<AddIcon />}
                              >
                                Sub Type  {/* Update the button text to "List" */}
                              </Button>
                              <button onClick={() => handleEdit(item)}>
                                <EditIcon/>
                              </button>
                              <button onClick={() => handleDelete(item._id)}>
                                <DeleteIcon/> 
                              </button>
                              
                              
                          </TableCell>

                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

            </MDBox>






          </div>


        </div>
      </section>

      
      <Dialog maxWidth="md" fullWidth open={isEditing} onClose={() => setIsEditing(false)}>
        <DialogTitle>Edit Filter</DialogTitle>
        <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Filter Type</InputLabel>
          <Select
            name="filtertype"
            value={formData.filter.filtertype}
            onChange={(e) => handleInputChange(e, "filter")}
            variant="outlined"
            style={{ lineHeight: "3em" }}
          >
            <MenuItem value=""><em>Select Filter Type</em></MenuItem>
            <MenuItem value="guideline">Guideline</MenuItem>
            <MenuItem value="uxinsight">Ux Insight</MenuItem>
          </Select>
        </FormControl>
          <TextField
            label="Filter Name"
            name="name"
            value={formData.filter.name}
            onChange={(e) => handleInputChange(e, "filter")}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)} variant="contained" style={{ backgroundColor: 'red', color: 'white' }}>
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} variant="contained" sx={{ color: '#fff' }} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;