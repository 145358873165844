import {
  Link, Router,
} from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AddIcon from '@mui/icons-material/Add';

// Data
import authorsTableData from "layouts/guidelineTypes/data/authorsTableData";

function Tables() {
  const { columns, rows } = authorsTableData();

  return (
    <DashboardLayout>
      
      <section className="FilterSec">
        <div className="Add_Guide_Sec">

          <div className="FilterHead">
            <h2>Guideline Types List</h2>
          </div>

          <div className="guidLink mt-5">
            <Link to="/guidelinetype-add"><AddIcon/>Add Guideline Type</Link>
          </div>

          <div className="GuidlineForm">
            <div className="GuideCateDiv">
              <DataTable table={{ columns, rows }} //isSorted={false}
                canSearch={true}
                entriesPerPage={false}
                pagination={false}
                showTotalEntries={false}
                noEndBorder
              />
            </div>
          </div>

        </div>
      </section>


    </DashboardLayout>
  );
}

export default Tables;
