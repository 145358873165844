import axios from "axios";
class CustomSpanTool {
  
    static get isInline() {
      return true; // This tool is an inline tool
    }
  
    static get title() {
      return 'Assign ID'; // Title for the toolbar
    }
  
    static get sanitize() {
      //console.log("11111111111111111111111");
      return {
        span: {
          'data-id': true, // Allow the `data-id` attribute
          'data-type': true, // Allow the `data-id` attribute
          'class': true,
          style: true, 
          title: true,
        },
      };
    }
  
    constructor({ api }) {
      //console.log("2222222222222222222222",api);
      this.api = api;
      this.selectedId = null; 
      this.selectedType = ""; 
      this.selectedColor = ""; 
      this.options = []; // Store fetched options
      this.tooltip = null; // Tooltip element
    }
  
    async fetchOptions() {
      try {
        let fields = {
            "_id": 1,
            "title": 1,
            "hashNumber": 1,
            "createdAt": 1
        }
        let filters = {}
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}api/admin/getGuidelinesWithCustom`,
          { fields, filters }
        );
  
        // Assuming the response structure contains a list of options
        // Update this based on the actual API response structure
        this.options = response.data.guidelines; // Adjust the path as per your API response
      } catch (error) {
        console.error('Error fetching options:', error.message);
      }
    }
    // Initialize the tooltip element
  createTooltip() {
     // Tooltip container
     const tooltip = document.createElement("div");
     tooltip.classList.add("custom-tooltip");
     tooltip.style.position = "absolute";
     tooltip.style.visibility = "hidden";
     tooltip.style.backgroundColor = "#000";
     tooltip.style.zIndex = "1000";
     tooltip.style.transform = "translateX(-50%)";
 
     // Tooltip arrow
     const arrow = document.createElement("div");
    //  arrow.classList.add("tooltip-arrow");
     arrow.style.position = "absolute";
    arrow.style.width = "0";
    arrow.style.height = "0";
    arrow.style.borderLeft = "6px solid transparent";
    arrow.style.borderRight = "6px solid transparent";
    arrow.style.borderBottom = "6px solid #1A2130"; // Matches your tooltip background
    arrow.style.top = "-6px"; // Put it *above* the tooltip now
    arrow.style.left = "50%";
    arrow.style.transform = "translateX(-50%)";

 
     // Append the arrow to the tooltip
     tooltip.appendChild(arrow);
     document.body.appendChild(tooltip);
 
     this.tooltip = tooltip;
  }

  // Show the tooltip at the cursor position
  // Show the tooltip at the cursor position
  async showTooltip(event, id) {
    try {
      // Fetch additional data from the API
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getSingleGuideline`, {
        id, // Send the ID to the API
      });

      // Extract relevant data from the response
      const tooltipData = response.data.guideline; // Adjust the response path based on your API

      // Display the tooltip content dynamically
      this.tooltip.innerHTML = `
        <span>#${tooltipData.hashNumber} Guideline</span>
        <H2>${tooltipData.title || ''}</H2>
        <H6>${tooltipData.titleDescription || ''}</H6>
        <button>See More Details</button>
        
      `;
      

      // Position the tooltip near the cursor
      this.tooltip.style.top = `${event.clientY + 10}px`; // Position below the mouse cursor
      this.tooltip.style.left = `${event.clientX + 10}px`;
      this.tooltip.style.visibility = 'visible';
    } catch (error) {
      console.error('Error fetching tooltip data:', error.message);
      // Handle the tooltip display in case of an error
      this.tooltip.innerText = 'Error loading data.';
      this.tooltip.style.top = `${event.clientY + 10}px`;
      this.tooltip.style.left = `${event.clientX + 10}px`;
      this.tooltip.style.visibility = 'visible';
    }
  }


  // Hide the tooltip
  hideTooltip() {
    if (this.tooltip) {
      this.tooltip.style.visibility = 'hidden';
    }
  }
  
    async render() {
      //console.log("333333333333333333333333333333333");
      // Fetch options dynamically
      if (!this.options.length) {
        await this.fetchOptions();
      }
  
      const container = document.createElement('div');
  
      // // Create a dropdown for ID selection
      // const select = document.createElement('select');
      // select.innerHTML = `
      //   <option value="" disabled selected>Assign Guideline</option>
      // `;
  
      // this.options.forEach((option) => {
      //   const optionElement = document.createElement('option');
      //   optionElement.value = option._id; // Assuming each option has an `_id` field
      //   // Limit the title to 10 characters with "..."
      //   let shortTitle = option.title.length > 10 
      //   ? option.title.substring(0, 10) + "..." 
      //   : option.title;
      //   optionElement.textContent = `# ${option.hashNumber} ${shortTitle}`; // Assuming each option has a `title` field
      //   select.appendChild(optionElement);
      // });
  
      // select.addEventListener('change', (event) => {
      //   this.selectedId = event.target.value;
      // });
  
      // container.appendChild(select);
  
      // const button = document.createElement('button');
      // button.type = 'button';
      // button.innerHTML = ''; // Add your icon or label
      // button.addEventListener('click', () => {
      //   this.api.inlineToolbar.close();
      // });
  
      //container.appendChild(button);

      
      // Create and add tooltip
      this.createTooltip();
      // ✅ Ensure existing spans get the class on load
      document.querySelectorAll('span[data-id][data-type]').forEach(span => {
        span.classList.add("highlighted-ux");
      });

      // Add event listeners to the editor content for showing tooltips
      document.addEventListener('mouseover', (event) => {
        //console.log("mouseover");
        const span = event.target.closest('span[data-id]');
        if (span && span.getAttribute('data-id')) {
          this.showTooltip(event, span.getAttribute('data-id'));
        }
      });

      document.addEventListener('mouseout', () => {
        //console.log("mouseout");
        this.hideTooltip();
      });

      return container;
    }

    bindTooltipEvents() {
     // console.log("44444444444444444444444");
      // Re-bind the event listeners for all the spans with `data-id`
      document.addEventListener('mouseover', (event) => {
        const span = event.target.closest('span[data-id]');
        if (span && span.getAttribute('data-id')) {
          span.classList.add("highlighted-ux");
          this.showTooltip(event, span.getAttribute('data-id'));
        }
      });
  
      document.addEventListener('mouseout', () => {
        //console.log("mouseout222222");
        this.hideTooltip();
      });

      // ✅ Ensure all existing spans have the class on initialization
      document.querySelectorAll('span[data-id][data-type]').forEach(span => {
        span.classList.add("highlighted-ux");
      });
    }
  
    surround(range) {
      //console.log("5555555555555555555555555");
        if (!range || range.collapsed) {
          console.log('No text selected');
          return;
        }
      
        if (!this.selectedId) {
          console.log('No ID selected');
          return;
        }
      
        // Check if the selection is already wrapped in a span
        const existingSpan = this.getSpan(range);
      
        if (existingSpan) {
            // Check if the span already has the data-type attribute with value 'uxinsight'
            if (existingSpan.getAttribute('data-type') === 'uxinsight') {
             // console.log("this.selectedId1111111111",this.selectedId);
                // Update the existing span's `data-id` and `data-type` attributes
                existingSpan.setAttribute('data-id', this.selectedId);
                existingSpan.setAttribute('data-type', this.selectedType);
                existingSpan.style.color = this.selectedColor; 
                // ✅ Ensure class is always added
                existingSpan.classList.add("highlighted-ux");
            } else {
              //console.log("this.selectedId2222222222",this.selectedId);
                // If the span exists but doesn't have `data-type="uxinsight"`, wrap it in a new span
                const span = document.createElement('span');
                span.setAttribute('data-id', this.selectedId);
                span.setAttribute('data-type', this.selectedType);
                span.style.color = this.selectedColor; // Set text color
                // ✅ Add class dynamically
                span.classList.add("highlighted-ux");
        
                // Preserve the existing content and move it into the new span
                range.surroundContents(span);
            }
        } else {
            // If no span exists, create a new one and wrap the selection in it
            //console.log("this.selectedId33333333333",this.selectedId);
            const span = document.createElement('span');
            span.setAttribute('data-id', this.selectedId);
            span.setAttribute('data-type', this.selectedType);
            span.style.color = this.selectedColor;
        
            try {
                range.surroundContents(span);
            } catch (e) {
                console.error('Error wrapping selection:', e.message);
            }
        }
        
        // Re-bind the tooltip event listeners after the content is updated
        //console.log("kkkkkkkkkkkkkkkkkkkkkk");
        this.bindTooltipEvents();
      }
      
      getSpan(range) {
        console.log("666666666666666666666666666666");
        
        const container = range.commonAncestorContainer;
        if (container.nodeType === Node.TEXT_NODE) {
          return container.parentElement?.closest('span');
        }
        return container.closest('span');
      }
  }
  
  export default CustomSpanTool;
  