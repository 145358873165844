import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Editor from "components/Custom/Editor";
import { HtmlEditor, Image, Inject, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-icons/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-richtexteditor/styles/material.css';

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

export default function EditContent() {
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [content, setContent] = useState("");
    let navigate = useNavigate();
    let { id } = useParams();
    const [errors, setErrors] = useState(false);
    const [data, setData] = useState(false);
    useEffect(() => {
        setEditorLoaded(true);
        if(id){
            axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getContentById`, {id}).then((res) => {
                const response = res.data;
                setData(response.contentsList);
            });
        }
    }, [id]);

    const changeData = (e) => {
        var name=e.target.name;
        var value=e.target.value;
        setData({name: value, 'content': 'test'});
    };

    const submitData = (e) => {
        e.preventDefault();
        var data = {
            id,
            title: e.target.title.value,
            content,
        };
        axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/editContents`, data).then((res) => {
            const response = res.data;
            if (response.status == 0) {
                setErrors(response.errors);
            } else {
                swal("success", "Content edited successfully!", "success");
                navigate("/contents");
            }
        });
    };       
    let toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
    };
    let quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'],
        link: ['Open', 'Edit', 'UnLink']
    }; 
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                            Edit Content
                            </MDTypography>
                            
                        </MDBox>
                        <MDBox mx={2} mt={3}>
                        <Button variant="contained" className="backbtn w-auto" color="primary" onClick={() => navigate(-1)}>
                                <ArrowBackIcon />
                                {/* Back */}
                                </Button>
                        </MDBox>
                        <MDBox pt={3}>
                            <div className="formContainer">
                                <form onSubmit={submitData}>
                                    <div class="form-group">
                                        <label for="email">Title:</label>
                                        <input type="text" class="form-control" name="title" value={data?.title} onChange={changeData} readOnly/>
                                        <span className="redError">{errors.title ? errors.title : ""}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="pwd">Content:</label>
										{
										(data && data.content)?(
                                            <>
                                                <RichTextEditorComponent height={450} toolbarSettings={toolbarSettings} quickToolbarSettings={quickToolbarSettings} change={(data) => {setContent(data.value)}}>
                                                    <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
                                                    <Inject services={[Toolbar, Image, HtmlEditor, QuickToolbar]} />
                                                </RichTextEditorComponent>
                                                {/* <Editor
                                                    name="content"
                                                    onChange={(data) => {
                                                        setContent(data);
                                                    }}
                                                    editorLoaded={editorLoaded}
                                                    value={data?.content}
                                                /> */}
                                            </>
										):""}
                                        <span className="redError">{errors.content ? errors.content : ""}</span>
                                    </div>
                                    <button type="submit" class="btn btn-primary float-end mt-2 mb-2">Update</button>
                                </form> 
                            </div>
                        </MDBox>
                    </Card>
                </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
