import { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/guidelines/data/authorsTableData";
import { Form } from "react-bootstrap";

function Tables() {
  const { columns, rows } = authorsTableData();

  // Filter data based on selected tab
  const [filterType, setFilterType] = useState("All");

  // Filter data based on selected option
  const filteredRows = filterType === "All" ? rows : rows.filter((row) => row.categoryname === filterType);

  // Filter columns: Hide 'icon' column for domain-specific types
  const filteredColumns = filterType === "Global" || filterType === "All"
    ? columns
    : columns.filter((col) => col.accessor !== "icon");

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <section className="FilterSec">
        <div className="Add_Guide_Sec">

          <div className="FilterHead">
            <h2>Guidelines List15</h2>
          </div>


          <div className="GuideListTop">

            <div className="guidLink mt-5">
              <Link to="/guideline-add"><Icon fontSize="small">add</Icon>Add Guideline</Link>
            </div>

            {/* Dropdown for filtering */}
            <div className="GuidFilters mt-5">
              <FormControl className="FilterControl">
                <InputLabel>Filter By</InputLabel>
                <Select
                  value={filterType}
                  onChange={(event) => setFilterType(event.target.value)}
                  className="FilterSelect"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Global">Global</MenuItem>
                  <MenuItem value="Domain Specific">Domain Specific</MenuItem>
                </Select>
              </FormControl>

             

              


            </div>

          </div>

          <div className="GuidlineForm">
            <div className='GuideCateDiv'>
              {/* <CustomTable /> */}
              <DataTable
                table={{ columns:filteredColumns, rows: filteredRows }}
                //isSorted={false}
                canSearch={true}
                //entriesPerPage={true}
                entriesPerPage={{ defaultValue: 20, entries: [5, 10, 15, 20, 25] }}
                pagination={false}
                showTotalEntries={false}
                noEndBorder
              />
            </div>
          </div>

        </div>

        

      </section>

    </DashboardLayout>
  );
}

export default Tables;
