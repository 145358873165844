import React, { useEffect, useState } from 'react';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadImages = ({ imageFiles, setImageFiles, imagesPreview, setImagesPreview, setImageDimensions, handleImageSelection}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleFileSelection = (selectedFiles) => {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/bmp'];

    const validFiles = Array.from(selectedFiles).filter((file) =>
      allowedFileTypes.includes(file.type)
    );

    if (validFiles?.length > 0) {
      setIsLoading(true);

      const newImageDimensions = [];
      const imageUrls = [];

      validFiles.forEach((file, index) => {
        const reader = new FileReader();

        reader.onload = () => {
          const img = new Image();
          img.src = reader.result;

          img.onload = () => {
            newImageDimensions.push({ width: img.width, height: img.height });
            imageUrls.push(URL.createObjectURL(file));

            // When all images are processed
            if (newImageDimensions.length === validFiles.length) {
              setImageFiles((prev) => [...prev, ...validFiles]);
              setImagesPreview((prev) => [...prev, ...imageUrls]);
              setImageDimensions((prev) => [...prev, ...newImageDimensions]);
              setIsLoading(false);
              // Dynamically call handleImageSelection for the first new image
              const firstNewIndex = imagesPreview.length; // Index of the first newly added image
              handleImageSelection(firstNewIndex,newImageDimensions,imageUrls);
            }
          };
        };

        reader.readAsDataURL(file);
      });
    } else {
      alert('No valid file types selected. Please select PNG, JPEG, or BMP files.');
    }
  };


  // const handleFileSelection = (selectedFiles) => {
  //   const allowedFileTypes = ['image/png', 'image/jpeg', 'image/bmp'];

  //   const validFiles = Array.from(selectedFiles).filter((file) =>
  //     allowedFileTypes.includes(file.type)
  //   );

  //   if (validFiles?.length > 0) {
  //     setIsLoading(true);
  //     setTimeout(() => {
  //       setImageFiles(validFiles);
  //       const imageUrls = validFiles.map((file) => URL.createObjectURL(file));
  //       setImagesPreview(imageUrls);
  //       setIsLoading(false);
  //     }, 4000)
  //   } else {
  //     alert('No valid file types selected. Please select PNG, JPEG, or BMP files.');
  //   }
  // };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles?.length > 0) {
      const updatedFiles = [...imageFiles, ...selectedFiles];
      handleFileSelection(updatedFiles);
    }
  };

  useEffect(() => {
    return () => {
      imageFiles.forEach((file) => URL.revokeObjectURL(file));
    };
  }, [imageFiles]);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles?.length > 0) {
      const updatedFiles = [...imageFiles, ...droppedFiles];
      handleFileSelection(updatedFiles);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  return (
    <div className="ReserchFilePerview">
      {/* <p className="upload_header"> <strong>Upload files</strong></p> */}
      <div className={`${isDraggingOver ?'bg-gray-200':'upload_con'}`} onDragEnter={handleDragEnter}onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        
        {/* <p className="info">Drag and Drop images here</p> */}

        <div className="container2">

          <div className="UplodeDiv" style={{display:"flex" , justifyContent: "center" , alignItems: "center" }}>
            <input
              type="file"
              id="ctl"
              accept=".png, .jpeg, .jpg, .bmp"
              //multiple
              onChange={handleFileChange}
            />
            <div className="upldInner">
              <span>
                <CloudUploadIcon />
              </span>
              <h6>Drag & Drop files here</h6>
              <p>or click to browse</p>
            </div>
          </div>

          <div> { isLoading ? ( <div> <p className="upload_header mt-3">Processing Files...</p> </div>  ) : (<></>)} </div>

          

          {/* <div className="input_con" >
            <input
              type="file"
              id="ctrl"
              webkitdirectory="true"
              directory=""
              multiple
              className="input"
              onChange={handleFileChange}
              style={{
                opacity: 0,
                position: 'absolute',
                zIndex: -1,
                // cursor: 'pointer',
              }}
            />
            <label htmlFor="ctrl" className="custom_label">
              Select Folder
            </label>
          </div> */}
        </div>

      </div>
    </div>
  );
};

export default UploadImages;