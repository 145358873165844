import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Annotation from "react-image-annotation";
import Icon from "@mui/material/Icon";
import image1 from "./dummy/aa.jpg";
import UploadImages from "./annotation/UploadImages";
import CustomEditor from "./annotation/CustomEditor";
import CustomContent from "./annotation/CustomContent";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useNavigate, useParams, Link, Router, } from "react-router-dom";
import {
  Avatar,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";


export default function EditAnnotation() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [singleResearchType, setSingleResearchType] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    title: "",
    devicetype: "",
    researchtype_id: "",
    researchtypesub_id: "",
  });

  

  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [dragCurrentImage, setDragCurrentImage] = useState('0');
  const undoStackRef = useRef([]);
  const redoStackRef = useRef([]);

  const complianceColors = {
    "Compliant High": "#2E9892", // Green
    "Compliant Low": "#2E9892", // Orange
    "Breach Moderate": "#E3A020", // Yellow
    "Breach High": "#D0463E", // Red
  };
  const complianceColorsBackground = {
    "Compliant High": "#2E989240", // Green
    "Compliant Low": "#2E989240", // Orange
    "Breach Moderate": "#E3A02040", // Yellow
    "Breach High": "#FF714E40", // Red
  };

  const style = {
    button: "text-[#fff] bg-[#4ca3dd] py-[2px] px-2 rounded-[5px]",
    thumbnail: "relative w-[100px] h-[70px] cursor-pointer border rounded",
    indexLabel:
      "absolute top-0 left-0 bg-[#0653C6] text-white text-sm font-bold px-2 py-1 rounded-bl-lg",
  };
  const markerStyle = {
    position: "absolute",
    backgroundColor: "#0653C6",
    color: "white",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "bold",
  };



  useEffect(() => {
    // Fetch existing annotation data
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getSingleAnnotation`, {id});
        const { researchtype_id, researchtypesub_id, name, title, devicetype, imageUrls, annotations } = response.data.annotationdetail;
        console.log("imageUrlsimageUrls",imageUrls);
        setFormData(
          { ...formData, 
            name: name, 
            title: title, 
            devicetype: devicetype, 
            researchtype_id: researchtype_id, 
            researchtypesub_id: researchtypesub_id, 

          })
          const baseUrl = process.env.REACT_APP_BASE_URL;
          const fullImageUrls = imageUrls.map((imageUrl) => `${baseUrl}${imageUrl}`);
          console.log("fullImageUrlsfullImageUrls",fullImageUrls);
          setImagesPreview(fullImageUrls);
          setImageFiles(fullImageUrls);

          // Set the annotations for each image
         setAnnotations(annotations);
         


        // setTitle(title || "");
        // setImageUrls(imageUrls || []);
        // setAnnotations(
        //   annotations.map((a) => ({
        //     geometry: a.geometry,
        //     data: a.data,
        //   }))
        // );
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
  }, [id]);


  useEffect(() => {
    // Check if annotations are set and if annotations array is not empty
    if (annotations && annotations.length > 0) {
      renderHighlight({ annotation: annotations });
      handleImageSelection(0);
    }
  }, [annotations]);

  const Box = ({ children, geometry, style }) => (
    <div
      style={{
        ...style,
        position: "absolute",
        left: `${geometry.x}%`,
        top: `${geometry.y}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
      }}
    >
      {children}
    </div>
  );

  function renderSelector({ annotation }) {
    const { geometry } = annotation;
    if (!geometry) return null;

    return (
      <Box
        geometry={geometry}
        style={{
          border: `solid 2px #E10000`,
        }}
      />
    );
  }

  function renderHighlight({ annotation }) {
    //console.log("renderHighlightrenderHighlight", annotation);

    const { geometry } = annotation;
    const idIntegerPart = Math.floor(geometry?.height);

    if (!geometry) return null;

    return (
      <>
        
        <Box
          className="PicnumbDiv"
          key={Math.random()}
          geometry={geometry}
          style={{
            position: "relative",
            background: complianceColorsBackground[annotation.data.compliant],
            border: `dashed 1.5px ${complianceColors[annotation.data.compliant]}`,
          }}
        >
          <div
            style={{
              position: "absolute",
              background: complianceColors[annotation.data.compliant],
              height: "32px",
              width: "32px",
              left: "0%", 
              top: "50%", 
              transform: "translateY(-50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            {" "}
            <h6
              style={{
                borderRadius: "10000px 10000px 12px 10000px",
                background: complianceColors[annotation.data.compliant],
                height: "16px",
                width: "16px",
                color: "#fff",
                fontSize: "11px",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #FFFFFF",
                margin: "0",
              }}
            >
              {" "}
              {annotation.data.number}
            </h6>{" "}
          </div>
        </Box>
      </>
    );
  }

  function renderContent(props) {
    console.log("renderContentrenderContent");
    return <CustomContent {...props} />;
  }

  function renderEditor(props) {
    console.log("renderEditorrenderEditor");
    return <CustomEditor {...props} />;
  }
  

  const onChange = (newAnnotation) => {
    setAnnotation(newAnnotation);
  };

  const onSubmit = (newAnnotation) => {
    const { geometry, data } = newAnnotation;

    redoStackRef.current = [];

    setAnnotation({});
    const newAnnotationData = {
      geometry,
      data: {
        ...data,
        id: Math.random(),
        imageId: selectedImage,
        number: annotations.length + 1,
        image_index: dragCurrentImage,
      },
    };

    setAnnotations([...annotations, newAnnotationData]);

    undoStackRef.current.push(annotations);

   
  };

  const handleImageSelection = (index) => {
    console.log("indexindexindex",index);
    setSelectedImage(imagesPreview[index]);
    setDragCurrentImage(index);
  };

  const handleUndo = () => {
    if (undoStackRef.current.length > 0) {
      const lastAnnotations = undoStackRef.current.pop();
      redoStackRef.current.push([...annotations]);
      setAnnotations(lastAnnotations);
    }
  };

  const handleRedo = () => {
    if (redoStackRef.current.length > 0) {
      const nextAnnotations = redoStackRef.current.pop();
      undoStackRef.current.push([...annotations]);
      setAnnotations(nextAnnotations);
    }
  };

  const applyAnnotationsToSelectedImage = () => {
    if (!selectedImage) {
      toast.error("Please select an image to apply annotations.");
      return;
    }
    const annotationsForNewImage = annotations.map((anno) => ({
      ...anno,
      data: {
        ...anno.data,
        id: Math.random(),
        imageId: selectedImage,
       
      },
    }));
    setAnnotations([...annotations, ...annotationsForNewImage]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if title is provided
    if (!formData.title) {
      toast.error("Title is required");
      return;
    }
    if (!formData.devicetype) {
      toast.error("Device type is required");
      return;
    }

    // Check if at least one image is provided
    if (imageFiles.length === 0) {
      toast.error("At least one image is required");
      return;
    }

    // Check if annotations are provided
    if (!annotations || annotations.length === 0) {
      toast.error("Annotations are required");
      return;
    }
    
    console.log("formData", formData);
    console.log("annotations", annotations);

   
    const totalCompliantPoints = annotations.reduce(
      (sum, annotation) => sum + (annotation.data.compliantPoint || 0),
      0
    );

    let Compliant_High = 0, Compliant_Low = 0, Breach_Moderate = 0, Breach_High = 0;

    annotations.forEach(annotation => {
      const compliantType = annotation.data.compliant;
      const compliantPoint = annotation.data.compliantPoint || 0;
  
      if (compliantType === "Compliant High") {
          Compliant_High += compliantPoint;
      } else if (compliantType === "Compliant Low") {
          Compliant_Low += compliantPoint;
      } else if (compliantType === "Breach Moderate") {
          Breach_Moderate += compliantPoint;
      } else if (compliantType === "Breach High") {
          Breach_High += compliantPoint;
      }
    });

  // Determine the highest points among the categories
  const totals = {"Compliant_High": Compliant_High,"Compliant_Low": Compliant_Low,"Breach_Moderate": Breach_Moderate,"Breach_High": Breach_High};
  
  // Determine the colors  among the categories
  const complianceColorsSelected = {"Compliant_High": "green","Compliant_Low": "green","Breach_Moderate": "yellow","Breach_High": "red"};

  //console.log("complianceColorsSelected",complianceColorsSelected);

  // Find the category with the highest points
  const highestCategory = Object.entries(totals).reduce((max, current) => 
    current[1] < max[1] ? current : max
  );
  //console.log("highestCategory",highestCategory);

  // Get the color for the lowest category
  const lowestCategoryColor = complianceColorsSelected[highestCategory[0]];

  // console.log("Compliant_High",Compliant_High);
  // console.log("Compliant_Low",Compliant_Low);
  // console.log("Breach_Moderate",Breach_Moderate);
  // console.log("Breach_High",Breach_High);
  // console.log("Highest Points:", highestCategory[0], "with", highestCategory[1], "points");
  // console.log("Color of Lowest Points:", lowestCategoryColor);

  // Replace underscores with blank spaces
  const formattedHighestCategory = highestCategory[0].replace(/_/g, " ");

  let scoredetail = {
    Compliant_High,
    Compliant_Low,
    Breach_Moderate,
    Breach_High,
    Highest_Points_Compliance: formattedHighestCategory,
    Highest_Points: highestCategory[1],
    Highest_Points_Compliance_Color: lowestCategoryColor,
  }

  let annotation_status;
  if (lowestCategoryColor === "red") {
      annotation_status = "Bad";
  } else if (lowestCategoryColor === "yellow") {
      annotation_status = "Moderate";
  } else if (lowestCategoryColor === "green") {
      annotation_status = "Good";
  } else {
      annotation_status = "Moderate";
  }


    const formDataToSend = new FormData();
    formDataToSend.append("id", id);
    formDataToSend.append("researchtype_id", formData.researchtype_id);
    formDataToSend.append("researchtypesub_id", formData.researchtypesub_id);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("annotation_status", annotation_status);
    formDataToSend.append("devicetype", formData.devicetype);
    // Add images to FormData
    imageFiles.forEach((file, index) => {
      formDataToSend.append("images", file);
    });
    // Add annotations to FormData
    formDataToSend.append("annotations", JSON.stringify(annotations));
    formDataToSend.append("scoredetail", JSON.stringify(scoredetail));
    formDataToSend.append("totalCompliantPoints", totalCompliantPoints);
    formDataToSend.append("score", 100+totalCompliantPoints);

    console.log("formDataToSend", formDataToSend);
    try {
      // Send data to your backend
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/editResearchAnnotation`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === 1) {
        swal("Success!", "Annotation updated successfully", "success");
        navigate(`/research-list/${formData.researchtype_id}/${formData.researchtypesub_id}`);
        

        // toast.success("Annotation saved successfully!");
        // setFormData({ title: "" });
        // setAnnotations([]);
        // setImagesPreview([]);
        // setImageFiles([]);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error saving Annotation:", error);
      toast.error("An error occurred while saving the Annotation.");
    }
   
  };

  return (
    <DashboardLayout>
      <section className="FilterSec">
          <div className="Add_Guide_Sec">
            <div className="FilterHead">
              <h2>Edit Page Review</h2>
            </div>
            <div className="guidLink mt-5">
              <Link to={`/research-list/${formData.researchtype_id}/${formData.researchtypesub_id}`} style={{ display: "flex",alignItems:"center",gap:"5px"}}>
                <Icon fontSize="small">arrow_back</Icon> Back
              </Link>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="GuidlineForm">

                {/* <div className="ResorsInptForm">

                  <div className="guidFormControl">
                    <h6> <strong>Research Title</strong></h6>
                    <TextField  fullWidth variant="outlined" label="Title"
                      value={formData.title} // Update the state value for title
                      onChange={(e) =>
                        setFormData({ ...formData, title: e.target.value })
                      } // Update the title in formData
                      multiline

                    />
                  </div>

                  <div className="guidFormControl">
                    <h6> <strong>Device Type</strong></h6>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Select</InputLabel>
                      <Select
                        name="devicetype"
                        value={formData.devicetype}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            devicetype: e.target.value,
                          })
                        }
                        label="For Users"
                        style={{ lineHeight: "3em" }}
                      >
                        <MenuItem value="">
                          <em>Select Device Type</em>
                        </MenuItem>
                        <MenuItem key="Desktop" value="Desktop">
                        Desktop
                        </MenuItem>
                        <MenuItem key="Mobile" value="Mobile">
                          Mobile
                        </MenuItem>
                        
                      </Select>
                    </FormControl>
                  </div>
                  
                </div> */}

                <div className="ResPicEditData mt-5">
                  {imagesPreview.length > 0 ? (
                  <>
                  
                  <div className="Recperpic">
                    {imageFiles?.map((file, index) => (
                      <div className="RecinerPic" key={index} >
                        <div className={style.indexLabel} style={{display: "none"}}>{index + 1}</div>
                        <img src={imagesPreview[index]}onClick={() => handleImageSelection(index)}alt={file.name}/>
                      </div>
                    ))}
                  </div>
                  </>
                  
                  ) : (
                    <p>Please upload an Image first.</p>
                  )}

                  {selectedImage && (
                    <div className="CrossHairDiv">
                      <Annotation
                        src={selectedImage}
                        alt="Annotate image"
                        annotations={annotations.filter(
                          (anno) => anno.data.imageId === selectedImage
                        )}
                        value={annotation}
                        type={annotation.type}
                        className="h-[300px]"
                        onChange={onChange}
                        onSubmit={onSubmit}
                        allowTouch
                        renderEditor={renderEditor}
                        renderSelector={renderSelector}
                        renderHighlight={renderHighlight}
                        renderContent={renderContent}
                      />
                    </div>
                  )}
                </div>
                
              
              </div>

              <div mt={4} mb={1} style={{display: "flex",justifyContent: "center",padding: "20px 0px",}}>
                <Button type="submit" class="myButton" data-label="Save"></Button>
              </div>
            </form>
          </div>
      </section>
      <ToastContainer />
    </DashboardLayout>
  );
}
