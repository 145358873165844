import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

// react-router-dom components
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from 'moment';

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import user from "assets/images/user.png"


export default function ViewAsset() {
    let { id } = useParams();
    const [guideline, setGuideline] = useState(null);
    useEffect(() => {
        // Replace with your actual API endpoint to fetch the guideline data
        axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getSingleGuideline`,{id})
          .then((response) => {
            console.log("response.data.guidelineresponse.data.guideline",response.data.guideline);
            setGuideline(response.data.guideline);
          })
          .catch((error) => {
            console.error('Error fetching guideline:', error);
          });
      }, []);
    
      if (!guideline) {
        return <div>Loading guideline...</div>;
      }

      const renderDescriptionBlocks = () => {
        if (guideline.description && guideline.description.blocks) {
          return guideline.description.blocks.map((block) => {
            switch (block.type) {
              case "header":
                return (
                  <h2 key={block.id} style={{ fontSize: `${block.data.level}em` }}>
                    {block.data.text}
                  </h2>
                );
      
              case "paragraph":
                return (
                  <p key={block.id} dangerouslySetInnerHTML={{ __html: block.data.text }}></p>
                );
      
              case "image":
                return (
                  <img key={block.id} src={block.data.file.url} alt={block.data.caption} style={{ maxWidth: '100%' }} />
                );
      
              case "list":
                if (block.data.style === "ordered") {
                  return (
                    <ol key={block.id}>
                      {block.data.items.map((item, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                      ))}
                    </ol>
                  );
                } else {
                  return (
                    <ul key={block.id}>
                      {block.data.items.map((item, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                      ))}
                    </ul>
                  );
                }
      
              case "quote":
                return (
                  <blockquote key={block.id}>
                    <p dangerouslySetInnerHTML={{ __html: block.data.text }}></p>
                    <cite>{block.data.caption}</cite>
                  </blockquote>
                );
      
              case "code":
                return (
                  <pre key={block.id}>
                    <code>{block.data.code}</code>
                  </pre>
                );
      
              case "table":
                return (
                  <table key={block.id} style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        {block.data.content[0].map((header, index) => (
                          <th key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {block.data.content.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex} style={{ border: '1px solid #ddd', padding: '8px' }}>
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
      
              default:
                return null; // Handle unknown block types
            }
          });
        }
        return <p>No description available.</p>;
      };
      
      
    
      return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <MDTypography variant="h1">{guideline.title}</MDTypography>
                        <img src={`${process.env.REACT_APP_BASE_URL}${guideline.guideicon}`} alt={guideline.title} style={{ width: '150px' }} />
                        <MDTypography variant="body1"><strong>Title Description:</strong> {guideline.titleDescription}</MDTypography>
                        <MDTypography variant="body1"><strong>Description:</strong></MDTypography>
                        {renderDescriptionBlocks()}
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}



