import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";

import {
  TextField,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



export default function AddGuideline() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!formData.name) {
      toast.error("Name is required");
      return;
    }

    console.log("formData", formData);
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);

    console.log("formDataToSend", formDataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/admin/addkeyword`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );
      if (response.data.status == 1) {
        swal("Success!", "Data saved successfully", "success");
        navigate("/search-keyword");
      }
      else {
        swal("Error!", response.data.message, "error");
      }

    } catch (error) {
      console.error("Error saving data:", error); // Log error for debugging
      swal("Error!", "Failed to save data", "error");
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <section className="FilterSec">
        <div className="container">
          <div className="Add_Guide_Sec">
            <div className="FilterHead">
              <h2>Add Keyword</h2>
            </div>
            <div className="guidLink mt-5" >
            <Link to={`/search-keyword`}><ArrowBackIcon/> Back</Link>
          </div>

            <div className="GuidlineForm">
              <form onSubmit={handleSubmit}>




                <div className="ThreeFormDetail">



                  <div className="Fomdetail">
                    <div className="TopForm">
                      <div className="guidFormControl">
                        <h6> <strong>Keyword Name</strong></h6>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Enter Name"
                          value={formData.name} // Update the state value for title
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          } // Update the title in formData
                        />
                      </div>
                    </div>
                  </div>



                </div>

                <div mt={4} mb={1} style={{ display: "flex", justifyContent: "center", padding: "20px 0px", }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ color: "#fff" }}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
}
