import Paragraph from "@editorjs/paragraph";
import './CheckboxTool.css';

class CheckboxTool extends Paragraph {
    static get toolbox() {
        return {
            title: 'Checkbox',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 3H3c-1.1 0-1.99.9-1.99 2L1 19c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM3 1h18c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0 .1.9 2 2 2zm9 6h6v2h-6v-2zm-8 2h6v2H4v-2zm0 4h12v2H4v-2zm0 4h12v2H4v-2z"/></svg>'
        };
    }

    static get conversion() {
        return {
            export: (data) => ({
                text: data.text || "",
                icon: data.icon || "",
                iconName: data.iconName || ""
            }),
            import: (data) => ({
                text: data.text,
                icon: data.icon,
                iconName: data.iconName
            })
        };
    }

    constructor({ data, api, config }) {
        super({ data, api, config });

        this.api = api;

        this.icons = [
            { name: "Correct 1", url: "/editorImages/checkbox.svg" },
            { name: "Correct 2", url: "/editorImages/correct.png" },
            { name: "Wrong", url: "/editorImages/wrong.png" },
            { name: "Warning", url: "/editorImages/warning.png" },
            { name: "Idea", url: "/editorImages/idea.png" },
        ];

        this.data = {
            text: data?.text || "",
            icon: data?.icon || this.icons[0].url,
            iconName: data?.iconName || this.icons[0].name
        };

        this.wrapper = null;
    }

    render() {
        this.wrapper = document.createElement("div");
        this.wrapper.classList.add("icon-with-text");

        this.wrapper.innerHTML = `
          <div class="icon-text-wrapper">
            <img src="${this.data.icon}" class="icon-preview" style="width: 24px; height: 24px; margin-left: 8px;" />
            <div class="editable-text" contenteditable="true" placeholder="Enter text...">
              ${this.data.text}
            </div>
          </div>
        `;

        this.textInput = this.wrapper.querySelector('.editable-text');
        this.iconPreview = this.wrapper.querySelector('.icon-preview');

        this.textInput.addEventListener('input', () => {
            this.data.text = this.textInput.innerHTML.trim();
        });

        return this.wrapper;
    }

    renderSettings() {
        const wrapper = document.createElement("div");
        wrapper.className = "ce-settings";

        const dropdownHTML = `
          <div class="ChooseIconSelect">
            <label>Choose Icon :- </label>
            <div class="">
                <select class="icon-dropdown">
                ${this.icons.map(icon => `
                    <option value="${icon.url}" ${icon.url === this.data.icon ? "selected" : ""}>
                    ${icon.name}
                    </option>
                `).join("")}
                </select>
                <img src="${this.data.icon}" class="icon-preview-settings" style="width: 18px; height: 18px; margin-left: 8px;" />
            </div>
          </div>
        `;

        wrapper.innerHTML = dropdownHTML;

        const iconDropdown = wrapper.querySelector('.icon-dropdown');
        const iconPreviewSettings = wrapper.querySelector('.icon-preview-settings');

        iconDropdown.addEventListener('change', () => {
            const selectedIcon = this.icons.find(icon => icon.url === iconDropdown.value);
            this.data.icon = selectedIcon.url;
            this.data.iconName = selectedIcon.name;
            iconPreviewSettings.src = this.data.icon;
            this.iconPreview.src = this.data.icon;
        });

        return wrapper;
    }

    save() {
        return {
            text: this.textInput?.innerHTML.trim() || "",
            icon: this.data.icon,
            iconName: this.data.iconName
        };
    }

    validate(savedData) {
        return savedData?.text?.trim().length > 0;
    }
}

export default CheckboxTool;
